import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import { useNotificationsList, useManageNotificationsVisibility } from '../queries';

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '-4px -16px',
  paddingBottom: 32,
});

const StyledButton = styled.button({
  padding: 8,
  outline: 'none',
  border: 'none',
  background: 'none',
});

export const Header = () => {
  const navigate = useNavigate();
  const { mutate: manageVisibility, isLoading } = useManageNotificationsVisibility();
  const { data } = useNotificationsList();

  const onClearAllClick = () => {
    manageVisibility({ state: 'hidden' });
  };

  const onCrossClick = () => {
    manageVisibility({ state: 'seen' });
    navigate(-1);
  };

  return (
    <Container>
      <StyledButton onClick={onClearAllClick} disabled={!data?.length || isLoading}>
        <Text type="p_s" weight={500}>
          Clear all
        </Text>
      </StyledButton>
      <Text type="h3" weight={600}>
        Notifications
      </Text>
      <StyledButton onClick={onCrossClick}>
        <Icon name="crossBigger" />
      </StyledButton>
    </Container>
  );
};
