import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon } from '@/components/Icon/Icon';
import { useOutsideClick } from '@/utils/hooks/useOutsideClick';

import { resetState } from '../slice';
import { getVoucherPreviewImage, getVoucherPreviewTitle, isVoucherPreviewVisible } from '../selectors';
import hideable from '@/utils/hideable';
import { Text } from '@/components/Text';
import { useAuth } from '@/features/Auth/hooks';

const Overlay = styled.div({
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: 'rgba(37, 15, 54, 0.9)',
  backdropFilter: 'blur(4px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Divider = styled.hr(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.purple.primary['300']}`,
  borderTop: 'none',
  width: '100%',
  margin: '24px 0',
}));

const Container = styled.div({
  maxWidth: 300,
  position: 'relative',
});

const CloseBtn = styled.button(({ theme }) => ({
  boxShadow: theme.boxShadow.primary,
  borderRadius: 8,
  backgroundColor: theme.colors.purple.primary['600'],
  color: theme.colors.purple.primary['50'],
  padding: '10px 20px',
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 15px auto',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '14px',
  position: 'absolute',
  right: 0,
  top: -72,
}));

const Image = styled.img({
  borderRadius: 8,
  width: '100%',
  maxWidth: 180,
  margin: '0 auto 25px',
});

const StyledIcon = styled(Icon)({
  marginRight: 8,
});

const Description = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['300'],
}));

const EmailText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const VoucherPreviewContainer = hideable(() => {
  const image = useSelector(getVoucherPreviewImage);
  const title = useSelector(getVoucherPreviewTitle);

  const { userEmail } = useAuth();

  const dispatch = useDispatch();
  const ref = useRef(null);

  const handleClose = () => {
    dispatch(resetState());
  };

  useOutsideClick(ref, handleClose);

  return (
    <Overlay>
      <Container ref={ref}>
        <CloseBtn onClick={handleClose}>
          <StyledIcon name="cross" stroke="#F1E7FA" />
          Close
        </CloseBtn>
        <Image src={image} alt="" />
        <>
          <Text align="center" type="p_l" weight={600}>
            {title}
          </Text>
          <Divider />
          <Description>
            If you win, Gift cards will be sent directly to your email at <EmailText>{userEmail}</EmailText> within 24
            hours.
          </Description>
        </>
      </Container>
    </Overlay>
  );
});

export const VoucherPreview = () => {
  const isVisible = useSelector(isVoucherPreviewVisible);

  return <VoucherPreviewContainer isVisible={isVisible} />;
};
