import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useQuery } from 'react-query';

import { firebaseApp, httpsFunction } from '@/utils/firebase';

import { GiftCards, TShopItemType } from './types';

interface IUseGiftCardsByTypeAndCountry {
  type: TShopItemType;
  countryCode?: string;
}

interface IUseGetGiftCardById {
  id?: string;
}

export const useGetGiftCardById = ({ id }: IUseGetGiftCardById) =>
  useQuery({
    queryKey: ['useGetGiftCardById', id],
    enabled: !!id,
    queryFn: async () => {
      const giftCardData = (await getDoc(doc(getFirestore(firebaseApp), `giftCards/${id}`)))?.data();

      return {
        id,
        payoutAmountLocalCurrency: giftCardData?.payoutAmountLocalCurrency as string | undefined,
        creditAmount: giftCardData?.creditAmount as number | undefined,
        currencyCode: giftCardData?.currencyCode as string | undefined,
        creditPriceLabel:
          giftCardData?.creditAmount && giftCardData.creditAmount > 1000 && giftCardData.creditAmount % 1000 === 0
            ? `${giftCardData?.creditAmount / 1000}k`
            : `${giftCardData?.creditAmount}`,
      };
    },
  });

export const useGiftCardsByTypeAndCountry = ({ type }: IUseGiftCardsByTypeAndCountry) =>
  useQuery({
    queryKey: ['useGiftCardsByTypeAndCountry', type],
    enabled: !!type,
    queryFn: async () => {
      const getGiftCards = httpsFunction('getGiftCards');
      const giftCards = await getGiftCards({ type });
      return giftCards.data as GiftCards;
    },
  });

export const useGiftCardsByType = (type: TShopItemType) => {
  return useGiftCardsByTypeAndCountry({ type });
};
