import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import OpenChestVisual from '@/assets/visuals/chest_open.svg';
import { Text } from '@/components/Text';
import { Button } from '@/components/Button/Button';

import { useSetCampaignRemoved } from '../queries';

const InfoBoxContainer = styled.div(({ theme }) => ({
  padding: '32px 16px',
  backgroundColor: theme.colors.darkPurple,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexDirection: 'column',
  whiteSpace: 'pre-wrap',
  borderRadius: 16,
  boxShadow: '2px 2px 0px 0px #000000',
}));

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 24,
});

const EndedCampaignVisual = styled.img({
  width: '100%',
  maxHeight: 100,
  margin: '0 auto 16px',
});

const Paragraph = styled(Text)({
  margin: '4px 0 24px 0',
});

const StyledButton = styled(Button)({
  height: 40,
});

export const GameCampaignEnded = ({
  slug,
  campaignId,
  isDisabled = false,
  className,
}: {
  slug?: string;
  campaignId?: string;
  isDisabled?: boolean;
  className?: string;
}) => {
  const navigate = useNavigate();

  const { mutate: removeCampaign, isLoading } = useSetCampaignRemoved({
    onSettled: () => {
      navigate(-1);
    },
  });

  const onClick = () => {
    removeCampaign({ slug, campaignId });
  };

  return (
    <Container className={className}>
      <InfoBoxContainer>
        <EndedCampaignVisual src={OpenChestVisual} alt="failed-activation" />
        <Text align="center" type="h3" weight={600}>
          Game campaign ended
        </Text>
        <Paragraph type="p_s" weight={400} align="center" colorType="extraLight">
          We will let you know once it renews, goodluck next time!
        </Paragraph>
        <StyledButton disabled={isLoading || isDisabled} onClick={onClick}>
          Remove from games list
        </StyledButton>
      </InfoBoxContainer>
    </Container>
  );
};
