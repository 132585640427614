import styled from 'styled-components';

import { Text } from './Text';
import hideable from '@/utils/hideable';

interface ITicketsProgress {
  currentTicketsAmount: number;
  maxTicketsAmount: number;
}

const ProgressBadge = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.green.primary['500'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 26,
  width: 100,
  position: 'absolute',
  borderTopLeftRadius: 8,
  borderBottomRightRadius: 8,
  left: 0,
  top: 0,
  boxShadow: `2px 2px 0px 0px ${theme.colors.purple.primary['800']}`,
}));

const ProgressBarContainer = styled.div(({ theme }) => ({
  height: 8,
  width: 40,
  borderRadius: 4,
  border: `1px solid ${theme.colors.purple.primary['500']}`,
  backgroundColor: theme.colors.purple.primary['50'],
  display: 'flex',
  justifyContent: 'flex-start',
}));

const ProgressBar = styled.div<{ width: number }>(({ theme, width }) => ({
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  backgroundColor: theme.colors.purple.primary['500'],
  width: `${width}%`,
  height: '100%',
}));

const ProgressText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['700'],
  marginLeft: 6,
}));

const TicketsProgressIndicatorComponents = ({ currentTicketsAmount, maxTicketsAmount }: ITicketsProgress) => {
  const percentage = Math.floor((currentTicketsAmount * 100) / maxTicketsAmount);

  return (
    <ProgressBadge>
      <ProgressBarContainer>
        <ProgressBar width={percentage} />
      </ProgressBarContainer>
      <ProgressText type="p_s" weight={600}>
        {percentage}%
      </ProgressText>
    </ProgressBadge>
  );
};

export const TicketsProgressIndicator = hideable(TicketsProgressIndicatorComponents);
