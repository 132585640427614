import { useState } from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ContestPlaceholder from '@/assets/visuals/contest_placeholder.png';
import { LoaderIcon } from '@/components/SVGs/LoaderIcon';

const ImgContainer = styled(LazyLoadImage)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transform: 'scale(1.02)',
});

const StyledLoader = styled(LoaderIcon)({
  position: 'absolute',
  top: -20,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  margin: 'auto',
  opacity: 0.5,
});

export const ContestThumbnail = ({ image }: { image: string }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <ImgContainer
        src={image}
        onLoad={() => {
          setIsLoaded(true);
        }}
      />

      {!isLoaded && (
        <>
          <StyledLoader />
          <ImgContainer src={ContestPlaceholder} />
        </>
      )}
    </>
  );
};
