import { Timestamp } from 'firebase/firestore';

export const timeAgo = (timestamp: Timestamp) => {
  const now = new Date();
  const seconds = Math.floor((now.valueOf() - timestamp.toDate().valueOf()) / 1000);

  if (seconds < 60) {
    return `${seconds}s`;
  } else if (seconds < 3600) {
    return `${Math.floor(seconds / 60)}m`;
  } else if (seconds < 86400) {
    return `${Math.floor(seconds / 3600)}h`;
  } else {
    return timestamp.toDate().toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
};
