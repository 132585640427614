import styled from 'styled-components';
import { FaqToggle } from '@/components/FaqToggle';
import { Text } from '@/components/Text';
import { LINKS } from '@/utils/constants/common';
import { useOpenLink } from '@/utils/hooks/useOpenLink';

const Container = styled.div({
  marginTop: 48,
});

const LightText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
}));

const MarkedText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const Link = styled(MarkedText)({
  textDecoration: 'underline',
  cursor: 'pointer',
});

const FirstFaq = styled(FaqToggle)({
  borderTop: 'none',
});

export const FaqSection = () => {
  const openLink = useOpenLink();

  const onHelpClick = () => {
    openLink(LINKS.CHATBASE_BOT);
  };

  return (
    <Container>
      <FirstFaq title="Completed challenge. Missing credits?">
        <LightText>
          Progress sync takes up to 48 hours, upon completions of the challenge conditions.
          <br />
          <br />
          Missing credits? <Link onClick={onHelpClick}>Reach out to our support</Link>
        </LightText>
      </FirstFaq>
      <FaqToggle title="Why game activation is taking so long?">
        <LightText>
          Activating partner games to link accounts, can take up to 30 minutes. Also, partner games have specific
          requirements for sharing data and rewards with BlockGames. To ensure successful activation, please consider
          the following:
          <br />
          <br />
          <MarkedText>New Users Only:</MarkedText> Activation is available exclusively for new users.
          <br />
          <br />
          <MarkedText>New mobile devices:</MarkedText> You haven’t played this game ever on your phone, or had it
          previously installed.
          <br />
          <br />
          <MarkedText>Disabled VPN:</MarkedText> Ensure that your VPN is turned off during the activation process.
          <br />
          <br />
          <MarkedText>Keep same IP:</MarkedText> Use same IP address, that was used at the moment of your sign up. If it
          doesn’t match activating a game - won’t work.
          <br />
          <br />
          <MarkedText>Mobile Device Requirement:</MarkedText> The game must be activated on a mobile device. Emulators
          are not supported.
        </LightText>
      </FaqToggle>
      <FaqToggle title="How to activate the game?">
        <LightText>
          Tap on <MarkedText>'Activate'</MarkedText> and download the game from the App Store or Play Store.
          <br />
          <br />
          Make sure, that you install the game, open it and sign up (if required) with a new gaming account, using the
          same email address as used for BlockGames.
          <br />
          <br />
          Activation may take up to 30min, until your account data is linked from the game partner to BlockGames.
        </LightText>
      </FaqToggle>
      <FaqToggle title="How to complete challenges?">
        <LightText>
          When the game is activated (green state), you can enter the game and start playing! Once you fulfill the
          challenge conditions, you will be able to claim your rewards. Please note that progress sync may take up to 48
          hours.
        </LightText>
      </FaqToggle>
    </Container>
  );
};
