import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@/features/Auth/hooks';
import { Header } from '@/features/Header/components/Header';

import { rootAuthenticatedRoute, routesWithoutHeaderAndFooter } from './constants';

export const EmailVerificationRoute = () => {
  const { isAuthenticated, isAuthenticatedStack, isOnboardingStack, isNicknameStack, isActivationStack } = useAuth();
  const location = useLocation();
  const isHeaderAndFooterVisible = !routesWithoutHeaderAndFooter.includes(location.pathname);

  if (!isAuthenticated) {
    return <Navigate to="/login/method" state={{ from: location }} replace />;
  }

  if (isActivationStack) {
    return <Navigate to="/sign-up-referral" state={{ from: location }} replace />;
  }

  if (isOnboardingStack) {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  if (isNicknameStack) {
    return <Navigate to="/nickname" state={{ from: location }} replace />;
  }

  if (isAuthenticatedStack) {
    return <Navigate to={rootAuthenticatedRoute} state={{ from: location }} replace />;
  }

  return (
    <>
      <Header isVisible={isHeaderAndFooterVisible} />
      <Outlet />
    </>
  );
};
