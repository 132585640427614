import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import styled, { useTheme } from 'styled-components';
import { Text } from '@/components/Text';
import * as Yup from 'yup';

import { Button } from '@/components/Button/Button';
import { FormInput } from '@/components/Input/FormInput';
import { ErrorMessage as CustomErrorMessage } from '@/components/ErrorMessage/ErrorMessage';
import { Link } from '@/components/Link/Link';
import { Icon } from '@/components/Icon/Icon';

const SignInFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export interface IEmailSignFormValues {
  email: string;
  password: string;
}

export interface IEmailSignForm {
  onSubmit(values: IEmailSignFormValues, formikHelpers: FormikHelpers<IEmailSignFormValues>): void;
  submitText: string;
}

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const StyledLink = styled(Link)({
  marginTop: 8,
  marginBottom: 24,
});

export const EmailSignForm = ({ onSubmit, submitText }: IEmailSignForm) => {
  const theme = useTheme();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={SignInFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => {
        return (
          <StyledForm>
            <FormInput
              label="Email"
              name="email"
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              suffix={
                dirty &&
                (errors.email ? (
                  <Icon name="cross" stroke={theme.colors.state.error} />
                ) : (
                  <Icon name="successTick" stroke={theme.colors.purple.primary['100']} />
                ))
              }
            />

            <ErrorMessage
              name="email"
              render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
            />
            <FormInput
              label="Password"
              name="password"
              type="password"
              className="mt-3"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />

            <ErrorMessage
              name="password"
              render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
            />
            <StyledLink to="/reset-password">
              <Text type="p_m" weight={600} align="center">
                Reset password?
              </Text>
            </StyledLink>
            <Button type="submit" disabled={isSubmitting}>
              {submitText}
            </Button>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
