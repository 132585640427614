import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@/features/Auth/hooks';
import { rootAuthenticatedRoute } from './constants';

export const OnboardingRoute = () => {
  const { isAuthenticated, isActivationStack, isAuthenticatedStack, isNicknameStack, isEmailVerificationStack } =
    useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login/method" state={{ from: location }} replace />;
  }

  if (isAuthenticatedStack) {
    return <Navigate to={rootAuthenticatedRoute} state={{ from: location }} replace />;
  }

  if (isNicknameStack) {
    return <Navigate to="/nickname" state={{ from: location }} replace />;
  }

  if (isEmailVerificationStack) {
    return <Navigate to="/email-verification" state={{ from: location }} replace />;
  }

  if (isActivationStack) {
    return <Navigate to="/sign-up-referral" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
