import styled from 'styled-components';
import progress0 from '@/assets/images/get-started-0.svg';
import progress1 from '@/assets/images/get-started-50.svg';
import progress2 from '@/assets/images/get-started-100.svg';

import { Text } from '@/components/Text';
import { BottomModal } from '@/components/BottomModal';
import { Icon } from '@/components/Icon/Icon';

import { TProgress } from '../queries';
import { PROFILE_COMPLETION_REWARD } from '../constants';

interface ICompleteProfileModal {
  isVisible: boolean;
  isActivateGameCompleted: boolean;
  isChallengeCompleted: boolean;
  progress: TProgress;
  onActivateGameClick: () => void;
  onCompleteChallengeClick: () => void;
  onClose: () => void;
}

interface IPointContainer {
  isLight?: boolean;
  onClick: () => void;
  hasBottomBorder?: boolean;
}

const PROGRESS_IMG_MAP = {
  0: progress0,
  1: progress1,
  2: progress2,
};

const Container = styled.div({
  padding: '48px 0',
});

const TextContainer = styled.div({
  padding: '0 24px 32px 24px',
});

const Flexbox = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const PointContainer = styled(Flexbox)<IPointContainer>(({ theme, isLight = false, hasBottomBorder = false }) => ({
  backgroundColor: isLight ? theme.colors.darkPurple : 'transparent',
  borderTop: `1px dashed ${theme.colors.purple.primary['600']}`,
  borderBottom: hasBottomBorder ? `1px solid ${theme.colors.purple.primary['600']}` : 'none',
  padding: '16px 24px',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

const StyledText = styled(Text)({
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
});

const CreditsIcon = styled(Icon)({
  marginLeft: 4,
});

const NotCompletedIcon = styled.div(({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: 9,
  border: `1px solid ${theme.colors.purple.primary['300']}`,
  marginRight: 16,
}));

const StyledIcon = styled(Icon)({
  marginRight: 16,
});

const StyledImage = styled.img({
  width: 40,
  height: 40,
  marginRight: 8,
});

export const CompleteProfileModal = ({
  isVisible,
  isActivateGameCompleted,
  isChallengeCompleted,
  progress,
  onActivateGameClick,
  onCompleteChallengeClick,
  onClose,
}: ICompleteProfileModal) => (
  <BottomModal type="secondary" isVisible={isVisible} onClose={onClose}>
    <Container>
      <TextContainer>
        <Flexbox>
          <StyledImage src={PROGRESS_IMG_MAP[progress]} />
          <Text type="p_l" weight={600} color="light">
            Get started!
          </Text>
        </Flexbox>
        <StyledText colorType="extraLight">
          Earn <CreditsIcon name="creditSmall" />
          {PROFILE_COMPLETION_REWARD} credits by completing these tasks:
        </StyledText>
      </TextContainer>
      <PointContainer isLight onClick={onActivateGameClick}>
        <Flexbox>
          {isActivateGameCompleted && <StyledIcon name="tickGreen" />}
          {!isActivateGameCompleted && <NotCompletedIcon />}
          <Text weight={600} colorType="light">
            Activate one game
          </Text>
        </Flexbox>
        {!isActivateGameCompleted && <Icon name="chevronRight" />}
      </PointContainer>
      <PointContainer onClick={onCompleteChallengeClick}>
        <Flexbox>
          {isChallengeCompleted && <StyledIcon name="tickGreen" />}
          {!isChallengeCompleted && <NotCompletedIcon />}
          <Text weight={600} colorType="light">
            Complete one challenge
          </Text>
        </Flexbox>
        {!isChallengeCompleted && <Icon name="chevronRight" />}
      </PointContainer>
    </Container>
  </BottomModal>
);
