import { useQuery } from 'react-query';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { formatDistanceToNowStrict, isPast } from 'date-fns';
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';

import { firebaseApp } from '@/utils/firebase';
import { getUserLocation, useAuth } from '@/features/Auth/hooks';
import { getPlatform } from '@/utils/getPlatform';
import { splitArrayChunks } from '@/utils/helpers/splitArrayChunks';

import { resolvePlatformGameFieldName } from './utils';
import { useFilteredGamesByCompletionStatus, useRemovedGames } from '../GamesIOS/queries';
import { GAME_GENRES, ALL_GAMES } from './constants';
import { getSelectedGenre } from './selectors';
import { CampaignStatus, ICampaign } from './types';
import { IGame } from '../Game/types';
import { useUserDataOnActivatedCampaign } from '../Game/queries';

const BLOCKGAMES_CAMPAIGNS_THRESHOLD = 1700;

const getLatestCampaign = (campaigns: ICampaign[]) => {
  const campaignIds = campaigns.map((campaign) => parseInt(campaign.id.match(/\d+$/)?.[0] as string));
  campaignIds.sort((a, b) => {
    if (a > BLOCKGAMES_CAMPAIGNS_THRESHOLD) {
      return 1;
    } else if (b > BLOCKGAMES_CAMPAIGNS_THRESHOLD) {
      return -1;
    }
    return b - a;
  });

  return campaigns.find((campaign) => parseInt(campaign.id.match(/\d+$/)?.[0] as string) == campaignIds[0]);
};

function mergeCampaignArrays(array1: ICampaign[], array2: ICampaign[]) {
  const combinedArray = [...array1, ...array2];
  const uniqueCampaigns = new Map();

  combinedArray.forEach((campaign) => {
    uniqueCampaigns.set(campaign.id, campaign);
  });

  return Array.from(uniqueCampaigns.values());
}

export const useUserActivatedCampaignsIds = () => {
  const { uid } = useAuth();
  return useQuery({
    queryKey: ['useUserActivatedCampaigns', uid],
    enabled: !!uid,
    queryFn: async () => {
      const userActivatedGamesSnapshot = (
        await getDocs(query(collection(getFirestore(firebaseApp), `users/${uid}/settings/activated-games/list`)))
      ).docs;

      const userActivatedGames: string[] = userActivatedGamesSnapshot
        .map((uag) => {
          return uag.data().campaigns[0].status == 'ACTIVATED' ? uag.data().campaigns[0].id : null;
        })
        .filter((campaign) => campaign !== null);

      return userActivatedGames;
    },
  });
};

export const useCampaigns = (userCountry: string | undefined, platform?: string) => {
  const { data: userActivatedCampaignsIds } = useUserActivatedCampaignsIds();
  return useQuery({
    queryKey: ['useCampaigns', userCountry, platform],
    enabled: !!userCountry && !!platform,
    queryFn: async () => {
      const userActivatedCampaignsSnapshot = await Promise.all(
        (userActivatedCampaignsIds || []).map((id) => getDoc(doc(getFirestore(firebaseApp), 'campaigns', id)))
      );

      const userActivatedCampaigns = userActivatedCampaignsSnapshot.map(
        (campaignDoc) =>
          ({
            id: campaignDoc.id,
            ...campaignDoc.data(),
          } as ICampaign)
      );

      const campaignDocs = (
        await getDocs(
          query(
            collection(getFirestore(firebaseApp), `campaigns`),
            where('availableCountries', 'array-contains', userCountry),
            where('platform', '==', platform),
            where('isDisabled', '==', false),
            where('isPaused', '==', false)
          )
        )
      ).docs;

      if (campaignDocs?.length === 0 && userActivatedCampaigns?.length === 0) {
        return [];
      }

      const campaigns = campaignDocs.map(
        (campaignDoc) =>
          ({
            id: campaignDoc.id,
            ...campaignDoc.data(),
          } as ICampaign)
      );

      const mergedCampaigns = mergeCampaignArrays(userActivatedCampaigns, campaigns);

      const groupedByGameId: { [key: string]: ICampaign[] } = {};
      mergedCampaigns.forEach((campaign) => {
        const gameId = campaign.androidStoreID || campaign.iosStoreID;
        if (gameId) {
          if (!groupedByGameId[gameId]) {
            groupedByGameId[gameId] = [];
          }
          groupedByGameId[gameId].push(campaign);
        }
      });

      const formedCampaigns = Object.values(groupedByGameId).map((gameCampaigns) => {
        if (gameCampaigns.length > 1) {
          const activatedCampaign = gameCampaigns.filter((campaign) => {
            return userActivatedCampaignsIds?.includes(campaign.id);
          });

          return activatedCampaign.length ? activatedCampaign[0] : getLatestCampaign(gameCampaigns);
        } else {
          return getLatestCampaign(gameCampaigns);
        }
      });

      return formedCampaigns as ICampaign[];
    },
  });
};

export const useCampaign = (campaignId: string | undefined, userCountry: string | undefined, platform?: string) => {
  const { data: campaigns } = useCampaigns(userCountry, platform);
  return useQuery({
    queryKey: ['useCampaign', campaignId, userCountry, platform, campaigns],
    enabled: !!campaignId && !!userCountry && !!platform,
    queryFn: () => campaigns?.find(({ id }) => id === campaignId),
  });
};

export const useGames = (userId?: string, userCountry?: string, platform?: string) => {
  const { data: campaigns } = useCampaigns(userCountry, platform);

  return useQuery({
    queryKey: ['useGames'],
    enabled: !!userId && !!userCountry && !!platform && !!campaigns,
    queryFn: async () => {
      const platformFieldName = resolvePlatformGameFieldName(platform);

      if (!platformFieldName) {
        return [];
      }

      if (!campaigns?.length) {
        return [];
      }

      const chunkedCampaigns = [...splitArrayChunks(campaigns, 30)];

      const chunkedGamesData = await Promise.all(
        chunkedCampaigns.map(async (campaignArr: ICampaign[]) => {
          const availableGameStoreIDs = campaignArr?.map((campaign) => campaign[platformFieldName]) || [];

          const availableGameDocs = (
            await getDocs(
              query(
                collection(getFirestore(firebaseApp), `games`),
                where(platformFieldName, 'in', availableGameStoreIDs)
              )
            )
          ).docs;

          return availableGameDocs.map((gameDoc) => ({ id: gameDoc.id, ...gameDoc.data() } as IGame));
        })
      );

      const flattenGameData = chunkedGamesData
        .flat()
        .sort((a, b) => (a?.sortOrder || Number.POSITIVE_INFINITY) - (b?.sortOrder || Number.POSITIVE_INFINITY));

      const availableGameTitles = flattenGameData.map(({ title }) => title);
      const availableGameCount = flattenGameData.length;

      mixpanel.people.set({
        availableGames: availableGameTitles,
        availableGameCount,
      });

      mixpanel.track('available-games', { availableGames: availableGameTitles, availableGameCount });

      return flattenGameData;
    },
  });
};

export const getTimeLeftOnUserActivatedCampaign = (slug?: string) => {
  const { uid } = useAuth();
  const { userCountry } = getUserLocation();
  const platform = useSelector(getPlatform);
  const { data: activatedCampaign } = useUserDataOnActivatedCampaign(slug, uid);
  const { data: campaign } = useCampaign(activatedCampaign?.id, userCountry, platform);

  if (activatedCampaign?.status !== CampaignStatus.ACTIVATED) {
    return undefined;
  }

  if (!activatedCampaign?.activationTime) {
    return undefined;
  }

  if (!campaign) {
    return undefined;
  }

  const activationTimeInMillis = activatedCampaign?.activationTime.toMillis();

  // Attribution window is in seconds, multiply by 1000 to get millis
  const campaignDuration = campaign?.attributionWindow * 1000;
  const campaignEndDate = new Date(activationTimeInMillis + campaignDuration);

  if (isPast(campaignEndDate)) {
    return undefined;
  }

  const timeLeftOnActiveCampaign = formatDistanceToNowStrict(new Date(activationTimeInMillis + campaignDuration), {
    addSuffix: false,
  });

  return `${timeLeftOnActiveCampaign} left`;
};

export const getSuggestedGames = () => {
  const { uid } = useAuth();
  const { userCountry } = getUserLocation();
  const platform = useSelector(getPlatform);
  const selectedGenre = useSelector(getSelectedGenre);

  const { data: removedGames, isLoading: isRemovedGamesLoading } = useRemovedGames(uid, userCountry, platform);
  const { data: games, isLoading: isGamesLoading } = useGames(uid, userCountry, platform);
  const { data: inProgressAndCompletedGames, isLoading: isInProgressOrCompletedGamesLoading } =
    useFilteredGamesByCompletionStatus(uid, userCountry, platform);

  const completedGames = inProgressAndCompletedGames?.completedGames || [];
  const campaignEndedGames = inProgressAndCompletedGames?.campaignEndedGames || [];

  // valid games - not completed / not failed activation / not expired campaigns
  const availableGames = games
    ?.filter((game) => !completedGames.some((completedGame) => completedGame.id === game.id))
    ?.filter((game) => !campaignEndedGames.some((completedGame) => completedGame.id === game.id))
    ?.filter((game) => !removedGames?.some((removedGame) => removedGame.id === game.id));

  const gamesBySelectedGenre =
    selectedGenre === ALL_GAMES ? availableGames : availableGames?.filter((game) => game.genre.includes(selectedGenre));

  const hotPick = availableGames?.filter((game) => game.hotPick);
  const comingSoonGames = availableGames?.filter((game) => game.comingSoon);
  const genres = GAME_GENRES.filter((genre) => availableGames?.some((game) => game.genre.includes(genre)));

  return {
    games,
    gamesBySelectedGenre,
    hotPick,
    isLoading: isGamesLoading || isInProgressOrCompletedGamesLoading || isRemovedGamesLoading,
    comingSoonGames,
    completedGames,
    genres: !!genres.length ? [ALL_GAMES, ...genres] : [],
  };
};

export const useGetCampaignByStoreId = () => {
  const { userCountry } = getUserLocation();
  const platform = useSelector(getPlatform);
  const { data: campaigns } = useCampaigns(userCountry, platform);

  return (storeId: string): ICampaign | undefined => {
    if (!storeId) {
      return undefined;
    }

    const platformFieldName = resolvePlatformGameFieldName(platform);

    if (!platformFieldName) {
      return undefined;
    }

    return campaigns?.find((campaign) => campaign[platformFieldName] === storeId);
  };
};
