import { useMutation } from 'react-query';

import { httpsFunction } from '@/utils/firebase';
import { TPlatform, getPlatform } from '@/utils/getPlatform';

interface InitialAuthTokenResponse {
  activationToken: string;
}

interface ICreateInitialAuthTokenRequestData {
  userPlatform: TPlatform;
}

export const handleOpenNativeBrowser = () => {
  const userPlatform = getPlatform();
  const initialAuthTokenFunction = httpsFunction<ICreateInitialAuthTokenRequestData, InitialAuthTokenResponse>(
    'createInitialAuthToken'
  );

  return useMutation(
    async () => {
      const response = await initialAuthTokenFunction({
        userPlatform,
      });

      return response.data;
    },
    {
      onSuccess: (data) => {
        window.location.href = `open://blockgames.app/games?gat=${data.activationToken}`;
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
};
