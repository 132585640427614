import { useMutation, useQueryClient } from 'react-query';

import { httpsFunction } from '@/utils/firebase';

interface IUseBuyQueryProps {
  onSuccess: () => void;
  onError: () => void;
}

interface IUseBuyUsdcParams {
  amount: number;
}

interface IUseBuyGiftCardParams {
  id: string;
}

export const useBuyUsdc = ({ onSuccess, onError }: IUseBuyQueryProps) => {
  const queryClient = useQueryClient();

  return useMutation<any, any, IUseBuyUsdcParams>(
    async ({ amount }) => {
      const buyUsdc = httpsFunction('buyUsdc');

      await buyUsdc({ amount });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useAssetsData']);

        onSuccess();
      },
      onError,
    }
  );
};

export const useBuyGiftCard = ({ onSuccess, onError }: IUseBuyQueryProps) => {
  const queryClient = useQueryClient();

  return useMutation<any, any, IUseBuyGiftCardParams>(
    async ({ id }) => {
      const buyGiftCard = httpsFunction('buyGiftCard');

      await buyGiftCard({ id });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useAssetsData']);

        if (onSuccess) {
          onSuccess();
        }
      },
      onError,
    }
  );
};
