import { PLATFORM } from '../App/constants';

export const resolvePlatformGameFieldName = (
  platform?: PLATFORM | string
): 'androidStoreID' | 'iosStoreID' | undefined => {
  switch (platform) {
    case PLATFORM.ANDROID:
      return 'androidStoreID';
    case PLATFORM.IOS:
      return 'iosStoreID';
    case PLATFORM.WEB:
      // Web has now no games. Can be used for testing;
      // return 'iosStoreID';
      // return 'androidStoreID';
      return undefined;
    default:
      return undefined;
  }
};
