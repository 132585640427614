import { NotificationModal } from '@/components/NotificationModal';
import VpnWorldVisual from '@/assets/visuals/vpn_world_visual.svg';

import styled from 'styled-components';
import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';

const Container = styled.div({
  padding: '32px 24px',
});

const Image = styled.img({
  width: '100%',
});

const RowFlexbox = styled.div({
  display: 'flex',
  marginTop: 12,
});

const LightText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
  textAlign: 'center',
}));

export const VpnBlockScreenComponent = () => (
  <NotificationModal isVisible>
    <Container>
      <Image src={VpnWorldVisual} />
      <Text type="h2" weight={600} align="center">
        Turn off VPN
      </Text>
      <RowFlexbox>
        <LightText>For a smoother experience and full access, please turn off your VPN</LightText>
      </RowFlexbox>
    </Container>
  </NotificationModal>
);

export const VpnBlockScreen = hideable(VpnBlockScreenComponent);
