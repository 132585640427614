import styled from 'styled-components';
import { Icon } from '@/components/Icon/Icon';
import { Divider } from '@/components/Divider';
import { ListContainer, ListContainerFooter } from '@/components/ListContainer';
import { Indicator } from '@/components/Indicator';
import ShieldVisual from '@/assets/visuals/shield_visual.png';
import { Button } from '@/components/Button/Button';
import { useState } from 'react';
import { BLCreditValue } from '@/components/BLCreditValue/BLCreditValue';
import { TournamentBoardEntry } from './TournamentBoardEntry';
import {
  TournamentBoardFounderRequired,
  TournamentBoardMoreItems,
  TournamentBoardMultipleReward,
  TournamentBoardWinThreshold,
} from './TournamentBoardElements';

const GradientFooter = styled(ListContainerFooter)`
  background: linear-gradient(70.4deg, #ffffff -32.87%, #e6b5e8 8.78%, #e78bf6 61.53%, #9867ff 100.4%);
`;

const LeaderBoardVisual = styled.img`
  width: 70px;
  flex-shrink: 0;
`;

const LeaderboardHeader = styled.div`
  font-size: 12px;
  background: #f5f1f8;
  color: #7a787c;
  text-transform: uppercase;
  border-top: 1px solid #94859c;
  border-bottom: 1px solid #94859c;
`;

const mockLeaderBoard = [
  {
    place: 1,
    username: 'mrKllah',
    trophies: 100,
    reward: 10000,
  },
  {
    place: 2,
    username: 'user 2',
    trophies: 50,
    reward: 1300,
  },
  {
    place: 3,
    username: 'nickname3',
    trophies: 40,
    reward: 1234,
  },
  {
    place: 4,
    username: 'fundaysunday',
    trophies: 21,
    reward: 34,
  },
  {
    place: 5,
    username: 'destroyer2000',
    trophies: 34554,
    reward: 1300,
  },
  {
    place: 6,
    username: 'blalaba',
    trophies: 23,
    reward: 100,
  },
  {
    place: 100,
    username: 'lucian',
    trophies: 10,
    reward: 100,
  },
  {
    place: 205,
    username: 'You',
    trophies: 4,
    reward: 10,
  },
];

export const TournamentBoard = ({
  title,
  endDate,
  isJoined,
  joinFee,
  rewardType,
}: {
  title: string;
  endDate: string;
  rewardType: 'USD' | 'BL_CREDIT';
  isJoined: boolean;
  joinFee?: number;
}) => {
  const [showList, setShowList] = useState(false);

  const handleListToggle = () => setShowList(!showList);
  return (
    <div className="w-full mb-5">
      <ListContainer className="w-full">
        <div className="p-4 xxs:p-6">
          <div className="flex mb-5">
            <LeaderBoardVisual src={ShieldVisual} alt="" className="mr-5" />
            <div className="flex flex-col justify-center">
              <div className="flex items-center">
                <Indicator success className="mr-3" />
                Ends in: {endDate}
              </div>
              <div className="font-semibold text-base xs:text-lg">{title}</div>
            </div>
          </div>
          <Divider className="mb-5" />
          <div className="flex">
            <div className="w-1/3 flex flex-col">
              <div className="text-slate-500 text-xs xs:text-sm mb-1">Prize pool</div>
              <div className="flex">
                {rewardType === 'USD' ? (
                  <>
                    <Icon width={20} height={20} name="usdc" className="mr-2" />
                    <div className="font-bold text-sm xs:text-base">$3000</div>
                  </>
                ) : (
                  <BLCreditValue value={100} />
                )}
              </div>
            </div>
            <div className="w-1/3 flex flex-col">
              <div className="text-slate-500 text-xs xs:text-sm mb-1">Participants</div>
              <div className="flex">
                <Icon width={20} height={20} name="users" className="mr-2" />
                <div className="font-bold text-sm xs:text-base">159</div>
              </div>
            </div>
            <div className="w-1/3 flex flex-col">
              <div className="text-slate-500 text-xs xs:text-sm mb-1">Game mode</div>
              <div className="flex">
                <Icon width={20} height={20} name="skull" className="mr-2" />
                <div className="font-bold text-sm xs:text-base">2 vs 2</div>
              </div>
            </div>
          </div>
        </div>

        {showList && (
          <div>
            <LeaderboardHeader className="flex px-4 xxs:px-6 py-3 font-semibold">
              <div className="w-16">Place</div>
              <div className="w-2/4">Player</div>
              <div className="w-1/4 flex justify-center">Trophies</div>
              <div className="w-1/4 flex justify-end">Reward</div>
            </LeaderboardHeader>

            {mockLeaderBoard.slice(0, 3).map(({ place, username, trophies, reward }, index) => {
              return (
                <TournamentBoardEntry
                  key={index}
                  place={place}
                  playerName={username}
                  trophies={trophies}
                  rewardType={rewardType}
                  rewardValue={reward}
                />
              );
            })}

            <TournamentBoardMoreItems />

            <TournamentBoardEntry
              place={100}
              playerName={'algis'}
              trophies={100}
              rewardType={rewardType}
              rewardValue={1000}
            />

            <TournamentBoardWinThreshold />

            <TournamentBoardEntry
              place={205}
              playerName={'You'}
              trophies={100}
              rewardType={rewardType}
              rewardValue={1000}
            />

            <TournamentBoardMultipleReward />

            <TournamentBoardEntry
              place={100}
              playerName={'algis'}
              trophies={100}
              rewardType={rewardType}
              rewardValue={1000}
            />
          </div>
        )}

        <TournamentBoardFounderRequired />

        <GradientFooter className="flex">
          <div className="w-1/2 flex items-center font-semibold cursor-pointer fn text-sm" onClick={handleListToggle}>
            <Icon name="crown2" className="mr-3" />
            {showList ? 'Hide' : 'Show'} leaderboard
          </div>
          <div className="w-1/2 flex justify-end">
            {isJoined ? (
              <div className="text-semibold flex items-center">
                <Icon name="successRoundGreenTick" className="mr-3" />
                You are in
              </div>
            ) : joinFee ? (
              <Button className="flex items-center">
                Join <BLCreditValue value={-10} className="ml-2" />
              </Button>
            ) : (
              <Button>Join for FREE</Button>
            )}
          </div>
        </GradientFooter>
      </ListContainer>
    </div>
  );
};
