import styled from 'styled-components';

import { NotificationModal } from '@/components/NotificationModal';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as BonusImage } from '@/assets/images/bonus.svg';
import { Button } from '@/components/Button/Button';

import { PLACE_EXTENSION_MAP } from '../constants';

interface IContestCreditsRewardNotification {
  onClose: () => void;
  onClaim: () => void;
  creditsEarned: number;
  place: 1 | 2 | 3;
}

const NotificationContentContainer = styled.div({
  padding: 24,
});

const MainTextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Text)({
  margin: '8px 0 4px 0',
});

const Description = styled(Text)(({ theme }) => ({
  margin: '8px 0 16px 0',
  color: theme.colors.purple.primary['50'],
}));

const StyledButton = styled(Button)({
  marginTop: 24,
  width: '100%',
});

export const ContestCreditsRewardNotification = ({
  onClaim,
  onClose,
  creditsEarned,
  place,
}: IContestCreditsRewardNotification) => (
  <NotificationModal isVisible onClose={onClose}>
    <NotificationContentContainer>
      <BonusImage />
      <Title type="h2" align="center" weight={600}>
        Congratulations!
      </Title>
      <Description align="center">
        You won the {`${place}${PLACE_EXTENSION_MAP[place]}`} place in the BlockGames Grand Prize contests
      </Description>
      <MainTextContainer>
        <Icon name="creditSmall" />
        <Text type="p_m">You have earned {creditsEarned} credits</Text>
      </MainTextContainer>
      <StyledButton onClick={onClaim}>Close</StyledButton>
    </NotificationContentContainer>
  </NotificationModal>
);
