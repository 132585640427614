import { Route, Routes, useNavigate, useLocation, Outlet, Navigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { firebaseAuth } from '@/utils/firebase';
import { AuthBackButton } from '@/components/AuthBackButton';
import { AuthLoader } from '@/components/AuthLoader';
import { isAuthLoading } from '@/features/Auth/selectors';
import { setAuthLoading } from '@/features/Auth/slice';

import { SelectSignInMethod } from './SelectSignInMethod';
import { SignInWithEmail } from './SignInWithEmail';
import { ResetEmailPassword } from './ResetEmailPassword';
import { CreateNewPassword } from './CreateNewPassword';
import { RegisterEmailAccount } from './RegisterEmailAccount';
import { useIsIosApp } from '@/features/App/hooks';
import { useEffect } from 'react';
import { signInWithCredential, OAuthProvider } from 'firebase/auth';
import { showToast } from '@/utils/toast';

declare global {
  interface Window {
    webkit: any;
  }
}

const Container = styled.div({
  width: '100%',
  height: '100%',
  maxWidth: 430,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const LoginLayout = () => {
  const location = useLocation();
  const isMethodSelectPage = location.pathname === '/login/method';
  const isCreateNewPasswordPage = location.pathname === '/create-new-password';

  return (
    <Container>
      {!isMethodSelectPage && !isCreateNewPasswordPage && <AuthBackButton />}
      <Outlet />
    </Container>
  );
};

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const isIosApp = useIsIosApp();

  const isLoading = useSelector(isAuthLoading);

  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'select_account',
  });

  useEffect(() => {
    const handleAppSignInWithGoogle = (event: CustomEvent) => {
      if (event.detail.token) {
        const credential = GoogleAuthProvider.credential(event.detail.token);
        signInWithCredential(firebaseAuth, credential)
          .catch((error) => {
            console.error(error);
            showToast({ type: 'error', text: `Something went wrong. Please try again later.` });
          })
          .finally(() => {
            dispatch(setAuthLoading(false));
          });
      }
    };
    const handleAppSignInWithApple = (event: CustomEvent) => {
      if (event.detail.withIDToken) {
        const appleProvider = new OAuthProvider('apple.com');
        const credential = appleProvider.credential({
          idToken: event.detail.withIDToken,
          rawNonce: event.detail.rawNonce,
        });
        signInWithCredential(firebaseAuth, credential)
          .catch((error) => {
            console.error(error);
            showToast({ type: 'error', text: `Something went wrong. Please try again later.` });
          })
          .finally(() => {
            dispatch(setAuthLoading(false));
          });
      }
    };
    // Listen for the login event
    window.addEventListener('app-sign-in-with-google', handleAppSignInWithGoogle as EventListener);
    window.addEventListener('app-sign-in-with-apple', handleAppSignInWithApple as EventListener);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('app-sign-in-with-google', handleAppSignInWithGoogle as EventListener);
      window.removeEventListener('app-sign-in-with-apple', handleAppSignInWithApple as EventListener);
    };
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      dispatch(setAuthLoading(true));

      if (isIosApp) {
        window.webkit?.messageHandlers?.googlesingin.postMessage('');
        return;
      }

      await signInWithRedirect(firebaseAuth, provider);
    } catch (error) {
      showToast({ type: 'error', text: `Something went wrong. Please try again later.` });
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

  const handleAppleSignIn = async () => {
    try {
      dispatch(setAuthLoading(true));

      if (isIosApp) {
        window.webkit.messageHandlers.applesingin.postMessage(null);
        return;
      }

      const appleProvider = new OAuthProvider('apple.com');
      appleProvider.addScope('email');
      appleProvider.addScope('name');

      await signInWithRedirect(firebaseAuth, appleProvider);
    } catch (error) {
      showToast({ type: 'error', text: `Something went wrong. Please try again later.` });
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

  if (isLoading) {
    return <AuthLoader />;
  }

  return (
    <>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route
            path="/login/method"
            element={
              <>
                <SelectSignInMethod
                  onGoogleClick={handleGoogleSignIn}
                  onAppleClick={handleAppleSignIn}
                  onEmailClick={() => navigate('/login/email', { state })}
                />
              </>
            }
          />

          <Route path="/login/email" element={<SignInWithEmail />} />
          <Route path="/reset-password" element={<ResetEmailPassword />} />
          <Route path="/create-new-password" element={<CreateNewPassword />} />
          <Route path="/register" element={<RegisterEmailAccount />} />
          <Route path="/*" element={<Navigate to="/login/method" />} />
        </Route>
      </Routes>
    </>
  );
};
