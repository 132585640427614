import { timeAgo } from '@/utils/helpers/timeAgo';
import { useNotificationByUid } from '../queries';
import { NotificationBox } from './NotificationBox';
import { useNavigate } from 'react-router-dom';

interface INotificationBoxContainer {
  uid: string;
}

export const NotificationBoxContainer = ({ uid }: INotificationBoxContainer) => {
  const navigate = useNavigate();

  const data = useNotificationByUid({ uid });

  if (!data) {
    return null;
  }

  const onClick = () => {
    if (data.path) {
      navigate(data.path);

      return;
    }
  };

  return (
    <NotificationBox
      title={data.title || ''}
      description={data.description || ''}
      timeAgoText={data.timestamp ? timeAgo(data.timestamp) : ''}
      type={data.type || 'news'}
      isSeen={!!data.isSeen}
      label={data.label || ''}
      onClick={onClick}
    />
  );
};
