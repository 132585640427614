import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { FormikHelpers } from 'formik';
import { AuthErrorCodes } from 'firebase/auth';
import { useCreateUserWithEmailAndPassword, useSendEmailVerification } from 'react-firebase-hooks/auth';

import { firebaseAuth } from '@/utils/firebase';
import { Text } from '@/components/Text';
import { showToast } from '@/utils/toast';
import { useHandleAfterUserRegistration } from '@/features/Auth/queries';

import { EmailRegisterForm, IEmailRegisterFormValues } from './forms/EmailRegisterForm';

export const RegisterEmailAccount = () => {
  const [createUserWithEmailAndPassword, _user, loading, error] = useCreateUserWithEmailAndPassword(firebaseAuth);
  const [sendEmailVerification] = useSendEmailVerification(firebaseAuth);

  const { mutate: handleAfterRegistration } = useHandleAfterUserRegistration();

  const handleFormSubmit = async (
    { email, password }: IEmailRegisterFormValues,
    { setSubmitting }: FormikHelpers<IEmailRegisterFormValues>
  ) => {
    const result = await createUserWithEmailAndPassword(email, password);

    if (!result) {
      setSubmitting(false);

      return;
    }

    await sendEmailVerification();

    setSubmitting(false);
    handleAfterRegistration();

    Sentry.configureScope((scope) => scope.setLevel('error').setUser({ email }));
  };

  useEffect(() => {
    if (error) {
      switch (error.code) {
        case AuthErrorCodes.EMAIL_EXISTS:
          showToast({ type: 'error', text: 'This email already exists' });
          return;
        case AuthErrorCodes.WEAK_PASSWORD:
          showToast({ type: 'error', text: 'Password should be at least 6 characters' });
          return;
        default:
          showToast({ type: 'error', text: 'Something has gone wrong. Please inform our support team via Discord' });
          Sentry.captureException(error);
      }
    }
  }, [error]);

  return (
    <>
      <div className="mb-5">
        <Text type="h2" weight={600}>
          Create an account
        </Text>
      </div>
      <EmailRegisterForm submitText={loading ? 'Registering...' : 'Register'} onSubmit={handleFormSubmit} />
    </>
  );
};
