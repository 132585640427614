export const USDC_REWARDS = [
  { creditPriceLabel: '10k', creditPrice: 10000, value: '10' },
  { creditPriceLabel: '15k', creditPrice: 15000, value: '15' },
  { creditPriceLabel: '25k', creditPrice: 25000, value: '25' },
  { creditPriceLabel: '50k', creditPrice: 50000, value: '50' },
];
export const currencySymbols: Record<string, string> = {
  USD: '$',
  EUR: '€',
  CAD: 'CA$',
  EGP: 'E£',
  GHS: 'GH₵',
  KES: 'KSh',
  NGN: '₦',
  RWF: 'FRw',
  ZAR: 'R',
  ARS: '$',
  BRL: 'R$',
  CLP: '$',
  COP: '$',
  MXN: '$',
  PEN: 'S/',
  UYU: '$U',
  AUD: 'A$',
  BDT: '৳',
  BHD: '.د.ب',
  CNY: '¥',
  HKD: 'HK$',
  IDR: 'Rp',
  INR: '₹',
  JPY: '¥',
  KRW: '₩',
  LKR: 'Rs',
  MYR: 'RM',
  NZD: 'NZ$',
  PHP: '₱',
  PKR: '₨',
  SGD: 'S$',
  THB: '฿',
  TWD: 'NT$',
  VND: '₫',
  BGN: 'лв',
  CHF: 'CHF',
  CZK: 'Kč',
  DKK: 'kr',
  HUF: 'Ft',
  NOK: 'kr',
  PLN: 'zł',
  RON: 'lei',
  RSD: 'дин',
  SEK: 'kr',
  TRY: '₺',
  AED: 'د.إ',
  ILS: '₪',
  JOD: 'د.ا',
  KWD: 'د.ك',
  OMR: 'ر.ع.',
  QAR: 'ر.ق',
  SAR: 'ر.س',
};
