import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import ReactDOM from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import { registerSW } from 'virtual:pwa-register';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { CookiesProvider } from 'react-cookie';

import { store } from '@/store/store';
import { initSentry } from '@/utils/sentry';
import { theme } from '@/theme/theme';

import { App } from './App';
import './index.css';

const GlobalStyles = createGlobalStyle`
  body {
    background-color: rgba(50, 5, 84, 0.98);

    .Toastify__toast-theme--dark {
      background-color: rgba(50, 5, 84, 0.98);
    }

    .Toastify__toast--success {
      border: 1px solid ${(props) => props.theme.colors.state.success}
    }

    .Toastify__toast--error {
      border: 1px solid ${(props) => props.theme.colors.state.error}
    }

    .Toastify__toast--info {
      border: 1px solid ${(props) => props.theme.colors.state.info}
    }

    .Toastify__toast {
      border-radius: 8px;
      margin-bottom: 12px;
    }

    .Toastify__toast-body {
      align-items: flex-start;
    }

    .Toastify__toast-container {
      left: 14px;
      right: 14px;
      width: auto;
    }

    @media only screen and (max-width: 480px) {
      .Toastify__toast-container {
        width: auto;
        left: 24px;
        right: 24px;
        top: 24px;
      }
    }
  }
`;

initSentry();

ReactGA.initialize('G-5N732G1DJ5');

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SkeletonTheme baseColor={theme.colors.darkPurple} highlightColor={theme.colors.purple.primary['600']}>
        <GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <BrowserRouter>
              <CookiesProvider defaultSetOptions={{ path: '/' }}>
                <App />
              </CookiesProvider>
            </BrowserRouter>
          </Provider>
        </QueryClientProvider>
      </SkeletonTheme>
    </ThemeProvider>
  </React.StrictMode>
);

registerSW({ immediate: true });
