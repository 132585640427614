import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface IEmailVerification {
  isVerificationSuccess: boolean;
  isVerificationError: boolean;
}

const initialState: IEmailVerification = {
  isVerificationSuccess: false,
  isVerificationError: false,
};

export const emailVerificationSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsEmailVerificationSuccess: (state, action: PayloadAction<boolean>) => {
      state.isVerificationSuccess = action.payload;
    },
    setIsEmailVerificationError: (state, action: PayloadAction<boolean>) => {
      state.isVerificationError = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setIsEmailVerificationSuccess, setIsEmailVerificationError, resetState } =
  emailVerificationSlice.actions;
