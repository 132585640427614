import { useRef } from 'react';
import styled from 'styled-components';

import { useOutsideClick } from '@/utils/hooks/useOutsideClick';
import hideable from '@/utils/hideable';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-button-image.svg';

interface IModal {
  onClose?: () => void;
  children: React.ReactNode;
}

const Overlay = styled.div({
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'rgba(16, 8, 21, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContent = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  border: `1px solid ${theme.colors.purple.primary['500']}`,
  borderRadius: 10,
  position: 'relative',
  overflow: 'hidden',
  width: '80%',
  minWidth: 296,
}));

const CloseButton = styled.button({
  position: 'absolute',
  top: 24,
  right: 24,
  outline: 'none',
  border: 'none',
  background: 'none',
});

const ModalComponent: React.FC<IModal> = ({ children, onClose, ...rest }) => {
  const ref = useRef(null);

  useOutsideClick(ref, onClose);

  return (
    <Overlay>
      <ModalContent ref={ref} {...rest}>
        {onClose && (
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        )}
        {children}
      </ModalContent>
    </Overlay>
  );
};

export const NotificationModal = hideable(ModalComponent);
