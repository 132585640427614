import styled from 'styled-components';

import OpenChestVisual from '@/assets/visuals/chest_open.svg';

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary[600],
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  boxShadow: theme.boxShadow.primary,
  borderRadius: 8,
  paddingTop: 29,
  paddingBottom: 34,
  '.title': {
    color: theme.colors.purple.primary[50],
    fontSize: 14,
    fontWeight: 600,
  },
  '.subTitle': {
    color: theme.colors.purple.primary[200],
    fontSize: 12,
  },
  img: {
    maxHeight: 80,
    marginBottom: 10,
  },
}));

export const ChallengesCompleted = () => {
  return (
    <Container>
      <img src={OpenChestVisual} alt="" />
      <div className="title">All challenges have been completed!</div>
      <div className="subTitle">We will let you know once it renews, goodluck next time!</div>
    </Container>
  );
};
