import { useState, useEffect } from 'react';
import { differenceInSeconds } from 'date-fns';

export const formatTimeDuration = (secondsRemaining: number | undefined) => {
  if (secondsRemaining === undefined) {
    return '';
  }

  if (secondsRemaining <= 0) {
    return 'Countdown expired';
  }

  const hours = Math.floor(secondsRemaining / 3600);
  const minutes = Math.floor((secondsRemaining % 3600) / 60);
  const seconds = secondsRemaining % 60;

  const formattedDuration = `${hours}:${minutes}:${seconds}`;

  return formattedDuration;
};

export const useCountdown = (endDate: any) => {
  const [timeLeft, setTimeLeft] = useState(differenceInSeconds(endDate, new Date()));

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(differenceInSeconds(endDate, new Date()));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [endDate]);

  return formatTimeDuration(timeLeft);
};
