import styled from 'styled-components';

import { NotificationModal } from '@/components/NotificationModal';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as ProgressImg } from '@/assets/images/get-started-100.svg';
import { Button } from '@/components/Button/Button';

interface ISignUpRewardNotification {
  onClose: () => void;
  onClaim: () => void;
  creditsEarned: number;
}

const NotificationContentContainer = styled.div({
  padding: '38px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Earnings = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Text)({
  margin: '4px 0',
});

const Description = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginBottom: 16,
}));

const StyledButton = styled(Button)({
  marginTop: 24,
  width: '100%',
});

const StyledProgressImg = styled(ProgressImg)({
  width: 148,
  height: 148,
});

export const GetStartedReward = ({ onClaim, onClose, creditsEarned }: ISignUpRewardNotification) => (
  <NotificationModal isVisible onClose={onClose}>
    <NotificationContentContainer>
      <StyledProgressImg />
      <Title type="h2" align="center" weight={600}>
        Congratulations!
      </Title>
      <Description align="center">You finished all taks</Description>
      <Earnings>
        <Icon name="creditSmall" />
        <Text type="p_l" weight={700}>
          {creditsEarned} credits
        </Text>
      </Earnings>
      <StyledButton onClick={onClaim}>Claim your rewards!</StyledButton>
    </NotificationContentContainer>
  </NotificationModal>
);
