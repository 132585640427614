import styled from 'styled-components';

import hideable from '@/utils/hideable';

import { Icon } from './Icon/Icon';

const Container = styled.div(({ theme }) => ({
  color: theme.colors.neutral.primary['0'],
  position: 'absolute',
  top: 6,
  right: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  fontSize: 10,
  lineHeight: '12px',
  padding: '4px 8px',
  fontWeight: 400,
  backgroundColor: 'rgba(37, 15, 54, 0.5)',
}));

const StyledIcon = styled(Icon)({
  marginRight: 4,
});

const ProvablyFairBadgeComponent = () => (
  <Container>
    <StyledIcon name="protected" />
    Provably fair
  </Container>
);

export const ProvablyFairBadge = hideable(ProvablyFairBadgeComponent);
