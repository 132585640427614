import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { BackButton } from '@/components/BackButton';
import { GameTitleHeader } from '@/features/Game/components/GameTitleHeader';
import { Description } from '@/features/Game/components/Description';
import { getGame } from '@/features/Game/queries';

import { ReactComponent as ComingSoonImg } from '@/assets/images/coming_soon.svg';
import { IGame } from '@/features/Game/types';

export const ComingSoonGame = () => {
  const { id: gameSlug } = useParams();
  const navigate = useNavigate();
  const { game, loading } = getGame(gameSlug);

  const handleBackClick = () => {
    navigate('/games');
  };

  if (loading) {
    return <Skeleton height={102} className="rounded-lg" containerClassName="w-full" />;
  }

  return (
    <>
      <BackButton onBack={handleBackClick} />
      <GameTitleHeader game={game as IGame} />
      <div className="flex items-center justify-center h-30 my-6">
        <ComingSoonImg />
      </div>
      <Description description={game?.description} descriptionImages={game?.storageImages?.descriptionImages} />
    </>
  );
};
