import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import ReferralHeader from '@/assets/images/referral-header.png';
import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text';

import { useReferrals } from '../queries';
import { showToast } from '@/utils/toast';
import { Icon } from '@/components/Icon/Icon';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 40,
});

const TextContainer = styled.div({
  maxWidth: 300,
  margin: '16px 0 24px 0',
});

const HeaderImage = styled.img({
  width: 262,
  height: 114,
  objectFit: 'contain',
});

const StyledIcon = styled(Icon)({
  marginRight: 4,
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  marginBottom: 8,
}));

const MainText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
}));

export const CopyReferralLink = () => {
  const { referralLink } = useReferrals();

  const handleCopySuccess = () => {
    showToast({ type: 'success', text: 'Copied!' });
  };

  return (
    <Container>
      <HeaderImage src={ReferralHeader} />
      <TextContainer>
        <Title type="h3" align="center" weight={600}>
          Invite friends to earn Credits
        </Title>
        <MainText type="p_m" align="center">
          Receive up to 200 credits and for every active friend you invite
        </MainText>
      </TextContainer>
      <CopyToClipboard onCopy={handleCopySuccess} text={referralLink}>
        <Button>
          <StyledIcon name="copy" />
          Copy your referral URL
        </Button>
      </CopyToClipboard>
    </Container>
  );
};
