import { useMutation } from 'react-query';

import { httpsFunction } from '@/utils/firebase';

interface IUseCompleteOnboardingProps {
  onSuccess: () => void;
}

export const useCompleteOnboarding = ({ onSuccess }: IUseCompleteOnboardingProps) =>
  useMutation(
    async () => {
      const completeOnboarding = httpsFunction('completeOnboarding');

      await completeOnboarding();
    },
    {
      onSuccess,
    }
  );
