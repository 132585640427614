import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from '@/components/PageHeader';

import { Menu } from './Menu';
import { AccountDetails } from './AccountDetails';
import { LogoutButton } from './LogoutButton';
import { Text } from '@/components/Text';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const VersionText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['300'],
  marginTop: 32,
}));

export const Settings = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <PageHeader title="Settings" onBack={onBack} />
      <AccountDetails />
      <Menu />
      <LogoutButton />
      <VersionText weight={600}>Version {APP_VERSION}</VersionText>
    </Container>
  );
};
