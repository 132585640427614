import styled from 'styled-components';

import { ReactComponent as Usdc } from '@/assets/images/usdc.svg';
import { ReactComponent as Paypal } from '@/assets/images/paypal-gift-card.svg';
import { ReactComponent as Amazon } from '@/assets/images/amazon-gift-card.svg';
import { ReactComponent as Playstation } from '@/assets/images/playstation-gift-card.svg';
import { ReactComponent as GooglePlay } from '@/assets/images/google-play-gift-card.svg';
import { ReactComponent as Apple } from '@/assets/images/apple-app-store-gift-card.svg';

import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import hideable from '@/utils/hideable';
import { TShopItemType } from '@/features/Shop/types';

interface IShopItem {
  title: string;
  descriptionText: JSX.Element;
  creditsValue: number;
  isDisabled: boolean;
  type?: TShopItemType;
  onBuyClick: () => void;
}

const ItemContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary[600],
  border: `1px solid ${theme.colors.purple.primary[500]}`,
  borderRadius: 16,
  minWidth: 124,
  minHeight: 328,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledUsdc = styled(Usdc)({
  maxWidth: '156px',
  width: '100%',
});

const StyledPaypal = styled(Paypal)({
  height: 182,
  width: '100%',
});

const StyledAmazon = styled(Amazon)({
  height: 182,
  width: '100%',
});

const StyledPlaystation = styled(Playstation)({
  height: 182,
  width: '100%',
});

const StyledGooglePlay = styled(GooglePlay)({
  height: 182,
  width: '100%',
});

const StyledApple = styled(Apple)({
  height: 182,
  width: '100%',
});

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '8px 0',
});

const PriceContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledButton = styled(Button)({
  width: '100%',
});

const StyledIcon = styled(Icon)({
  marginRight: 4,
});

const Divider = styled.div({
  width: '100%',
  opacity: 1,
  borderTop: '1px solid #502572',
  margin: '24px 0',
});

const ErrorText = styled(Text)(({ theme }) => ({
  color: theme.colors.state.error,
  marginTop: 8,
}));

const ICON_BY_TYPE = {
  amazon: <StyledAmazon />,
  paypal: <StyledPaypal />,
  playstation: <StyledPlaystation />,
  usdc: <StyledUsdc />,
  googleplay: <StyledGooglePlay />,
  apple: <StyledApple />,
};

export const ShopItemComponent = ({
  title,
  descriptionText,
  creditsValue,
  isDisabled,
  type = 'usdc',
  onBuyClick,
}: IShopItem) => (
  <>
    <ItemContainer>{ICON_BY_TYPE[type]}</ItemContainer>
    <TitleContainer>
      <Text type="p_l" weight={600}>
        {title}
      </Text>
      <PriceContainer>
        <StyledIcon name="credit" />
        <Text type="h3" weight={600}>
          {creditsValue}
        </Text>
      </PriceContainer>
    </TitleContainer>
    <StyledButton disabled={isDisabled} onClick={onBuyClick}>
      Buy
    </StyledButton>
    {isDisabled && <ErrorText align="center">You don't have enough credits to buy this item</ErrorText>}
    <Divider />
    <Text colorType="extraLight">{descriptionText}</Text>
  </>
);

export const ShopItem = hideable(ShopItemComponent);
