import { useEffect, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import Slider from 'react-slider';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { LinkToAssetsSwap } from '@/components/LinkToAssetsSwap';
import { Icon } from '@/components/Icon/Icon';
import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text';
import { useAssets } from '@/features/Assets/queries';
import { setModalVisible } from '@/features/AssetsSwap/slice';
import { ErrorMessage } from '@/components/ErrorMessage/ErrorMessage';
import { useAuth } from '@/features/Auth/hooks';
import { showToast } from '@/utils/toast';
import hideable from '@/utils/hideable';

import { getTicketsAmount } from '../selectors';
import { setTicketsAmount } from '../slice';
import { useApplyToContest, useContest } from '../queries';

const SliderContainer = styled.div(({ theme }) => ({
  width: '100%',
  height: 62,
  paddingTop: 12,
  margin: '34px 0 16px 0',
  backgroundColor: theme.colors.purple.primary['600'],
  borderRadius: 8,
}));

const StyledSlider = styled(Slider)({
  margin: '0 20px',
});

const CustomThumbContainer = styled.div({
  outline: 'none',
});

const CustomThumb = styled.div(({ theme }) => ({
  position: 'relative',
  height: 40,
  width: 16,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.yellow.primary['500'],
  border: `1px solid ${theme.colors.purple.primary['900']}`,
  boxShadow: '2px 2px 0px 0px #000000',
  outline: 'none',
}));

const ValueContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['50'],
  position: 'absolute',
  top: '-46px',
  left: '50%',
  transform: 'translateX(-50%)',
  boxShadow: '2px 2px 0px 0px #000000',
  padding: '6px 12px',
  borderRadius: 8,
}));

const InfoTextTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  marginBottom: 4,
}));

const InfoText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
}));

const ArrowBox = styled.div(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '&:after': {
    content: '" "',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '-10px',
    borderBottom: 'none',
    borderRight: '10px solid transparent',
    borderLeft: '10px solid transparent',
    borderTop: `5px solid ${theme.colors.purple.primary['50']}`,
  },
}));

const PrefillButtonsContainer = styled.div({
  display: 'flex',
});

const PrefillButton = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['400'],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 6,
  flexGrow: 1,
  marginRight: 8,
  borderRadius: 4,
  cursor: 'pointer',
  ':last-of-type': {
    marginRight: 0,
  },
}));

const ValueText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['900'],
}));

const ButtonIcon = styled(Icon)({
  margin: '0 4px',
});

const StyledButton = styled(Button)({
  width: '100%',
  marginTop: 24,
});

const StyledErrorMessage = styled(ErrorMessage)({
  marginTop: 4,
  textAlign: 'center',
});

const CustomTrack = styled.div(({ theme, isRightTrack }: { theme: DefaultTheme; isRightTrack: boolean }) => ({
  background: isRightTrack ? theme.colors.purple.primary['500'] : theme.colors.yellow.primary['500'],
  height: 4,
  borderRadius: 7,
  top: 18,
}));

const UserEntryContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 24,
});

const UserEntryText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginRight: 16,
}));

const UserEntryAmountContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 6,
  height: 40,
  width: 76,
}));

const AmountText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
}));

const renderThumb = (props: any, state: any, isTouching: boolean) => (
  <CustomThumbContainer {...props}>
    {isTouching && (
      <ValueContainer>
        <ArrowBox>
          <Icon name="ticketSmall" />
          <ValueText type="p_l" weight={600}>
            {state.valueNow}
          </ValueText>
        </ArrowBox>
      </ValueContainer>
    )}
    <CustomThumb>
      <Icon name="thumbDots" />
    </CustomThumb>
  </CustomThumbContainer>
);

const renderTrack = (props: any, state: any) => {
  return <CustomTrack {...props} isRightTrack={state.index === 1} />;
};

const TicketsInputComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid: contestId = '' } = useParams();

  const { title }: { title: string } = useOutletContext();
  const { tickets: availableTickets } = useAssets();
  const { data } = useContest({ contestId });
  const { uid: userId } = useAuth();

  const ticketsAmount = useSelector(getTicketsAmount);

  const {
    mutate: applyToContest,
    isLoading,
    isError,
    error,
  } = useApplyToContest({
    onSuccess: () => {
      showToast({ text: `Successfully applied ${ticketsAmount} tickets`, type: 'success' });

      dispatch(setTicketsAmount(0));
    },
  });

  const [isTouching, setIsTouching] = useState(false);

  useEffect(() => {
    if (data?.isEnded) {
      navigate(`/contest/${contestId}/winners`);
    }
  }, [data?.isEnded]);

  const handleSliderChange = (value: number | readonly number[]) => {
    if (typeof value === 'object') {
      return;
    }

    dispatch(setTicketsAmount(value));
  };

  const handlePrefillClick = (value: number) => {
    dispatch(setTicketsAmount(Math.floor(availableTickets * value)));
  };

  const handleAssetsSwapClick = () => {
    dispatch(setModalVisible(true));
  };

  const handleApplyToContest = () => {
    mixpanel.track('added-tickets', { contestId, ticketsAmount, distinct_id: userId, contestTitle: title });
    applyToContest({ amount: ticketsAmount, id: contestId });
  };

  return (
    <>
      {!!availableTickets && (
        <>
          <InfoTextTitle type="p_l" weight={600} align="center">
            Select the amount
          </InfoTextTitle>
          <InfoText type="p_s" align="center">
            More tickets, bigger chance to win
          </InfoText>
          <SliderContainer>
            <StyledSlider
              value={ticketsAmount}
              onChange={handleSliderChange}
              onBeforeChange={() => setIsTouching(true)}
              onAfterChange={() => setIsTouching(false)}
              className="horizontal-slider"
              renderThumb={(props, state) => renderThumb(props, state, isTouching)}
              renderTrack={renderTrack}
              max={availableTickets}
            />
          </SliderContainer>
          <PrefillButtonsContainer>
            <PrefillButton onClick={() => handlePrefillClick(0.25)}>
              <Text type="p_s" weight={500}>
                25%
              </Text>
            </PrefillButton>
            <PrefillButton onClick={() => handlePrefillClick(0.5)}>
              <Text type="p_s" weight={500}>
                50%
              </Text>
            </PrefillButton>
            <PrefillButton onClick={() => handlePrefillClick(0.75)}>
              <Text type="p_s" weight={500}>
                75%
              </Text>
            </PrefillButton>
            <PrefillButton onClick={() => handlePrefillClick(1)}>
              <Text type="p_s" weight={500}>
                All-in
              </Text>
            </PrefillButton>
          </PrefillButtonsContainer>
          <StyledButton disabled={isLoading} onClick={handleApplyToContest}>
            {!isLoading && (
              <>
                Add tickets <ButtonIcon name="ticketSmall" />
                {ticketsAmount}
              </>
            )}
            {isLoading && 'Adding tickets...'}
          </StyledButton>
          {isError && (
            <StyledErrorMessage>{error?.message || 'Something went wrong. Please try again later.'}</StyledErrorMessage>
          )}
        </>
      )}
      <LinkToAssetsSwap onClick={handleAssetsSwapClick} isVisible={!availableTickets} />
      {!!data?.userEntry && (
        <UserEntryContainer>
          <UserEntryText>Your current entry</UserEntryText>
          <UserEntryAmountContainer>
            <Icon name="ticketSmall" />
            <AmountText type="p_l" weight={600}>
              {data?.userEntry}
            </AmountText>
          </UserEntryAmountContainer>
        </UserEntryContainer>
      )}
    </>
  );
};

export const TicketsInput = hideable(TicketsInputComponent);
