import { Icon } from '@/components/Icon/Icon';
import { IChallenge } from '@/features/Challenges/types';
import { resolveChallengeVisual } from '@/features/Challenges/utils/challengeVisuals';
import { ClaimBtn, ChallengeContainer, CreditContainer, Visual } from '../ChallengeEntry';
import { abbreviateNumber } from '@/utils/helpers/abbreviateNumber';
import styled from 'styled-components';
import { Text } from '@/components/Text';

const StyledIcon = styled(Icon)({
  minWidth: 16,
  margin: '0 0 0 4px',
});

const StyledText = styled(Text)({
  margin: '0 8px',
});

const Flexbox = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const BasicChallenge = ({
  challenge,
  onClaim,
  isLoading,
  disabled,
}: {
  challenge: IChallenge;
  onClaim(): void;
  isLoading: boolean;
  disabled?: boolean;
}) => (
  <ChallengeContainer disabled={disabled || challenge.isClaimed} isNew={challenge.isNew}>
    <Flexbox>
      <Visual url={resolveChallengeVisual(challenge.icon)} />
      <StyledText weight={600}>{challenge.title}</StyledText>
    </Flexbox>
    <CreditContainer>
      {challenge.canClaim && (
        <ClaimBtn disabled={isLoading} onClick={onClaim}>
          {isLoading ? (
            'Claiming...'
          ) : (
            <>
              Claim <StyledIcon name="credit" /> {abbreviateNumber(challenge.credits)}
            </>
          )}
        </ClaimBtn>
      )}
      {challenge.isClaimed && <Icon name="successEmblem" />}
      {!challenge.canClaim && !challenge.isClaimed && (
        <div className="flex items-center justify-end text-[#F1E7FA] font-semibold">
          <Icon name="credit" height={24} className="ml-2 mr-1" /> {abbreviateNumber(challenge.credits)}
        </div>
      )}
    </CreditContainer>
  </ChallengeContainer>
);
