import styled from 'styled-components';

import { IChallenge } from '../types';
import { ChallengeEntry } from './ChallengeEntry';
import { NoChallenges } from './challenges/NoChallenges';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ChallengeList = ({ challenges }: { challenges: Array<IChallenge> }) => {
  if (challenges.length === 0) {
    return (
      <Container>
        <NoChallenges />
      </Container>
    );
  }

  return (
    <Container>
      {challenges?.map((challenge, index) => (
        <ChallengeEntry key={index} challenge={challenge} />
      ))}
    </Container>
  );
};
