import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { TPlatform } from '@/utils/getPlatform';

export type TNotificationType = 'success' | 'error';

interface IApp {
  platform: TPlatform | undefined;
  appsflyerAndroidId: string | undefined;
  appsflyerIosId: string | undefined;
  appsflyerWebId: string | undefined;
  firebaseAndroidId: string | undefined;
  firebaseIosId: string | undefined;
  firebaseWebId: string | undefined;
}

const initialState: IApp = {
  platform: undefined,
  appsflyerAndroidId: undefined,
  appsflyerIosId: undefined,
  appsflyerWebId: undefined,
  firebaseAndroidId: undefined,
  firebaseIosId: undefined,
  firebaseWebId: undefined,
};

export const appSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setPlatform: (state, action: PayloadAction<TPlatform>) => {
      state.platform = action.payload;
    },
    setAppsflyerAndroidId: (state, action: PayloadAction<string>) => {
      state.appsflyerAndroidId = action.payload;
    },
    setAppsflyerIosId: (state, action: PayloadAction<string>) => {
      state.appsflyerIosId = action.payload;
    },
    setAppsflyerWebId: (state, action: PayloadAction<string>) => {
      state.appsflyerWebId = action.payload;
    },
    setFirebaseAndroidId: (state, action: PayloadAction<string>) => {
      state.firebaseAndroidId = action.payload;
    },
    setFirebaseIosId: (state, action: PayloadAction<string>) => {
      state.firebaseIosId = action.payload;
    },
    setFirebaseWebId: (state, action: PayloadAction<string>) => {
      state.firebaseWebId = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setPlatform,
  setAppsflyerAndroidId,
  setAppsflyerIosId,
  setAppsflyerWebId,
  setFirebaseAndroidId,
  setFirebaseIosId,
  setFirebaseWebId,
  resetState,
} = appSlice.actions;
