import styled from 'styled-components';

import { NotificationModal } from '@/components/NotificationModal';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as BonusImage } from '@/assets/images/bonus.svg';
import { Button } from '@/components/Button/Button';

interface ISignUpRewardNotification {
  onClose: () => void;
  onClaim: () => void;
  creditsEarned?: number;
  ticketsEarned?: number;
}

const NotificationContentContainer = styled.div({
  padding: 24,
});

const Earnings = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '8px 0',
});

const Title = styled(Text)({
  margin: '4px 0',
});

const Description = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginBottom: 16,
}));

const StyledButton = styled(Button)({
  marginTop: 24,
  width: '100%',
});

export const SignUpRewardNotification = ({
  onClaim,
  onClose,
  creditsEarned,
  ticketsEarned,
}: ISignUpRewardNotification) => (
  <NotificationModal isVisible onClose={onClose}>
    <NotificationContentContainer>
      <BonusImage />
      <Title type="h2" align="center" weight={600}>
        Welcome Bonus
      </Title>
      <Description align="center">Few bonus rewards to kickstart your earning adventure!</Description>
      {creditsEarned && (
        <Earnings>
          <Icon name="creditSmall" />
          <Text type="p_m" weight={600}>
            {creditsEarned} credits
          </Text>
        </Earnings>
      )}
      {ticketsEarned && (
        <Earnings>
          <Icon name="ticketSmall" />
          <Text type="p_m" weight={600}>
            {ticketsEarned} tickets
          </Text>
        </Earnings>
      )}
      <StyledButton onClick={onClaim}>Bring it on!</StyledButton>
    </NotificationContentContainer>
  </NotificationModal>
);
