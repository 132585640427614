import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

import { ReactComponent as Logo } from '@/assets/logos/white-logo.svg';
import { SocialSignInButton } from '@/components/SocialSignInButton';
import { Text } from '@/components/Text';
import LoginChest from '@/assets/images/mobile-sign-in-background.png';
import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { LINKS } from '@/utils/constants/common';
import { SignInSmallButton } from '@/components/SignInSmallButton';
import { useIsIosApp } from '@/features/App/hooks';

interface ISelectSignInMethod {
  onEmailClick(): void;
  onGoogleClick(): void;
  onAppleClick(): void;
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: 360,
});

const Link = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
});

const TermsText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary[200],
  width: 200,
}));

const AlternativeText = styled(Text)({
  marginTop: 24,
  marginBottom: 16,
});

const SmallSignInButtonsContainer = styled.div({
  display: 'flex',
  gap: 24,
});

export const SelectSignInMethod = ({ onEmailClick, onGoogleClick, onAppleClick }: ISelectSignInMethod) => {
  const openLink = useOpenLink();
  const isIosApp = useIsIosApp();

  useEffect(() => {
    mixpanel.track('main-login-page');
  }, []);

  const onTermsClick = () => {
    openLink(LINKS.BG_TERMS);
  };

  const onPolicyClick = () => {
    openLink(LINKS.BG_PRIVACY_POLICY);
  };

  return (
    <Container>
      <Logo />
      <Text align="center" type="h2" weight={600} className="max-w-lg my-6">
        Start earning instant rewards while you play!
      </Text>
      <img src={LoginChest} alt="login chest" className="my-3" />
      <ButtonsContainer>
        <SocialSignInButton isVisible={!isIosApp} onClick={onGoogleClick} buttonType="google" />
        <SocialSignInButton isVisible={isIosApp} onClick={onAppleClick} buttonType="apple" />
      </ButtonsContainer>
      <AlternativeText colorType="light">Or continue with</AlternativeText>
      <SmallSignInButtonsContainer>
        <SignInSmallButton isVisible onClick={onEmailClick} buttonType="email" />
        <SignInSmallButton isVisible={!isIosApp} onClick={onAppleClick} buttonType="apple" />
        <SignInSmallButton isVisible={isIosApp} onClick={onGoogleClick} buttonType="google" />
      </SmallSignInButtonsContainer>
      <TermsText align="center" type="p_xs" weight={400} className="max-w[200px] absolute bottom-8">
        By tapping continue you agree with our <Link onClick={onTermsClick}>Terms of Use</Link> &{' '}
        <Link onClick={onPolicyClick}>Privacy Policy</Link>
      </TermsText>
    </Container>
  );
};
