import styled from 'styled-components';

import { LoaderIcon } from '@/components/SVGs/LoaderIcon';

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  padding: 32,
  position: 'relative',
  overflow: 'hidden',
});

export const AuthLoader = () => (
  <Container>
    <LoaderIcon className="mx-auto" />
  </Container>
);
