import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { showToast } from '@/utils/toast';
import { useAssets } from '@/features/Assets/queries';
import { TShopItemType } from '@/features/Shop/types';
import { useAuth } from '@/features/Auth/hooks';
import { useGetGiftCardById } from '@/features/Shop/queries';

import { ShopItemSummary } from './ShopItemSummary';
import { useBuyGiftCard } from '../queries';
import { currencySymbols } from '@/features/Shop/constants';

interface ILocation {
  state: {
    type?: TShopItemType;
    id?: string;
  };
}

const EmailText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const TITLE_BY_TYPE = {
  playstation: 'PlayStation gift card',
  amazon: 'Amazon gift card',
  paypal: 'Paypal gift card',
  apple: 'App Store gift card',
  googleplay: 'Google Play gift card',
  usdc: '',
};

export const GiftCardShopItemSummaryContainer = () => {
  const navigate = useNavigate();
  const { state }: ILocation = useLocation();

  const { credits } = useAssets();
  const { userEmail } = useAuth();
  const { data } = useGetGiftCardById({ id: state?.id });

  const price = data?.creditAmount || 0;
  const value = data?.payoutAmountLocalCurrency || '';
  const currencyCode = data?.currencyCode || '';

  const onSuccess = () => {
    showToast({ text: 'Order confirmed', description: 'Will arrive in your email within 24h', type: 'success' });

    navigate('/shop');
  };

  const onError = () => {
    showToast({ text: 'Something went wrong. Please try again later.', type: 'error' });
  };

  const { mutate: buyGiftCard, isLoading: isBuyGiftCardLoading } = useBuyGiftCard({ onSuccess, onError });

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleConfirm = () => {
    if (state?.id) {
      buyGiftCard({ id: state.id });

      return;
    }
  };

  if (!state.type) {
    return null;
  }

  return (
    <>
      <BackButton onBack={handleBackClick} />
      <ShopItemSummary
        isVisible={!!value && !!price && !!state?.type}
        type={state.type}
        title={`${currencySymbols[currencyCode]}${value} ${TITLE_BY_TYPE[state.type]}`}
        creditsValue={price}
        newBalance={credits - price}
        balance={credits}
        onConfirm={handleConfirm}
        isLoading={isBuyGiftCardLoading}
        descriptionText={
          <>
            Gift cards will be sent directly to your email at <EmailText>{userEmail}</EmailText> within 24 hours. For
            additional information, please feel free to reach out to us
          </>
        }
      />
    </>
  );
};
