import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import mixpanel from 'mixpanel-browser';

import { SEARCH_PARAMS, COOKIES, LOCAL_STORAGE } from '@/utils/constants/common';

import { handleAppMount } from './actions';
import { IOS_PLATFORM } from './constants';

const MIXPANEL_TOKEN = process.env.VITE_MIXPANEL_TOKEN || 'eb0b49b77a7c9e23afffe8d739223329';

export const useAppMount = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies([
    COOKIES.APPSFLYER_IOS_ID,
    COOKIES.APPSFLYER_WEB_ID,
    COOKIES.FIREBASE_IOS_ID,
    COOKIES.FIREBASE_WEB_ID,
  ]);

  const referralCode = searchParams.get(SEARCH_PARAMS.REFERRAL_CODE);
  const initialAuthToken = searchParams.get(SEARCH_PARAMS.INITIAL_AUTH_TOKEN);
  const appsflyerAndroidId =
    searchParams.get(SEARCH_PARAMS.APPSFLYER_ANDROID_ID) || localStorage.getItem(LOCAL_STORAGE.APPSFLYER_ANDROID_ID);
  const appsflyerIosId = cookies[COOKIES.APPSFLYER_IOS_ID] || localStorage.getItem(LOCAL_STORAGE.APPSFLYER_IOS_ID);
  const appsflyerWebId = !appsflyerAndroidId && !appsflyerIosId ? cookies[COOKIES.APPSFLYER_WEB_ID] : null;
  const firebaseAndroidId =
    searchParams.get(SEARCH_PARAMS.FIREBASE_ANDROID_ID) || localStorage.getItem(LOCAL_STORAGE.FIREBASE_ANDROID_ID);
  const firebaseIosId = cookies[COOKIES.FIREBASE_IOS_ID] || localStorage.getItem(LOCAL_STORAGE.FIREBASE_IOS_ID);
  const firebaseWebId = (cookies[COOKIES.FIREBASE_WEB_ID] || '').slice(6);

  return useEffect(() => {
    dispatch(
      handleAppMount({
        referralCode,
        initialAuthToken,
        appsflyerAndroidId,
        appsflyerIosId,
        appsflyerWebId,
        firebaseAndroidId,
        firebaseIosId,
        firebaseWebId,
      })
    );

    mixpanel.init(MIXPANEL_TOKEN);
    mixpanel.people.set({});
  }, [referralCode, initialAuthToken]);
};

export const useIsIosApp = () => {
  const [cookies] = useCookies(['app-platform']);
  return cookies['app-platform'] === IOS_PLATFORM;
};
