import { Routes, Route } from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';
import { PublicRoute } from './PublicRoute';
import { ActivationRoute } from './ActivationRoute';
import { OnboardingRoute } from './OnboardingRoute';
import { NicknameRoute } from './NicknameRoute';
import { EmailVerificationRoute } from './EmailVerificationRoute';

import { AuthLoader } from '@/components/AuthLoader';
import { useAuth } from '@/features/Auth/hooks';

import { Login } from '@/pages/Login/Login';

import { Games } from '@/pages/Games';
import { Game } from '@/pages/Game';
import { Tournaments } from '@/pages/Tournaments/Tournaments';
import { Events } from '@/pages/Events/Events';
import { SignUpFlowReferralForm } from '@/pages/SignUpFlowReferralForm';
import { SettingsReferralForm } from '@/features/EnterReferral/components/SettingsReferralForm';

import { ContestPage } from '@/pages/Contest';
import { Onboarding } from '@/pages/Onboarding';
import { RedeemRewards } from '@/pages/RedeemRewards';
import { Nickname } from '@/pages/Nickname';
import { Referrals } from '@/pages/Referrals';

import { ProfilePage } from '@/pages/Profile';
import { ActivationTutorial } from '@/pages/ActivationTutorial';

import { TABS } from '@/features/Contest/constants';
import { TicketInputContainer } from '@/features/Contest/components/TicketsInputContainer';
import { ContestDescription } from '@/features/Contest/components/ContestDescription';
import { ContestPrizes } from '@/features/Contest/components/ContestPrizes';
import { Settings } from '@/features/Settings/components/Settings';
import { DeleteAccount } from '@/features/Settings/components/DeleteAccount';
import { ComingSoonGame } from '@/features/ComingSoonGame/components/ComingSoonGame';
import { UsdcShopItemContainer } from '@/features/ShopItem/components/UsdcShopItemContainer';
import { UsdcShopItemSummaryContainer } from '@/features/ShopItem/components/UsdcShopItemSummaryContainer';
import { GiftCardShopItemContainer } from '@/features/ShopItem/components/GiftCardShopItemContainer';
import { GiftCardShopItemSummaryContainer } from '@/features/ShopItem/components/GiftCardShopItemSummaryContainer';
import { GameActivation } from '@/pages/GameActivation';
import { Feedback } from '@/pages/Feedback';
import { ConfirmEmail } from '@/features/EmailVerification/components/ConfirmEmail';
import { EmailVerification } from '@/features/EmailVerification/components/EmailVerification';
import { NotificationsList } from '@/features/NotificationsList/components/NotificationsList';
import { ContestsPage } from '@/pages/Contests';
import { ShopPage } from '@/pages/Shop';

export const Router = () => {
  const { isAuthLoading } = useAuth();

  if (isAuthLoading) {
    return <AuthLoader />;
  }

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/contests" element={<ContestsPage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/games" element={<Games />} />
        <Route path="/games/soon/:id" element={<ComingSoonGame />} />
        <Route path="/games/:id/:campaignId" element={<Game />} />
        <Route path="/tournaments/*" element={<Tournaments />} />
        <Route path="/events" element={<Events />} />
        <Route path="/usdc-shop-item" element={<UsdcShopItemContainer />} />
        <Route path="/usdc-shop-item/summary" element={<UsdcShopItemSummaryContainer />} />
        <Route path="/gift-card-shop-item" element={<GiftCardShopItemContainer />} />
        <Route path="/gift-card-shop-item/summary" element={<GiftCardShopItemSummaryContainer />} />
        <Route path="/contest/:uid" element={<ContestPage />} />
        <Route path="/rewards" element={<RedeemRewards />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/activation-tutorial" element={<ActivationTutorial />} />
        <Route path="/enter-referral" element={<SettingsReferralForm />} />
        <Route path="/notifications-list" element={<NotificationsList />} />
        <Route element={<ContestPage />}>
          <Route path={`/contest/:uid/${TABS.ENTRY}`} element={<TicketInputContainer />} />
          <Route path={`/contest/:uid/${TABS.WINNERS}`} element={<ContestPrizes />} />
          <Route path={`/contest/:uid/${TABS.DESCRIPTION}`} element={<ContestDescription />} />
          <Route path={`/contest/:uid/${TABS.PRIZE}`} element={<ContestPrizes />} />
        </Route>
      </Route>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Login />} />
      </Route>
      <Route element={<EmailVerificationRoute />}>
        <Route path="/email-verification" element={<EmailVerification />} />
      </Route>
      <Route element={<ActivationRoute />}>
        <Route path="/sign-up-referral" element={<SignUpFlowReferralForm />} />
      </Route>
      <Route element={<NicknameRoute />}>
        <Route path="/nickname" element={<Nickname />} />
      </Route>
      <Route element={<OnboardingRoute />}>
        <Route path="/onboarding" element={<Onboarding />} />
      </Route>
      <Route path="/activate-games" element={<GameActivation />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
    </Routes>
  );
};
