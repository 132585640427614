export enum REWARD_NOTIFICATION_TYPE {
  SIGN_UP_REWARD = 'SIGN_UP_REWARD',
  CONTEST_CREDITS_REWARD = 'CONTEST_CREDITS_REWARD',
  CONTEST_ITEMS_REWARD = 'CONTEST_ITEMS_REWARD',
  COMPLETE_PROFILE_REWARD = 'COMPLETE_PROFILE_REWARD',
}

export const PLACE_EXTENSION_MAP = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};
