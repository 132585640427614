import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

interface IReferrals {
  isInfoModalVisible: boolean;
}

const initialState: IReferrals = {
  isInfoModalVisible: false,
};

export const referralsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setInfoModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isInfoModalVisible = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setInfoModalVisible, resetState } = referralsSlice.actions;
