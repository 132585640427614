import styled, { DefaultTheme } from 'styled-components';

type TTextType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p_l' | 'p_m' | 'p_s' | 'p_xs';
type TColorType = 'normal' | 'light' | 'extraLight';

interface IText {
  type?: TTextType;
  colorType?: TColorType;
  align?: 'left' | 'center' | 'right';
  weight?: number;
}

const getColor = (colorType: TColorType, theme: DefaultTheme) =>
  ({
    normal: {
      color: theme.colors.purple.primary['50'],
    },
    light: {
      color: theme.colors.purple.primary['200'],
    },
    extraLight: {
      color: theme.colors.purple.primary['300'],
    },
  }[colorType]);

const getStyleByType = (type: TTextType) =>
  ({
    h1: {
      fontSize: 32,
      letterSpacing: 0.25,
      fontWeight: 400,
    },
    h2: {
      fontSize: 24,
      letterSpacing: 0,
      fontWeight: 400,
    },
    h3: {
      fontSize: 20,
      letterSpacing: 0.15,
      fontWeight: 400,
    },
    h4: {
      fontSize: 16,
      letterSpacing: 0.4,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      letterSpacing: 0.15,
      fontWeight: 600,
    },
    h6: {
      fontSize: 12,
      letterSpacing: 0.1,
      fontWeight: 600,
    },
    p_l: {
      fontSize: 16,
      letterSpacing: 0.15,
      fontWeight: 400,
    },
    p_m: {
      fontSize: 14,
      letterSpacing: 0.1,
      fontWeight: 400,
    },
    p_s: {
      fontSize: 12,
      letterSpacing: 0.4,
      fontWeight: 400,
    },
    p_xs: {
      fontSize: 10,
      letterSpacing: 0.5,
      fontWeight: 400,
    },
  }[type]);

export const Text = styled.div<IText>(({ type = 'p_m', align = 'left', colorType = 'normal', theme, weight }) => ({
  ...getStyleByType(type),
  ...getColor(colorType, theme),
  textAlign: align,
  ...(weight ? { fontWeight: weight } : {}),
}));
