export const styledPropsMatcher = (modifiers: any) => {
  return (params: any) => {
    const propsKeys = Object.keys(modifiers);
    const matchingModifier = Object.keys(params)
      .filter((key) => typeof params[key] === 'boolean' && params[key])
      .filter((param) => propsKeys.includes(param))
      .pop();

    if (!matchingModifier) {
      return false;
    }
    return modifiers[matchingModifier];
  };
};
