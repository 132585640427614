export const rootAuthenticatedRoute = '/games';

export const routesWithoutHeaderAndFooter = [
  '/settings',
  '/delete-account',
  '/profile',
  '/feedback',
  '/how-to-get-a-code',
  '/activation-tutorial',
  '/email-verification',
  '/enter-referral',
  '/notifications-list',
];
export const routesWithoutFooter = [
  '/usdc-shop-item',
  '/usdc-shop-item/summary',
  '/gift-card-shop-item',
  '/gift-card-shop-item/summary',
];
export const heightSpecifiedPaths = ['/activation-tutorial'];
export const routesWithNoSidePadding = ['/games', '/profile', '/shop', '/contests'];
export const logoutMenuPaths = ['/nickname', '/sign-up-referral', '/how-to-get-a-code', '/onboarding'];
