import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSendEmailVerification } from 'react-firebase-hooks/auth';
import { useEffect, useState } from 'react';

import { AuthBackButton } from '@/components/AuthBackButton';
import { Text } from '@/components/Text';
import { logout } from '@/features/Auth/actions';
import { useAuth } from '@/features/Auth/hooks';
import { Button } from '@/components/Button/Button';
import { firebaseAuth } from '@/utils/firebase';
import { showToast } from '@/utils/toast';
import { setShouldRefreshToken } from '@/features/Auth/slice';

const MarginBottomText = styled(Text)({
  marginBottom: 24,
});

const Paragraph = styled(Text)({
  margin: '16px 0',
});

const StyledButton = styled(Button)({
  width: '100%',
});

export const EmailVerification = () => {
  const dispatch = useDispatch();
  const [sendVerificationEmail, isLoading] = useSendEmailVerification(firebaseAuth);
  const [isButtonVisible, setButtonVisible] = useState(true);
  const { userEmail } = useAuth();

  useEffect(() => {
    dispatch(setShouldRefreshToken(true));

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        dispatch(setShouldRefreshToken(true));
      }
    });
  }, []);

  const onBack = () => {
    dispatch(logout());
  };

  const onButtonClick = async () => {
    const success = await sendVerificationEmail();

    if (success) {
      showToast({ type: 'success', text: 'Email has been sent successfully!' });
      setButtonVisible(false);
    }

    if (!success) {
      showToast({ type: 'error', text: 'Too many attempts. Try again later.' });
    }
  };

  return (
    <div>
      <AuthBackButton onBack={onBack} />
      <MarginBottomText type="h2" weight={600} align="center">
        Please verify your email
      </MarginBottomText>
      <Text colorType="light" align="center">
        You're almost there! We sent an email to
      </Text>
      <Text align="center">{userEmail}</Text>
      <Paragraph colorType="light" align="center">
        Just click on the link in that email to complete your signup. If you don't see it, you may need to check your
        spam folder
      </Paragraph>
      {isButtonVisible && (
        <>
          <MarginBottomText colorType="light" align="center">
            Still can't find the email? No problem
          </MarginBottomText>
          <StyledButton buttonType="secondary" onClick={onButtonClick} disabled={isLoading}>
            Resend verification email
          </StyledButton>
        </>
      )}
    </div>
  );
};
