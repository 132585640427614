import styled from 'styled-components';
import { Text } from '@/components/Text';
import background from '@/assets/images/app_country_unavailable.png';

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.darkPurple,
  border: `1px solid ${theme.colors.purple.primary['600']}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px 16px',
  borderRadius: 16,
  margin: '0 24px',
}));

const BackgroundImage = styled.div({
  backgroundImage: `url(${background})`,
  height: 150,
  width: 328,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

const Title = styled(Text)({
  margin: '24px 0 8px 0',
});

export const AppUnavailableCountry = () => (
  <Container>
    <BackgroundImage />
    <Title type="h3" weight={600} align="center">
      We haven't landed in your country yet!
    </Title>
    <Text colorType="light" align="center">
      We will notify you once we get to you!
    </Text>
  </Container>
);
