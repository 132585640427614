import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { LAUNCH_CAMPAIGN_END_TIME } from '../constants';
import { BybitCampaign } from './BybitCampaign';
import { LINKS } from '@/utils/constants/common';

export const BybitCampaignContainer = () => {
  const openLink = useOpenLink();

  const onClick = () => {
    openLink(LINKS.BYBIT_CAMPAIGN);
  };

  const isVisible = new Date().getTime() < LAUNCH_CAMPAIGN_END_TIME;

  return <BybitCampaign isVisible={isVisible} onClick={onClick} />;
};
