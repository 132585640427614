import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { BottomModal } from '@/components/BottomModal';
import { Text } from '@/components/Text';
// import { Icon } from '@/components/Icon/Icon';

import { setProvablyFairModalVisible } from '../slice';
import { isProvablyFairModalVisible } from '../selectors';
import { useContest } from '../queries';

const Container = styled.div({
  height: '80%',
  padding: '54px 32px',
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled(Text)({
  marginBottom: 8,
});

const Description = styled(Text)({
  marginBottom: 16,
});

const InfoContainer = styled.div({
  marginBottom: 16,
});

const InfoTitle = styled(Text)(({ theme }) => ({
  marginBottom: 8,
  color: theme.colors.purple.primary['200'],
}));

const InfoBox = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.darkPurple,
  borderRadius: 8,
  marginTop: 4,
  width: '100%',
  padding: '10px 12px',
  color: theme.colors.purple.primary['100'],
  cursor: 'pointer',
}));

// const MoreInfoBox = styled.div(({ theme }) => ({
//   backgroundColor: theme.colors.purple.primary['800'],
//   borderRadius: 8,
//   padding: 16,
//   display: 'flex',
//   marginTop: 'auto',
// }));

const StyledText = styled(Text)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

// const MoreInfoLink = styled.a(({ theme }) => ({
//   color: theme.colors.purple.primary['50'],
//   marginTop: 4,
//   fontSize: 12,
//   lineHeight: '14px',
//   textDecoration: 'underline',
// }));

// const StyledIcon = styled(Icon)({
//   marginRight: 12,
// });

export const ProvablyFair = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isProvablyFairModalVisible);
  const { uid: contestId = '' } = useParams();
  const { data } = useContest({ contestId });

  const formattedTickets = data?.userTickets
    ?.map((ticketRange) =>
      ticketRange.ticketFrom === ticketRange.ticketTo
        ? ticketRange.ticketFrom
        : `${ticketRange.ticketFrom}-${ticketRange.ticketTo}`
    )
    .join(', ');

  const onClose = () => {
    dispatch(setProvablyFairModalVisible(false));
  };

  return (
    <BottomModal isVisible={isVisible} onClose={onClose}>
      <Container>
        <Title type="h3" weight={600}>
          Provably Fair information
        </Title>
        <Description type="p_s">
          The outcome of every box is randomly generated using the following information
        </Description>
        {!!formattedTickets && (
          <InfoContainer>
            <InfoTitle type="p_s">Ticket number</InfoTitle>
            <CopyToClipboard text={formattedTickets || ''}>
              <InfoBox>
                <StyledText>{formattedTickets}</StyledText>
              </InfoBox>
            </CopyToClipboard>
          </InfoContainer>
        )}
        <InfoContainer>
          <InfoTitle type="p_s">Contest Seed Hash</InfoTitle>
          <CopyToClipboard text={data?.seedHash || ''}>
            <InfoBox>
              <StyledText>{data?.seedHash}</StyledText>
            </InfoBox>
          </CopyToClipboard>
        </InfoContainer>
        {!!data?.seed && (
          <InfoContainer>
            <InfoTitle type="p_s">Contest Seed</InfoTitle>
            <CopyToClipboard text={data?.seed}>
              <InfoBox>
                <StyledText>{data?.seed}</StyledText>
              </InfoBox>
            </CopyToClipboard>
          </InfoContainer>
        )}
        {!!data?.wnonce && (
          <InfoContainer>
            <InfoTitle type="p_s">Winner nonce</InfoTitle>
            <CopyToClipboard text={`${data?.wnonce || 0}`}>
              <InfoBox>
                <Text>{data?.wnonce}</Text>
              </InfoBox>
            </CopyToClipboard>
          </InfoContainer>
        )}
        {!!data?.ticketsAmount && (
          <InfoContainer>
            <InfoTitle type="p_s">Tickets amount</InfoTitle>
            <CopyToClipboard text={`${data?.ticketsAmount}`}>
              <InfoBox>
                <Text>{data?.ticketsAmount}</Text>
              </InfoBox>
            </CopyToClipboard>
          </InfoContainer>
        )}
        {/* <MoreInfoBox>
          <StyledIcon name="info" />
          <div>
            <Text colorType="light" type="p_s">
              Want to understand more about how our provably fair system works?
            </Text>
            <MoreInfoLink target="_blank">Check out our guide here</MoreInfoLink>
          </div>
        </MoreInfoBox> */}
      </Container>
    </BottomModal>
  );
};
