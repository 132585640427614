import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { GameCardBig } from '@/components/GameCardBig';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import { Swiper } from 'swiper/types';

import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';

import { useGetCampaignByStoreId } from '../queries';
import { IGames } from '../types';

interface IHotPicksCarousel {
  data: IGames;
}

interface IIndicatorProps {
  isActive: boolean;
}

const StyledGameCard = styled(GameCardBig)({
  width: '100%',
});

const StyledSwiperSlide = styled(SwiperSlide)({
  padding: '0 24px',
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginLeft: 4,
}));

const IndicatorsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 4,
});

const Indicator = styled.div<IIndicatorProps>(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.colors.yellow.primary['500'] : '#522177',
  margin: 4,
  width: 8,
  height: 8,
  borderRadius: 4,
}));

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
  paddingLeft: 24,
});

export const HotPicksCarouselComponent = ({ data }: IHotPicksCarousel) => {
  const navigate = useNavigate();
  const getCampaignByStoreId = useGetCampaignByStoreId();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleOpenGame = (slug: string, storeId: string) => {
    const campaign = getCampaignByStoreId(storeId);
    navigate(`/games/${slug}/${campaign?.id}`);
  };

  const handleSlideChange = (swiper: Swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <>
      <TitleContainer>
        <Icon name="flame" />
        <Title weight={600}>Hot pick</Title>
      </TitleContainer>
      <SwiperComponent slidesPerView={1} onSlideChange={handleSlideChange}>
        {data.map((game) => (
          <StyledSwiperSlide key={game.slug}>
            <StyledGameCard
              slug={game.slug}
              gameStoreId={(game.androidStoreID || game.iosStoreID) as string}
              image={game.storageImages.backgroundImage}
              logo={game.storageImages.logo}
              onOpenClick={handleOpenGame}
              title={game.title}
              genres={game.genre}
            />
          </StyledSwiperSlide>
        ))}
      </SwiperComponent>
      <IndicatorsContainer>
        {[...Array(data.length)].map((_, index) => (
          <Indicator key={`${index}Indicator`} isActive={index === activeIndex} />
        ))}
      </IndicatorsContainer>
    </>
  );
};

export const HotPicksCarousel = hideable(HotPicksCarouselComponent);
