import { useEffect } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import { FormikHelpers } from 'formik';
import { AuthErrorCodes } from 'firebase/auth';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '@/utils/firebase';
import { EmailSignForm, IEmailSignFormValues } from './forms/EmailSignInForm';
import { Link } from '@/components/Link/Link';
import { Text } from '@/components/Text';
import { showToast } from '@/utils/toast';

const StyledLink = styled(Link)({
  marginTop: 16,
});

export const SignInWithEmail = () => {
  const [signInWithEmailAndPassword, _user, loading, error] = useSignInWithEmailAndPassword(firebaseAuth);

  const handleFormSubmit = async (
    { email, password }: IEmailSignFormValues,
    { setSubmitting }: FormikHelpers<IEmailSignFormValues>
  ) => {
    const success = await signInWithEmailAndPassword(email, password);

    if (success) {
      setSubmitting(false);

      return;
    }

    Sentry.configureScope((scope) => scope.setLevel('error').setUser({ email }));
  };

  useEffect(() => {
    if (error) {
      switch (error.code) {
        case AuthErrorCodes.INVALID_PASSWORD:
          showToast({ type: 'error', text: 'Wrong password' });
          return;
        case AuthErrorCodes.USER_DELETED:
          showToast({ type: 'error', text: 'We couldn`t find this user' });
          return;
        default:
          showToast({ type: 'error', text: 'Something has gone wrong. Please inform our support team via Discord' });
          Sentry.captureException(error);
      }
    }
  }, [error]);

  return (
    <>
      <div className="text-purple-100 text-2xl font-semibold mb-6">Login with Email</div>
      <EmailSignForm submitText={loading ? 'Logging in...' : 'Log in'} onSubmit={handleFormSubmit} />
      <StyledLink to="/register" className="z-10">
        <Text type="p_m" weight={600}>
          Create new account
        </Text>
      </StyledLink>
    </>
  );
};
