import { useRef } from 'react';
import styled from 'styled-components';

import { useOutsideClick } from '@/utils/hooks/useOutsideClick';
import hideable from '@/utils/hideable';
import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';

interface IMenu {
  onClose: () => void;
  onLogout: () => void;
}

const Container = styled.div({
  position: 'absolute',
  top: 40,
  left: -5,
});

const ArrowBox = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  boxShadow: `2px 2px 0px 0px ${theme.colors.neutral.primary['1000']}`,
  borderRadius: 6,
  padding: '8px 16px',
  position: 'relative',
  cursor: 'default',
  '&:after': {
    content: '" "',
    position: 'absolute',
    left: '10px',
    top: '-6px',
    borderTop: 'none',
    borderRight: '12px solid transparent',
    borderLeft: '12px solid transparent',
    borderBottom: `6px solid ${theme.colors.purple.primary['600']}`,
  },
}));

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  marginLeft: 8,
}));

const LogoutButton = styled.button({
  background: 'none',
  border: 'none',
  outline: 'none',
  margin: 0,
  padding: '4px 8px',
  display: 'flex',
  alignItems: 'center',
});

const MenuComponent = ({ onClose, onLogout }: IMenu) => {
  const ref = useRef(null);

  useOutsideClick(ref, onClose);

  return (
    <Container ref={ref}>
      <ArrowBox>
        <LogoutButton onClick={onLogout}>
          <Icon name="logout" />
          <StyledText type="p_s" weight={600}>
            Logout
          </StyledText>
        </LogoutButton>
      </ArrowBox>
    </Container>
  );
};

export const Menu = hideable(MenuComponent);
