import { useState } from 'react';
import styled from 'styled-components';

import { Text } from './Text';
import { Icon } from './Icon/Icon';

interface IFaqToggle {
  title: string;
  children: React.ReactNode;
}

const Container = styled.div(({ theme }) => ({
  margin: '0 -24px',
  padding: '0 24px 0 24px',
  borderTop: `1px solid ${theme.colors.purple.primary['600']}`,
}));

const TitleContainer = styled.div({
  display: 'flex',
  padding: '24px 0 24px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

const FaqContent = styled.div({
  paddingBottom: 24,
});

export const FaqToggle = ({ title, children, ...rest }: IFaqToggle) => {
  const [isOpen, setIsOpen] = useState(false);

  const onFaqToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Container {...rest}>
      <TitleContainer onClick={onFaqToggle}>
        <Text weight={600}>{title}</Text>
        <Icon name={isOpen ? 'chevronUpLight' : 'chevronDownLight'} />
      </TitleContainer>
      {isOpen && <FaqContent>{children}</FaqContent>}
    </Container>
  );
};
