import { createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface IAddToHomeScreen {
  isAddToHomeScreenHidden: boolean;
}

const initialState: IAddToHomeScreen = {
  isAddToHomeScreenHidden: false,
};

export const addToHomeScreenSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setAddToHomeScreenHidden: (state) => {
      state.isAddToHomeScreenHidden = true;
    },
    resetState: () => initialState,
  },
});

export const { setAddToHomeScreenHidden, resetState } = addToHomeScreenSlice.actions;
