import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@/components/Text';
import { BottomModal } from '@/components/BottomModal';

import { isInfoModalVisible } from '../selectors';
import { setInfoModalVisible } from '../slice';

const ContentContainer = styled.div({
  padding: '54px 32px',
});

const Title = styled(Text)({
  marginBottom: 32,
});

const Subtitle = styled(Text)({
  marginBottom: 8,
});

const MainText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginBottom: 32,
}));

export const InfoModal = () => {
  const dispatch = useDispatch();

  const isModalVisible = useSelector(isInfoModalVisible);

  const onClose = () => {
    dispatch(setInfoModalVisible(false));
  };

  return (
    <BottomModal isVisible={isModalVisible} onClose={onClose}>
      <ContentContainer>
        <Title type="h3" weight={600}>
          Earn credits by inviting your friends
        </Title>
        <Subtitle type="p_l" weight={600}>
          Activity
        </Subtitle>
        <MainText>Get a bonus when your invitees reach a credit milestone through platform engagement.</MainText>
        <Subtitle type="p_l" weight={600}>
          Virality
        </Subtitle>
        <MainText>
          Earn extra for 'friends of friends' activity. The more active connections, the higher your reward.
        </MainText>
      </ContentContainer>
    </BottomModal>
  );
};
