import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';

interface IVoucherPreview {
  title: string;
  image: string;
}

const initialState: IVoucherPreview = {
  title: '',
  image: '',
};

export const voucherPreviewSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    openVoucherPreview: (state, { payload }: PayloadAction<IVoucherPreview>) => {
      state.title = payload.title;
      state.image = payload.image;
    },
    resetState: () => initialState,
  },
});

export const { openVoucherPreview, resetState } = voucherPreviewSlice.actions;
