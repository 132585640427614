import styled from 'styled-components';

import { NotificationModal } from '@/components/NotificationModal';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as ContestPrize } from '@/assets/images/contest_prize.svg';

import { PLACE_EXTENSION_MAP } from '../constants';
import { IReward } from '../queries';

interface IContestItemsRewardNotification {
  onClose: () => void;
  onReachSupportClick: () => void;
  rewards: IReward[];
  place: 1 | 2 | 3;
}

const NotificationContentContainer = styled.div({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const RewardContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 8,
});

const Title = styled(Text)({
  margin: '8px 0 4px 0',
});

const Description = styled(Text)({
  margin: '8px 0',
});

const StyledIcon = styled(Icon)({
  marginRight: 4,
});

const CongratulationsImage = styled(ContestPrize)({
  width: 248,
  margin: 24,
});

const Divider = styled.hr(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.purple.primary['500']}`,
  borderTop: 'none',
  width: '100%',
  margin: '24px 0',
}));

const LightText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  cursor: 'pointer',
  fontWeight: 500,
}));

export const ContestItemsRewardNotification = ({
  onClose,
  onReachSupportClick,
  rewards,
  place,
}: IContestItemsRewardNotification) => (
  <NotificationModal isVisible onClose={onClose}>
    <NotificationContentContainer>
      <CongratulationsImage />
      <Title type="h2" align="center" weight={600}>
        Congratulations!
      </Title>
      <Description align="center" colorType="light">
        You won the
        {` ${place}${PLACE_EXTENSION_MAP[place]}`} place in the BlockGames Grand Prize contests
      </Description>
      {rewards.map((reward, index) => (
        <RewardContainer key={`${reward.name || reward.type}${index}`}>
          {reward.type === 'item' && <StyledIcon name="itemSmall" />}
          {reward.type === 'credits' && <StyledIcon name="creditSmall" />}
          <Text type="p_l" weight={600}>
            {!!reward.name && reward.name}
            {!!reward.creditsAmount && `${reward.creditsAmount} credits`}
          </Text>
        </RewardContainer>
      ))}
      <Divider />
      <Text type="p_s" colorType="extraLight" weight={400} align="center">
        Have a question? <LightText onClick={onReachSupportClick}>Reach out to our support</LightText>
      </Text>
    </NotificationContentContainer>
  </NotificationModal>
);
