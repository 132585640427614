import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME, SUB_TABS } from './constants';

interface IContests {
  activeSubTab: string;
}

const initialState: IContests = {
  activeSubTab: SUB_TABS.ACTIVE,
};

export const contestsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setActiveSubTab: (state, action: PayloadAction<string>) => {
      state.activeSubTab = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setActiveSubTab, resetState } = contestsSlice.actions;
