import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';

interface IBackButton {
  onBack?: () => void;
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  margin: '0 0 22px 0',
  cursor: 'pointer',
});

const StyledText = styled(Text)({
  marginLeft: 8,
});

export const BackButton = ({ onBack, ...rest }: IBackButton) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (onBack) {
      onBack();

      return;
    }

    navigate(-1);
  };

  return (
    <Container onClick={onClick} {...rest}>
      <Icon name="chevronLeftSmall" />
      <StyledText type="p_s" weight={500}>
        Back
      </StyledText>
    </Container>
  );
};
