import { styledPropsMatcher } from '@/utils/helpers/styledPropsMatcher';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

const getColor = styledPropsMatcher({
  success: '#00ed42',
  error: 'red',
});

export const Indicator = styled.div<HTMLAttributes<HTMLDivElement> & { error?: boolean; success?: boolean }>`
  width: 10px;
  height: 10px;
  background: ${(params) => getColor(params)};
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 50%;
`;
