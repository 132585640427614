import { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

import { firebaseAuth } from '@/utils/firebase';
import { Button } from '@/components/Button/Button';
import { FormInput } from '@/components/Input/FormInput';
import { ErrorMessage as CustomErrorMessage } from '@/components/ErrorMessage/ErrorMessage';
import { Icon } from '@/components/Icon/Icon';

import { SuccessMessage } from './components/SuccessMessage';
import { showToast } from '@/utils/toast';

const actionCodeSettings = {
  url: `${process.env.VITE_FIREBASE_EMAIL_CONTINUE_URL}create-new-password`,
};

const EmailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const CreateNewPassword = () => {
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(firebaseAuth);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (error) {
      showToast({ type: 'error', text: error.message });
    }
  }, [error]);

  return (
    <>
      {showSuccessMessage ? (
        <SuccessMessage message="Reset email has been send to your inbox." />
      ) : (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={EmailValidationSchema}
          onSubmit={async ({ email }, { setSubmitting }) => {
            const success = await sendPasswordResetEmail(email, actionCodeSettings);

            if (success) {
              setSubmitting(false);
              setShowSuccessMessage(true);
              return;
            }
          }}
        >
          {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => (
            <StyledForm>
              <FormInput
                label="Email"
                name="email"
                className="mb-3"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                suffix={dirty && (errors.email ? <Icon name="cross" /> : <Icon name="successTick" />)}
              />

              <ErrorMessage
                name="email"
                render={(msg) => <CustomErrorMessage className="ml-5">{msg}</CustomErrorMessage>}
              />

              <Button className="mt-3" type="submit" disabled={isSubmitting}>
                {sending ? 'Sending...' : 'Reset password'}
              </Button>
            </StyledForm>
          )}
        </Formik>
      )}
    </>
  );
};
