import styled from 'styled-components';

import hideable from '@/utils/hideable';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { LINKS } from '@/utils/constants/common';
import { Button } from '@/components/Button/Button';
import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { NotificationModal } from '@/components/NotificationModal';
import downloadAppCover from '@/assets/images/download_bg_app.png';

const Container = styled.div({
  padding: '16px 24px 32px',
});

const Image = styled.img({
  width: '100%',
});

const StyledButton = styled(Button)({
  width: '100%',
  marginBottom: 16,
});

const StyledText = styled(Text)({
  marginBottom: 16,
});

const FlexRow = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.colors.purple.primary[200],
  fontWeight: 400,
  fontSize: 12,
}));

const StyledIcon = styled(Icon)({
  margin: '0 6px',
});

const DownloadAppComponent = () => {
  const openLink = useOpenLink();

  const onButtonClick = () => openLink(LINKS.GOOGLE_PLAY);

  return (
    <NotificationModal isVisible>
      <Image src={downloadAppCover} />
      <Container>
        <StyledText type="h3" weight={600} align="center">
          Download BlockGames: Rewarding Play app
        </StyledText>
        <StyledButton onClick={onButtonClick}>Download</StyledButton>
        <FlexRow>
          On <StyledIcon name="googlePlayColor" /> Google Play
        </FlexRow>
      </Container>
    </NotificationModal>
  );
};

export const DownloadApp = hideable(DownloadAppComponent);
