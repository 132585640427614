import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import failedActivation from '@/assets/images/failed-activation.png';
import { Text } from '@/components/Text';
import { Button } from '@/components/Button/Button';
import { Icon } from '@/components/Icon/Icon';

import { useSetCampaignRemoved } from '../queries';

const InfoBoxContainer = styled.div(({ theme }) => ({
  padding: '32px 16px',
  backgroundColor: theme.colors.darkPurple,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexDirection: 'column',
  whiteSpace: 'pre-wrap',
  borderRadius: 16,
  boxShadow: '2px 2px 0px 0px #000000',
  marginTop: 24,
}));

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const FailedActivationImage = styled.img({
  width: '100%',
});

const Paragraph = styled(Text)({
  margin: '4px 0 24px 0',
});

const StyledButton = styled(Button)({
  height: 40,
});

const failureReasons = [
  {
    title: 'New users only:',
    description: 'Activation is available exclusively for new sign ups in games.',
  },
  {
    title: 'New mobile devices:',
    description: 'You haven’t played this game ever on your phone, or had it previously installed.',
  },
  {
    title: 'Disable VPN:',
    description: 'Ensure that your VPN is turned off during the activation process.',
  },
  {
    title: 'Changing IP:',
    description:
      'Your IP address, that was used at the moment of your sign up, doesn’t match with the current one when activating.',
  },
  {
    title: 'Mobile Device Requirement:',
    description: 'The game must be activated on a mobile device. Emulators are not allowed.',
  },
];

export const GameActivationFailed = ({ slug, campaignId }: { slug: string; campaignId?: string }) => {
  const navigate = useNavigate();

  const { mutate: removeCampaign, isLoading } = useSetCampaignRemoved({
    onSettled: () => {
      navigate(-1);
    },
  });
  const onClick = () => {
    removeCampaign({ slug, campaignId });
  };

  return (
    <Container>
      <InfoBoxContainer>
        <FailedActivationImage src={failedActivation} alt="failed-activation" />
        <Text align="center" type="h3" weight={600}>
          Oops, this game cannot be activated
        </Text>
        <Paragraph type="p_s" weight={400} align="center" colorType="extraLight">
          But don't worry, there are plenty more games to explore!
        </Paragraph>
        <StyledButton disabled={isLoading} onClick={onClick}>
          Remove from games list
        </StyledButton>
      </InfoBoxContainer>
      <Text type="p_l" weight={600} className="!text-center mt-8">
        Why activation failed?
      </Text>
      {failureReasons.map((reason, index) => (
        <div className="flex mt-4" key={index}>
          <div className="mt-1 mr-1">
            <Icon name="redPointyStar" />
          </div>
          <div className="flex items-center">
            <Text type="p_m" weight={400} className="!text-[#DBC9E8] inline">
              <Text type="p_m" weight={600} className="inline">
                {reason.title}
              </Text>{' '}
              {reason.description}
            </Text>
          </div>
        </div>
      ))}
    </Container>
  );
};
