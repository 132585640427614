import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

interface IEnterReferral {
  referralCode: string;
  isActivated: boolean | null;
}

const initialState: IEnterReferral = {
  referralCode: '',
  isActivated: null,
};

export const enterReferralSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setReferralCode: (state, action: PayloadAction<string>) => {
      state.referralCode = action.payload;
    },
    setActivated: (state, action: PayloadAction<boolean>) => {
      state.isActivated = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setReferralCode, setActivated, resetState } = enterReferralSlice.actions;
