import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { NewResetPasswordForm } from '@/pages/Login/forms/NewPasswordForm';

import { useResetPassword, useVerifyEmail } from '../queries';
import { setIsEmailVerificationError, setIsEmailVerificationSuccess } from '../slice';
import { isVerificationSuccess, isVerificationError } from '../selectors';
import { SuccessEmailVerification } from './SuccessEmailVerification';
import { FailedEmailVerification } from './FailedEmailVerification';
import { LoadingEmailVerification } from './LoadingEmailVerification';

export const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const actionCode = searchParams.get('oobCode');
  const mode = searchParams.get('mode');

  const isSuccess = useSelector(isVerificationSuccess);
  const isLinkError = useSelector(isVerificationError);

  const onSuccess = () => {
    dispatch(setIsEmailVerificationSuccess(true));
  };

  const { mutate: verifyEmail, isError: isMutateError, isLoading } = useVerifyEmail({ onSuccess });
  const { mutate: resetPassword, isError: isPasswordChangeError } = useResetPassword({ onSuccess });

  const handleResetPassword = (password: string) => {
    if (!actionCode) {
      return;
    }

    if (!password) {
      return;
    }

    resetPassword({ actionCode, password });
  };

  useEffect(() => {
    if (mode === 'resetPassword' && actionCode) {
      return;
    }

    if (mode === 'verifyEmail' && actionCode) {
      verifyEmail({ actionCode });
      return;
    }

    dispatch(setIsEmailVerificationError(true));
  }, []);

  return (
    <>
      <NewResetPasswordForm
        isVisible={mode === 'resetPassword' && !!actionCode && !isSuccess && !isPasswordChangeError}
        onSubmit={handleResetPassword}
      />
      <SuccessEmailVerification
        isVisible={isSuccess}
        successText={mode === 'verifyEmail' ? 'Email verified!' : 'Password changed!'}
      />
      <FailedEmailVerification isVisible={isLinkError || isMutateError || isPasswordChangeError} />
      <LoadingEmailVerification isVisible={isLoading} />
    </>
  );
};
