export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const SEARCH_PARAMS = {
  TOKEN: 'token',
  REFERRAL_CODE: 'referral_code',
  USER_UID: 'uid',
  GAME_NAME: 'game',
  TWITTER_OAUTH_TOKEN: 'oauth_token',
  TWITTER_OAUTH_VERIFIER: 'oauth_verifier',
  INITIAL_AUTH_TOKEN: 'gat',
  APPSFLYER_ANDROID_ID: 'appsflyer_android_id',
  FIREBASE_ANDROID_ID: 'firebase_android_id',
};

export const COOKIES = {
  APPSFLYER_IOS_ID: 'appsflyerIosId',
  APPSFLYER_WEB_ID: 'afUserId',
  FIREBASE_IOS_ID: 'firebaseIosId',
  FIREBASE_WEB_ID: '_ga',
};

export const LOCAL_STORAGE = {
  APPSFLYER_ANDROID_ID: 'appsflyerAndroidId',
  APPSFLYER_IOS_ID: 'appsflyerIosId',
  FIREBASE_ANDROID_ID: 'firebaseAndroidId',
  FIREBASE_IOS_ID: 'firebaseIosId',
};

export enum LINKS {
  BYBIT_CAMPAIGN = 'https://partner.bybit.com/b/get500block',
  CHATBASE_BOT = 'https://www.chatbase.co/chatbot-iframe/sRh2HcMppskcQ-qgi_jH1',
  BG_TERMS = 'https://blockgames.com/terms-of-use',
  BG_PRIVACY_POLICY = 'https://blockgames.com/privacy-policy',
  FEEDBACK = 'https://blockgames.zendesk.com/hc/en-us/requests/new',
  TELEGRAM = 'https://t.me/playernetwork',
  GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=com.blockgames.arcade&hl=en&gl=US',
  TWITTER = 'https://twitter.com/home',
  TWITTER_BLOCKGAMES = 'https://twitter.com/GetBlockGames',
  UPP_LAUNCH_TASK = 'https://upp.blockgames.app/launch-tasks',
  TWITTER_FIRST_SOCIAL_POST = 'https://twitter.com/intent/tweet?text=Don%E2%80%99t%20miss%20out%20on%20%24BLOCK!%20%F0%9F%9A%80%0A%0A%40GetBlockGames%20is%20building%20the%20%231%20mobile%20gaming%20ecosystem.%0A%0ADrop%20a%20comment%20%26%20lets%20farm%20together.%20%F0%9F%91%87',
}
