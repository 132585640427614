import { PillToggle } from '@/components/PillToggle/PillToggle';
import { Card } from '@/components/Card/Card';
import TrophyVisual from '@/assets/visuals/trophy_visual.svg';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SponsoredTournaments } from './components/SponsoredTournaments';
import { StandardTournaments } from './components/StandardTournaments';
import { useState } from 'react';

const VisualWrapper = styled.img`
  width: 300px;
  margin: 20px auto -20px;
`;

export const Tournaments = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('sponsored');

  const handlePillButton = (value: string) => {
    setActiveTab(value);
    navigate(`/tournaments/${value}`);
  };

  return (
    <div className="flex flex-col w-full">
      <VisualWrapper src={TrophyVisual} alt="" />
      <Card>
        <PillToggle
          className="my-3 -mt-5 mb-8"
          options={[
            { label: 'Sponsored', value: 'sponsored' },
            { label: 'Standard', value: 'standard' },
          ]}
          onChange={handlePillButton}
          selected={activeTab}
        />

        <Routes>
          <Route path="/sponsored" index element={<SponsoredTournaments />} />
          <Route path="/standard" element={<StandardTournaments />} />
          <Route path="/*" element={<SponsoredTournaments />} />
        </Routes>
      </Card>
    </div>
  );
};
