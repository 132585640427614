import { NotificationModal } from '@/components/NotificationModal';
import phonoMock from '@/assets/images/add_to_home_bg.png';

import styled from 'styled-components';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import hideable from '@/utils/hideable';

const Container = styled.div({
  padding: '16px 24px 32px',
});

const Image = styled.img({
  width: '100%',
});

const RowFlexbox = styled.div({
  display: 'flex',
  marginTop: 12,
});

const LightText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  whiteSpace: 'nowrap',
}));

const ShareText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  display: 'flex',
  alignItems: 'center',
}));

const StyledIcon = styled(Icon)({
  marginLeft: 4,
});

const StarIcon = styled(Icon)({
  marginTop: 4,
  marginRight: 8,
  minWidth: 12,
});

export const AddToHomeScreenComponent = () => (
  <NotificationModal isVisible>
    <Image src={phonoMock} />
    <Container>
      <Text type="h2" weight={600} align="center">
        Add BlockGames to your Home screen
      </Text>
      <RowFlexbox>
        <StarIcon name="purpleStar" />{' '}
        <Text colorType="extraLight">
          In your browser menu, tap the{' '}
          <ShareText>
            Share icon <StyledIcon name="share" />
          </ShareText>
        </Text>
      </RowFlexbox>
      <RowFlexbox>
        <StarIcon name="purpleStar" />{' '}
        <Text colorType="extraLight">
          Choose <LightText>Add to Home Screen</LightText> in the options. Then click <LightText>Add</LightText>
        </Text>
      </RowFlexbox>
      <RowFlexbox>
        <StarIcon name="purpleStar" />{' '}
        <Text colorType="extraLight">Open the BlockGames app from your home screen.</Text>
      </RowFlexbox>
    </Container>
  </NotificationModal>
);

export const AddToHomeScreen = hideable(AddToHomeScreenComponent);
