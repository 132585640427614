import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { initializeApp, getApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getRemoteConfig } from 'firebase/remote-config';

import { getAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';

const firebaseConfig = {
  projectId: process.env.VITE_FIREBASE_PROJECT_ID,
  appId: process.env.VITE_FIREBASE_APP_ID,
  storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
  apiKey: process.env.VITE_FIREBASE_API_KEY,
  authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const isFirebaseEmulator = process.env.VITE_ENABLE_LOCAL_FIREBASE_FUNCTIONS === 'true';

export const firebaseApp = initializeApp(firebaseConfig);
if (process.env.VITE_RECAPTCHA_KEY !== undefined && process.env.VITE_RECAPTCHA_KEY !== '') {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(process.env.VITE_RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
  });
}
export const firestore = initializeFirestore(firebaseApp, { host: process.env.VITE_FIRESTORE_HOST });
export const firebaseAuth = getAuth(firebaseApp);
export const remoteConfig = getRemoteConfig(firebaseApp);

remoteConfig.settings.minimumFetchIntervalMillis = 60000;
remoteConfig.defaultConfig = {
  is_extra_points_enabled: false,
  is_gift_cards_enabled: false,
  is_social_points_enabled: false,
};

export const firebaseFunctions = isFirebaseEmulator
  ? getFunctions(getApp())
  : getFunctions(firebaseApp, process.env.VITE_CF_DOMAIN);

export function httpsFunction<Request, Response>(functionName: string) {
  return isFirebaseEmulator
    ? httpsCallable<Request, Response>(firebaseFunctions, functionName)
    : httpsCallable<Request, Response>(firebaseFunctions, `cf/${functionName.toLowerCase()}`);
}

if (isFirebaseEmulator) {
  connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
}
