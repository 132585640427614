import styled from 'styled-components';

import { Text } from './Text';
import hideable from '@/utils/hideable';

const Badge = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.red,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 26,
  width: 100,
  position: 'absolute',
  borderTopLeftRadius: 8,
  borderBottomRightRadius: 8,
  left: 0,
  top: 0,
  boxShadow: `2px 2px 0px 0px ${theme.colors.purple.primary['800']}`,
}));

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['700'],
}));

const EndedIndicatorComponents = () => (
  <Badge>
    <StyledText type="p_s" weight={600}>
      Ended
    </StyledText>
  </Badge>
);

export const EndedIndicator = hideable(EndedIndicatorComponents);
