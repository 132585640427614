import { Timestamp, DocumentReference } from 'firebase/firestore';
import { ChallengeIcon } from './utils/challengeVisuals';

export enum SocMediaChallengeStatus {
  CLAIMED = 'CLAIMED',
  SHARED = 'SHARED',
  NOT_SHARED = 'NOT_SHARED',
}

export enum QuestType {
  SOCIAL = 'SOCIAL',
  CHALLENGE = 'CHALLENGE',
  DAILY = 'DAILY',
}

export enum SocialType {
  MEDIUM = 'medium',
  X = 'X',
  DISCORD = 'discord',
}

export enum ChallengeType {
  BASIC = 'BASIC',
  PROGRESS = 'PROGRESS',
  TIME_BASED = 'TIME_BASED',
}

export enum AffiliateCampaignProvider {
  TYRADS = 'TYRADS',
  AYET = 'AYET',
}

export interface IBaseChallengeFirestoreResponse {
  title: string;
  description: string;
  credits: number;
  endDate: Timestamp;
  isActive: boolean;
  type: ChallengeType;
  showAfterCompleted?: DocumentReference;
  shouldShowNewStatus?: boolean;
  icon?: ChallengeIcon;
}

export interface IUserChallengeFirestoreResponse {
  canClaim: boolean;
  challengeID: DocumentReference;
  isClaimed: boolean;
  seenAt?: Timestamp;
  progress?: number;
  progressDescription?: string;
}

export interface IChallenge {
  baseChallengeID: string;
  userChallengeID: string;
  endDate: Timestamp;
  isActive: boolean;
  title: string;
  type: ChallengeType;
  credits: number;
  canClaim: boolean;
  isClaimed: boolean;
  progress?: number;
  progressDescription?: string;
  description?: string;
  seenAt?: Timestamp;
  isNew?: boolean;
  icon: ChallengeIcon;
}

export interface ISocialMediaChallenge {
  credits: number;
  isActive: boolean;
  title: string;
  link: string;
  type: SocialType;
  status: SocMediaChallengeStatus;
}
