import styled from 'styled-components';
import { Countdown } from '@/components/Countdown/Countdown';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 24,
  color: theme.colors.purple.primary['200'],
}));

export const DailyPlayCountdown = () => {
  return (
    <Container>
      <div className="text-xs mr-2">Daily rewards restart in</div>
      <Countdown />
    </Container>
  );
};
