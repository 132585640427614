import { NotificationModal } from '@/components/NotificationModal';
import phonoMock from '@/assets/images/phone-mock.png';

import styled from 'styled-components';
import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';

const Container = styled.div({
  padding: '32px 24px',
});

const Image = styled.img({
  width: '100%',
});

const Paragraph = styled(Text)({
  marginTop: 16,
});

export const NotSupportedBrowserComponent = () => (
  <NotificationModal isVisible>
    <Container>
      <Image src={phonoMock} />
      <Text type="h2" weight={600} align="center">
        Unsupported Browser Detected
      </Text>
      <Paragraph colorType="light" align="center">
        To install the app, you need to place our site on your home screen.
      </Paragraph>
      <Paragraph colorType="light" align="center">
        Use Safari to navigate to BlockGames.app and proceed with the app installation.
      </Paragraph>
    </Container>
  </NotificationModal>
);

export const NotSupportedBrowser = hideable(NotSupportedBrowserComponent);
