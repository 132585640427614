import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { TicketsProgressIndicator } from '@/components/TicketsProgressIndicator';
import { TimeProgressIndicator } from '@/components/TimeLeftIndicator';
import { Text } from '@/components/Text';
import { ProvablyFairBadge } from '@/components/ProvablyFairBadge';
import { PillToggle } from '@/components/PillToggle/PillToggle';
import { EndedIndicator } from '@/components/EndedIndicator';

import { ProvablyFair } from './ProvablyFair';
import { useContest } from '../queries';
import { TABS } from '../constants';
import { resetState, setActiveTab, setProvablyFairModalVisible } from '../slice';

interface IStyledContestItemProps {
  image?: string;
  isMarked: boolean;
}

const TABS_OPTIONS = [
  {
    value: TABS.ENTRY,
    label: 'Entry',
  },
  {
    value: TABS.PRIZE,
    label: 'Prize',
  },
  {
    value: TABS.DESCRIPTION,
    label: 'Description',
  },
];

const ENDED_TABS_OPTIONS = [
  {
    value: TABS.WINNERS,
    label: 'Winners',
  },
  {
    value: TABS.DESCRIPTION,
    label: 'Description',
  },
];

const Container = styled.div({});

const ImageContainer = styled.div<IStyledContestItemProps>(({ image, isMarked, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: 80,
  width: '100%',
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  border: `1px solid ${isMarked ? theme.colors.yellow.primary['500'] : theme.colors.purple.primary['600']}`,
  borderRadius: 9,
  margin: '8px 0',
  cursor: 'pointer',
  overflow: 'hidden',
}));

const ContentContainer = styled.div({
  position: 'absolute',
  backgroundColor: 'rgba(52, 12, 83, 0.6)',
  display: 'flex',
  flexDirection: 'column',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: 12,
  borderRadius: 8,
});

const Title = styled(Text)({
  marginTop: 'auto',
});

const StyledTabs = styled(PillToggle)({
  margin: '16px 0 24px 0',
});

export const Contest = () => {
  const dispatch = useDispatch();
  const { uid } = useParams();
  const navigate = useNavigate();
  const { data } = useContest({ contestId: uid || '' });
  const { pathname } = useLocation();
  const lastPath = pathname.split('/').pop() || '';

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const handleTabsChange = (value: string) => {
    navigate(`/contest/${uid}/${value}`);
    dispatch(setActiveTab(value));
  };

  const handleBackClick = () => {
    navigate('/contests');
  };

  const onContestImageClick = () => {
    dispatch(setProvablyFairModalVisible(true));
  };

  return (
    <Container>
      <BackButton onBack={handleBackClick} />
      <ImageContainer isMarked={!!data?.userEntry} image={data?.image} onClick={onContestImageClick}>
        <ContentContainer>
          <EndedIndicator isVisible={!!data?.isEnded} />
          <TicketsProgressIndicator
            isVisible={!!data?.maxTicketsAmount && !data?.isEnded}
            currentTicketsAmount={data?.currentTicketsAmount || 0}
            maxTicketsAmount={data?.maxTicketsAmount || 0}
          />
          <TimeProgressIndicator isVisible={!!data?.endTime && !data?.isEnded} endTime={data?.endTime || 0} />
          <ProvablyFairBadge isVisible={!!data?.isProvablyFair} />
          <Title type="p_l" weight={600}>
            {data?.title}
          </Title>
        </ContentContainer>
      </ImageContainer>
      <StyledTabs
        onChange={handleTabsChange}
        selected={lastPath}
        options={data?.isEnded ? ENDED_TABS_OPTIONS : TABS_OPTIONS}
      />
      <Outlet context={{ title: data?.title }} />
      <ProvablyFair />
    </Container>
  );
};
