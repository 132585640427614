import styled from 'styled-components';
import { ChangeEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import secondaryModalImage from '@/assets/images/swap_background.png';
import { ReactComponent as SwapCompletedImage } from '@/assets/images/swap_completed.svg';
import { Button } from '@/components/Button/Button';
import { FormInput } from '@/components/Input/FormInput';
import { useAssets } from '@/features/Assets/queries';
import { ErrorMessage } from '@/components/ErrorMessage/ErrorMessage';

import { setCreditsAmount } from '../slice';
import { getCreditsAmount } from '../selectors';
import { useAssetsSwap } from '../queries';

const Container = styled.div({
  maxWidth: 340,
  padding: '32px 16px 82px 16px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
});

const StyledText = styled(Text)(({ theme }) => ({
  marginTop: 4,
  marginBottom: 24,
  color: theme.colors.purple.primary['200'],
}));

const StyledImage = styled.img({
  position: 'absolute',
  width: 320,
  bottom: 0,
  zIndex: 0,
});

const PrefillButtonsContainer = styled.div({
  display: 'flex',
  width: '100%',
  marginTop: 16,
});

const TicketsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '18px 16px',
  backgroundColor: theme.colors.purple.primary['600'],
  boxShadow: '1px 1px 0px 0px #250F36',
  borderRadius: 8,
  marginBottom: 32,
}));

const AssetTextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const AssetText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const AssetIcon = styled(Icon)({
  marginRight: 4,
});

const PrefillButton = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['400'],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 6,
  flexGrow: 1,
  marginRight: 8,
  borderRadius: 4,
  cursor: 'pointer',
  ':last-of-type': {
    marginRight: 0,
  },
}));

const StyledError = styled(ErrorMessage)({
  alignSelf: 'flex-start',
  marginLeft: 0,
});

const StyledButton = styled(Button)({
  zIndex: 1,
});

const ButtonIcon = styled(Icon)({
  margin: '0 4px',
});

const StylesChevronDown = styled(Icon)({
  margin: '16px auto 16px auto',
});

const StyledSuccessImage = styled(SwapCompletedImage)({
  alignSelf: 'center',
  marginBottom: 24,
});

export const AssetsSwapForm = () => {
  const dispatch = useDispatch();

  const creditsAmount = useSelector(getCreditsAmount);
  const { credits } = useAssets();
  const { mutate: swap, isLoading, isError, isSuccess } = useAssetsSwap();

  const handlePrefillClick = (value: number) => {
    if (!!value) {
      dispatch(setCreditsAmount(Math.floor(credits * value)));
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    const value = event.target.valueAsNumber;

    dispatch(setCreditsAmount(value));
  };

  const handleSubmit = () => {
    mixpanel.track('swapped-credits', { creditsAmount });
    swap({ amount: creditsAmount, assetToBuy: 'tickets' });
  };

  const isAmountError = creditsAmount > credits;
  const isButtonDisabled = !creditsAmount || isAmountError || isLoading;
  const ticketsText = creditsAmount === 1 ? 'ticket' : 'tickets';

  return (
    <Container>
      <Text type="h3" weight={600} align="center">
        {!isSuccess && 'Swap'}
        {isSuccess && 'Congratulations!'}
      </Text>
      <StyledText type="p_s" weight={400} align="center">
        {!isSuccess && 'Credits for tickets'}
        {isSuccess && `${creditsAmount} ${ticketsText} are now yours!`}
      </StyledText>
      {isSuccess && <StyledSuccessImage />}
      {!isSuccess && (
        <>
          <FormInput
            label="Enter amount"
            type="number"
            value={creditsAmount === 0 ? '' : creditsAmount}
            onChange={handleChange}
            suffixPositionTop={14}
            suffix={
              <AssetTextContainer>
                <AssetIcon name="creditSmall" />
                <AssetText type="p_l" weight={600}>
                  Credits
                </AssetText>
              </AssetTextContainer>
            }
          />
          {(isAmountError || isError) && (
            <StyledError className="ml-5 mt-1">You do not have enough credits</StyledError>
          )}
          <PrefillButtonsContainer>
            <PrefillButton onClick={() => handlePrefillClick(0.25)}>
              <Text type="p_s" weight={500}>
                25%
              </Text>
            </PrefillButton>
            <PrefillButton onClick={() => handlePrefillClick(0.5)}>
              <Text type="p_s" weight={500}>
                50%
              </Text>
            </PrefillButton>
            <PrefillButton onClick={() => handlePrefillClick(0.75)}>
              <Text type="p_s" weight={500}>
                75%
              </Text>
            </PrefillButton>
            <PrefillButton onClick={() => handlePrefillClick(1)}>
              <Text type="p_s" weight={500}>
                Max
              </Text>
            </PrefillButton>
          </PrefillButtonsContainer>
          <StylesChevronDown name="chevronDown" />
          <TicketsContainer>
            <AssetText type="p_l" weight={400}>
              {!!creditsAmount && creditsAmount}
            </AssetText>
            <AssetTextContainer>
              <AssetIcon name="ticketSmall" />
              <AssetText type="p_l" weight={600}>
                Tickets
              </AssetText>
            </AssetTextContainer>
          </TicketsContainer>
          <StyledButton disabled={isButtonDisabled} onClick={handleSubmit}>
            {isButtonDisabled && !isLoading && 'Swap'}
            {!isButtonDisabled && !isLoading && (
              <>
                Swap to <ButtonIcon name="ticketSmall" /> {creditsAmount}
              </>
            )}
            {isLoading && 'Swapping...'}
          </StyledButton>
        </>
      )}
      <StyledImage src={secondaryModalImage} />
    </Container>
  );
};
