import styled from 'styled-components';

import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';
import noGamesImage from '@/assets/images/no-games.png';

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.darkPurple,
  border: `1px solid ${theme.colors.purple.primary['600']}`,
  boxShadow: theme.boxShadow.primary,
  padding: '40px 92px 40px 24px',
  margin: '0 24px 52px 24px',
  borderRadius: 8,
  position: 'relative',
}));

const Image = styled.img({
  width: 80,
  position: 'absolute',
  right: 0,
  top: 0,
});

const Description = styled(Text)({
  marginTop: 8,
});

const NoGamesComponent = () => (
  <Container>
    <Image src={noGamesImage} alt="no-games" />
    <Text type="h3" weight={600}>
      No games available
    </Text>
    <Description colorType="light">We’ll notify you once we get more games added.</Description>
  </Container>
);

export const NoGames = hideable(NoGamesComponent);
