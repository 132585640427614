export type TPlatform = 'ios' | 'android' | 'web';
type TGetPlatform = () => TPlatform;

export const getPlatform: TGetPlatform = () => {
  const ua = navigator.userAgent;
  const checker = {
    ios: ua.match(/(iPhone|iPod|iPad)/),
    android: ua.match(/Android/),
  };

  if (checker.android) {
    return 'android';
  } else if (checker.ios) {
    return 'ios';
  } else {
    // Web has now no games. Can be used for testing;
    // return 'android';
    // return 'ios';
    return 'web';
  }
};
