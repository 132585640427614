import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import { useAppMount } from '@/features/App/hooks';
import { useOnAuth } from '@/features/Auth/hooks';
import { NFTPreviewContainer } from '@/features/NFTList/components/NFTPreview';
import { VoucherPreview } from '@/features/VoucherPreview/components/VoucherPreview';
import { AssetsSwap } from '@/features/AssetsSwap/components/AssetsSwap';
import { AppVersionInfo } from '@/features/Header/components/AppVersionInfo';
import { AddToHomeScreenContainer } from '@/features/AddToHomeScreen/components/AddToHomeScreenContainer';
import { CompleteProfileModalContainer } from '@/features/CompleteProfile/components/CompleteProfileModalContainer';
import { VpnBlockScreenContainer } from './features/VpnBlock/components/VpnBlockScreenContainer';
import background from '@/assets/images/desktop_background.png';
import { isApp } from '@/utils/isApp';
import { routesWithNoSidePadding } from '@/routes/constants';
import { BREAKPOINTS, DESKTOP_CARD_DIMENSIONS } from '@/theme/constants';

import { Router } from './routes/Router';

import 'swiper/css';
import 'react-toastify/dist/ReactToastify.css';

interface IStyleProps {
  isNoSidePadding: boolean;
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100dvh',
  backgroundColor: 'transparent',
  [BREAKPOINTS.SM_WIDTH]: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
});

const ScrollWrapper = styled.div(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
  [BREAKPOINTS.SM_WIDTH]: {
    borderRadius: 16,
    width: DESKTOP_CARD_DIMENSIONS.WIDTH,
    height: DESKTOP_CARD_DIMENSIONS.HEIGHT,
    filter: 'drop-shadow(0px 0px 50px rgba(243, 82, 255, 0.50))',
    border: `1px solid ${theme.colors.pink.primary[500]}`,
  },
}));

const Card = styled.div<IStyleProps>(({ theme, isNoSidePadding }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'auto',
  height: '100%',
  width: '100%',
  overscrollBehavior: 'none',
  padding: isNoSidePadding ? '24px 0 24px 0' : 24,
  [BREAKPOINTS.SM_WIDTH]: {
    backgroundColor: theme.colors.background,
    height: DESKTOP_CARD_DIMENSIONS.HEIGHT,
    width: DESKTOP_CARD_DIMENSIONS.WIDTH,
    padding: isNoSidePadding ? '24px 0 24px 0' : 24,
    borderRadius: 16,
  },
}));

export const App = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const isMobileApp = isApp();
  useAppMount();
  useOnAuth();

  useEffect(() => {
    scrollContainerRef?.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <ScrollWrapper>
        {isMobileApp && (
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Helmet>
        )}
        <Card ref={scrollContainerRef} isNoSidePadding={routesWithNoSidePadding.includes(pathname)}>
          <Router />
          <NFTPreviewContainer />
          <VoucherPreview />
          <ToastContainer theme="dark" hideProgressBar closeButton={false} autoClose={4000} />
          <AssetsSwap />
          <AppVersionInfo />
          <AddToHomeScreenContainer />
          <VpnBlockScreenContainer />
          <CompleteProfileModalContainer />
        </Card>
      </ScrollWrapper>
    </Container>
  );
};
