import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

import { firebaseApp } from '@/utils/firebase';

import { TContestType } from './types';
import { getActiveSubTab } from './selectors';
import { SUB_TABS } from './constants';
import { useAuth } from '@/features/Auth/hooks';

const MAX_ENDED_CONTEST_AGE = 10;

interface IUseMyEntries {
  userId: string;
}

export const useContestsData = () =>
  useQuery({
    queryKey: ['useContestsData'],
    queryFn: async () => {
      const queryRef = query(collection(getFirestore(firebaseApp), 'contests'), where('state', '!=', 'hidden'));

      const contestsDocs = (await getDocs(queryRef)).docs;

      const data = contestsDocs.map((contest) => ({
        uid: contest.id,
        key: contest.id as string,
        image: contest?.data().image as string,
        isProvablyFair: contest.data().isProvablyFair as boolean,
        currentTicketsAmount: contest.data().currentTicketsAmount as number | undefined,
        maxTicketsAmount: contest.data().maxTicketsAmount as number | undefined,
        title: contest.data().title as string,
        type: contest.data().type as TContestType,
        isEnded: (contest.data()?.state === 'closed') as boolean,
        isSoon: (contest.data()?.state === 'soon') as boolean,
        endTime: contest.data().endTime?.toDate()?.getTime() as number | undefined,
      }));

      const sortedData = data.sort((a, b) => {
        if (!a.isSoon && b.isSoon) {
          return -1;
        } else if (a.isSoon && !b.isSoon) {
          return 1;
        } else {
          return 0;
        }
      });

      return sortedData;
    },
  });

export const useMyEntries = ({ userId }: IUseMyEntries) =>
  useQuery({
    queryKey: ['useMyEntries', userId],
    queryFn: async () => {
      const contestsDocs = (await getDocs(collection(getFirestore(firebaseApp), `users/${userId}/contests`))).docs;

      return contestsDocs.map((contest) => ({
        uid: contest.id,
        ticketsAdded: contest.data().ticketsAdded as number,
      }));
    },
  });

export const useContests = () => {
  const activeSubTab = useSelector(getActiveSubTab);
  const { uid } = useAuth();
  const { data: contests = [], isLoading: isLoadingContestData } = useContestsData();
  const { data: myEntries = [], isLoading: isLoadingMyEntries } = useMyEntries({ userId: uid || '' });
  const myEntriesUids = myEntries.map((entry) => entry.uid);

  const formattedContests = contests.map((contest) => ({
    ...contest,
    userEntry: myEntries.find((entry) => entry.uid.trim() === contest.uid.trim())?.ticketsAdded || 0,
  }));

  let data = formattedContests;

  if (activeSubTab === SUB_TABS.ACTIVE) {
    data = formattedContests.filter((contest) => !contest.isEnded);
  }

  if (activeSubTab === SUB_TABS.ENDED) {
    data = formattedContests.filter(
      (contest) =>
        contest.isEnded &&
        contest.endTime &&
        Math.floor((new Date().valueOf() - new Date(contest.endTime).valueOf()) / (1000 * 60 * 60 * 24)) <
          MAX_ENDED_CONTEST_AGE
    );
  }

  if (activeSubTab === SUB_TABS.MY_ENTRIES) {
    data = formattedContests.filter((contest) => myEntries.some((entry) => entry.uid.trim() === contest.uid.trim()));
  }

  return {
    data,
    myEntriesUids,
    isLoadingContestData,
    isLoadingMyEntries,
  };
};
