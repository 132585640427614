import { useIsIosApp } from '@/features/App/hooks';

const replaceProtocol = (url: string) => {
  const regex = /^(https?:\/\/)/;
  const replacedUrl = url.replace(regex, 'open://');

  return replacedUrl;
};

export const useOpenLink = () => {
  const isIosApp = useIsIosApp();

  return (link: string, windowReference?: Window | null) => {
    if (isIosApp) {
      window.open(replaceProtocol(link), '_blank', 'noreferrer');

      return;
    }

    if (windowReference) {
      windowReference.location = link;

      return;
    }

    window.open(link, '_blank', 'noreferrer');
  };
};
