import { useMutation, useQuery } from 'react-query';

import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { firebaseApp, httpsFunction } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';

interface IUseSetNicknameParams {
  nickname: string;
}

interface IUseSetNickname {
  onSuccess: () => void;
}

interface IUseNicknameData {
  uid?: string;
}

export const useSetNickname = ({ onSuccess }: IUseSetNickname) => {
  return useMutation<any, any, IUseSetNicknameParams>(
    async ({ nickname }) => {
      const setNickname = httpsFunction('setNickname');

      await setNickname({ nickname });
    },
    { onSuccess }
  );
};

export const useNicknameData = ({ uid }: IUseNicknameData) =>
  useQuery({
    queryKey: ['useNicknameData', uid],
    enabled: !!uid,
    queryFn: async () => {
      const nickname: string = (await getDoc(doc(getFirestore(firebaseApp), `users/${uid}`)))?.data()?.nickname || '';

      return {
        nickname,
      };
    },
  });

export const useNickname = () => {
  const { uid } = useAuth();

  const { data } = useNicknameData({ uid });

  return {
    nickname: data?.nickname,
  };
};
