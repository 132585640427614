import { useQuery } from 'react-query';

import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { firebaseApp } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';

interface IUseNicknameData {
  uid: string;
}

type TIconNumber = 1 | 2 | 3 | 4 | 5;

export const useProfileData = ({ uid }: IUseNicknameData) =>
  useQuery({
    queryKey: ['useProfileData', uid],
    enabled: !!uid,
    queryFn: async () => {
      const iconNumber: TIconNumber =
        (await getDoc(doc(getFirestore(firebaseApp), `users/${uid}`)))?.data()?.iconNumber || 1;

      return {
        iconNumber,
      };
    },
  });

export const useProfile = () => {
  const { uid } = useAuth();

  const { data } = useProfileData({ uid: uid || '' });

  return {
    iconNumber: data?.iconNumber || 1,
  };
};
