import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Text } from '@/components/Text';
import { setShouldRefreshToken } from '@/features/Auth/slice';
import { Icon } from '@/components/Icon/Icon';

import { useSkipReferral } from '../queries';
import { ReferralForm } from './ReferralForm';
import { showToast } from '@/utils/toast';

const Container = styled.div({
  width: '100%',
  paddingTop: 60,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const SkipButton = styled.button({
  padding: 0,
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  marginTop: 32,
});

const Title = styled(Text)({
  marginBottom: 32,
  width: '100%',
});

const ButtonText = styled(Text)(({ theme }) => ({
  color: theme.colors.yellow.primary['500'],
  marginRight: 4,
}));

const StyledIcon = styled(Icon)({
  marginLeft: 4,
});

export const SignUpFlowReferralForm = () => {
  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch(setShouldRefreshToken(true));
  };

  const { mutate: skipReferral, isLoading, isError } = useSkipReferral({ onSuccess });

  const handleSkip = () => {
    skipReferral();
  };

  useEffect(() => {
    if (isError) {
      showToast({ type: 'error', text: 'Something went wrong. Please try again later.' });
    }
  }, [isError]);

  return (
    <Container>
      <Title type="h2" align="center" weight={600}>
        Enter your referral code
      </Title>
      <ReferralForm />
      <SkipButton onClick={handleSkip}>
        {isLoading && <ButtonText>Skipping...</ButtonText>}
        {!isLoading && (
          <>
            <ButtonText>Skip this step</ButtonText>
            <StyledIcon name="skip" />
          </>
        )}
      </SkipButton>
    </Container>
  );
};
