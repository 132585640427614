import styled from 'styled-components';

import hideable from '@/utils/hideable';

import { Icon } from './Icon/Icon';

type TSocialSignInType = 'google' | 'apple' | 'email';

interface ISocialSignInButton {
  buttonType: TSocialSignInType;
  onClick: () => void;
}

const iconByType = {
  google: <Icon name="google" />,
  email: <Icon name="email" />,
  apple: <Icon name="appleBlack" />,
};

const textByType = {
  google: 'Continue with Google',
  apple: 'Continue with Apple ID',
  email: 'Continue with Email',
};

const StyledButton = styled('button')<{ buttonType: TSocialSignInType }>(({ theme }) => ({
  border: `1px solid ${theme.colors.neutral.primary[1000]}`,
  outline: 'none',
  height: 56,
  width: '100%',
  display: 'flex',
  borderRadius: 8,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 500,
  backgroundColor: theme.colors.purple.primary['50'],
  boxShadow: theme.boxShadow.primary,
}));

const IconContainer = styled.span({
  marginRight: 8,
});

const SocialSignInButtonComponent = ({ buttonType, onClick, ...rest }: ISocialSignInButton) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    onClick();
  };

  return (
    <StyledButton buttonType={buttonType} onClick={handleClick} {...rest}>
      <IconContainer>{iconByType[buttonType]}</IconContainer>
      {textByType[buttonType]}
    </StyledButton>
  );
};

export const SocialSignInButton = hideable(SocialSignInButtonComponent);
