import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@/features/Auth/hooks';

import { rootAuthenticatedRoute } from './constants';

export const PublicRoute = () => {
  const location = useLocation();
  const { isActivationStack, isAuthenticatedStack, isOnboardingStack, isNicknameStack, isEmailVerificationStack } =
    useAuth();

  const redirectDestination = location?.state?.from?.pathname || rootAuthenticatedRoute;

  if (isActivationStack) {
    return <Navigate to="/sign-up-referral" state={{ from: location }} replace />;
  }

  if (isOnboardingStack) {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  if (isNicknameStack) {
    return <Navigate to="/nickname" state={{ from: location }} replace />;
  }

  if (isEmailVerificationStack) {
    return <Navigate to="/email-verification" state={{ from: location }} replace />;
  }

  if (isAuthenticatedStack) {
    return <Navigate to={redirectDestination} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
