import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { QuestType } from '@/features/Challenges/types';
import { Button } from '@/components/Button/Button';
import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import DroneVisual from '@/assets/visuals/daily_play_drone_visual.svg';
import TrophyVisual from '@/assets/visuals/daily_play_trophy_visual.svg';
import RightVisual from '@/assets/visuals/daily_play_right_block_visual.svg';
import LeftVisual from '@/assets/visuals/daily_play_left_block_visual.svg';
import { useClaimCredits } from '@/features/Challenges/mutations';

import { useDailyPlay } from '../queries';
import { TimeMilestone } from './TimeMilestone';
import { DailyPlayCountdown } from './DailyPlayCountdown';
import { DailyPlayCompleted } from './DailyPlayCompleted';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.purple.primary['700'],
  borderRadius: '10px',
  border: `1px solid ${theme.colors.purple.primary['500']}`,
  boxShadow: theme.boxShadow.primary,
}));

const ClaimBtn = styled(Button)({
  padding: '12px 8px',
  height: 34,
  borderRadius: 5,
  fontSize: 12,
  width: 100,
  marginLeft: 29,
});

const ContainerHeader = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '10px 15px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 10,
  width: '100%',
  position: 'relative',
  zIndex: 2,
  borderBottom: `1px solid ${theme.colors.purple.primary['500']}`,
  backgroundColor: theme.colors.purple.primary['600'],
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const ContainerContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  backgroundImage: `url(${DroneVisual}), url(${TrophyVisual}), url(${LeftVisual}), url(${RightVisual})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom 20px left 25px, bottom 20px right 25px, top 20px left 20px, top 20px right 20px',
  backgroundSize: '80px, 100px, 35px, 55px',
});

const StyledPlayTimeText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
}));

export const DailyPlay = () => {
  const { id: gameSlug } = useParams();
  const { dailyPlay, availableToClaim, allClaimed } = useDailyPlay(gameSlug || '');
  const { mutate: claimCredits, isLoading } = useClaimCredits();

  const handleClaim = () => {
    if (!gameSlug) {
      console.error('Could not find game from params');
      return;
    }
    claimCredits({
      questType: QuestType.DAILY,
      gameSlug: gameSlug,
    });
  };

  if (dailyPlay.isLoading) {
    return <Skeleton height={450} className="rounded-lg" containerClassName="w-full flex" />;
  }

  return (
    <>
      <DailyPlayCountdown />
      <Container>
        <ContainerHeader>
          <div className="mr-2">
            <StyledPlayTimeText type="p_m">Your play time</StyledPlayTimeText>
          </div>
          <div className="text-[#FFD500] flex items-center">
            <Icon name="clock" fill="#FFD500" className="mr-1" />{' '}
            {!!dailyPlay.data?.playTime?.hours && `${dailyPlay.data?.playTime?.hours}h`}{' '}
            {dailyPlay.data?.playTime.minutes}min
          </div>
          {!!availableToClaim && (
            <ClaimBtn onClick={handleClaim}>
              {isLoading ? (
                'Claiming...'
              ) : (
                <>
                  Claim <Icon name="credit" height={20} className="ml-2 mr-1" /> {availableToClaim}
                </>
              )}
            </ClaimBtn>
          )}
        </ContainerHeader>
        {allClaimed ? (
          <DailyPlayCompleted />
        ) : (
          <ContainerContent>
            {dailyPlay.data?.milestones.map(({ playTime, credits, isClaimed }) => (
              <TimeMilestone
                key={playTime}
                time={playTime}
                credits={credits}
                isClaimed={isClaimed}
                isCompleted={dailyPlay?.data?.playTimeInSeconds ? dailyPlay?.data?.playTimeInSeconds > playTime : false}
              />
            ))}
          </ContainerContent>
        )}
      </Container>
    </>
  );
};
