export const abbreviateNumber = (input: number | string): string => {
  const num = typeof input === 'string' ? parseFloat(input) : input;

  if (isNaN(num) || num === 0) {
    return '0';
  }

  const abbreviations = ['', 'k', 'm', 'b'];
  const tier = (Math.log10(Math.abs(num)) / 3) | 0;

  if (tier === 0) {
    return num.toString();
  }

  const suffix = abbreviations[tier];
  const scale = Math.pow(10, tier * 3);

  const scaledNumber = num / scale;
  const formattedNumber = scaledNumber.toFixed(1 + (scaledNumber % 1 === 0 ? 0 : 1));

  return formattedNumber + suffix;
};
