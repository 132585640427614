import { useNotificationsList } from '../queries';
import { Header } from './Header';
import { NoNotifications } from './NoNotifications';
import { NotificationBoxContainer } from './NotificationBoxContainer';

export const NotificationsList = () => {
  const { data } = useNotificationsList();

  return (
    <>
      <Header />
      {data?.map((notification) => (
        <NotificationBoxContainer key={notification.uid} uid={notification.uid} />
      ))}
      <NoNotifications isVisible={!data?.length} />
    </>
  );
};
