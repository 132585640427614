import styled from 'styled-components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import iosConsent from '@/assets/images/consent.png';
import iosConsent2 from '@/assets/images/consent2.png';
import background from '@/assets/images/tutorial-background.png';
import activationVideo from '@/assets/animations/activation.mp4';
import { ActivationTutorialScreen } from '@/components/ActivationTutorialScreen';
import { getGame } from '@/features/Game/queries';
import { useSetCampaignActivating, useIssueAttestation } from '@/features/Game/mutations';
import { useAuth } from '@/features/Auth/hooks';
import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { getPlatform } from '@/utils/getPlatform';
import { resolveCampaignDeepLink } from '@/features/Game/utils/resolveCampaignDeepLink';

import { getActivationStep } from '../selectors';
import { ACTIVATION_STEPS } from '../constants';
import { setActivationStep, resetState } from '../slice';

const isIos = getPlatform() === 'ios';

const StyledImage = styled.img({
  maxWidth: 250,
});

const SecondImage = styled.img({
  maxWidth: 290,
});

const StyledVideo = styled.video({
  marginTop: 48,
  maxWidth: 260,
});

const Background = styled.div({
  backgroundImage: `url(${background})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  paddingBottom: 44,
  margin: '0 -24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const IMAGE_MAP_BY_STEP = {
  [ACTIVATION_STEPS.HIT_ALLOW]: (
    <Background>
      <StyledImage src={iosConsent} />
    </Background>
  ),
  [ACTIVATION_STEPS.INSTALL]: (
    <Background>
      <SecondImage src={iosConsent2} />
    </Background>
  ),
  [ACTIVATION_STEPS.READY_TO_PLAY]: (
    <Background>
      <StyledVideo autoPlay loop muted playsInline>
        <source src={activationVideo} type="video/mp4" />
      </StyledVideo>
    </Background>
  ),
};

const CURRENT_ACTIVE_STEP = {
  [ACTIVATION_STEPS.HIT_ALLOW]: 1,
  [ACTIVATION_STEPS.INSTALL]: !isIos ? 1 : 2,
  [ACTIVATION_STEPS.READY_TO_PLAY]: !isIos ? 2 : 3,
};

const POINTS_TEXT_BY_STEP = {
  [ACTIVATION_STEPS.HIT_ALLOW]: [
    {
      title: "Press 'Allow' when asked to share activity",
    },
  ],
  [ACTIVATION_STEPS.INSTALL]: [
    {
      title: 'Install the game and open it',
    },
    {
      title: 'Return to BlockGames to check status',
    },
  ],
  [ACTIVATION_STEPS.READY_TO_PLAY]: [
    {
      title: "When the game appears in 'Activated', it's ready to play",
      description: 'Activating may take up to 30min',
    },
  ],
};

const StyledScreen = styled(ActivationTutorialScreen)({
  paddingTop: 0,
});

export const ActivationTutorial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const openLink = useOpenLink();
  const activationStep = useSelector(getActivationStep);
  const { uid } = useAuth();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const { mutate: issueAttestation } = useIssueAttestation();
  const { mutate: setCampaignActivating, isLoading } = useSetCampaignActivating({
    onSettled: () => {
      navigate(-1);
    },
  });

  const { game, isActivationInProgress, campaign } = getGame(state?.slug, state?.campaignId);

  const handleBackClick = () => {
    if (activationStep === ACTIVATION_STEPS.READY_TO_PLAY) {
      dispatch(setActivationStep(ACTIVATION_STEPS.INSTALL));

      return;
    }

    if (activationStep === ACTIVATION_STEPS.INSTALL && isIos) {
      dispatch(setActivationStep(ACTIVATION_STEPS.HIT_ALLOW));

      return;
    }

    navigate(-1);
  };

  const handleNextClick = () => {
    if (activationStep === ACTIVATION_STEPS.HIT_ALLOW) {
      dispatch(setActivationStep(ACTIVATION_STEPS.INSTALL));

      return;
    }

    if (activationStep === ACTIVATION_STEPS.INSTALL) {
      dispatch(setActivationStep(ACTIVATION_STEPS.READY_TO_PLAY));

      return;
    }

    if (activationStep === ACTIVATION_STEPS.READY_TO_PLAY) {
      try {
        setCampaignActivating({ slug: state?.slug, campaignId: state?.campaignId });
        issueAttestation();

        mixpanel.track('clicked-activate', { game: state?.slug, campaignId: state?.campaignId, distinct_id: uid });
      } catch (error) {
        console.error('Error while activating game: ', { error });
      }

      if (!isActivationInProgress && campaign?.trackingUrl) {
        openLink(resolveCampaignDeepLink(campaign, uid));
      } else if (game?.deepLink) {
        // openLink(game.deepLink);
      }

      return;
    }
  };

  return (
    <StyledScreen
      image={IMAGE_MAP_BY_STEP[activationStep]}
      points={POINTS_TEXT_BY_STEP[activationStep]}
      title="How to activate"
      currentActiveStep={CURRENT_ACTIVE_STEP[activationStep]}
      activeSteps={isIos ? 3 : 2}
      onNext={handleNextClick}
      onBack={handleBackClick}
      isLoading={isLoading}
    />
  );
};
