import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

const LinkStyles = css<{ inheritfontsize?: boolean }>`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: all 0.4s;
  font-weight: 700;
  font-size: ${({ inheritfontsize = false }) => (inheritfontsize ? 'inherit' : '14px')};

  &:hover {
    color: #414656;
  }
`;

const RouterLinkWrapper = styled(ReactRouterLink)`
  ${LinkStyles};
`;

const LinkWrapper = styled.a`
  ${LinkStyles};
`;

interface ILink extends LinkProps {
  external?: boolean;
  inheritfontsize?: boolean;
}

export const Link = ({ external = false, to, ...props }: ILink) =>
  external ? <LinkWrapper href={to as string} {...props} /> : <RouterLinkWrapper to={to} {...props} />;
