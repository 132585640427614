import { toast } from 'react-toastify';

import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { ToastCloseButton } from '@/components/ToastCloseButton';

type TToastType = 'success' | 'error' | 'info';

interface IMessage {
  text: string;
  description?: string;
}

type TShowToast = IMessage & { type: TToastType };

const Message = ({ text, description }: IMessage) => (
  <>
    <Text weight={600}>{text}</Text>
    {description && (
      <Text colorType="light" type="p_s" weight={400}>
        {description}
      </Text>
    )}
  </>
);

export const showToast = ({ text, description, type }: TShowToast) => {
  if (type === 'success') {
    toast.success(<Message text={text} description={description} />, {
      icon: <Icon name="toastSuccess" />,
      closeButton: ({ closeToast }) => <ToastCloseButton closeToast={closeToast} />,
    });
  }

  if (type === 'error') {
    toast.error(<Message text={text} description={description} />, {
      icon: <Icon name="toastError" />,
      closeButton: ({ closeToast }) => <ToastCloseButton closeToast={closeToast} />,
    });
  }

  if (type === 'info') {
    toast.info(<Message text={text} description={description} />, {
      icon: <Icon name="toastInfo" />,
      closeButton: ({ closeToast }) => <ToastCloseButton closeToast={closeToast} />,
    });
  }
};
