import { useMutation, useQueryClient } from 'react-query';

import { httpsFunction } from '@/utils/firebase';
import { CampaignStatus } from '@/features/Games/types';

import { useAuth } from '../Auth/hooks';

interface IUseSetCampaignActivating {
  slug?: string;
  campaignId?: string;
}

interface IUseSetCampaignActivatingProps {
  onSettled?: () => void;
}

export const useSetCampaignActivating = ({ onSettled }: IUseSetCampaignActivatingProps) => {
  const { uid } = useAuth();
  const queryClient = useQueryClient();

  return useMutation<any, any, IUseSetCampaignActivating>(
    async ({ slug, campaignId }) => {
      const activateCampaign = httpsFunction('setCampaignStatus');

      await activateCampaign({ uid, gameSlug: slug, campaignId, status: CampaignStatus.ACTIVATING });
    },
    {
      onSettled,
      onMutate: () => {
        queryClient.setQueriesData(['useGame', 'useUserDataOnActivatedGames'], []);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['useGame'] });
        queryClient.invalidateQueries({ queryKey: ['useUserDataOnActivatedGames'] });
      },
    }
  );
};

export const useIssueAttestation = () => {
  const { uid } = useAuth();

  return useMutation(async () => {
    const issueAttestation = httpsFunction('issueAttestation');

    await issueAttestation({
      recipient: uid,
      issuerName: 'blockgames',
      schemaName: 'clicked_activate',
      data: {},
      visibility: 'private',
    });
  });
};
