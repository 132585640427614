import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Table } from '@/components/Table';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as CreditBigIcon } from '@/assets/icons/credit_big.svg';
import { ReactComponent as CreditSmallIcon } from '@/assets/icons/credit_small.svg';

import { useReferrals } from '../queries';
import { InfoModal } from './InfoModal';
import { setInfoModalVisible } from '../slice';

const TableFooter = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 16px',
});

const FooterItem = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const FooterItemLabel = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginRight: 8,
}));

const FooterItemValue = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const TableColumnValue = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  display: 'flex',
  alignItems: 'center',
}));

const InviteeHeaderComponent = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledInfoIcon = styled(Icon)({
  marginLeft: 4,
  cursor: 'pointer',
});

export const ReferralsData = () => {
  const dispatch = useDispatch();
  const { referrals, referralsAmount, totalCreditsReceived } = useReferrals();

  const onInfoClick = () => {
    dispatch(setInfoModalVisible(true));
  };

  const tableData = referrals.map((referral) => ({
    key: referral.nickname,
    invitee: referral.nickname,

    activityCredits: (
      <TableColumnValue type="p_s" weight={600}>
        <CreditSmallIcon />
        {referral.activityCredits}
      </TableColumnValue>
    ),
    vitalityCredits: (
      <TableColumnValue type="p_s" weight={600}>
        <CreditSmallIcon />
        {referral.viralityCredits}
      </TableColumnValue>
    ),
  }));

  const tableHeader = [
    {
      name: 'invitee',
      component: (
        <InviteeHeaderComponent>
          Invitee
          <StyledInfoIcon onClick={onInfoClick} name="info" />
        </InviteeHeaderComponent>
      ),
    },
    {
      name: 'activityCredits',
      component: 'Activity',
    },
    {
      name: 'vitalityCredits',
      component: 'Virality',
    },
  ];

  const footer = (
    <TableFooter>
      <FooterItem>
        <FooterItemLabel type="p_xs" weight={400}>
          Total invitees
        </FooterItemLabel>
        <FooterItemValue type="h4" weight={700}>
          {referralsAmount}
        </FooterItemValue>
      </FooterItem>
      <FooterItem>
        <FooterItemLabel type="p_xs" weight={400}>
          Total credits
        </FooterItemLabel>
        <CreditBigIcon />
        <FooterItemValue type="h4" weight={700}>
          {totalCreditsReceived}
        </FooterItemValue>
      </FooterItem>
    </TableFooter>
  );

  return (
    <>
      <Table header={tableHeader} data={tableData} footer={footer} noDataText="There are no friends connected" />
      <InfoModal />
    </>
  );
};
