import { signInWithCustomToken, getAuth, Auth } from 'firebase/auth';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { HttpsCallable } from 'firebase/functions';

import { setReferralCode } from '@/features/EnterReferral/slice';
import { setAddToHomeScreenHidden } from '@/features/AddToHomeScreen/slice';
import { httpsFunction } from '@/utils/firebase';
import { TPlatform, getPlatform } from '@/utils/getPlatform';
import { LOCAL_STORAGE } from '@/utils/constants/common';
import { showToast } from '@/utils/toast';

import { IHandleAppMount } from './actions';
import { HANDLE_APP_MOUNT } from './actionTypes';
import { setAuthLoading } from '../Auth/slice';
import {
  setPlatform,
  setAppsflyerAndroidId,
  setAppsflyerIosId,
  setAppsflyerWebId,
  setFirebaseAndroidId,
  setFirebaseIosId,
  setFirebaseWebId,
} from './slice';

interface INotificationSagaAction {
  type: typeof HANDLE_APP_MOUNT;
  payload: IHandleAppMount;
}

interface IGetAuthTokenResponse {
  data: {
    googleAuthToken: string;
  };
}

export function* handleAppMountSaga({ payload }: INotificationSagaAction) {
  const platform: TPlatform = yield call(getPlatform);

  yield put(setPlatform(platform));

  if (payload.referralCode) {
    yield call([localStorage, localStorage.setItem], 'referral_code', payload.referralCode);
    yield put(setReferralCode(payload.referralCode));
  }

  const storedReferralCode: string | null = yield call([localStorage, localStorage.getItem], 'referral_code');

  if (!payload.referralCode && !!storedReferralCode) {
    yield put(setReferralCode(storedReferralCode));
  }

  if (payload.initialAuthToken) {
    yield put(setAuthLoading(true));

    try {
      const getGoogleAuthToken: HttpsCallable = yield call(httpsFunction, 'getGoogleAuthToken');

      yield put(setAddToHomeScreenHidden());

      const response: IGetAuthTokenResponse = yield call(getGoogleAuthToken, {
        initialAuthToken: payload.initialAuthToken,
      });

      if (response?.data?.googleAuthToken) {
        const auth: Auth = yield call(getAuth);

        yield call(signInWithCustomToken, auth, response?.data?.googleAuthToken);
      }
    } catch (error) {
      console.error({ error });

      yield call(showToast, { type: 'error', text: 'Something went wrong. please try again later' });
    } finally {
      yield put(setAuthLoading(false));
    }
  }

  if (payload.appsflyerAndroidId) {
    yield put(setAppsflyerAndroidId(payload.appsflyerAndroidId));
    yield call([localStorage, localStorage.setItem], LOCAL_STORAGE.APPSFLYER_ANDROID_ID, payload.appsflyerAndroidId);
  }

  if (payload.appsflyerIosId) {
    yield put(setAppsflyerIosId(payload.appsflyerIosId));
    yield call([localStorage, localStorage.setItem], LOCAL_STORAGE.APPSFLYER_IOS_ID, payload.appsflyerIosId);
  }

  if (payload.appsflyerWebId) {
    yield put(setAppsflyerWebId(payload.appsflyerWebId));
  }

  if (payload.firebaseAndroidId) {
    yield put(setFirebaseAndroidId(payload.firebaseAndroidId));
    yield call([localStorage, localStorage.setItem], LOCAL_STORAGE.FIREBASE_ANDROID_ID, payload.firebaseAndroidId);
  }

  if (payload.firebaseIosId) {
    yield put(setFirebaseIosId(payload.firebaseIosId));
    yield call([localStorage, localStorage.setItem], LOCAL_STORAGE.FIREBASE_IOS_ID, payload.firebaseIosId);
  }

  if (payload.firebaseWebId) {
    yield put(setFirebaseWebId(payload.firebaseWebId));
  }
}

export function* watchApp() {
  yield all([takeEvery(HANDLE_APP_MOUNT, handleAppMountSaga)]);
}
