import styled from 'styled-components';

export const Badge = styled.div(({ theme }) => ({
  padding: '4px 8px',
  background: 'linear-gradient(180deg, #E78BF6 0%, #9867FF 100%)',
  color: theme.colors.purple.primary['700'],
  borderRadius: 13,
  fontSize: 12,
  lineHeight: '14px',
  fontWeight: 500,
}));
