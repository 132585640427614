import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';

import { TNotificationType } from '../types';

interface INotificationBox {
  isSeen: boolean;
  label: string;
  title: string;
  description: string;
  timeAgoText: string;
  type: TNotificationType;
  onClick?: () => void;
}

interface IContainer {
  isSeen: boolean;
}

const ICON_BY_TYPE = {
  game: <Icon name="controllerYellow" />,
  contest: <Icon name="prizeGreen" />,
  profile: <Icon name="chatSmile" />,
  news: <Icon name="heartPink" />,
  airdrop: <Icon name="airdropSmall" />,
};

const Container = styled.div<IContainer>(({ isSeen, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: isSeen ? 'none' : 'linear-gradient(62.42deg, #4D0C7F -9.58%, #350856 106.39%)',
  margin: '0 -24px',
  padding: 16,
  borderTop: `1px solid ${theme.colors.darkPurple}`,
  minHeight: 90,
  ':last-of-type': {
    borderBottom: `1px solid ${theme.colors.darkPurple}`,
  },
}));

const IconBox = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 40,
  minWidth: 40,
  borderRadius: 6,
  backgroundColor: theme.colors.purple.primary['500'],
  boxShadow: theme.boxShadow.primary,
  marginRight: 24,
}));

const Label = styled(Text)(({ theme }) => ({
  color: theme.colors.pink.primary['500'],
}));

const Title = styled(Text)({
  margin: '2px 0',
});

const TimeAgoText = styled(Text)({
  whiteSpace: 'nowrap',
});

const Indicators = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  paddingLeft: 12,
});

const SeenIndicator = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.red,
  height: 8,
  width: 8,
  marginTop: 8,
  borderRadius: 4,
  alignSelf: 'flex-end',
}));

export const NotificationBox = ({
  isSeen,
  title,
  description,
  label,
  timeAgoText,
  type,
  onClick,
}: INotificationBox) => (
  <Container isSeen={isSeen} onClick={onClick}>
    <IconBox>{ICON_BY_TYPE[type]}</IconBox>
    <div>
      <Label type="p_xs" weight={600}>
        {label}
      </Label>
      <Title weight={600}>{title}</Title>
      <Text type="p_s" weight={400} colorType="extraLight">
        {description}
      </Text>
    </div>
    <Indicators>
      <TimeAgoText type="p_xs" weight={600} colorType="extraLight">
        {timeAgoText}
      </TimeAgoText>
      {!isSeen && <SeenIndicator />}
    </Indicators>
  </Container>
);
