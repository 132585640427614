import styled from 'styled-components';

import { Button } from '@/components/Button/Button';
import { Icon } from '@/components/Icon/Icon';
import { PageHeader } from '@/components/PageHeader';
import { Text } from '@/components/Text';
import { LINKS } from '@/utils/constants/common';
import { useOpenLink } from '@/utils/hooks/useOpenLink';

const Description = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
}));

const StyledPageHeader = styled(PageHeader)({
  marginBottom: 32,
});

const StyledButton = styled(Button)({
  width: '100%',
  marginTop: 24,
});

const StyledIcon = styled(Icon)({
  marginLeft: 8,
});

export const Feedback = () => {
  const openLink = useOpenLink();

  const onBugReportClick = () => {
    openLink(LINKS.CHATBASE_BOT);
  };

  const onFeedbackClick = () => {
    openLink(LINKS.FEEDBACK);
  };

  return (
    <>
      <StyledPageHeader title="Feedback" />
      <Description>
        You are currently exploring an exclusive early release designed for a closed group of gaming enthusiasts.
        <br />
        <br />
        This version serves as a testing ground for selected features, allowing us to gather valuable feedback and
        identify any bugs before the wider official launch.
        <br />
        <br />
        Your experience and insights are crucial to us. Please share feedback or report a bug via below buttons.
        <br />
        <br />
        Your contributions will be appreciated and rewarded appropriately!
        <StyledButton buttonType="secondary" onClick={onFeedbackClick}>
          Share feedback <StyledIcon name="externalLink" />
        </StyledButton>
        <StyledButton buttonType="secondary" onClick={onBugReportClick}>
          Report a bug <StyledIcon name="externalLink" />
        </StyledButton>
      </Description>
    </>
  );
};
