import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { collection, doc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { isPast } from 'date-fns';

import { firebaseApp, httpsFunction } from '@/utils/firebase';
import { useCampaign } from '@/features/Games/queries';

import { getUserLocation, useAuth } from '../Auth/hooks';
import { IActivatedCampaign } from '../GamesIOS/queries';
import { getPlatform } from '../App/selectors';
import { IGame } from './types';
import { CampaignStatus } from '../Games/types';

interface IUseSetCampaignRemovedProps {
  onSettled?: () => void;
}
interface IUseSetCampaignRemoved {
  slug?: string;
  campaignId?: string;
}

export const useGame = (slug: string | undefined, userId: string | undefined, userCountry: string | undefined) =>
  useQuery({
    queryKey: ['useGame', slug, userId, userCountry],
    enabled: !!userId && !!slug && !!userCountry,
    queryFn: async () => {
      const gameDocSnapshot = (
        await getDocs(query(collection(getFirestore(firebaseApp), 'games'), where('slug', '==', slug)))
      ).docs[0];
      const gameData = gameDocSnapshot.data();
      return { id: gameDocSnapshot.id, ...gameData } as IGame;
    },
  });

export const useUserDataOnActivatedCampaign = (slug: string | undefined, userId: string | undefined) =>
  useQuery<IActivatedCampaign>({
    queryKey: ['useUserDataOnActivatedCampaign', slug, userId],
    enabled: !!userId && !!slug,
    queryFn: async () => {
      const db = getFirestore(firebaseApp);

      const activatedCampaignData = (
        await getDocs(
          query(
            collection(db, `users/${userId}/settings/activated-games/list`),
            where('gameRef', '==', doc(db, `games/${slug}`))
          )
        )
      )?.docs[0]?.data();

      return activatedCampaignData?.campaigns[0] as IActivatedCampaign;
    },
  });

export const getGame = (slug: string | undefined, campaignId?: string) => {
  const { uid } = useAuth();
  const { userCountry } = getUserLocation();
  const platform = useSelector(getPlatform);

  const { data: game, isLoading: gameLoading } = useGame(slug, uid, userCountry);
  const { data: activatedCampaign, isLoading: activatedCampaignLoading } = useUserDataOnActivatedCampaign(slug, uid);
  const { data: campaign } = useCampaign(campaignId, userCountry, platform);

  const isCampaignEnded =
    activatedCampaign?.activationTime &&
    campaign &&
    isPast(new Date(activatedCampaign?.activationTime.toMillis() + campaign.attributionWindow * 1000));

  return {
    game,
    campaign,
    loading: gameLoading || activatedCampaignLoading,
    isActivationInProgress: activatedCampaign?.status === CampaignStatus.ACTIVATING,
    hasActiveCampaign: !!activatedCampaign,
    campaignStatus: activatedCampaign?.status,
    isCampaignEnded,
  };
};

export const useSetCampaignRemoved = ({ onSettled }: IUseSetCampaignRemovedProps) => {
  const { uid } = useAuth();
  const queryClient = useQueryClient();
  return useMutation<any, any, IUseSetCampaignRemoved>(
    async ({ slug, campaignId }) => {
      const activateCampaign = httpsFunction('setCampaignRemoved');
      await activateCampaign({ uid, gameSlug: slug, campaignId, status: CampaignStatus.REMOVED });
    },
    {
      onSettled,
      onMutate: () => {
        queryClient.setQueriesData(['useGame', 'useUserDataOnActivatedGames'], []);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['useGame'] });
        queryClient.invalidateQueries({ queryKey: ['useUserDataOnActivatedGames'] });
      },
    }
  );
};
