import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isApp } from '@/utils/isApp';
import { useAuth } from '@/features/Auth/hooks';
import { getPlatform } from '@/utils/getPlatform';
import { isMobile } from '@/utils/constants/common';

import { DownloadApp } from './DownloadApp';
import { AddToHomeScreen } from './AddToHomeScreen';
import { NotSupportedBrowser } from './NotSupportedBrowser';
import { allowedMobileBrowserRoutes } from '../constants';
import { isAddToHomeScreenHidden } from '../selectors';

export const AddToHomeScreenContainer = () => {
  const location = useLocation();
  const platform = getPlatform();
  const { isAuthenticatedStack } = useAuth();

  const shouldHide = useSelector(isAddToHomeScreenHidden);

  const isIos = platform === 'ios';
  const isAndroid = platform === 'android';
  const isPwaAddedToHomeScreen = 'standalone' in window.navigator && window.navigator.standalone === true;
  const isSafari = navigator.userAgent.indexOf('AppleWebKit') > -1 && navigator.userAgent.indexOf('Version') > -1;
  const isAllowedMobileBrowserRoute = allowedMobileBrowserRoutes.includes(location.pathname);

  return (
    <>
      <AddToHomeScreen
        isVisible={
          isIos &&
          isAuthenticatedStack &&
          !isPwaAddedToHomeScreen &&
          !isAllowedMobileBrowserRoute &&
          isSafari &&
          !shouldHide
        }
      />
      {/* Disable modal until reliable method is implemented how to detect android app */}
      <DownloadApp isVisible={isAndroid && isMobile && !isApp() && false} />
      <NotSupportedBrowser
        isVisible={isIos && !isPwaAddedToHomeScreen && !isAllowedMobileBrowserRoute && !isSafari && !shouldHide}
      />
    </>
  );
};
