import { useMutation, useQueryClient } from 'react-query';

import { httpsFunction } from '@/utils/firebase';

type TAssetToBuy = 'credits' | 'tickets';

// interface IUseAssetsSwapProps {
//   onSuccess: () => void;
// }

interface IUseAssetsSwap {
  assetToBuy: TAssetToBuy;
  amount: number;
}

export const useAssetsSwap = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, IUseAssetsSwap>(
    async ({ assetToBuy, amount }) => {
      const swapAssets = httpsFunction('swapAssets');

      await swapAssets({ assetToBuy, amount });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useAssetsData']);
      },
    }
  );
};
