import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { LINKS } from '@/utils/constants/common';
import { useIsIosApp } from '@/features/App/hooks';
import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { Icon } from '@/components/Icon/Icon';

import { useContest } from '../queries';
import { AppleDisclaimerTag } from './AppleDisclaimerTag';

const DescriptionText = styled.pre(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 400,
  fontFamily: "'Inter', sans-serif",
  whiteSpace: 'pre-wrap',
}));

const Link = styled.div(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
  fontSize: 14,
  marginTop: 30,
  lineHeight: '16px',
  cursor: 'pointer',
  marginBottom: 10,
  display: 'flex',
  svg: {
    marginLeft: 5,
  },
}));

export const ContestDescription = () => {
  const openLink = useOpenLink();
  const isIOSapp = useIsIosApp();
  const { uid: contestId = '' } = useParams();
  const { data } = useContest({ contestId });

  const onTermsClick = () => openLink(LINKS.BG_TERMS);

  const formattedText = data?.description?.replace(/\|/g, '\n');

  return (
    <>
      <DescriptionText>{formattedText}</DescriptionText>

      <Link onClick={onTermsClick}>
        Terms of Use
        <Icon name="externalLink" width={14} />
      </Link>

      <AppleDisclaimerTag isVisible={isIOSapp} />
    </>
  );
};
