import styled from 'styled-components';
import { useCountdown } from './useCountdown';

export const WatchCard = styled.div<{ isEndingSoon?: boolean }>(({ theme, isEndingSoon = false }) => ({
  backgroundColor: isEndingSoon ? '#461452' : theme.colors.purple.primary['600'],
  borderRadius: 2,
  height: 28,
  width: 20,
  boxShadow: '1px 1px 0px 0px #000',
  display: 'flex',
  color: isEndingSoon ? theme.colors.red : theme.colors.purple.primary['200'],
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 2,
  ':last-of-type': {
    marginRight: 0,
  },
}));

const SubTitle = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.colors.purple.primary[100],
  fontWeight: 600,
  fontSize: 16,
  marginLeft: 5,
  textTransform: 'capitalize',
}));

export const Separator = styled.div<{ isEndingSoon?: boolean }>(({ theme, isEndingSoon = false }) => ({
  opacity: 0.5,
  marginRight: 2,
  color: isEndingSoon ? theme.colors.red : theme.colors.purple.primary['200'],
}));

export const WatchFaceContainer = styled.div({
  display: 'flex',
  fontSize: 16,
});

export const Countdown = ({ startDate, endDate }: { startDate?: Date | number; endDate?: Date | number }) => {
  const [countdown, isEndingSoon, isMoreThanADayLeft] = useCountdown(startDate, endDate);

  return (
    <WatchFaceContainer>
      {countdown.split('').map((char, index) => {
        if (char === ':') {
          return (
            <Separator isEndingSoon={isEndingSoon} key={index}>
              :
            </Separator>
          );
        }
        return (
          <WatchCard isEndingSoon={isEndingSoon} key={index}>
            {char}
          </WatchCard>
        );
      })}
      {isMoreThanADayLeft && <SubTitle>Days</SubTitle>}
    </WatchFaceContainer>
  );
};
