import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

const SuccessMessageWrapper = styled.div`
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 125%;
`;

interface IMessage extends HTMLAttributes<HTMLDivElement> {
  message: ReactNode;
}

export const SuccessMessage = ({ message, ...rest }: IMessage) => (
  <SuccessMessageWrapper {...rest}>{message}</SuccessMessageWrapper>
);
