import { isPast } from 'date-fns';
import { useQuery } from 'react-query';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { DocumentReference, Timestamp, collection, getDocs, getFirestore, query } from 'firebase/firestore';

import { firebaseApp } from '@/utils/firebase';
import { getPlatform } from '@/utils/getPlatform';

import { getUserLocation, useAuth } from '../Auth/hooks';
import { CampaignStatus } from '../Games/types';
import { useGames, useGetCampaignByStoreId } from '../Games/queries';

export interface IActivatedCampaign {
  activationTime: Timestamp;
  id: string;
  status: CampaignStatus;
}

export interface IActivatedGame {
  gameRef: DocumentReference;
  campaigns: Array<IActivatedCampaign>;
}

export const useUserDataOnActivatedGames = (uid?: string) => {
  return useQuery({
    queryKey: ['useUserDataOnActivatedGames', uid],
    enabled: !!uid,
    queryFn: async () => {
      const activatedGamesDocs = (
        await getDocs(collection(getFirestore(firebaseApp), `users/${uid}/settings/activated-games/list`))
      ).docs;

      return activatedGamesDocs.map((activatedGamesDoc) => activatedGamesDoc.data() as IActivatedGame);
    },
  });
};

export const useGetUserActivatedCampaignByGameId = () => {
  const { uid } = useAuth();
  const { data: userDataOnActivatedGames } = useUserDataOnActivatedGames(uid);

  return (gameId?: string) => userDataOnActivatedGames?.find(({ gameRef }) => gameRef.id === gameId)?.campaigns[0];
};

export const useActivatedGames = (uid?: string, userCountry?: string, platform?: string) => {
  const { data: games } = useGames(uid, userCountry, platform);
  const { data: userDataOnActivatedGames } = useUserDataOnActivatedGames(uid);

  return useQuery({
    queryKey: ['useActivatedGames', uid, userCountry, platform, games, userDataOnActivatedGames],
    enabled: !!uid && !!userCountry && !!platform && !!games && !!userDataOnActivatedGames,
    queryFn: async () => {
      const userActivatedGames = userDataOnActivatedGames?.filter(
        ({ campaigns }) => campaigns[0]?.status === CampaignStatus.ACTIVATED
      );

      const activatedGames = games?.filter(({ id }) => userActivatedGames?.find(({ gameRef }) => gameRef.id === id));

      if (activatedGames) {
        mixpanel.people.set({
          activatedGames: activatedGames.map(({ title }) => title),
          activatedGameCount: activatedGames.length,
        });
      }

      return activatedGames;
    },
  });
};

export const useRemovedGames = (uid?: string, userCountry?: string, platform?: string) => {
  const { data: games } = useGames(uid, userCountry, platform);
  const { data: userDataOnActivatedGames } = useUserDataOnActivatedGames(uid);

  return useQuery({
    queryKey: ['useRemovedGames', uid, userCountry, platform, games, userDataOnActivatedGames],
    enabled: !!uid && !!userCountry && !!platform && !!games && !!userDataOnActivatedGames,
    queryFn: async () => {
      const removedGames = userDataOnActivatedGames?.filter(
        ({ campaigns }) => campaigns[0]?.status === CampaignStatus.REMOVED
      );
      return games?.filter(({ id }) => removedGames?.find(({ gameRef }) => gameRef.id === id));
    },
  });
};

// Would be best to split into 2 hooks - inProgressGames / completedGames,
// however then there is a need to loop over all challenges 2 times or make a third hook to fetch all challenges.
// Can be solved by adding additional status (eg: challengeCompleted) to user campaign doc
// Can be also improved by adding if the campaign is ended.
export const useFilteredGamesByCompletionStatus = (uid?: string, userCountry?: string, platform?: string) => {
  const { data: activatedGames } = useActivatedGames(uid, userCountry, platform);
  const { data: userDataOnActivatedGames } = useUserDataOnActivatedGames(uid);
  const getCampaignByStoreId = useGetCampaignByStoreId();
  return useQuery({
    queryKey: ['useFilteredGamesByCompletionStatus', uid, userCountry, platform, activatedGames],
    enabled: !!uid && !!userCountry && !!platform && !!activatedGames,
    queryFn: async () => {
      // This block can be removed when isCompleted is added to user campaign doc by checking if all challenges completed
      const completedChallengesGameIds =
        activatedGames &&
        (await Promise.all(
          activatedGames.map(async (game) => {
            const activatedGameCampaignId = userDataOnActivatedGames?.find(({ gameRef }) => gameRef.id === game.id)
              ?.campaigns[0].id;

            const userChallengesSnapshot = (
              await getDocs(query(collection(getFirestore(firebaseApp), `users/${uid}/challenges/${game.slug}/list`)))
            ).docs;
            const userChallenges = userChallengesSnapshot.map((challenge) => challenge.data());

            const allGameChallengesSnapshot = (
              await getDocs(query(collection(getFirestore(firebaseApp), `challenges/${game.slug}/list`)))
            ).docs;
            const allGameChallenges = allGameChallengesSnapshot
              .map((challenge) => challenge.data())
              .filter((challenge) => challenge.campaignRef.id === activatedGameCampaignId);

            if (!userChallenges?.length) return undefined;

            const allChallengesClaimed =
              userChallenges.filter((challenge) => challenge.isClaimed)?.length === allGameChallenges?.length;

            if (allChallengesClaimed) {
              return game.id;
            }

            return undefined;
          })
        ));
      // end of block

      const campaignEndedGamesIds = activatedGames?.map((game) => {
        const campaign = getCampaignByStoreId((game?.androidStoreID || game?.iosStoreID) as string);
        const userDataOnCampaign = userDataOnActivatedGames?.find(({ gameRef }) => gameRef.id === game.id)
          ?.campaigns[0];

        if (!userDataOnCampaign) {
          return undefined;
        }

        const activationTimeInMillis = userDataOnCampaign?.activationTime?.toMillis();

        if (userDataOnCampaign.status !== CampaignStatus.ACTIVATED) {
          return undefined;
        }

        if (!campaign) {
          return undefined;
        }

        // Attribution window is in seconds, multiply by 1000 to get millis
        const campaignDuration = campaign?.attributionWindow * 1000;
        const campaignEndDate = new Date(activationTimeInMillis + campaignDuration);

        if (isPast(campaignEndDate)) {
          return game.id;
        }
      });

      const completedGames = activatedGames?.filter(({ id }) => completedChallengesGameIds?.includes(id)) || [];
      const inProgressGames =
        activatedGames?.filter(
          ({ id }) => !completedChallengesGameIds?.includes(id) && !campaignEndedGamesIds?.includes(id)
        ) || [];
      const campaignEndedGames = activatedGames?.filter(({ id }) => campaignEndedGamesIds?.includes(id)) || [];

      mixpanel.people.set({
        completedGames: completedGames.map(({ title }) => title),
        completedGameCount: completedGames.length,
        inProgressGames: inProgressGames.map(({ title }) => title),
        inProgressGamesCount: inProgressGames.length,
        campaignEndedGames: campaignEndedGames.map(({ title }) => title),
        campaignEndedGamesCount: campaignEndedGames.length,
      });

      return { completedGames, inProgressGames, campaignEndedGames };
    },
  });
};

export const getInProgressGames = () => {
  const { uid } = useAuth();
  const { userCountry } = getUserLocation();
  const platform = useSelector(getPlatform);
  const { data, ...rest } = useFilteredGamesByCompletionStatus(uid, userCountry, platform);

  return { data: data?.inProgressGames || [], ...rest };
};

export const getCampaignEndedGames = () => {
  const { uid } = useAuth();
  const { userCountry } = getUserLocation();
  const platform = useSelector(getPlatform);
  const { data, ...rest } = useFilteredGamesByCompletionStatus(uid, userCountry, platform);

  return { data: data?.campaignEndedGames || [], ...rest };
};
