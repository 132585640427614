import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

interface ICompleteProfile {
  isModalVisible: boolean;
}

const initialState: ICompleteProfile = {
  isModalVisible: false,
};

export const completeProfileSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isModalVisible = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setIsModalVisible, resetState } = completeProfileSlice.actions;
