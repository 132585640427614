export const NAME = 'games';

export const GAMES_SUGGESTIONS = [
  {
    genre: 'Action',
    isShown: true,
    title: 'Explore Action games',
  },
  {
    genre: 'Adventure',
    isShown: true,
    title: 'Explore Adventure games',
  },
  {
    genre: 'Casual',
    isShown: true,
    title: 'Explore Casual games',
  },
  {
    genre: 'Indie',
    isShown: true,
    title: 'Explore Indie games',
  },
  {
    genre: 'Multiplayer',
    isShown: true,
    title: 'Explore Multiplayer games',
  },
  {
    genre: 'Racing',
    isShown: true,
    title: 'Explore Racing games',
  },
  {
    genre: 'RPG',
    isShown: true,
    title: 'Explore RPG games',
  },
  {
    genre: 'Simulation',
    isShown: true,
    title: 'Explore Simulation games',
  },
  {
    genre: 'Sports',
    isShown: true,
    title: 'Explore Sports games',
  },
  {
    genre: 'Strategy',
    isShown: true,
    title: 'Explore Strategy games',
  },
  {
    genre: 'Puzzle',
    isShown: true,
    title: 'Explore Puzzle games',
  },
];

export const ALL_GAMES = 'All games';

export const GAME_GENRES = [
  'Action',
  'Puzzle',
  'Casual',
  'Single player',
  'Offline',
  'Strategy',
  'Multiplayer',
  'Role Playing',
  'Arcade',
  'Simulation',
  'Casino',
  'Adventure',
  'Board',
  'Card',
  'Anime',
  'Social Networking',
  'Trivia',
  'Build & battle',
  'Family',
  'Tycoon',
  'Idle',
  'Logic',
  'Turn-based RPG',
  'Tower defense',
  'Shooter',
  'Misc',
];
