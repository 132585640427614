import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

interface IAssetsSwap {
  isModalVisible: boolean;
  creditsAmount: number;
}

const initialState: IAssetsSwap = {
  isModalVisible: false,
  creditsAmount: 0,
};

export const assetsSwapSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isModalVisible = action.payload;
    },
    setCreditsAmount: (state, action: PayloadAction<number>) => {
      state.creditsAmount = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setModalVisible, setCreditsAmount, resetState } = assetsSwapSlice.actions;
