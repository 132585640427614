import styled from 'styled-components';

import { useAnimate, stagger } from 'framer-motion';
import hideable from '@/utils/hideable';
import { useAssets } from '@/features/Assets/queries';
import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import { useState, useEffect } from 'react';

const Flexbox = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 12,
});

const AmountItemContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 4,
});

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  marginLeft: 4,
  display: 'flex',
}));

const AssetInfoComponent = () => {
  const [initialAssets, setInitialAssets] = useState<{ tickets: number; credits: number } | undefined>(undefined);
  const { tickets, credits, isFetched } = useAssets();

  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isFetched) {
      setInitialAssets({ tickets, credits });
    }
  }, [isFetched]);

  const runAnimation = async (assetType: 'credit' | 'ticket') => {
    const assetCharArray = `${assetType === 'credit' ? credits : tickets}`.split('');

    const pulseIconAnimation = [
      [`.${assetType}Icon`, { scale: 1.4 }, { duration: 1 }],
      [`.${assetType}Icon`, { scale: 1 }],
    ] as any;

    const fadeOutAnimation = [
      ...assetCharArray.map((_, index) => [
        `.${assetType}-${index}`,
        { y: 10, opacity: 0 },
        { duration: 0.2, delay: stagger(0.01), ...(index !== 0 && { at: index * 0.05 }) },
      ]),
    ] as any;

    const fadeInAnimation = [
      ...assetCharArray.map((_, index) => [`.${assetType}-${index}`, { y: -10, opacity: 0 }, { duration: 0 }]),
      ...assetCharArray.map((_, index) => [
        `.${assetType}-${index}`,
        { y: 0, opacity: 1 },
        {
          duration: 0.15,
          delay: stagger(0.01),
          ...(index !== 0 && { at: index * 0.05 }),
        },
      ]),
    ] as any;

    animate(pulseIconAnimation);
    await animate(fadeOutAnimation);
    animate(fadeInAnimation);
  };

  useEffect(() => {
    if (initialAssets?.tickets && tickets !== initialAssets?.tickets) {
      setInitialAssets({ ...initialAssets, tickets });
      runAnimation('ticket');
    }
    if (initialAssets?.credits && credits !== initialAssets?.credits) {
      setInitialAssets({ ...initialAssets, credits });
      runAnimation('credit');
    }
  }, [tickets, credits]);

  return (
    <Flexbox ref={scope}>
      <AmountItemContainer>
        <Icon name="creditBig" className="creditIcon" />
        <StyledText className="creditCount" type="h5" weight={700}>
          {`${credits}`.split('').map((char, index) => (
            <div key={index} className={`credit-${index}`}>
              {char}
            </div>
          ))}
        </StyledText>
      </AmountItemContainer>
      <AmountItemContainer>
        <Icon name="ticketBig" className="ticketIcon" />
        <StyledText type="h5" weight={700}>
          {`${tickets}`.split('').map((char, index) => (
            <div key={index} className={`ticket-${index}`}>
              {char}
            </div>
          ))}
        </StyledText>
      </AmountItemContainer>
    </Flexbox>
  );
};

export const AssetInfo = hideable(AssetInfoComponent);
