import styled from 'styled-components';

import { Text } from '@/components/Text';
import NoGamesVisual from '@/assets/visuals/connect_games_visual.svg';
import hideable from '@/utils/hideable';

const Visual = styled.div({
  backgroundImage: `url(${NoGamesVisual})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  height: 168,
  width: 308,
  margin: '48px auto 0',
});

const Container = styled.div({
  maxWidth: 308,
  margin: '0 auto',
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary[50],
  marginBottom: 4,
}));

const Paragraph = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary[200],
}));

export const NoInProgressGamesComponent = () => (
  <Container>
    <Visual />
    <Title type="h3" align="center">
      Activate your first game
    </Title>
    <Paragraph type="p_m" align="center">
      Choose a game, activate it, start playing, and collect your rewards.
    </Paragraph>
  </Container>
);

export const NoInProgressGames = hideable(NoInProgressGamesComponent);
