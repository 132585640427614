import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Button } from '@/components/Button/Button';
import hideable from '@/utils/hideable';
import launchCampaignBackground from '@/assets/images/launch-campaign-card-background.png';

interface ILaunchCampaignCardProps {
  onClick: () => void;
}

const Title = styled(Text)({
  marginBottom: 4,
  padding: '0 32px',
});

const Paragraph = styled(Text)({
  margin: '8px 0 0 0',
});

const StyledButton = styled(Button)({
  marginTop: 16,
});

const Container = styled.div(({ theme }) => ({
  background: 'linear-gradient(360deg, #521383 -30.95%, rgba(82, 19, 131, 0) 100%)',
  border: `1px solid ${theme.colors.purple.primary['600']}`,
  borderRadius: 8,
  margin: '16px 24px',
}));

const ContentWrapper = styled.div({
  padding: '32px 24px',
  minHeight: '74px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 8,
  backgroundImage: `url(${launchCampaignBackground})`,
  backgroundPosition: 'top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const BybitCampaignComponent = ({ onClick }: ILaunchCampaignCardProps) => (
  <Container>
    <ContentWrapper>
      <Title type="p_l" weight={600} align="center">
        Be among the first 1,000 users to win 500 $BLOCK!
      </Title>
      <Paragraph type="p_s" weight={400} colorType="light" align="center">
        First 1,000 new users to register, deposit and trade $BLOCK on Bybit win 500 $BLOCK
      </Paragraph>
      <StyledButton onClick={onClick} buttonSize="medium">
        Learn more on Bybit
      </StyledButton>
    </ContentWrapper>
  </Container>
);

export const BybitCampaign = hideable(BybitCampaignComponent);
