export const BREAKPOINTS = {
  LG_WIDTH: '@media (min-width: 1024px)',
  MD_WIDTH: '@media (min-width: 764px)',
  SM_WIDTH: '@media (min-width: 640px)',
  XS_WIDTH: '@media (min-width: 475px)',
};

export const DESKTOP_CARD_DIMENSIONS = {
  WIDTH: 380,
  HEIGHT: 686,
};
