import { SVGProps } from 'react';
import { ReactComponent as BLCreditIcon } from '@/assets/icons/BL_credit_icon.svg';
import { ReactComponent as UsersIcon } from '@/assets/icons/users_icon.svg';
import { ReactComponent as SuccessRoundTickGreenIcon } from '@/assets/icons/success_round_tick_green_icon.svg';
import { ReactComponent as CashSackIcon } from '@/assets/icons/cash_sack_icon.svg';
import { ReactComponent as LockIcon } from '@/assets/icons/lock_icon.svg';
import { ReactComponent as USDCIcon } from '@/assets/icons/USDC_icon.svg';
import { ReactComponent as SkullIcon } from '@/assets/icons/skull_icon.svg';
import { ReactComponent as Crown2Icon } from '@/assets/icons/crown_2_icon.svg';
import { ReactComponent as MoreItemsIcon } from '@/assets/icons/more_items_icon.svg';
import { ReactComponent as SuccessTickIcon } from '@/assets/icons/success_tick_icon.svg';
import { ReactComponent as ChevronIcon } from '@/assets/icons/chevron.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/user_icon.svg';
import { ReactComponent as CrossIcon } from '@/assets/icons/cross_icon.svg';
import { ReactComponent as CrossBigger } from '@/assets/icons/cross-bigger.svg';
import { ReactComponent as Referrals } from '@/assets/icons/referrals.svg';
import { ReactComponent as CreditIcon } from '@/assets/icons/credit_icon.svg';
import { ReactComponent as TicketIcon } from '@/assets/icons/ticket.svg';
import { ReactComponent as ClockIcon } from '@/assets/icons/clock_icon.svg';
import { ReactComponent as TicketBig } from '@/assets/icons/ticket_big.svg';
import { ReactComponent as CreditBig } from '@/assets/icons/credit_big.svg';
import { ReactComponent as CheckMark2Icon } from '@/assets/icons/check_mark_2_icon.svg';
import { ReactComponent as Logout } from '@/assets/icons/logout.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info_icon.svg';
import { ReactComponent as Protected } from '@/assets/icons/protected.svg';
import { ReactComponent as ClockSmall } from '@/assets/icons/clock_small.svg';
import { ReactComponent as TicketSmall } from '@/assets/icons/ticket_small.svg';
import { ReactComponent as ChevronLeft } from '@/assets/icons/chevron_left.svg';
import { ReactComponent as ChevronLeftSmall } from '@/assets/icons/chevron_left_small.svg';
import { ReactComponent as ThumbDots } from '@/assets/icons/thumb_dots.svg';
import { ReactComponent as WinnersIcon } from '@/assets/icons/winners_icon.svg';
import { ReactComponent as GlobeIcon } from '@/assets/icons/globe.svg';
import { ReactComponent as GooglePlayIcon } from '@/assets/icons/gplay.svg';
import { ReactComponent as AppleIcon } from '@/assets/icons/apple.svg';
import { ReactComponent as EmailIcon } from '@/assets/icons/email.svg';
import { ReactComponent as GiftIcon } from '@/assets/icons/gift.svg';
import { ReactComponent as RollPaperIcon } from '@/assets/icons/roll_paper.svg';
import { ReactComponent as ItemSmall } from '@/assets/icons/item_small.svg';
import { ReactComponent as PurpleCross } from '@/assets/icons/purple_cross.svg';
import { ReactComponent as CreditSmall } from '@/assets/icons/credit_small.svg';
import { ReactComponent as StarFill } from '@/assets/icons/star_fill_icon.svg';
import { ReactComponent as FireLine } from '@/assets/icons/fire_line.svg';
import { ReactComponent as ChevronDown } from '@/assets/icons/chevron_down.svg';
import { ReactComponent as Immutable } from '@/assets/icons/immutable.svg';
import { ReactComponent as Polygon } from '@/assets/icons/polygon.svg';
import { ReactComponent as Bnb } from '@/assets/icons/bnb.svg';
import { ReactComponent as EthIcon } from '@/assets/icons/eth_icon.svg';
import { ReactComponent as CubeIcon } from '@/assets/icons/cube_icon.svg';
import { ReactComponent as CubeActiveIcon } from '@/assets/icons/cube_active_icon.svg';
import { ReactComponent as CoinsIcon } from '@/assets/icons/coins_icon.svg';
import { ReactComponent as CoinsActiveIcon } from '@/assets/icons/coins_active_icon.svg';
import { ReactComponent as CollectiblesIcon } from '@/assets/icons/collectibles_icon.svg';
import { ReactComponent as CollectiblesActiveIcon } from '@/assets/icons/collectibles_active_icon.svg';
import { ReactComponent as Block } from '@/assets/icons/block.svg';
import { ReactComponent as Rules } from '@/assets/icons/rules.svg';
import { ReactComponent as RulesActive } from '@/assets/icons/rules_active.svg';
import { ReactComponent as Leaderboard } from '@/assets/icons/leaderboard.svg';
import { ReactComponent as Coin } from '@/assets/icons/coin.svg';
import { ReactComponent as CoinActive } from '@/assets/icons/coinActive.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close_icon.svg';
import { ReactComponent as ArrowRight } from '@/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from '@/assets/icons/arrow-up.svg';
import { ReactComponent as ExternalLink } from '@/assets/icons/external-link.svg';
import { ReactComponent as ChevronRight } from '@/assets/icons/chevron-right.svg';
import { ReactComponent as BadgeX3 } from '@/assets/icons/3x-badge.svg';
import { ReactComponent as BadgeX2 } from '@/assets/icons/2x-badge.svg';
import { ReactComponent as Settings } from '@/assets/icons/settings.svg';
import { ReactComponent as Needle } from '@/assets/icons/needle.svg';
import { ReactComponent as Copy } from '@/assets/icons/copy.svg';
import { ReactComponent as ExternalLinkDark } from '@/assets/icons/external-link-dark.svg';
import { ReactComponent as GreenDot } from '@/assets/icons/green_dot.svg';
import { ReactComponent as OrangeDot } from '@/assets/icons/orange_dot.svg';
import { ReactComponent as RedDot } from '@/assets/icons/red_dot.svg';
import { ReactComponent as ChevronBlackRight } from '@/assets/icons/chevron-black-right.svg';
import { ReactComponent as SuccessTickEmblem } from '@/assets/icons/success_tick_emblem.svg';
import { ReactComponent as Flame } from '@/assets/icons/flame.svg';
import { ReactComponent as PurpleRectangle } from '@/assets/icons/purple-rectangle.svg';
import { ReactComponent as GooglePlay } from '@/assets/icons/google-play.svg';
import { ReactComponent as GoogleIcon } from '@/assets/icons/google_colour.svg';
import { ReactComponent as AppStore } from '@/assets/icons/appstore.svg';
import { ReactComponent as OpenSea } from '@/assets/icons/opensea.svg';
import { ReactComponent as ChevronUpLight } from '@/assets/icons/chevronUpLight.svg';
import { ReactComponent as ChevronDownLight } from '@/assets/icons/chevronDownLight.svg';
import { ReactComponent as YellowStar } from '@/assets/icons/yellow_star.svg';
import { ReactComponent as RedPointyStar } from '@/assets/icons/red_pointy_star.svg';
import { ReactComponent as ToastError } from '@/assets/icons/toast-error.svg';
import { ReactComponent as ToastInfo } from '@/assets/icons/toast-info.svg';
import { ReactComponent as ToastSuccess } from '@/assets/icons/toast-success.svg';
import { ReactComponent as Goodies } from '@/assets/icons/goodies.svg';
import { ReactComponent as Discovery } from '@/assets/icons/discovery.svg';
import { ReactComponent as PurpleStar } from '@/assets/icons/purple-star.svg';
import { ReactComponent as Share } from '@/assets/icons/share.svg';
import { ReactComponent as GreenCheckmark } from '@/assets/icons/green_checkmark.svg';
import { ReactComponent as TickGreen } from '@/assets/icons/tick-green.svg';
import { ReactComponent as NewText } from '@/assets/icons/new-text.svg';
import { ReactComponent as Skip } from '@/assets/icons/skip-arrow-right.svg';
import { ReactComponent as ControllerYellor } from '@/assets/icons/controller-yellow.svg';
import { ReactComponent as Bell } from '@/assets/icons/bell.svg';
import { ReactComponent as BellRinging } from '@/assets/icons/bell-ringing.svg';
import { ReactComponent as ChatSmile } from '@/assets/icons/chat-smile.svg';
import { ReactComponent as HeartPink } from '@/assets/icons/heart-pink.svg';
import { ReactComponent as PrizeGreen } from '@/assets/icons/prize-green.svg';
import { ReactComponent as BlockText } from '@/assets/icons/block-text.svg';
import { ReactComponent as Trophy } from '@/assets/icons/trophy.svg';
import { ReactComponent as ArrowRightUp } from '@/assets/icons/arrow_right_up.svg';
import { ReactComponent as ArrowUpRightBlack } from '@/assets/icons/arrow-up-right-black.svg';
import { ReactComponent as GooglePlayColor } from '@/assets/icons/google_play_color.svg';
import { ReactComponent as AirdropSmall } from '@/assets/icons/airdrop-small.svg';
import { ReactComponent as GoogleWhite } from '@/assets/icons/google-white.svg';
import { ReactComponent as AppleBlack } from '@/assets/icons/apple-black.svg';

export type IconProps = {
  name:
    | 'arrowRightUp'
    | 'blCredit'
    | 'greenDot'
    | 'users'
    | 'successRoundGreenTick'
    | 'cashSack'
    | 'lock'
    | 'usdc'
    | 'skull'
    | 'crown2'
    | 'moreItems'
    | 'successTick'
    | 'user'
    | 'chevron'
    | 'cross'
    | 'credit'
    | 'referrals'
    | 'clock'
    | 'checkMark2'
    | 'ticketBig'
    | 'creditBig'
    | 'logout'
    | 'info'
    | 'protected'
    | 'clockSmall'
    | 'ticketSmall'
    | 'chevronLeft'
    | 'chevronLeftSmall'
    | 'thumbDots'
    | 'winnersIcon'
    | 'globe'
    | 'googlePlay'
    | 'googlePlayColor'
    | 'apple'
    | 'email'
    | 'google'
    | 'gift'
    | 'rollPaper'
    | 'close'
    | 'itemSmall'
    | 'creditSmall'
    | 'purpleCross'
    | 'ticket'
    | 'starFill'
    | 'fireLine'
    | 'chevronDown'
    | 'immutable'
    | 'polygon'
    | 'eth'
    | 'cube'
    | 'cubeActive'
    | 'coins'
    | 'coinsActive'
    | 'collectibles'
    | 'collectiblesActive'
    | 'bnb'
    | 'block'
    | 'rules'
    | 'arrowUp'
    | 'arrowUpRightBlack'
    | 'rulesActive'
    | 'leaderboard'
    | 'coin'
    | 'arrowRight'
    | 'chevronRight'
    | 'externalLink'
    | 'badgeX3'
    | 'badgeX2'
    | 'settings'
    | 'needle'
    | 'coinActive'
    | 'copy'
    | 'externalLinkDark'
    | 'chevronBlackRight'
    | 'successEmblem'
    | 'flame'
    | 'googlePlaySmall'
    | 'appStore'
    | 'openSea'
    | 'orangeDot'
    | 'redDot'
    | 'chevronDownLight'
    | 'chevronUpLight'
    | 'yellowStar'
    | 'purpleRectangle'
    | 'toastSuccess'
    | 'toastError'
    | 'toastInfo'
    | 'redPointyStar'
    | 'discovery'
    | 'purpleStar'
    | 'share'
    | 'tickGreen'
    | 'greenCheckmark'
    | 'newText'
    | 'skip'
    | 'controllerYellow'
    | 'bell'
    | 'crossBigger'
    | 'bellRinging'
    | 'chatSmile'
    | 'heartPink'
    | 'prizeGreen'
    | 'blockText'
    | 'trophy'
    | 'airdropSmall'
    | 'googleWhite'
    | 'appleBlack'
    | 'goodies';
  width?: number;
  height?: number;
} & SVGProps<SVGSVGElement>;

export const Icon = ({ name, ...svgProps }: IconProps) => {
  const Icons: Record<IconProps['name'], any> = {
    blCredit: <BLCreditIcon {...svgProps} />,
    users: <UsersIcon {...svgProps} />,
    successRoundGreenTick: <SuccessRoundTickGreenIcon {...svgProps} />,
    cashSack: <CashSackIcon {...svgProps} />,
    lock: <LockIcon {...svgProps} />,
    usdc: <USDCIcon {...svgProps} />,
    skull: <SkullIcon {...svgProps} />,
    crown2: <Crown2Icon {...svgProps} />,
    moreItems: <MoreItemsIcon {...svgProps} />,
    successTick: <SuccessTickIcon {...svgProps} />,
    chevron: <ChevronIcon {...svgProps} />,
    user: <UserIcon {...svgProps} />,
    cross: <CrossIcon {...svgProps} />,
    referrals: <Referrals {...svgProps} />,
    credit: <CreditIcon {...svgProps} />,
    ticket: <TicketIcon {...svgProps} />,
    clock: <ClockIcon {...svgProps} />,
    checkMark2: <CheckMark2Icon {...svgProps} />,
    ticketBig: <TicketBig {...svgProps} />,
    creditBig: <CreditBig {...svgProps} />,
    logout: <Logout {...svgProps} />,
    info: <InfoIcon {...svgProps} />,
    protected: <Protected {...svgProps} />,
    clockSmall: <ClockSmall {...svgProps} />,
    ticketSmall: <TicketSmall {...svgProps} />,
    chevronLeft: <ChevronLeft {...svgProps} />,
    chevronLeftSmall: <ChevronLeftSmall {...svgProps} />,
    thumbDots: <ThumbDots {...svgProps} />,
    winnersIcon: <WinnersIcon {...svgProps} />,
    globe: <GlobeIcon {...svgProps} />,
    googlePlay: <GooglePlayIcon {...svgProps} />,
    googlePlayColor: <GooglePlayColor {...svgProps} />,
    apple: <AppleIcon {...svgProps} />,
    email: <EmailIcon {...svgProps} />,
    google: <GoogleIcon {...svgProps} />,
    gift: <GiftIcon {...svgProps} />,
    rollPaper: <RollPaperIcon {...svgProps} />,
    itemSmall: <ItemSmall {...svgProps} />,
    purpleCross: <PurpleCross {...svgProps} />,
    creditSmall: <CreditSmall {...svgProps} />,
    starFill: <StarFill {...svgProps} />,
    fireLine: <FireLine {...svgProps} />,
    chevronDown: <ChevronDown {...svgProps} />,
    immutable: <Immutable {...svgProps} />,
    polygon: <Polygon {...svgProps} />,
    bnb: <Bnb {...svgProps} />,
    eth: <EthIcon {...svgProps} />,
    cube: <CubeIcon {...svgProps} />,
    cubeActive: <CubeActiveIcon {...svgProps} />,
    coins: <CoinsIcon {...svgProps} />,
    coinsActive: <CoinsActiveIcon {...svgProps} />,
    collectibles: <CollectiblesIcon {...svgProps} />,
    collectiblesActive: <CollectiblesActiveIcon {...svgProps} />,
    block: <Block {...svgProps} />,
    rules: <Rules {...svgProps} />,
    rulesActive: <RulesActive {...svgProps} />,
    leaderboard: <Leaderboard {...svgProps} />,
    coin: <Coin {...svgProps} />,
    coinActive: <CoinActive {...svgProps} />,
    close: <CloseIcon {...svgProps} />,
    arrowRight: <ArrowRight {...svgProps} />,
    arrowUp: <ArrowUp {...svgProps} />,
    externalLink: <ExternalLink {...svgProps} />,
    chevronRight: <ChevronRight {...svgProps} />,
    badgeX2: <BadgeX2 {...svgProps} />,
    badgeX3: <BadgeX3 {...svgProps} />,
    settings: <Settings {...svgProps} />,
    needle: <Needle {...svgProps} />,
    externalLinkDark: <ExternalLinkDark {...svgProps} />,
    copy: <Copy {...svgProps} />,
    greenDot: <GreenDot {...svgProps} />,
    orangeDot: <OrangeDot {...svgProps} />,
    chevronBlackRight: <ChevronBlackRight {...svgProps} />,
    successEmblem: <SuccessTickEmblem {...svgProps} />,
    flame: <Flame {...svgProps} />,
    purpleRectangle: <PurpleRectangle {...svgProps} />,
    googlePlaySmall: <GooglePlay {...svgProps} />,
    appStore: <AppStore {...svgProps} />,
    openSea: <OpenSea {...svgProps} />,
    chevronDownLight: <ChevronDownLight {...svgProps} />,
    chevronUpLight: <ChevronUpLight {...svgProps} />,
    yellowStar: <YellowStar {...svgProps} />,
    redPointyStar: <RedPointyStar {...svgProps} />,
    redDot: <RedDot {...svgProps} />,
    toastError: <ToastError {...svgProps} />,
    toastInfo: <ToastInfo {...svgProps} />,
    toastSuccess: <ToastSuccess {...svgProps} />,
    goodies: <Goodies {...svgProps} />,
    discovery: <Discovery {...svgProps} />,
    purpleStar: <PurpleStar {...svgProps} />,
    share: <Share {...svgProps} />,
    greenCheckmark: <GreenCheckmark {...svgProps} />,
    tickGreen: <TickGreen {...svgProps} />,
    newText: <NewText {...svgProps} />,
    skip: <Skip {...svgProps} />,
    controllerYellow: <ControllerYellor {...svgProps} />,
    crossBigger: <CrossBigger {...svgProps} />,
    bell: <Bell {...svgProps} />,
    bellRinging: <BellRinging {...svgProps} />,
    chatSmile: <ChatSmile {...svgProps} />,
    heartPink: <HeartPink {...svgProps} />,
    prizeGreen: <PrizeGreen {...svgProps} />,
    blockText: <BlockText {...svgProps} />,
    trophy: <Trophy {...svgProps} />,
    arrowRightUp: <ArrowRightUp {...svgProps} />,
    arrowUpRightBlack: <ArrowUpRightBlack {...svgProps} />,
    airdropSmall: <AirdropSmall {...svgProps} />,
    googleWhite: <GoogleWhite {...svgProps} />,
    appleBlack: <AppleBlack {...svgProps} />,
  };

  return Icons[name];
};
