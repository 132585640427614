import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@/components/Button/Button';
import { PageHeader } from '@/components/PageHeader';
import { Text } from '@/components/Text';
import deleteAccountImg from '@/assets/images/delete-account.png';

import { useDeleteAccount } from '../queries';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
});

const StyledImg = styled.img({
  width: '100%',
  marginBottom: 24,
  marginTop: 24,
});

const DeleteAccountButton = styled.button(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  fontSize: 16,
  lineHeight: '20px',
  fontWeight: 500,
  outline: 'none',
  border: 'none',
  background: 'none',
  marginTop: 24,
  '&:disabled': {
    opacity: 0.4,
  },
}));

const StyledText = styled(Text)({
  marginTop: 32,
});

const StyledButton = styled(Button)({
  marginTop: 'auto',
  width: '100%',
});

export const DeleteAccount = () => {
  const navigate = useNavigate();
  const { mutate: deleteAccount, isLoading } = useDeleteAccount();

  const onDeleteAccount = () => {
    deleteAccount();
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <PageHeader title="Delete Account" onBack={onBack} />
      <StyledText>
        This step is irreversible and your progress together with unclaimed rewards will be lost forever. Are you sure
        you want to delete your account?
      </StyledText>
      <StyledImg src={deleteAccountImg}></StyledImg>
      <StyledButton disabled={isLoading} onClick={onBack}>
        Keep account open
      </StyledButton>
      <DeleteAccountButton onClick={onDeleteAccount} disabled={isLoading}>
        {isLoading ? 'Deleting...' : 'Delete account'}
      </DeleteAccountButton>
    </Container>
  );
};
