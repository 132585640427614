import { Visual, ChallengeContainer } from '../ChallengeEntry';

export const NoChallenges = ({}) => {
  return (
    <ChallengeContainer>
      <Visual className="w-1/4"></Visual>
      <div className="w-3/4 px-4">
        <div className="text-[#F1E7FA] font-semibold text-sm">No active challenges</div>
      </div>
    </ChallengeContainer>
  );
};
