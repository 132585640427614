import { Timestamp } from 'firebase/firestore';
import { AffiliateCampaignProvider } from '../Challenges/types';
import { IGame } from '../Game/types';

export type IImages = {
  imageDirection: string;
  images: {
    url: string;
    type: string;
  }[];
};

export enum GameIntegrationType {
  SDK = 'SDK',
  MMP = 'MMP',
  AFFILIATED = 'AFFILIATED',
}

export enum CampaignStatus {
  ACTIVATING = 'ACTIVATING',
  ACTIVATED = 'ACTIVATED',
  FAILED = 'FAILED',
  REMOVED = 'REMOVED',
}

export interface ICampaign {
  id: string;
  isDisabled: boolean;
  availableCountries: Array<string>;
  conversionTime?: number;
  dailyCap: number;
  endDate?: Timestamp;
  endDateTs?: number;
  startDate?: Timestamp;
  startDateTs?: number;
  platform: string;
  iosStoreID?: string;
  androidStoreID?: string;
  provider: AffiliateCampaignProvider;
  trackingUrl?: string;
  attributionWindow: number;
  isPaused?: boolean;
}

export type IGames = IGame[];
