import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Button } from '@/components/Button/Button';
import { LoaderIcon } from '@/components/SVGs/LoaderIcon';
import { Icon } from '@/components/Icon/Icon';

import { handleOpenNativeBrowser } from '../mutations';

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.darkPurple,
  borderWidth: 1,
  borderColor: theme.colors.purple.primary[600],
  borderRadius: 16,
  textAlign: 'center',
  padding: '32px 24px',
  margin: '0 24px 12px 24px',
}));

const StyledButton = styled(Button)({
  marginTop: 16,
  width: '100%',
});

const Title = styled(Text)({
  marginBottom: 4,
});

const TextContainer = styled.div({
  padding: '0 4px',
});

export const ActivateGames = () => {
  const { mutate: openInNativeBrowser, isLoading } = handleOpenNativeBrowser();
  const handleOpenInNativeBrowser = () => {
    openInNativeBrowser();
  };

  return (
    <Container>
      <TextContainer>
        <Title type="h3">Game On, Earn Rewards</Title>
        <Text colorType="light">Play games, earn credits and claim prizes!</Text>
      </TextContainer>
      <StyledButton onClick={handleOpenInNativeBrowser}>
        {isLoading ? (
          <LoaderIcon className="stroke-black w-6 h-6" />
        ) : (
          <>
            Activate Games
            <Icon name="externalLinkDark" width={24} height={24} />
          </>
        )}
      </StyledButton>
    </Container>
  );
};
