import { combineReducers } from '@reduxjs/toolkit';

import { constants as appConstants, appSlice } from '@/features/App';
import { constants as authConstants, authSlice } from '@/features/Auth';
import { constants as enterReferralConstants, enterReferralSlice } from '@/features/EnterReferral';
import { constants as referralsConstants, referralsSlice } from '@/features/Referral';
import { constants as nftListConstants, nftListSlice } from '@/features/NFTList';
import { constants as headerConstants, headerSlice } from '@/features/Header';
import { constants as contestsConstants, contestsSlice } from '@/features/Contests';
import { constants as contestConstants, contestSlice } from '@/features/Contest';
import { constants as onboardingConstants, onboardingSlice } from '@/features/Onboarding';
import { constants as nicknameConstants, nicknameSlice } from '@/features/Nickname';
import { constants as assetsSwapConstants, assetsSwapSlice } from '@/features/AssetsSwap';
import { constants as gamesConstants, gamesSlice } from '@/features/Games';
import { constants as voucherPreviewConstants, voucherPreviewSlice } from '@/features/VoucherPreview';
import { constants as activationStepsConstants, activationStepsSlice } from '@/features/ActivationTutorial';
import { constants as completeProfileConstants, completeProfileSlice } from '@/features/CompleteProfile';
import { constants as addToHomeScreenConstants, addToHomeScreenSlice } from '@/features/AddToHomeScreen';
import { constants as emailVerificationConstants, emailVerificationSlice } from '@/features/EmailVerification';

export const rootReducer = combineReducers({
  [appConstants.NAME]: appSlice.reducer,
  [authConstants.NAME]: authSlice.reducer,
  [enterReferralConstants.NAME]: enterReferralSlice.reducer,
  [referralsConstants.NAME]: referralsSlice.reducer,
  [nftListConstants.NAME]: nftListSlice.reducer,
  [headerConstants.NAME]: headerSlice.reducer,
  [contestsConstants.NAME]: contestsSlice.reducer,
  [contestConstants.NAME]: contestSlice.reducer,
  [onboardingConstants.NAME]: onboardingSlice.reducer,
  [nicknameConstants.NAME]: nicknameSlice.reducer,
  [assetsSwapConstants.NAME]: assetsSwapSlice.reducer,
  [gamesConstants.NAME]: gamesSlice.reducer,
  [voucherPreviewConstants.NAME]: voucherPreviewSlice.reducer,
  [activationStepsConstants.NAME]: activationStepsSlice.reducer,
  [completeProfileConstants.NAME]: completeProfileSlice.reducer,
  [addToHomeScreenConstants.NAME]: addToHomeScreenSlice.reducer,
  [emailVerificationConstants.NAME]: emailVerificationSlice.reducer,
});
