import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User, getAdditionalUserInfo, getRedirectResult } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';

import { firebaseAuth, httpsFunction } from '@/utils/firebase';
import { setActivated } from '@/features/EnterReferral/slice';
import { setIsNicknameSet } from '@/features/Nickname/slice';
import { isOnboardingCompleted as isOnboardingCompletedSelector } from '@/features/Onboarding/selectors';
import { isNicknameSet as isNicknameSetSelector } from '@/features/Nickname/selectors';
import { setOnboardingCompleted } from '@/features/Onboarding/slice';
import { isActivated, isActivatedFetching as isActivatedFetchingSelector } from '@/features/EnterReferral/selectors';

import { syncBalances } from './actions';
import { setShouldRefreshToken, setEmailVerified } from './slice';
import {
  useGrantDailyCreditReward,
  useHandleUserSignIn,
  useUserDailyActivity,
  useUserLocation,
  useHandleAfterUserRegistration,
} from './queries';
import {
  shouldRefreshToken as shouldRefreshTokenSelector,
  isEmailVerified as isEmailVerifiedSelector,
} from './selectors';

export const useOnAuth = () => {
  const dispatch = useDispatch();
  const [user] = useAuthState(firebaseAuth);
  const { data: lastDailyActivityReward, status: dailyRewardStatus } = useUserDailyActivity(user?.uid);
  const { mutate: grantDailyCreditReward } = useGrantDailyCreditReward();
  const { mutate: handleUserSignIn } = useHandleUserSignIn();
  const { mutate: handleAfterSignUp } = useHandleAfterUserRegistration();

  const isAuthenticated = !!user;

  const checkRedirectDetails = async () => {
    const result = await getRedirectResult(firebaseAuth);

    if (!result) {
      return;
    }

    const additionalUserInfo = await getAdditionalUserInfo(result);

    if (!additionalUserInfo?.isNewUser) {
      return;
    }

    handleAfterSignUp();
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (dailyRewardStatus === 'success') {
      const ONE_DAY_IN_SECONDS = 24 * 60 * 60;
      const nowUnixTimestamp = Math.floor(new Date().getTime() / 1000);
      const isLastRewardTimeNotSet = !lastDailyActivityReward;
      const hasReceivedMoreThanOneDayAgo =
        !!lastDailyActivityReward && lastDailyActivityReward?.seconds + ONE_DAY_IN_SECONDS < nowUnixTimestamp;

      if (hasReceivedMoreThanOneDayAgo || isLastRewardTimeNotSet) {
        grantDailyCreditReward();
      }
    }
  }, [lastDailyActivityReward, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const updateUserLocation = httpsFunction('updateUserLocation');

      dispatch(syncBalances());
      updateUserLocation();
      handleUserSignIn();

      Sentry.setUser({ uid: user.uid, email: user.email || '' });

      mixpanel.set_config({ persistence: 'localStorage' });
      mixpanel.identify(user.uid);

      checkRedirectDetails();
    }
  }, [isAuthenticated]);
};

export const useAuth = () => {
  const dispatch = useDispatch();

  const isUserActivated = useSelector(isActivated);
  const isOnboardingCompleted = useSelector(isOnboardingCompletedSelector);
  const shouldRefreshToken = useSelector(shouldRefreshTokenSelector);
  const isActivatedFetching = useSelector(isActivatedFetchingSelector);
  const isNicknameSet = useSelector(isNicknameSetSelector);
  const isEmailVerified = useSelector(isEmailVerifiedSelector);

  const handleUserChange = async (loadedUser: User | null) => {
    const result = await loadedUser?.getIdTokenResult();

    dispatch(setActivated(!!result?.claims?.isActivated));
    dispatch(setIsNicknameSet(!!result?.claims?.nickname));
    dispatch(setOnboardingCompleted(!!result?.claims?.isOnboardingCompleted));
    dispatch(setEmailVerified(!!result?.claims?.email_verified));
  };

  const [user, isAuthLoading] = useAuthState(firebaseAuth, { onUserChanged: handleUserChange });

  useEffect(() => {
    const refreshData = async () => {
      await user?.getIdToken(true);
      await handleUserChange(user || null);

      dispatch(setShouldRefreshToken(false));
    };

    if (shouldRefreshToken) {
      refreshData();
    }
  }, [shouldRefreshToken]);

  const isAuthenticated = !!user;

  const isAuthenticatedStack =
    isAuthenticated && isEmailVerified && isUserActivated && isOnboardingCompleted && isNicknameSet;
  const isEmailVerificationStack = isAuthenticated && !isEmailVerified;
  const isActivationStack = isAuthenticated && isEmailVerified && !isUserActivated;
  const isNicknameStack = isAuthenticated && isEmailVerified && isUserActivated && !isNicknameSet;
  const isOnboardingStack =
    isAuthenticated && isEmailVerified && isUserActivated && isNicknameSet && !isOnboardingCompleted;

  return {
    userEmail: user?.email,
    uid: user?.uid,
    isAuthenticated,
    isAuthenticatedStack,
    isActivationStack,
    isOnboardingStack,
    isUserActivated,
    isNicknameStack,
    isEmailVerificationStack,
    isAuthLoading: isActivatedFetching || isAuthLoading,
  };
};

export const getUserLocation = () => {
  const { uid } = useAuth();
  const { data, isLoading } = useUserLocation(uid);

  return { userContinent: data?.userContinent, userCountry: data?.userCountry, isLoading };
};
