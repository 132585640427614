import { useParams } from 'react-router-dom';

import { PrizeItem } from './PrizeItem';
import { usePrizesData } from '../queries';

export enum PRIZE_TYPE {
  LIST = 'list',
  CREDITS = 'credits',
}

type TPlaceType = 1 | 2 | 3;

export interface IListItem {
  image: string;
  name: string;
  collectionName: string;
  collectionLink: string;
  type: 'nft' | 'voucher';
}

export interface IPrizeItem {
  type: PRIZE_TYPE;
  winnersAmount: number;
  place: TPlaceType;
  creditsReward?: number;
  winners?: string[];
  list?: IListItem[];
}

export const ContestPrizes = () => {
  const { uid = '' } = useParams();
  const { data = [] } = usePrizesData({ contestId: uid });

  return (
    <>
      {data.map((prize: IPrizeItem) => (
        <PrizeItem key={prize.place} {...prize} />
      ))}
    </>
  );
};
