import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { CampaignStatus } from '@/features/Games/types';

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
}));

export const resolveCampaignIndicationIcon = (campaignStatus: CampaignStatus | undefined) => {
  switch (campaignStatus) {
    case CampaignStatus.ACTIVATED:
      return 'greenDot';
    case CampaignStatus.ACTIVATING:
      return 'orangeDot';
    case CampaignStatus.FAILED:
    case CampaignStatus.REMOVED:
      return 'redDot';
    default:
      return 'greenDot';
  }
};

const resolveCampaignIndicationText = (campaignStatus: CampaignStatus | undefined) => {
  switch (campaignStatus) {
    case CampaignStatus.ACTIVATED:
      return 'This game is activated to your account';
    case CampaignStatus.ACTIVATING:
      return 'Waiting for game activation, may take a while';
    default:
      return 'This game is activated to your account';
  }
};

export const GameActivationStatus = ({ campaignStatus }: { campaignStatus: CampaignStatus | undefined }) => {
  return (
    <div className="flex justify-center items-center mt-2">
      <Icon name={resolveCampaignIndicationIcon(campaignStatus)} className="mr-2" />
      <Title weight={600} type="p_s">
        {resolveCampaignIndicationText(campaignStatus)}
      </Title>
    </div>
  );
};
