import styled from 'styled-components';

import { Text } from '@/components/Text';
import { useAuth } from '@/features/Auth/hooks';

const DetailText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  backgroundColor: theme.colors.purple.primary['600'],
  padding: '18px 16px',
  borderRadius: 8,
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const SectionTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  alignSelf: 'flex-start',
  marginBottom: 8,
  marginLeft: 8,
  marginTop: 32,
}));

export const AccountDetails = () => {
  const { userEmail } = useAuth();

  return (
    <>
      <SectionTitle weight={600}>Account details</SectionTitle>
      <DetailText type="p_l">{userEmail}</DetailText>
    </>
  );
};
