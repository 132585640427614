import { useMutation, useQueryClient } from 'react-query';

import { httpsFunction } from '@/utils/firebase';

import { QuestType, SocialType } from './types';

export const useClaimCredits = () => {
  const queryClient = useQueryClient();

  return useMutation<
    any,
    any,
    {
      questType: QuestType;
      gameSlug: string;
      socialMediaType?: SocialType;
      baseChallengeID?: string;
      userChallengeID?: string;
    }
  >(
    async ({ questType, socialMediaType, baseChallengeID, userChallengeID, gameSlug }) => {
      const claimCredits = httpsFunction('claimCredits');
      await claimCredits({
        questType,
        socialMediaType,
        baseChallengeID,
        userChallengeID,
        gameSlug: gameSlug,
      });
    },
    {
      onMutate: (vars) => {
        const { questType } = vars;
        queryClient.setQueryData('useAssetsData', []);

        if (questType === QuestType.SOCIAL) {
          queryClient.setQueryData('useSocMediaChallengeData', []);
          return;
        }

        if (questType === QuestType.CHALLENGE || questType === QuestType.DAILY) {
          queryClient.setQueryData('useChallengeListData', []);
          queryClient.setQueryData('useDailyPlay', []);
        }
      },
      onSuccess: (data, vars) => {
        const { questType } = vars;
        queryClient.invalidateQueries({ queryKey: ['useAssetsData'] });

        if (questType === QuestType.SOCIAL) {
          queryClient.invalidateQueries({ queryKey: ['useSocMediaChallengeData'] });
          return;
        }

        if (questType === QuestType.CHALLENGE || questType === QuestType.DAILY) {
          queryClient.invalidateQueries({ queryKey: ['useChallengeListData'] });
          queryClient.invalidateQueries({ queryKey: ['useDailyPlay'] });
        }
      },
    }
  );
};

export const useSetSocialChallengeStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, { socialType: SocialType; gameSlug: string }>(
    async ({ socialType, gameSlug }) => {
      const setSocialChallengeStatus = httpsFunction('setSocialChallengeStatus');
      await setSocialChallengeStatus({ socialType, gameSlug });
    },
    {
      onMutate: () => {
        queryClient.setQueryData('useSocMediaChallengeData', []);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['useSocMediaChallengeData']);
      },
    }
  );
};
