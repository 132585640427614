import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { ErrorMessage } from '@/components/ErrorMessage/ErrorMessage';

import { FormInput } from '@/components/Input/FormInput';
import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text';
import { useSetNickname } from '../queries';
import { useDispatch } from 'react-redux';
import { setShouldRefreshToken } from '@/features/Auth/slice';

interface IFormFields {
  nickname: string;
}

const NicknameFormValidation = Yup.object().shape({
  nickname: Yup.string().required('Nickname is required'),
});

const Container = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 320,
  width: '100%',
  height: '100%',
  paddingTop: 80,
});

const Title = styled(Text)({
  marginBottom: 32,
});

const StyledError = styled(ErrorMessage)({
  alignSelf: 'flex-start',
  marginLeft: 0,
});

export const NicknameForm = () => {
  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch(setShouldRefreshToken(true));
  };

  const { mutate: setNickname, isLoading, isError } = useSetNickname({ onSuccess });

  const handleSubmit = ({ nickname }: IFormFields, { setSubmitting }: FormikHelpers<IFormFields>) => {
    setNickname({ nickname: nickname.trim() });
    setSubmitting(false);
  };

  return (
    <Formik validationSchema={NicknameFormValidation} initialValues={{ nickname: '' }} onSubmit={handleSubmit}>
      {({ handleChange, handleBlur, values }) => (
        <Container>
          <Title type="h2" align="center" weight={600}>
            Create your nickname
          </Title>
          <FormInput
            label="Nickname"
            name="nickname"
            type="nickname"
            value={values.nickname}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {!!isError && <StyledError className="ml-5 mt-1">Nickname is already taken</StyledError>}
          <Button className="mt-5" type="submit" disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create'}
          </Button>
        </Container>
      )}
    </Formik>
  );
};
