import { useDispatch } from 'react-redux';

import hideable from '@/utils/hideable';

import { CompleteProfileBox } from './CompleteProfileBox';
import { setIsModalVisible } from '../slice';
import { useCompleteProfileProgress } from '../queries';
import { PROFILE_COMPLETION_REWARD } from '../constants';

export const CompleteProfileBoxContainerComponent = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { data } = useCompleteProfileProgress();

  const onClick = () => {
    dispatch(setIsModalVisible(true));
  };

  return (
    <CompleteProfileBox
      isVisible={!data?.isProfileCompleted}
      progress={data?.progress}
      onClick={onClick}
      creditsAmount={PROFILE_COMPLETION_REWARD}
      {...rest}
    />
  );
};

export const CompleteProfileBoxContainer = hideable(CompleteProfileBoxContainerComponent);
