import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useQuery } from 'react-query';

import { firebaseApp } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';

interface IUseCompleteProfileProgressData {
  userId?: string;
}

export type TProgress = 0 | 1 | 2;

export const useCompleteProfileProgressData = ({ userId }: IUseCompleteProfileProgressData) =>
  useQuery({
    queryKey: ['useCompleteProfileProgressData', userId],
    enabled: !!userId,
    queryFn: async () => {
      const completionDoc = await getDoc(doc(getFirestore(firebaseApp), `users/${userId}/settings/profileCompletion`));

      return {
        isGameActivated: completionDoc.data()?.isGameActivated as boolean | undefined,
        isChallengeCompleted: completionDoc.data()?.isChallengeCompleted as boolean | undefined,
      };
    },
  });

export const useCompleteProfileProgress = () => {
  const { uid: userId } = useAuth();

  const { data, ...rest } = useCompleteProfileProgressData({ userId: userId });

  const isGameActivated = !!data?.isGameActivated;
  const isChallengeCompleted = !!data?.isChallengeCompleted;

  const progress = [isGameActivated, isChallengeCompleted].filter(Boolean).length as TProgress;

  return {
    data: {
      isGameActivated,
      isChallengeCompleted,
      progress,
      isProfileCompleted: progress === 2,
    },
    ...rest,
  };
};
