import styled from 'styled-components';
import { Icon } from '@/components/Icon/Icon';
import { IChallenge } from '@/features/Challenges/types';
import { resolveChallengeVisual } from '@/features/Challenges/utils/challengeVisuals';
import { ChallengeContainer, Visual, ClaimBtn, CreditContainer } from '../ChallengeEntry';
import { abbreviateNumber } from '@/utils/helpers/abbreviateNumber';

interface IProgressChallenge {
  challenge: IChallenge;
  onClaim(): void;
  isLoading: boolean;
  disabled?: boolean;
}

const ProgressBar = styled.div<{ progress: number }>(({ progress, theme }) => ({
  background: theme.colors.purple.primary['800'],
  height: '4px',
  width: '100%',
  borderRadius: '10px',
  position: 'relative',
  overflow: 'hidden',
  marginTop: 8,
  marginBottom: 8,
  '::before': {
    content: '""',
    position: 'absolute',
    height: '4px',
    width: `${progress}%`,
    borderRadius: '10px',
    background: theme.colors.yellow.primary['500'],
  },
}));

export const ProgressChallenge = ({ challenge, onClaim, isLoading, disabled }: IProgressChallenge) => (
  <ChallengeContainer disabled={disabled} isNew={challenge.isNew}>
    <Visual url={resolveChallengeVisual(challenge.icon)} />
    <div className="flex-1 px-2">
      <div className="text-[#F1E7FA] font-semibold text-sm">{challenge.title}</div>
      <ProgressBar progress={challenge?.progress || 0} />
      {challenge?.progressDescription && <div className="text-xs text-[#9473B5]">{challenge.progressDescription}</div>}
    </div>
    <CreditContainer>
      {challenge.canClaim ? (
        <ClaimBtn disabled={isLoading} onClick={onClaim}>
          {isLoading ? (
            'Claiming...'
          ) : (
            <>
              Claim <Icon name="credit" height={24} className="ml-2 mr-1" /> {abbreviateNumber(challenge.credits)}
            </>
          )}
        </ClaimBtn>
      ) : (
        <div className="flex items-center justify-end text-[#F1E7FA] font-semibold">
          <Icon name="credit" height={24} className="ml-2 mr-1" /> {abbreviateNumber(challenge.credits)}
        </div>
      )}
    </CreditContainer>
  </ChallengeContainer>
);
