import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Button } from '@/components/Button/Button';
import { Icon } from '@/components/Icon/Icon';

interface IOnboarding {
  image: string;
  title: string;
  buttonText: string;
  description?: string;
  activeSteps: number;
  currentActiveStep: number;
  isLoading?: boolean;
  maxImageWidth?: number;
  onNext: () => void;
  onSkip?: () => void;
}

interface IStepImage {
  maxImageWidth: number;
}

const SkipContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  marginBottom: 16,
});

const IndicatorsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '24px 0',
});

const Indicator = styled.div<{ isActive: boolean }>(({ theme, isActive }) => ({
  width: 12,
  height: 12,
  borderRadius: 12,
  backgroundColor: isActive ? theme.colors.yellow.primary['500'] : theme.colors.darkPurple,
  margin: '0 8px',
}));

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 400,
  paddingTop: 26,
  height: '100%',
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  margin: '12px 0 8px 0',
}));

const StepImage = styled.img<IStepImage>(({ maxImageWidth }) => ({
  objectFit: 'contain',
  maxWidth: maxImageWidth,
}));

const SkipButton = styled.button({
  margin: '0 8px 0 0',
  padding: 0,
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
});

const SkipText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const Description = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
}));

const StyledButton = styled(Button)({
  width: '100%',
});

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  marginBottom: '20px',
  alignItems: 'center',
  width: '100%',
});

export const TutorialScreen = ({
  image,
  title,
  description,
  buttonText,
  activeSteps,
  currentActiveStep,
  isLoading = false,
  maxImageWidth = 328,
  onNext,
  onSkip,
  ...rest
}: IOnboarding) => (
  <Container {...rest}>
    {onSkip && (
      <SkipContainer>
        <SkipButton disabled={isLoading} onClick={onSkip}>
          <SkipText type="p_s" weight={500}>
            Skip
          </SkipText>
        </SkipButton>
        <Icon name="chevronRight" />
      </SkipContainer>
    )}
    <StepImage src={image} maxImageWidth={maxImageWidth} alt="onboarding" />
    <Title type="h2" align="center" weight={600}>
      {title}
    </Title>
    {description && <Description align="center">{description}</Description>}
    <ButtonContainer>
      <IndicatorsContainer>
        {[...Array(activeSteps)].map((_, index) => (
          <Indicator key={index} isActive={currentActiveStep === index + 1} />
        ))}
      </IndicatorsContainer>
      <StyledButton disabled={isLoading} onClick={onNext}>
        {buttonText}
      </StyledButton>
    </ButtonContainer>
  </Container>
);
