import styled, { DefaultTheme } from 'styled-components';

interface IStyledButton {
  $buttonType: TButtonType;
  $buttonSize: TButtonSize;
}

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: TButtonType;
  buttonSize?: TButtonSize;
  children: React.ReactNode;
}

type TButtonType = 'primary' | 'secondary' | 'shiny' | 'no-background';
type TButtonSize = 'small' | 'large' | 'medium';

const getStyleByType = (type: TButtonType, theme: DefaultTheme) =>
  ({
    primary: {
      color: theme.colors.neutral.primary['1000'],
      background: theme.colors.yellow.primary['500'],
    },
    secondary: {
      color: theme.colors.purple.primary['50'],
      backgroundColor: theme.colors.purple.primary['600'],
    },
    shiny: {
      background: 'linear-gradient(128.51deg, #AAEE51 9.47%, #5ADEE7 50.02%, #E65EFA 94.09%)',
      color: theme.colors.neutral.primary['1000'],
    },
    'no-background': {
      background: 'none',
      color: theme.colors.yellow.primary['500'],
      boxShadow: 'none',
      border: 'none',
    },
  }[type]);

const getStyleBySize = (size: TButtonSize) =>
  ({
    large: {
      fontSize: 16,
      lineHeight: '20px',
      padding: '0 20px',
      borderRadius: '10px',
      height: 56,
    },
    small: {
      fontSize: 12,
      lineHeight: '14px',
      padding: '0 8px',
      borderRadius: 6,
      height: 32,
    },
    medium: {
      fontSize: 14,
      lineHeight: '17px',
      padding: '0 16px',
      borderRadius: 10,
      height: 40,
    },
  }[size]);

const StyledButton = styled.button<IStyledButton>(({ theme, $buttonType = 'primary', $buttonSize = 'large' }) => ({
  fontWeight: 500,
  outline: 'none',
  position: 'relative',
  background: 'none',
  appearance: 'none',
  cursor: 'pointer',
  boxSizing: 'border-box',
  boxShadow: `2px 2px 0px ${theme.colors.neutral.primary['1000']}`,
  border: '1px solid #000000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:active': {
    boxShadow: 'none',
    transform: 'translate3d(2px, 2px, 0px)',
  },
  ...getStyleByType($buttonType, theme),
  ...getStyleBySize($buttonSize),
  '&:disabled': {
    backgroundColor: theme.colors.purple.primary['350'],
    color: theme.colors.green.primary['200'],
    borderColor: '#000',
    boxShadow: 'none',
    opacity: 0.4,
    cursor: 'auto',
    '&:active': {
      boxShadow: 'none',
      transform: 'none',
    },
  },
}));

export const Button: React.FC<IButton> = ({
  buttonType = 'primary',
  buttonSize = 'large',
  children,
  disabled,
  ...rest
}) => (
  <StyledButton $buttonType={buttonType} $buttonSize={buttonSize} disabled={disabled} {...rest}>
    {children}
  </StyledButton>
);
