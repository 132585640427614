import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface IHeader {
  isMenuOpen: boolean;
  isVersionModalOpen: boolean;
}

const initialState: IHeader = {
  isMenuOpen: false,
  isVersionModalOpen: false,
};

export const headerSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isMenuOpen = true;
    },
    closeMenu: (state) => {
      state.isMenuOpen = false;
    },
    setVersionModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.isVersionModalOpen = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { openMenu, closeMenu, resetState, setVersionModalVisibility } = headerSlice.actions;
