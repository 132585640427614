import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { ReactComponent as X } from '@/assets/icons/x.svg';
import { ReactComponent as Discord } from '@/assets/icons/discord.svg';
import { ReactComponent as Medium } from '@/assets/icons/medium_icon.svg';
import { Icon } from '@/components/Icon/Icon';
import { Button } from '@/components/Button/Button';
import { isApp } from '@/utils/isApp';

import { useClaimCredits, useSetSocialChallengeStatus } from '../mutations';
import { ISocialMediaChallenge, QuestType, SocMediaChallengeStatus } from '../types';

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  boxShadow: theme.boxShadow.primary,
  borderRadius: 8,
  marginRight: 14,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px 7px',
  width: '33.3333%',
  ':last-of-type': {
    marginRight: 0,
  },

  'svg.soc-icon': {
    width: 30,
    height: 30,
  },
}));

const ClaimBtn = styled(Button)({
  padding: '12px 8px',
  height: 34,
  borderRadius: 5,
  fontSize: 12,
  width: 87,
  zIndex: 15,
});

const resolveIcon = (type: string) => {
  return {
    x: <X className="mb-3 soc-icon" />,
    discord: <Discord className="mb-3 soc-icon" />,
    medium: <Medium className="mb-3 soc-icon" />,
  }[type];
};

export const SocialMediaEntry = ({ socialMediaChallenge }: { socialMediaChallenge: ISocialMediaChallenge }) => {
  const { title, type, status, credits, link } = socialMediaChallenge;

  const { id: gameSlug } = useParams();
  const { mutate: claimCredits, isLoading } = useClaimCredits();

  const { mutate: setSocialChallengeStatus } = useSetSocialChallengeStatus();
  const handleClaim = async () => {
    if (!gameSlug) {
      console.error('Could not find game from params');
      return;
    }
    await claimCredits({ questType: QuestType.SOCIAL, socialMediaType: type, gameSlug });
  };

  const handleShare = async () => {
    if (status === SocMediaChallengeStatus.NOT_SHARED) {
      if (!gameSlug) {
        console.error('Could not find game from params');
        return;
      }

      await setSocialChallengeStatus({ socialType: type, gameSlug });
    }

    const isMobileApp = isApp();
    if (isMobileApp) {
      window.location.href = link;
    } else {
      window.open(link, '_blank');
    }
  };

  return (
    <Container
      onClick={handleShare}
      key={title}
      className={status === SocMediaChallengeStatus.NOT_SHARED ? 'cursor-pointer' : ''}
    >
      <div className="text-sm text-white text-semibold mb-3">{title}</div>
      {resolveIcon(type)}
      {status === SocMediaChallengeStatus.NOT_SHARED && (
        <div className="text-[#F1E7FA] font-semibold flex items-center">
          <Icon name="credit" height={24} className="mr-1" /> {credits}
        </div>
      )}
      {status === SocMediaChallengeStatus.SHARED && (
        <ClaimBtn onClick={handleClaim} disabled={isLoading}>
          {isLoading ? (
            'Claiming...'
          ) : (
            <>
              Claim <Icon name="credit" height={20} className="ml-1 mr-0.5" /> 50
            </>
          )}
        </ClaimBtn>
      )}
      {status === SocMediaChallengeStatus.CLAIMED && <Icon name="checkMark2" height={28} />}
    </Container>
  );
};
