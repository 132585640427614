import styled from 'styled-components';

interface IPrizeImage {
  image: string;
}

export const PrizeImage = styled.div<IPrizeImage>(({ image, theme }) => ({
  height: 75,
  width: 75,
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundOrigin: 'content-box',
  marginRight: 32,
  border: `1px solid ${theme.colors.darkPurple}`,
  cursor: 'pointer',
  borderRadius: 8,
  boxShadow: theme.boxShadow.primary,
  ':last-of-type': {
    marginRight: 0,
  },
}));
