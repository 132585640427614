import ChallengeCrown from '@/assets/visuals/challenge_crown.svg';
import ChallengeCraft from '@/assets/visuals/challenge_craft.svg';
import ChallengePvp from '@/assets/visuals/challenge_pvp.svg';
import ChallengePvpStreak from '@/assets/visuals/challenge_pvp_streak.svg';
import ChallengeLevel from '@/assets/visuals/challenge_level.svg';
import ChallengePurchase from '@/assets/visuals/challenge_purchase.svg';
import ChallengeChest from '@/assets/visuals/challenge_chest.svg';
import ChallengeUnknownBox from '@/assets/visuals/challenge_unknown_box.svg';
import ChallengeClock from '@/assets/visuals/challenge_clock.svg';
import ChallengeVault from '@/assets/visuals/challenge_vault.svg';
import ChallengeOther from '@/assets/visuals/challenge_other.svg';
import ChallengeController from '@/assets/visuals/challenge_controller.svg';

export enum ChallengeIcon {
  LEVEL_UP = 'LEVEL_UP',
  PVP_MATCH = 'PVP_MATCH',
  PVP_STREAK = 'PVP_STREAK',
  CROWN = 'CROWN',
  CRAFT_ITEM = 'CRAFT_ITEM',
  DROPBOX = 'DROPBOX',
  CHEST = 'CHEST',
  CLOCK = 'CLOCK',
  UNKNOWN_BOX = 'UNKNOWN_BOX',
  CONTROLLER = 'CONTROLLER',
  OTHER = 'OTHER',
  VAULT = 'VAULT',
}

export const resolveChallengeVisual = (icon: ChallengeIcon) => {
  switch (icon) {
    case ChallengeIcon.LEVEL_UP:
      return ChallengeLevel;
    case ChallengeIcon.PVP_MATCH:
      return ChallengePvp;
    case ChallengeIcon.PVP_STREAK:
      return ChallengePvpStreak;
    case ChallengeIcon.CROWN:
      return ChallengeCrown;
    case ChallengeIcon.CRAFT_ITEM:
      return ChallengeCraft;
    case ChallengeIcon.CHEST:
      return ChallengeChest;
    case ChallengeIcon.DROPBOX:
      return ChallengePurchase;
    case ChallengeIcon.CLOCK:
      return ChallengeClock;
    case ChallengeIcon.UNKNOWN_BOX:
      return ChallengeUnknownBox;
    case ChallengeIcon.CONTROLLER:
      return ChallengeController;
    case ChallengeIcon.VAULT:
      return ChallengeVault;
    case ChallengeIcon.OTHER:
      return ChallengeOther;
    default:
      return ChallengeOther;
  }
};
