import { useQuery } from 'react-query';
import { intervalToDuration } from 'date-fns';
import { collection, getFirestore, getCountFromServer, Timestamp, getDoc, doc } from 'firebase/firestore';
import { firebaseApp } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';

const playTimes = [
  { playTime: 300, credits: 10 },
  { playTime: 600, credits: 50 },
  { playTime: 1200, credits: 100 },
  { playTime: 1800, credits: 150 },
  { playTime: 2400, credits: 250 },
  { playTime: 3600, credits: 500 },
];

export interface IDailyMilestone {
  playTime: number;
  credits: number;
  isClaimed: boolean;
  canClaim: boolean;
}

export interface IDailyPlayFirestoreResponse {
  playTime: number;
  created: Timestamp;
  milestones: Array<IDailyMilestone>;
}

export interface IDailyPlay {
  playTime: Duration;
  playTimeInSeconds: number;
  created: Timestamp;
  milestones: Array<IDailyMilestone>;
}

const initialDailyPlayState = {
  playTimeInSeconds: 0,
  playTime: intervalToDuration({ start: 0, end: 0 }),
  milestones: playTimes.map((milestone) => ({
    ...milestone,
    canClaim: false,
    isClaimed: false,
  })),
};

export const useDailyPlayData = ({ uid, gameName }: { uid: string; gameName: string }) =>
  useQuery({
    queryKey: ['useDailyPlay', uid],
    enabled: !!uid,
    queryFn: async () => {
      const today = new Date();
      const todayString = `${today.getUTCDate().toString().padStart(2, '0')}-${(today.getUTCMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getUTCFullYear()}`;

      const dailyPlayCollectionRef = collection(
        getFirestore(firebaseApp),
        `/users/${uid}/challenges/${gameName}/daily-play`
      );

      const dailyPlayTotalCount = (await getCountFromServer(dailyPlayCollectionRef)).data().count;
      if (dailyPlayTotalCount === 0) {
        return initialDailyPlayState;
      }

      const todayData = (
        await getDoc(doc(getFirestore(firebaseApp), `/users/${uid}/challenges/${gameName}/daily-play/${todayString}`))
      )?.data() as IDailyPlayFirestoreResponse;

      if (!todayData) {
        return initialDailyPlayState;
      }

      return {
        ...todayData,
        playTimeInSeconds: todayData?.playTime,
        playTime: intervalToDuration({ start: 0, end: todayData?.playTime * 1000 }),
      } as IDailyPlay;
    },
  });

export const useDailyPlay = (gameName: string) => {
  const { uid } = useAuth();
  const dailyPlay = useDailyPlayData({ uid: uid || '', gameName });
  const availableToClaim = dailyPlay?.data?.milestones.reduce((acc, crr) => {
    if (crr.canClaim && !crr.isClaimed) {
      return acc + crr.credits;
    }
    return acc;
  }, 0);
  const allClaimed = dailyPlay?.data?.milestones.every(({ isClaimed }) => isClaimed);

  return { dailyPlay, availableToClaim: availableToClaim, allClaimed };
};
