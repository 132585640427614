import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface IExtraStateProps {
  isPreviewOpen: boolean;
}

interface INftItem {
  title: string;
  image: string;
  description?: string;
  collectionName?: string;
  collectionLink?: string;
}

type TNftPreview = INftItem & IExtraStateProps;

const initialState: TNftPreview = {
  isPreviewOpen: false,
  title: '',
  image: '',
  description: undefined,
};

export const nftListSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    openCollectablePreview: (state, { payload }: PayloadAction<INftItem>) => {
      state.isPreviewOpen = true;
      state.title = payload.title;
      state.image = payload.image;
      state.description = payload.description;
      state.collectionLink = payload.collectionLink;
      state.collectionName = payload.collectionName;
    },
    closeCollectablePreview: () => initialState,
    resetState: () => initialState,
  },
});

export const { openCollectablePreview, closeCollectablePreview } = nftListSlice.actions;
