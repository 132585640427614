import { useCompleteProfileProgress } from '@/features/CompleteProfile/queries';
import { TicketsInput } from './TicketsInput';
import { LockedContest } from './LockedContest';

export const TicketInputContainer = () => {
  const { data } = useCompleteProfileProgress();

  return (
    <>
      <LockedContest isVisible={!data?.isProfileCompleted} />
      <TicketsInput isVisible={data?.isProfileCompleted} />
    </>
  );
};
