import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CompleteProfileModal } from './CompleteProfileModal';
import { isModalVisible } from '../selectors';
import { setIsModalVisible } from '../slice';
import { useCompleteProfileProgress } from '../queries';

export const CompleteProfileModalContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isVisible = useSelector(isModalVisible);

  const { data } = useCompleteProfileProgress();

  const onClose = () => {
    dispatch(setIsModalVisible(false));
  };

  const onActivateGameClick = () => {
    if (data?.isGameActivated) {
      return;
    }

    dispatch(setIsModalVisible(false));

    navigate('/games');
  };

  const onCompleteChallengeClick = () => {
    if (data?.isChallengeCompleted) {
      return;
    }

    dispatch(setIsModalVisible(false));

    navigate('/games');
  };

  return (
    <CompleteProfileModal
      onActivateGameClick={onActivateGameClick}
      onCompleteChallengeClick={onCompleteChallengeClick}
      onClose={onClose}
      progress={data?.progress}
      isVisible={isVisible}
      isActivateGameCompleted={data?.isGameActivated}
      isChallengeCompleted={data?.isChallengeCompleted}
    />
  );
};
