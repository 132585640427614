import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import { LinkToAssetsSwap } from '@/components/LinkToAssetsSwap';
import { setModalVisible } from '@/features/AssetsSwap/slice';
import { PillToggle } from '@/components/PillToggle/PillToggle';

import { useContests } from '../queries';
import { ContestItem } from './ContestItem';
import { NoContestsMessage } from './NoContestsMessage';
import { getActiveSubTab } from '../selectors';
import { setActiveSubTab } from '../slice';
import { SUB_TABS } from '../constants';

const Container = styled.div({
  padding: '0 24px',
});

const PILL_NAV_OPTIONS = [
  { label: 'Active', value: SUB_TABS.ACTIVE },
  { label: 'My entries', value: SUB_TABS.MY_ENTRIES },
  { label: 'Ended', value: SUB_TABS.ENDED },
];

export const Contests = () => {
  const dispatch = useDispatch();

  const { data, isLoadingContestData } = useContests();
  const activeSubTab = useSelector(getActiveSubTab);

  const handlePillButton = (value: string) => {
    dispatch(setActiveSubTab(value));
  };

  const handleLinkToAssetsSwapClick = () => {
    dispatch(setModalVisible(true));
  };

  return (
    <>
      <Container>
        <PillToggle options={PILL_NAV_OPTIONS} selected={activeSubTab} onChange={handlePillButton} />
        {isLoadingContestData && <Skeleton count={2} height={250} className="rounded-lg" />}
        <div className="mt-4">
          {data?.map(({ key, ...props }) => (
            <ContestItem key={key} {...props} />
          ))}
        </div>
        <NoContestsMessage isVisible={!isLoadingContestData && !data.length} />
        <LinkToAssetsSwap onClick={handleLinkToAssetsSwapClick} isVisible={!!data.length} />
      </Container>
    </>
  );
};
