import { useIsIosApp } from '@/features/App/hooks';
import { Games as GamesList } from '@/features/Games/components/Games';
import { IOSAppGames } from '@/features/GamesIOS/components/IOSAppGames';
import { BybitCampaignContainer } from '@/features/NewBanner/components/BybitCampaignContainer';

export const Games = () => {
  const isIOSapp = useIsIosApp();

  return (
    <>
      <BybitCampaignContainer />
      {isIOSapp && <IOSAppGames />}
      {!isIOSapp && <GamesList />}
    </>
  );
};
