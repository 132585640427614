import styled from 'styled-components';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

import { ShopCard, IOnClickProps } from './ShopCard';
import { USDC_REWARDS } from '../constants';

const Container = styled.div({
  width: '100%',
  overflow: 'hidden',
  marginBottom: 32,
});

const Title = styled.h3(({ theme }) => ({
  color: theme.colors.purple.primary[200],
  fontSize: 14,
  fontWeight: 600,
  marginLeft: 32,
  marginBottom: 8,
}));

const StyledSwiperSlide = styled(SwiperSlide)({
  width: 124,
  marginLeft: 16,
  ':first-of-type': {
    marginLeft: 24,
  },
  ':last-of-type': {
    marginRight: 24,
  },
});

export const UsdcCarousel = () => {
  const navigate = useNavigate();

  const onCardClick = ({ value }: IOnClickProps) => {
    navigate('/usdc-shop-item', { state: { value } });
  };

  return (
    <Container>
      <Title>USDC rewards</Title>
      <SwiperComponent slidesPerView="auto">
        {USDC_REWARDS.map((usdcReward) => (
          <StyledSwiperSlide key={usdcReward.value}>
            <ShopCard
              type="usdc"
              onClick={onCardClick}
              value={usdcReward.value}
              price={usdcReward.creditPrice}
              creditPriceLabel={usdcReward.creditPriceLabel}
              currencyCode="USD"
            />
          </StyledSwiperSlide>
        ))}
      </SwiperComponent>
    </Container>
  );
};
