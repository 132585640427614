import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { MenuOption } from './MenuOption';
import { Text } from '@/components/Text';
import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { LINKS } from '@/utils/constants/common';

const Container = styled.div(({ theme }) => ({
  borderRadius: 8,
  width: '100%',
  boxShadow: '2px 2px 0px 0px #000000',
  padding: '0 16px',
  backgroundColor: theme.colors.purple.primary['600'],
}));

const SectionTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  alignSelf: 'flex-start',
  marginBottom: 8,
  marginLeft: 8,
  marginTop: 32,
}));

export const Menu = () => {
  const navigate = useNavigate();
  const openLink = useOpenLink();

  const onDeleteAccount = () => {
    navigate('/delete-account');
  };

  const onFeedback = () => {
    navigate('/feedback');
  };

  const onEnterReferral = () => {
    navigate('/enter-referral');
  };

  const onTermsClick = () => {
    openLink(LINKS.BG_TERMS);
  };

  const onPrivacyPolicyClick = () => {
    openLink(LINKS.BG_PRIVACY_POLICY);
  };

  return (
    <>
      <SectionTitle weight={600}>Others</SectionTitle>
      <Container>
        <MenuOption title="Enter referral code" iconType="internalLink" onClick={onEnterReferral} />
        <MenuOption title="Bug Report & Feedback" iconType="internalLink" onClick={onFeedback} />
        <MenuOption title="Privacy policy" iconType="externalLink" onClick={onPrivacyPolicyClick} />
        <MenuOption title="Terms of service" iconType="externalLink" onClick={onTermsClick} />
        <MenuOption title="Delete account" iconType="internalLink" onClick={onDeleteAccount} />
      </Container>
    </>
  );
};
