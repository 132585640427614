import styled from 'styled-components';

import { Text } from '@/components/Text';
import shopRewards from '@/assets/images/shop-rewards.png';

import { UsdcCarousel } from './UsdcCarousel';
import { AmazonCarousel } from './AmazonCarousel';
import { PaypalCarousel } from './PaypalCarousel';
import { PlaystationCarousel } from './PlaystationCarousel';
import { useRemoteConfig } from '@/features/RemoteConfig/queries';
import { AppStoreCarousel } from './AppStoreCarousel';
import { GooglePlayCarousel } from './GooglePlayCarousel';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 24px',
});

const RewardsTitle = styled(Text)({
  marginBottom: 8,
});

const StyledImage = styled.img({
  width: 213,
});

export const Shop = () => {
  const { data } = useRemoteConfig();
  return (
    <Container>
      {data?.isGiftCardsEnabled && (
        <>
          <AmazonCarousel />
          <PaypalCarousel />
          <PlaystationCarousel />
          <AppStoreCarousel />
          <GooglePlayCarousel />
        </>
      )}
      <UsdcCarousel />
      <TextContainer>
        <RewardsTitle type="h3" weight={600} align="center">
          More exciting rewards are coming!
        </RewardsTitle>
        <Text colorType="light" align="center">
          Including wider vouchers selection, limited gaming skins, gated experiences and more.{' '}
        </Text>
        <StyledImage src={shopRewards} alt="shop-rewards" />
      </TextContainer>
    </Container>
  );
};
