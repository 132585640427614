import { createAction } from '@reduxjs/toolkit';

import { HANDLE_APP_MOUNT } from './actionTypes';

export interface IHandleAppMount {
  referralCode: string | null;
  initialAuthToken: string | null;
  appsflyerAndroidId: string | null;
  appsflyerIosId: string | null;
  appsflyerWebId: string | null;
  firebaseAndroidId: string | null;
  firebaseIosId: string | null;
  firebaseWebId: string | null;
}

export const handleAppMount = createAction<IHandleAppMount>(HANDLE_APP_MOUNT);
