import styled from 'styled-components';

import { getCampaignEndedGames, getInProgressGames } from '@/features/GamesIOS/queries';
import { CompleteProfileBoxContainer } from '@/features/CompleteProfile/components/CompleteProfileBoxContainer';
import { GamesCarouselRow } from '@/components/GamesCarouselRow';
import { getPlatform } from '@/utils/getPlatform';
import hideable from '@/utils/hideable';

import { getSuggestedGames } from '../queries';
import { GamesByGenre } from './GamesByGenre';
import { HotPicksCarousel } from './HotPickCarousel';
import { WebQrCode } from './WebQrCode';
import { NoInProgressGames } from './NoInProgressGames';
import { NoGames } from './NoGames';

const Divider = hideable(
  styled.div(({ theme }) => ({
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.darkPurple,
    marginTop: 32,
  }))
);

export const Games = () => {
  const { data: inProgressGames } = getInProgressGames();
  const { data: campaignEndedGames } = getCampaignEndedGames();
  const { gamesBySelectedGenre, hotPick, comingSoonGames, completedGames, genres, isLoading } = getSuggestedGames();
  const isWeb = getPlatform() === 'web';
  const hasCarouselRows = !!inProgressGames?.length || !!comingSoonGames?.length;

  return (
    <>
      <WebQrCode isVisible={isWeb} />
      <HotPicksCarousel data={hotPick || []} isVisible={!!hotPick?.length} />
      <CompleteProfileBoxContainer isVisible={!!hotPick?.length} />
      <GamesByGenre
        isVisible={!!gamesBySelectedGenre?.length}
        data={gamesBySelectedGenre || []}
        genres={genres || []}
      />
      <NoGames isVisible={!isWeb && !isLoading && !genres.length && !!completedGames.length} />
      <CompleteProfileBoxContainer isVisible={!hotPick?.length} />
      <Divider isVisible={hasCarouselRows} />
      <NoInProgressGames isVisible={!inProgressGames.length} />
      <GamesCarouselRow
        data={inProgressGames || []}
        title="Activated"
        dotStatus="greenDot"
        isVisible={!!inProgressGames?.length}
      />
      <GamesCarouselRow
        data={campaignEndedGames || []}
        title="Ended campaigns"
        dotStatus="redDot"
        isVisible={!!campaignEndedGames?.length}
      />
      <GamesCarouselRow data={comingSoonGames || []} title="Coming soon" isVisible={!!comingSoonGames?.length} />
    </>
  );
};
