import styled from 'styled-components';

import { ReactComponent as Progress0 } from '@/assets/images/get-started-0.svg';
import { ReactComponent as Progress1 } from '@/assets/images/get-started-50.svg';
import { ReactComponent as Progress2 } from '@/assets/images/get-started-100.svg';

import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';

import { TProgress } from '../queries';

interface ICompleteProfileBox {
  progress: TProgress;
  creditsAmount: number;
  onClick: () => void;
}

const ImageByProgress = {
  0: <Progress0 />,
  1: <Progress1 />,
  2: <Progress2 />,
};

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  padding: '8px 8px 8px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: 16,
  cursor: 'pointer',
  margin: 24,
  boxShadow: theme.boxShadow.primary,
  alignItems: 'center',
}));

const Paragraph = styled(Text)({
  display: 'flex',
});

const StyledIcon = styled(Icon)({
  margin: '0 4px',
});

export const CompleteProfileBoxComponent = ({ progress, creditsAmount, onClick, ...rest }: ICompleteProfileBox) => (
  <Container onClick={onClick} {...rest}>
    <div>
      <Text type="p_l" weight={600}>
        Get started!
      </Text>
      <Paragraph colorType="extraLight">
        And get <StyledIcon name="creditSmall" /> {creditsAmount} credits
      </Paragraph>
    </div>
    {ImageByProgress[progress]}
  </Container>
);

export const CompleteProfileBox = hideable(CompleteProfileBoxComponent);
