import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import { useOutsideClick } from '@/utils/hooks/useOutsideClick';
import { useWalletsAddresses } from '@/features/Vault/queries';

import { closeCollectablePreview } from '../slice';
import { getNFTPreview } from '../selectors';
import { useOpenLink } from '@/utils/hooks/useOpenLink';

const Overlay = styled.div({
  position: 'fixed',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: 'rgba(37, 15, 54, 0.9)',
  backdropFilter: 'blur(4px)',
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'auto',
});

const Container = styled.div({
  maxWidth: 300,
  position: 'relative',
  top: '15%',
  padding: '24px 0',
});

const Divider = styled.hr(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.purple.primary['300']}`,
  borderTop: 'none',
  width: '100%',
  margin: '24px 0',
}));

const Description = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['300'],
}));

const CloseBtn = styled.button(({ theme }) => ({
  boxShadow: theme.boxShadow.primary,
  borderRadius: 8,
  backgroundColor: theme.colors.purple.primary['600'],
  color: theme.colors.purple.primary['50'],
  padding: '10px 20px',
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 15px auto',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '14px',
  position: 'absolute',
  right: 0,
  top: -72,
}));

const Image = styled.img({
  borderRadius: 8,
  width: 'auto',
  maxHeight: 300,
  margin: '0 auto 25px',
});

const CollectionName = styled.a(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  display: 'inline',
}));

const LinkToUPP = styled.a(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const StyledIcon = styled(Icon)({
  marginRight: 8,
});

const UPP_DOMAIN = process.env.VITE_UPP_DOMAIN as string;

export const NFTPreviewContainer = () => {
  const ref = useRef(null);
  const openLink = useOpenLink();
  const dispatch = useDispatch();

  const nftPreviewState = useSelector(getNFTPreview);
  const { data: walletAddresses } = useWalletsAddresses();

  const isEvmWalletConnected = !!walletAddresses?.evmAddress;

  const openUpp = () => {
    openLink(UPP_DOMAIN);
  };

  const handleClose = () => {
    dispatch(closeCollectablePreview());
  };

  const onCollectionLinkClick = () => {
    if (!nftPreviewState.collectionLink) {
      return;
    }

    openLink(nftPreviewState.collectionLink);
  };

  useOutsideClick(ref, handleClose);

  if (!nftPreviewState.isPreviewOpen) {
    return null;
  }

  const isCollectionInfoVisible = !!nftPreviewState.collectionName && !!nftPreviewState.collectionLink;

  return (
    <Overlay>
      <Container ref={ref}>
        <CloseBtn onClick={handleClose}>
          <StyledIcon name="cross" stroke="#F1E7FA" />
          Close
        </CloseBtn>
        <Image src={nftPreviewState.image} alt="" />
        <div className="flex flex-col items-center">
          <Text align="center" type="p_l" weight={600}>
            {nftPreviewState.title}
          </Text>
          <Divider />
          <div className="text-sm text-[#DBC9E8]">{nftPreviewState.description}</div>
          {isCollectionInfoVisible && (
            <Description>
              Visit{' '}
              <CollectionName onClick={onCollectionLinkClick}>
                {nftPreviewState.collectionName}{' '}
                <Icon className="ml-1/2 mb-1 inline" name="externalLink" width={13} height={13} />
              </CollectionName>{' '}
              to check out this collection
              <br />
              <br />
              {isEvmWalletConnected && (
                <>
                  If you win, the NFT will be transferred to your connected wallet{' '}
                  <span className="text-white">
                    ({walletAddresses?.evmAddress?.slice(0, 6)}...{walletAddresses?.evmAddress?.slice(-4)})
                  </span>{' '}
                  within 24 hours. Manage your wallets in
                  <LinkToUPP onClick={() => openUpp()} target="_blank">
                    UPP <Icon className="ml-1" name="arrowRightUp" stroke="#DBC9E8" />
                  </LinkToUPP>
                </>
              )}
              {!isEvmWalletConnected && (
                <>
                  If you win, the NFT will be transferred to your connected wallet within 24 hours.
                  <LinkToUPP onClick={() => openUpp()} target="_blank">
                    Connect a ETH wallet in UPP{' '}
                    <Icon className="ml-1/2 mb-1 inline" name="arrowRightUp" stroke="#DBC9E8" />
                  </LinkToUPP>
                </>
              )}
            </Description>
          )}
        </div>
      </Container>
    </Overlay>
  );
};
