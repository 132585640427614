import styled from 'styled-components';
import { ReactComponent as Image } from '@/assets/images/dataxp.svg';
import { Text } from '@/components/Text';
import { Badge } from '@/components/Badge';

const Container = styled.div(({ theme }) => ({
  background: 'linear-gradient(62.42deg, #4D0C7F -9.58%, #350856 106.39%)',
  boxShadow: theme.boxShadow.thin,
  position: 'relative',
  border: `1px solid ${theme.colors.purple.primary[600]}`,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 328,
  padding: '16px 24px',
  margin: '0 16px ',
}));

const TextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 4,
  lineHeight: '18px',
});

const ProgressBarContainer = styled.div(({ theme }) => ({
  height: 12,
  width: 184,
  borderRadius: 8,
  padding: 3,
  backgroundColor: theme.colors.purple.primary['500'],
  display: 'flex',
  justifyContent: 'start',
}));

const ProgressBar = styled.div<{ width: number }>(({ width }) => ({
  borderRadius: 4,
  background: 'linear-gradient(128.51deg, #AAEE51 9.47%, #5ADEE7 50.02%, #E65EFA 94.09%)',
  width: `${width}%`,
  height: 6,
}));

const StyledBadge = styled(Badge)({
  position: 'absolute',
  top: -12,
  left: 24,
});

export const DataXp = () => {
  const progress = 1200;
  const max = 3000;

  const percentage = Math.floor((progress * 100) / max);

  return (
    <Container>
      <StyledBadge>Coming soon</StyledBadge>
      <div>
        <ProgressBarContainer>
          <ProgressBar width={percentage} />
        </ProgressBarContainer>
        <TextContainer>
          <Text type="p_s" weight={400} colorType="light">
            DATA XP
          </Text>
          <Text type="p_s" weight={400} colorType="light">
            {progress} / {max}
          </Text>
        </TextContainer>
      </div>
      <Image className="ml-auto" />
    </Container>
  );
};
