import { useLocation, useNavigate } from 'react-router-dom';

import { USDC_REWARDS } from '@/features/Shop/constants';
import { BackButton } from '@/components/BackButton';
import { useAssets } from '@/features/Assets/queries';

import { ShopItem } from './ShopItem';
import { Icon } from '@/components/Icon/Icon';
import styled from 'styled-components';
import { useOpenLink } from '@/utils/hooks/useOpenLink';

interface ILocation {
  state: {
    value?: string;
  };
}

const LinkToUPP = styled.a(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  alignItems: 'center',
  cursor: 'pointer',
}));

const UPP_DOMAIN = process.env.VITE_UPP_DOMAIN as string;

export const UsdcShopItemContainer = () => {
  const navigate = useNavigate();
  const openLink = useOpenLink();

  const openUpp = () => {
    openLink(UPP_DOMAIN);
  };
  const { state }: ILocation = useLocation();

  const { credits } = useAssets();

  const value = state?.value || '';
  const usdcCreditPrice = USDC_REWARDS.find((usdcReward) => usdcReward.value === value)?.creditPrice || 0;

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleBuyClick = () => {
    navigate('/usdc-shop-item/summary', { state: { value } });
  };

  return (
    <>
      <BackButton onBack={handleBackClick} />
      <ShopItem
        isDisabled={usdcCreditPrice > credits}
        onBuyClick={handleBuyClick}
        isVisible={!!value && !!usdcCreditPrice}
        title={`${value} USDC`}
        creditsValue={usdcCreditPrice}
        type="usdc"
        descriptionText={
          <>
            USDC will be deposited into your connected wallet via the Polygon network in up to 5 business days. Manage
            your wallets in{' '}
            <LinkToUPP onClick={() => openUpp()} target="_blank">
              <span className="inline">
                UPP <Icon className="ml-1/2 mb-1 inline" name="arrowRightUp" stroke="#DBC9E8" />
              </span>
            </LinkToUPP>
          </>
        }
      />
    </>
  );
};
