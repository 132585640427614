import styled from 'styled-components';

import { Text } from '@/components/Text';
import { ReactComponent as Coins } from '@/assets/images/contest_coins_reward.svg';
import { ReactComponent as Avatar1 } from '@/assets/avatars/avatar1.svg';
import { ReactComponent as Avatar2 } from '@/assets/avatars/avatar2.svg';
import { ReactComponent as Avatar3 } from '@/assets/avatars/avatar3.svg';
import { ReactComponent as Avatar4 } from '@/assets/avatars/avatar4.svg';
import { ReactComponent as Avatar5 } from '@/assets/avatars/avatar5.svg';

import { IPrizeItem, PRIZE_TYPE } from './ContestPrizes';
import { ListItem } from './ListItem';

const Icons = [
  <Avatar1 width={30} height={30} key={1} />,
  <Avatar2 width={30} height={30} key={2} />,
  <Avatar3 width={30} height={30} key={3} />,
  <Avatar4 width={30} height={30} key={4} />,
  <Avatar5 width={30} height={30} key={5} />,
];

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const PlaceText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginBottom: 8,
}));

const CreditsRewardText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%)',
}));

const PrizeContainer = styled.div<{ hasBottomBorderRadius: boolean }>(({ theme, hasBottomBorderRadius }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  borderRadius: 8,
  borderBottomLeftRadius: hasBottomBorderRadius ? 8 : 0,
  borderBottomRightRadius: hasBottomBorderRadius ? 8 : 0,
  position: 'relative',
  height: 140,
  display: 'flex',
  alignItems: 'center',
  padding: '32px 20px',
}));

const WinnerAmountBadge = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['500'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 26,
  width: 100,
  position: 'absolute',
  borderTopRightRadius: 8,
  borderBottomLeftRadius: 8,
  right: 0,
  top: 0,
  boxShadow: `1px 1px 0px 0px ${theme.colors.purple.primary['800']}`,
}));

const WinnerAmountText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
}));

const WinnerNicknameText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const WinnersContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
  gridGap: 10,
  borderTop: `1px dashed ${theme.colors.purple.primary['500']}`,
  backgroundColor: theme.colors.purple.primary['600'],
  padding: '12px 20px',
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}));

const OnePrizeContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledPrizeItem = styled(ListItem)({
  marginRight: 24,
});

const WinnerItem = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const IconContainer = styled.span({
  marginRight: 4,
});

const PLACE_EXTENSION_MAP = {
  1: 'st',
  2: 'nd',
  3: 'rd',
};

export const PrizeItem = ({ place, type, list = [], creditsReward, winnersAmount, winners }: IPrizeItem) => (
  <Container>
    <PlaceText type="p_s" weight={600}>
      {`${place}${PLACE_EXTENSION_MAP[place]}`} place
    </PlaceText>
    <PrizeContainer hasBottomBorderRadius={!winners?.length}>
      <WinnerAmountBadge>
        <WinnerAmountText type="p_s" weight={600}>
          {winnersAmount} {winnersAmount > 1 ? 'Winners' : 'Winner'}
        </WinnerAmountText>
      </WinnerAmountBadge>
      {type === PRIZE_TYPE.LIST && list.length > 1 && (
        <>
          {list?.map((listItem) => (
            <ListItem key={listItem.name} {...listItem} />
          ))}
        </>
      )}
      {type === PRIZE_TYPE.LIST && list.length === 1 && (
        <OnePrizeContainer>
          <StyledPrizeItem key={list[0].name} {...list[0]} />
          <Text type="p_l" weight={600}>
            {list[0].name}
          </Text>
        </OnePrizeContainer>
      )}
      {type === PRIZE_TYPE.CREDITS && (
        <>
          <Coins />
          <CreditsRewardText type="p_m" weight={600}>
            {creditsReward} credits
          </CreditsRewardText>
        </>
      )}
    </PrizeContainer>
    {!!winners?.length && (
      <WinnersContainer>
        {winners.map((winner) => (
          <WinnerItem key={winner}>
            <IconContainer>{Icons[Math.floor(Math.random() * 4)]}</IconContainer>
            <WinnerNicknameText type="p_s">{winner}</WinnerNicknameText>
          </WinnerItem>
        ))}
      </WinnersContainer>
    )}
  </Container>
);
