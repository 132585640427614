import styled from 'styled-components';

import { Header } from './Header';
import { DataXp } from './DataXP';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '50vh',
});

export const Profile = () => {
  return (
    <Container>
      <Header />
      <DataXp />
    </Container>
  );
};
