import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useQuery } from 'react-query';

import { firebaseApp } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';

export const useWalletsAddressesData = ({ uid }: { uid: string }) =>
  useQuery<{ evmAddress: string | undefined; aptosAddress: string | undefined }>({
    queryKey: ['useWalletsAddressesData', uid],
    enabled: !!uid,
    queryFn: async () => {
      const userData = (await getDoc(doc(getFirestore(firebaseApp), `/users/${uid}`))).data();

      const { evmAddress, aptosAddress } = userData || {};

      return {
        evmAddress,
        aptosAddress,
      };
    },
  });

export const useWalletsAddresses = () => {
  const { uid } = useAuth();

  return useWalletsAddressesData({ uid: uid || '' });
};
