import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { logout } from '@/features/Auth/actions';
import { Icon } from '@/components/Icon/Icon';
import { Button } from '@/components/Button/Button';
import mixpanel from 'mixpanel-browser';

const StyledButton = styled(Button)({
  marginTop: 32,
  width: '100%',
});

const StyledIcon = styled(Icon)({
  marginRight: 8,
});

export const LogoutButton = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    mixpanel.reset();
    dispatch(logout());
  };

  return (
    <StyledButton buttonType="secondary" onClick={onClick}>
      <StyledIcon name="logout" />
      Log out
    </StyledButton>
  );
};
