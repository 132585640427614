import { all, call, put, takeEvery } from 'redux-saga/effects';

import { LOGOUT, SYNC_BALANCES } from './actionTypes';
import { firebaseAuth, httpsFunction } from '@/utils/firebase';
import { resetState as resetHeaderState } from '@/features/Header/slice';
import { HttpsCallable } from 'firebase/functions';

export function* handleLogoutSaga() {
  yield put(resetHeaderState());

  yield call([firebaseAuth, firebaseAuth.signOut]);
}

export function* syncBalancesSaga() {
  const timestamp: string | null = yield call([localStorage, localStorage.getItem], 'syncBalancesTimestamp');
  const syncBalances: HttpsCallable = yield call(httpsFunction, 'syncBalances');

  if (!timestamp) {
    yield call(syncBalances);
    yield call([localStorage, localStorage.setItem], 'syncBalancesTimestamp', new Date().getTime().toString());

    return;
  }

  const timestampInt = parseInt(timestamp, 10);
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - timestampInt;

  if (timeDifference / (1000 * 60 * 60) < 1) {
    return;
  }

  yield call(syncBalances);
  yield call([localStorage, localStorage.setItem], 'syncBalancesTimestamp', new Date().getTime().toString());
}

export function* watchAuth() {
  yield all([takeEvery(LOGOUT, handleLogoutSaga), takeEvery(SYNC_BALANCES, syncBalancesSaga)]);
}
