import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Button } from '@/components/Button/Button';
import BottomImage from '@/assets/images/contests_bottom_image.png';

import hideable from '@/utils/hideable';

interface ILinkToAssetsSwap {
  onClick: () => void;
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 32,
});

const BottomBackgroundImage = styled.img({
  width: '100%',
  maxWidth: 300,
  marginTop: 32,
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const StyledButton = styled(Button)({
  width: '100%',
  margin: '16px 0',
});

const LinkToAssetsSwapComponents = ({ onClick }: ILinkToAssetsSwap) => (
  <Container>
    <Title type="h3" weight={600} align="center">
      Get tickets to enter the contest!
    </Title>
    <StyledButton onClick={onClick} buttonType="secondary">
      Swap credits for tickets
    </StyledButton>
    <BottomBackgroundImage src={BottomImage} />
  </Container>
);

export const LinkToAssetsSwap = hideable(LinkToAssetsSwapComponents);
