import styled from 'styled-components';

import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';
import { ReactComponent as LoveVisual } from '@/assets/visuals/love_visual.svg';

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: 'auto',
  marginTop: 140,
});

const Visual = styled(LoveVisual)({
  marginBottom: 10,
});

const Title = styled(Text)({
  marginBottom: 5,
});

const Paragraph = styled(Text)({
  width: 230,
  textAlign: 'center',
});

interface ISuccessEmailVerification {
  successText: string;
}

const SuccessEmailVerificationComponent = ({ successText }: ISuccessEmailVerification) => {
  return (
    <Container>
      <Visual />
      <Title type="h3" weight={600}>
        {successText}
      </Title>
      <Paragraph colorType="light">You can close this window and return to the app</Paragraph>
    </Container>
  );
};

export const SuccessEmailVerification = hideable(SuccessEmailVerificationComponent);
