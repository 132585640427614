import { useDispatch } from 'react-redux';

import { openCollectablePreview } from '@/features/NFTList/slice';
import { PrizeImage } from '@/components/PrizeImage';

import { IListItem } from './ContestPrizes';
import { openVoucherPreview } from '@/features/VoucherPreview/slice';

export const ListItem = ({ name, image, collectionLink, collectionName, type, ...rest }: IListItem) => {
  const dispatch = useDispatch();

  const handleNftClick = () => {
    if (type === 'nft') {
      dispatch(openCollectablePreview({ title: name, image, collectionLink, collectionName }));
    }

    if (type === 'voucher') {
      dispatch(openVoucherPreview({ image, title: name }));
    }
  };

  return <PrizeImage onClick={handleNftClick} image={image} {...rest} />;
};
