import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BackButton } from '@/components/BackButton';
import { useAssets } from '@/features/Assets/queries';
import { TShopItemType } from '@/features/Shop/types';
import { useGetGiftCardById } from '@/features/Shop/queries';
import { useAuth } from '@/features/Auth/hooks';

import { ShopItem } from './ShopItem';
import { currencySymbols } from '@/features/Shop/constants';

interface ILocation {
  state: {
    type?: TShopItemType;
    id?: string;
  };
}

const TITLE_BY_TYPE = {
  playstation: 'PlayStation gift card',
  amazon: 'Amazon gift card',
  paypal: 'Paypal gift card',
  apple: 'App Store gift card',
  googleplay: 'Google Play gift card',
  usdc: '',
};

const EmailText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

export const GiftCardShopItemContainer = () => {
  const navigate = useNavigate();
  const { state }: ILocation = useLocation();

  const { userEmail } = useAuth();
  const { data } = useGetGiftCardById({ id: state?.id });
  const { credits } = useAssets();

  const price = data?.creditAmount || 0;
  const value = data?.payoutAmountLocalCurrency || '';
  const currencyCode = data?.currencyCode || '';

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleBuyClick = () => {
    navigate('/gift-card-shop-item/summary', { state });
  };

  if (!state?.type) {
    return null;
  }

  return (
    <>
      <BackButton onBack={handleBackClick} />
      <ShopItem
        isDisabled={price > credits}
        onBuyClick={handleBuyClick}
        isVisible={!!value && !!price}
        title={`${currencySymbols[currencyCode]}${value} ${TITLE_BY_TYPE[state.type]}`}
        creditsValue={price}
        type={state.type}
        descriptionText={
          <>
            Gift cards will be sent directly to your email at <EmailText>{userEmail}</EmailText> within 24 hours. For
            additional information, please feel free to reach out to us
          </>
        }
      />
    </>
  );
};
