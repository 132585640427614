import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

interface INickname {
  isNicknameSet: boolean;
}

const initialState: INickname = {
  isNicknameSet: false,
};

export const nicknameSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsNicknameSet: (state, action: PayloadAction<boolean>) => {
      state.isNicknameSet = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setIsNicknameSet, resetState } = nicknameSlice.actions;
