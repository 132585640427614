import Skeleton from 'react-loading-skeleton';

import { getSuggestedGames } from '@/features/Games/queries';

import { ActivateGames } from './ActivateGames';
import { GamesCarouselRow } from '@/components/GamesCarouselRow';
import { AppUnavailableCountry } from './AppUnavailableCountry';
import { getInProgressGames } from '../queries';
import { CompleteProfileBoxContainer } from '@/features/CompleteProfile/components/CompleteProfileBoxContainer';
import { NoInProgressGames } from '@/features/Games/components/NoInProgressGames';

export const IOSAppGames = () => {
  const { games } = getSuggestedGames();
  const { data: inProgressGames, isLoading } = getInProgressGames();

  if (isLoading) {
    return <Skeleton height={300} className="rounded-lg" containerClassName="w-full" />;
  }

  if (!games?.length) {
    return <AppUnavailableCountry />;
  }

  return (
    <>
      <ActivateGames />
      <CompleteProfileBoxContainer isVisible />
      <GamesCarouselRow
        data={inProgressGames}
        title="Activated"
        dotStatus="greenDot"
        isVisible={!!inProgressGames.length}
      />
      <NoInProgressGames isVisible={!inProgressGames.length} />
    </>
  );
};
