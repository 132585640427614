import {
  InputHTMLAttributes,
  useState,
  ChangeEvent,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  ReactNode,
} from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  suffix?: ReactNode;
}

interface ISuffixStyleProps {
  suffixPositionTop?: number;
  suffixPositionRight?: number;
}

type TInput = IInput & ISuffixStyleProps;

export type InputWrapperStyleProps = {
  hasValue?: boolean;
  focused?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  theme: DefaultTheme;
  hasLabel?: boolean;
};

export type InputStyleProps = {
  hasLabel?: boolean;
};

const Suffix = styled.div<ISuffixStyleProps>((props) => ({
  position: 'absolute',
  right: props.suffixPositionRight,
  top: props.suffixPositionTop,
  zIndex: 3,
}));

const InputWrapper = styled.div<InputWrapperStyleProps>`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.purple.primary['600']};
  overflow: hidden;
  height: 53px;
  border: 1px solid ${({ theme }) => theme.colors.purple.primary['800']};
  min-width: 258px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  label {
    position: absolute;
    color: ${({ theme }) => theme.colors.purple.primary['100']};
    left: 20px;
    pointer-events: none;
    transition: cubic-bezier(0.25, 1, 0.5, 1);
    ${({ hasValue, focused }) =>
      focused || hasValue
        ? css`
            font-size: 12px;
            top: 7px;
          `
        : css`
            font-size: 16px;
            top: 15px;
          `};
  }

  ${Suffix} {
    ${({ hasLabel }) =>
      !hasLabel &&
      css`
        top: 16px;
      `}
  }
`;

const StyledInput = styled.input<InputStyleProps>`
  width: 100%;
  height: 100%;
  outline: none;
  appearance: none;
  display: block;
  border: 0px;
  font-size: 16px;
  line-height: 1;
  min-width: 200px;
  background: none;
  padding: 0px 20px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.purple.primary['100']};
  background-color: ${({ theme }) => theme.colors.purple.primary['600']};
  ${({ hasLabel }) =>
    hasLabel &&
    css`
      padding-top: 10px;
    `}
  font-weight: 400;
  padding-right: 45px;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.purple.primary['100']} !important;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const FormInput = forwardRef<{ focus: () => void }, TInput>(
  (
    {
      value,
      onChange,
      defaultValue,
      label,
      autoFocus,
      suffix,
      className,
      disabled,
      suffixPositionRight = 17,
      suffixPositionTop = 19,
      ...restProps
    },
    ref
  ) => {
    const [focused, setFocus] = useState(autoFocus ?? false);
    const [hasValue, setHasValue] = useState(!!defaultValue || !!value || defaultValue === 0 || value === 0);

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          if (!focused) {
            setFocus(true);
          }

          if (inputRef.current) {
            inputRef.current.focus();
          }
        },
      }),
      [focused]
    );

    useEffect(() => {
      setHasValue(!!defaultValue || !!value || defaultValue === 0 || value === 0);
    }, [value, defaultValue]);

    const onInnerChange = (evt: ChangeEvent<HTMLInputElement>) => {
      if (!evt.currentTarget.value && hasValue) {
        setHasValue(false);
      }

      if (evt.currentTarget.value && !hasValue) {
        setHasValue(true);
      }

      if (onChange) {
        onChange(evt);
      }
    };

    return (
      <InputWrapper
        className={className}
        hasValue={hasValue}
        focused={focused}
        disabled={disabled}
        hasLabel={!!label}
        onFocus={() => {
          if (!focused) {
            setFocus(true);
          }

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        onBlur={() => focused && setFocus(false)}
      >
        {label && <label>{label}</label>}
        <StyledInput
          hasLabel={!!label}
          disabled={disabled}
          ref={inputRef}
          value={value}
          onChange={onInnerChange}
          {...restProps}
        />
        {suffix && (
          <Suffix suffixPositionRight={suffixPositionRight} suffixPositionTop={suffixPositionTop}>
            {suffix}
          </Suffix>
        )}
      </InputWrapper>
    );
  }
);
