import styled from 'styled-components';

import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as Usdc } from '@/assets/images/usdc.svg';
import { ReactComponent as Paypal } from '@/assets/images/paypal-gift-card.svg';
import { ReactComponent as Amazon } from '@/assets/images/amazon-gift-card.svg';
import { ReactComponent as Playstation } from '@/assets/images/playstation-gift-card.svg';
import { ReactComponent as GooglePlay } from '@/assets/images/google-play-gift-card.svg';
import { ReactComponent as Apple } from '@/assets/images/apple-app-store-gift-card.svg';
import { Text } from '@/components/Text';

import { TShopItemType } from '../types';
import { currencySymbols } from '../constants';

export interface IOnClickProps {
  id?: string;
  value?: string;
  type: TShopItemType;
}

interface IShopCard {
  value: string;
  id?: string;
  price: number;
  creditPriceLabel: string;
  type: TShopItemType;
  onClick: ({ value }: IOnClickProps) => void;
  currencyCode: string;
}

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary[600],
  border: `1px solid ${theme.colors.purple.primary[500]}`,
  boxShadow: '2px 2px 0px 0px #000',
  borderRadius: 8,
  width: 124,
  height: 180,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 8,
  marginBottom: 2,
  justifyContent: 'space-between',
  cursor: 'pointer',
  marginRight: 16,
  '::last-of-type': {
    marginRight: 0,
  },
}));

const StyledUsdc = styled(Usdc)({
  width: 60,
  height: 60,
  alignSelf: 'center',
});

const ItemCreditPrice = styled.div(({ theme }) => ({
  color: theme.colors.purple.primary[50],
  fontSize: 14,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  alignSelf: 'flex-start',
}));

const ValueContainer = styled(Text)({
  display: 'flex',
  alignSelf: 'flex-end',
  alignItems: 'flex-end',
});

const ValueSign = styled(Text)({
  paddingBottom: 2,
});

const IconContainer = styled.div({
  paddingBottom: 12,
});

const IMAGE_BY_TYPE = {
  amazon: <Amazon />,
  usdc: <StyledUsdc />,
  playstation: <Playstation />,
  paypal: <Paypal />,
  googleplay: <GooglePlay />,
  apple: <Apple />,
};

export const ShopCard = ({ value, creditPriceLabel, type, id, onClick, currencyCode }: IShopCard) => {
  const handleClick = () => {
    onClick({ value, type, id });
  };

  return (
    <Container onClick={handleClick}>
      <ValueContainer>
        {type !== 'usdc' && <ValueSign weight={600}>{currencySymbols[currencyCode]} </ValueSign>}
        <Text type="h3" align="right" weight={600}>
          {value}
        </Text>
        {type === 'usdc' && <ValueSign weight={600}>&nbsp;USDC</ValueSign>}
      </ValueContainer>
      <IconContainer>{IMAGE_BY_TYPE[type]}</IconContainer>
      <ItemCreditPrice>
        <Icon name="credit" className="mr-0.5" /> {creditPriceLabel}
      </ItemCreditPrice>
    </Container>
  );
};
