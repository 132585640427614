import styled from 'styled-components';

import { Icon } from './Icon/Icon';
import { Text } from './Text';
import { useEffect, useState } from 'react';
import { timeLeftUntil } from '@/utils/helpers/timeLeftUntil';
import hideable from '@/utils/hideable';

interface ITimeProgress {
  endTime: number;
}

const ProgressBadge = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.green.primary['500'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 26,
  padding: '0 10px 0 8px',
  position: 'absolute',
  borderTopLeftRadius: 8,
  borderBottomRightRadius: 8,
  left: 0,
  top: 0,
  boxShadow: `2px 2px 0px 0px ${theme.colors.purple.primary['800']}`,
}));

const ProgressText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['700'],
  marginLeft: 6,
}));

const TimeProgressIndicatorComponent = ({ endTime }: ITimeProgress) => {
  const [time, setTime] = useState(timeLeftUntil(endTime));
  const now = new Date();
  const timeDifference = endTime - now.getTime();
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  const hoursDifference = Math.floor(Math.floor(timeDifference / 1000) / 3600) - daysDifference * 24;

  useEffect(() => {
    if (daysDifference < 1) {
      const interval = setInterval(() => {
        setTime(timeLeftUntil(endTime));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [endTime]);

  return (
    <ProgressBadge>
      <Icon name="clockSmall" />
      <ProgressText type="p_s" weight={600}>
        {`${daysDifference < 1 ? time : `${daysDifference}d ${hoursDifference}h left`}`}
      </ProgressText>
    </ProgressBadge>
  );
};

export const TimeProgressIndicator = hideable(TimeProgressIndicatorComponent);
