import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from '@/features/Auth/hooks';
import { BREAKPOINTS, DESKTOP_CARD_DIMENSIONS } from '@/theme/constants';
import hideable from '@/utils/hideable';
import { logout } from '@/features/Auth/actions';
import { logoutMenuPaths } from '@/routes/constants';
import { useProfile } from '@/features/Profile/queries';
import { ReactComponent as Avatar1 } from '@/assets/avatars/avatar1.svg';
import { ReactComponent as Avatar2 } from '@/assets/avatars/avatar2.svg';
import { ReactComponent as Avatar3 } from '@/assets/avatars/avatar3.svg';
import { ReactComponent as Avatar4 } from '@/assets/avatars/avatar4.svg';
import { ReactComponent as Avatar5 } from '@/assets/avatars/avatar5.svg';

import { AssetInfo } from './AssetInfo';
import { Menu } from './Menu';
import { isMenuOpen } from '../selectors';
import { closeMenu, openMenu, setVersionModalVisibility } from '../slice';
import { Icon } from '@/components/Icon/Icon';
import { useNotificationsList } from '@/features/NotificationsList/queries';

const DesktopWrapper = styled.div({
  [BREAKPOINTS.SM_WIDTH]: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

const Container = styled.div(({ theme }) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px',
  zIndex: 20,
  backgroundColor: theme.colors.background,
  borderBottom: `1px dashed ${theme.colors.purple.primary['600']}`,
  [BREAKPOINTS.SM_WIDTH]: {
    left: 'unset',
    top: 'unset',
    right: 'unset',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    width: DESKTOP_CARD_DIMENSIONS.WIDTH,
  },
}));

const Flexbox = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const ProfileButtonContainer = styled.div({
  position: 'relative',
});

const IconContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  border: `1px solid ${theme.colors.neutral.primary['0']}`,
  borderRadius: 17,
  width: 34,
  height: 34,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const VersionTag = styled.div(({ theme }) => ({
  fontSize: 12,
  fontWeight: 500,
  color: theme.colors.purple.primary[200],
  backgroundColor: theme.colors.purple.primary[600],
  padding: '2px 8px',
  position: 'relative',
  borderRadius: 6,
  cursor: 'pointer',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 6,
    border: '1px solid transparent',
    background: 'linear-gradient(180deg, #E78BF6 0%, #9867FF 100%) border-box',
    '-webkit-mask': `linear-gradient(#fff 0 0) padding-box,linear-gradient(#fff 0 0)`,
    '-webkit-mask-composite': 'destination-out',
    maskComposite: 'exclude',
  },
}));

const RightSideComponents = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const NotificationsListButton = styled.button({
  outline: 'none',
  border: 'none',
  background: 'none',
  padding: 4,
  marginLeft: 12,
});

const userIconByNumber = {
  1: <Avatar1 width={30} />,
  2: <Avatar2 width={30} />,
  3: <Avatar3 width={30} />,
  4: <Avatar4 width={30} />,
  5: <Avatar5 width={30} />,
};

export const HeaderComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { isActivationStack } = useAuth();
  const { iconNumber } = useProfile();
  const { hasNewNotifications } = useNotificationsList();

  const isMenuVisible = useSelector(isMenuOpen);

  const onMenuClose = () => {
    dispatch(closeMenu());
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const handleIconClick = () => {
    if (logoutMenuPaths.includes(location.pathname)) {
      dispatch(openMenu());

      return;
    }

    navigate('/profile');
  };

  const handleVersionClick = () => {
    dispatch(setVersionModalVisibility(true));
  };

  const onNotificationsClick = () => {
    navigate('/notifications-list');
  };

  return (
    <DesktopWrapper>
      <Container>
        <ProfileButtonContainer>
          <Flexbox>
            <IconContainer onClick={handleIconClick}>
              {userIconByNumber[iconNumber] || <Avatar1 width={30} />}
            </IconContainer>
            <AssetInfo isVisible={!isActivationStack} />
          </Flexbox>
          <Menu onLogout={onLogout} onClose={onMenuClose} isVisible={isMenuVisible} />
        </ProfileButtonContainer>
        <RightSideComponents>
          <VersionTag onClick={handleVersionClick}>Alpha</VersionTag>
          <NotificationsListButton onClick={onNotificationsClick}>
            <Icon name={hasNewNotifications ? 'bellRinging' : 'bell'} />
          </NotificationsListButton>
        </RightSideComponents>
      </Container>
    </DesktopWrapper>
  );
};

export const Header = hideable(HeaderComponent);
