import { Text } from '@/components/Text';
import { IImages } from '@/features/Games/types';
import styled from 'styled-components';
import { ImagesCarousel } from './ImagesCarousel';
import Skeleton from 'react-loading-skeleton';

const DescriptionTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginBottom: 10,
}));

const DescriptionText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
}));

export const Description = ({
  description,
  descriptionImages,
}: {
  description?: string;
  descriptionImages?: IImages;
}) => {
  return (
    <div className="my-5">
      <DescriptionTitle type="h5">Description</DescriptionTitle>
      {!description ? (
        <Skeleton height={102} className="rounded-lg" containerClassName="w-full" />
      ) : (
        <DescriptionText type="p_m"> {description}</DescriptionText>
      )}
      {descriptionImages && <ImagesCarousel images={descriptionImages} />}
    </div>
  );
};
