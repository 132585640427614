import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useQuery } from 'react-query';

import { firebaseApp } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';

interface IUseTicketsData {
  uid: string;
}

export const useAssetsData = ({ uid }: IUseTicketsData) =>
  useQuery({
    queryKey: ['useAssetsData', uid],
    enabled: !!uid,
    queryFn: async () => {
      const userData = (await getDoc(doc(getFirestore(firebaseApp), `users/${uid}`)))?.data();

      return {
        tickets: (userData?.tickets || 0) as number,
        credits: (userData?.credits || 0) as number,
        usdc: (userData?.usdc || 0) as number,
        eth: (userData?.eth || 0) as number,
        pacePoints: (userData?.pacePoints || 0) as number,
        socialPoints: (userData?.socialPoints || 0) as number,
        totalPacePointsBalance: (userData?.totalPacePointsBalance || 0) as number,
      };
    },
  });

export const useAssets = () => {
  const { uid } = useAuth();
  const { data, isFetched, isRefetchError } = useAssetsData({ uid: uid || '' });

  return {
    tickets: data?.tickets || 0,
    credits: data?.credits || 0,
    usdc: data?.usdc || 0,
    eth: data?.eth || 0,
    pacePoints: data?.pacePoints || 0,
    socialPoints: data?.socialPoints || 0,
    totalPacePointsBalance: data?.totalPacePointsBalance || 0,
    isFetched,
    isRefetchError,
  };
};
