import { IImages } from '@/features/Games/types';
import styled from 'styled-components';
import { IMAGE_VERTICAL } from '../constants';

interface IStyledContestItemProps {
  image: string;
  imageDirection: string;
}

const Container = styled.div<IStyledContestItemProps>(({ image, imageDirection }) => ({
  height: imageDirection === IMAGE_VERTICAL ? 165 : 292,
  width: imageDirection === IMAGE_VERTICAL ? 292 : 163,
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 8,
  margin: '8px 20px 8px 0',
  flexShrink: 0,
}));

export const ImagesCarousel = ({ images }: { images: IImages }) => {
  const imageDirection = images.imageDirection;
  const { images: imagesArray } = images;
  return (
    <div className="flex flex-row overflow-x-scroll my-5">
      {imagesArray.map(({ url }, idx) => (
        <Container key={idx} image={url} imageDirection={imageDirection} />
      ))}
    </div>
  );
};
