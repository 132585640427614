import { Icon } from '@/components/Icon/Icon';
import { Link } from '@/components/Link/Link';

export const TournamentBoardMoreItems = () => (
  <div className="border-b border-gray border-dashed px-4 xxs:px-6 py-3">
    <Icon name="moreItems" />
  </div>
);

export const TournamentBoardWinThreshold = () => (
  <div className="border-b border-gray border-dashed px-4 xxs:px-6 py-3 text-xs text-center bg-yellow relative">
    Only <strong>Top 100</strong> players win prizes
    <Icon name="arrowUp" className="absolute right-6 top-0 bottom-0 my-auto" />
  </div>
);

export const TournamentBoardMultipleReward = () => (
  <div className="w-full py-1 bg-light-purple flex justify-center items-center border-b border-gray border-dashed last:border-none text-purple text-sm">
    <Icon name="lock" width={10} height={13} className="mr-3" />
    <strong className="mr-1">3x</strong> your reward by owning Founder hero
  </div>
);

export const TournamentBoardFounderRequired = () => (
  <div className="border-t border-black border-solid  px-4 xxs:px-6 py-2 bg-pink text-xs xxs:text-sm">
    <Link to="google.com" inheritfontsize>
      Founder hero
    </Link>{' '}
    collectible is required to participate
  </div>
);
