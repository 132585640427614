import styled from 'styled-components';
import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Button } from '@/components/Button/Button';
import { FormInput } from '@/components/Input/FormInput';
import { ErrorMessage as CustomErrorMessage } from '@/components/ErrorMessage/ErrorMessage';
import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import { Text } from '@/components/Text';

const ResetPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export interface IPasswordResetFormValues {
  email: string;
}

export interface IPasswordResetForm {
  onSubmit(values: IPasswordResetFormValues, formikHelpers: FormikHelpers<IPasswordResetFormValues>): void;
  submitText: string;
  isSuccess: boolean;
}

const StyledButton = styled(Button)({
  marginTop: 32,
});

const InfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 18,
});

const StyledInfoIcon = styled(InfoIcon)({
  marginRight: 8,
});

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ResetPasswordForm = ({ onSubmit, submitText, isSuccess }: IPasswordResetForm) => (
  <Formik initialValues={{ email: '' }} validationSchema={ResetPasswordFormValidationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => (
      <StyledForm>
        <FormInput
          label="Email"
          name="email"
          type="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          suffix={dirty && (errors.email ? <Icon name="cross" /> : <Icon name="successTick" />)}
        />

        <ErrorMessage
          name="email"
          render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
        />
        <StyledButton type="submit" disabled={isSubmitting}>
          {submitText}
        </StyledButton>
        {isSuccess && (
          <InfoContainer>
            <StyledInfoIcon />
            <Text type="p_s">Check your email for instructions</Text>
          </InfoContainer>
        )}
      </StyledForm>
    )}
  </Formik>
);
