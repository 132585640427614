import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { firebaseApp, httpsFunction } from '@/utils/firebase';
import { TContestType } from '@/features/Contests/types';
import { useMyEntries } from '@/features/Contests/queries';
import { useAuth } from '@/features/Auth/hooks';

import { IPrizeItem } from './components/ContestPrizes';

interface IUseContestData {
  contestId: string;
  userId: string;
}

interface IUseContest {
  contestId: string;
}

interface IUserTicket {
  ticketFrom: number;
  ticketTo: number;
}

interface IIUseApplyToContestProps {
  onSuccess: () => void;
}

interface IUseApplyToContest {
  amount: number;
  id: string;
}

export const useContestData = ({ contestId, userId }: IUseContestData) =>
  useQuery({
    queryKey: ['useContestData', contestId, userId],
    enabled: !!contestId,
    queryFn: async () => {
      const contest = (await getDoc(doc(getFirestore(firebaseApp), `contests/${contestId}`)))?.data();
      const userTickets =
        (await getDoc(doc(getFirestore(firebaseApp), `contests/${contestId}/participants/${userId}`)))?.data()
          ?.tickets || [];

      return {
        uid: contestId,
        image: contest?.image as string,
        isProvablyFair: contest?.isProvablyFair as boolean,
        isEnded: (contest?.state === 'closed') as boolean,
        isSoon: (contest?.state === 'soon') as boolean,
        currentTicketsAmount: contest?.currentTicketsAmount as number | undefined,
        maxTicketsAmount: contest?.maxTicketsAmount as number | undefined,
        title: contest?.title as string,
        description: contest?.description as string,
        type: contest?.type as TContestType,
        endTime: contest?.endTime?.toDate()?.getTime() as number | undefined,
        userTickets: userTickets as IUserTicket[],
        seedHash: contest?.seedHash as string | undefined,
        wnonce: (contest?.state === 'closed' ? contest?.wnonce : 0) as number,
        seed: (contest?.state === 'closed' ? contest?.seed : '') as string,
        ticketsAmount: (contest?.state === 'closed' ? contest?.tickets?.length : 0) as number,
      };
    },
  });

export const usePrizesData = ({ contestId }: IUseContest) =>
  useQuery({
    queryKey: ['usePrizesData', contestId],
    enabled: !!contestId,
    queryFn: async () => {
      const prizes: IPrizeItem[] =
        (await getDoc(doc(getFirestore(firebaseApp), `contests/${contestId}`)))?.data()?.prizes || [];

      return prizes;
    },
  });

export const useContest = ({ contestId }: IUseContest) => {
  const { uid: userId } = useAuth();
  const { data: contest } = useContestData({ contestId, userId: userId || '' });
  const { data: myEntries = [] } = useMyEntries({ userId: userId || '' });

  if (!contest) {
    return {
      data: undefined,
    };
  }

  const formattedContest = {
    ...contest,
    userEntry: myEntries.find((entry) => entry.uid.trim() === contest?.uid?.trim())?.ticketsAdded || 0,
  };

  return {
    data: formattedContest,
  };
};

export const useApplyToContest = ({ onSuccess }: IIUseApplyToContestProps) => {
  const queryClient = useQueryClient();

  return useMutation<any, any, IUseApplyToContest>(
    async ({ amount, id }) => {
      const applyToContest = httpsFunction('applyToContest');

      await applyToContest({ contestId: id, ticketsAmount: amount });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useAssetsData']);
        queryClient.invalidateQueries(['useContestsData']);
        queryClient.invalidateQueries(['useContestData']);
        queryClient.invalidateQueries(['useMyEntries']);

        onSuccess();
      },
    }
  );
};
