import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getTimeLeftOnUserActivatedCampaign, useGetCampaignByStoreId } from '@/features/Games/queries';
import { ActiveCampaignTimer } from '@/features/Game/components/CampaignTimer';

import { GameCardLogo } from './GameCardLogo';

interface IStyledContestItemProps {
  image: string;
}

interface IGameCard {
  image: string;
  slug: string;
  logo: string;
  storeId: string;
}

const Container = styled.div<IStyledContestItemProps>(({ image, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  height: 180,
  width: 124,
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  border: `1px solid ${theme.colors.purple.primary['500']}`,
  borderRadius: 9,
  flexShrink: 0,
  '::before': {
    backgroundColor: 'rgb(59 36 77 / 80%)',
    content: "''",
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    filter: 'blur(35px)',
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const CampaignTimerContainer = styled.div({
  zIndex: 1,
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  marginBottom: 2,
});

export const GameCard = ({ image, logo, slug, storeId, ...rest }: IGameCard) => {
  const navigate = useNavigate();
  const getCampaignByStoreId = useGetCampaignByStoreId();
  const timeLeftOnActiveCampaignString = getTimeLeftOnUserActivatedCampaign(slug);

  const navigateToGameView = () => {
    const campaign = getCampaignByStoreId(storeId);
    navigate(`/games/${slug}/${campaign?.id}`);
  };

  return (
    <Container image={image} onClick={navigateToGameView} {...rest}>
      {!!logo && <GameCardLogo logoUrl={logo} />}
      <CampaignTimerContainer>
        <ActiveCampaignTimer isVisible={!!timeLeftOnActiveCampaignString}>
          {timeLeftOnActiveCampaignString}
        </ActiveCampaignTimer>
      </CampaignTimerContainer>
    </Container>
  );
};
