import styled from 'styled-components';

import { theme as baseTheme } from '@/theme/theme';
import { Icon } from '@/components/Icon/Icon';
import hideable from '@/utils/hideable';
import { ReactNode } from 'react';

const StyledIcon = styled(Icon)({ marginRight: 3 });

const TimerContainer = styled.div(({ theme }) => ({
  color: theme.colors.purple.primary[50],
  display: 'flex',
  fontWeight: 600,
  fontSize: 12,
}));

const EndedCampaignTimerComponent = () => {
  return (
    <TimerContainer>
      <StyledIcon fill={baseTheme.colors.red} name="clock" /> 0 days left
    </TimerContainer>
  );
};

const ActiveCampaignTimerComponent = ({ children }: { children: ReactNode }) => {
  return (
    <TimerContainer>
      <StyledIcon fill={baseTheme.colors.green.primary[500]} name="clock" /> {children}
    </TimerContainer>
  );
};

export const EndedCampaignTimer = hideable(EndedCampaignTimerComponent);
export const ActiveCampaignTimer = hideable(ActiveCampaignTimerComponent);
