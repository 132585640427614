import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';

import { IGame } from '../types';

interface IStyledContestItemProps {
  image: string;
}

const GameHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const GameLogoContainer = styled.div<IStyledContestItemProps>(({ image, theme }) => ({
  height: 70,
  width: 70,
  backgroundImage: `url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  border: `1px solid ${theme.colors.purple.primary['500']}`,
  borderRadius: 6,
  flexShrink: 0,
  position: 'relative',
}));

const IconContainer = styled.div({
  position: 'absolute',
  height: 20,
  width: 20,
  borderRadius: 10,
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: -10,
  right: -10,
  borderWidth: 1,
  borderColor: '#7625B4',
});

const GameTitleDescription = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginRight: 4,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const GameTitleHeader = ({ game, className }: { game: IGame; className?: string }) => {
  const rating = Number(game.rating).toFixed(1);

  return (
    <GameHeader className={className}>
      <GameLogoContainer image={game?.storageImages?.icon}>
        {game?.chain && (
          <IconContainer>
            <Icon name={game.chain} />
          </IconContainer>
        )}
      </GameLogoContainer>
      <div className="ml-2.5">
        <Text type="h3" weight={600}>
          {game.title}
        </Text>
        <div className="flex flex-row">
          <GameTitleDescription type="p_s">{game.subtitleText}</GameTitleDescription>
        </div>
        <GameTitleDescription type="p_s">
          <Icon name="starFill" className="mr-0.5" />
          {rating} Rating
        </GameTitleDescription>
      </div>
    </GameHeader>
  );
};
