import { useLocation, useNavigate } from 'react-router-dom';

import { BackButton } from '@/components/BackButton';
import { USDC_REWARDS } from '@/features/Shop/constants';
import { showToast } from '@/utils/toast';
import { useAssets } from '@/features/Assets/queries';

import { ShopItemSummary } from './ShopItemSummary';
import { useBuyUsdc } from '../queries';
import styled from 'styled-components';
import { Icon } from '@/components/Icon/Icon';
import { useOpenLink } from '@/utils/hooks/useOpenLink';

interface ILocation {
  state: {
    value?: string;
  };
}

const LinkToUPP = styled.a(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  alignItems: 'center',
  cursor: 'pointer',
}));

const UPP_DOMAIN = process.env.VITE_UPP_DOMAIN as string;

export const UsdcShopItemSummaryContainer = () => {
  const navigate = useNavigate();
  const openLink = useOpenLink();

  const openUpp = () => {
    openLink(UPP_DOMAIN);
  };

  const { credits } = useAssets();
  const { state }: ILocation = useLocation();

  const onSuccess = () => {
    showToast({
      text: 'Purchase completed!',
      description: 'USDC will be deposited into your connected wallet within 5 business day',
      type: 'success',
    });

    navigate('/shop');
  };

  const onError = () => {
    showToast({ text: 'Something went wrong. Please try again later.', type: 'error' });
  };

  const { mutate: buyUsdc, isLoading: isBuyUsdcLoading } = useBuyUsdc({ onSuccess, onError });

  const value = state?.value || '';
  const price = USDC_REWARDS.find((usdcReward) => usdcReward.value === state?.value)?.creditPrice || 0;

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleConfirm = () => {
    const amount = parseInt(value);

    buyUsdc({ amount });
  };

  return (
    <>
      <BackButton onBack={handleBackClick} />
      <ShopItemSummary
        isVisible={!!value && !!price}
        title={`${value} USDC`}
        creditsValue={price}
        newBalance={credits - price}
        balance={credits}
        onConfirm={handleConfirm}
        isLoading={isBuyUsdcLoading}
        descriptionText={
          <>
            USDC will be deposited into your connected wallet via the Polygon network in up to 5 business days. Manage
            your wallets in{' '}
            <LinkToUPP onClick={() => openUpp()} target="_blank">
              <span className="inline">
                UPP <Icon className="ml-1/2 mb-1 inline" name="arrowRightUp" stroke="#DBC9E8" />
              </span>
            </LinkToUPP>
          </>
        }
      />
    </>
  );
};
