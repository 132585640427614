import { useDispatch, useSelector } from 'react-redux';

import { setShouldRefreshToken } from '@/features/Auth/slice';
import RewardsImage from '@/assets/images/rewards.png';
import ResourcesImage from '@/assets/images/resources.png';
import ControllerImage from '@/assets/images/controller.png';

import { getOnboardingStep } from '../selectors';
import { ONBOARDING_STEPS } from '../constants';
import { setOnboardingStep } from '../slice';
import { useCompleteOnboarding } from '../queries';
import { useEffect } from 'react';
import { TutorialScreen } from '@/components/TutorialScreen';

const IMAGE_MAP_BY_STEP = {
  [ONBOARDING_STEPS.GAMES]: ControllerImage,
  [ONBOARDING_STEPS.RESOURCES]: ResourcesImage,
  [ONBOARDING_STEPS.REWARDS]: RewardsImage,
};

const CURRENT_ACTIVE_STEP = {
  [ONBOARDING_STEPS.GAMES]: 1,
  [ONBOARDING_STEPS.RESOURCES]: 2,
  [ONBOARDING_STEPS.REWARDS]: 3,
};

const TITLE_MAP_BY_STEP = {
  [ONBOARDING_STEPS.GAMES]: 'Explore games',
  [ONBOARDING_STEPS.RESOURCES]: 'Collect resources while you play',
  [ONBOARDING_STEPS.REWARDS]: 'Redeem real rewards',
};

const DESCRIPTION_MAP_BY_STEP = {
  [ONBOARDING_STEPS.GAMES]: 'Choose from the variety of mobile games connected to BlockGames',
  [ONBOARDING_STEPS.RESOURCES]: 'Win credits while having fun, helping games grow or simply completing achievements',
  [ONBOARDING_STEPS.REWARDS]:
    'Spend your resources to claim gift cards, digital collectibles, cash or other real world prizes!',
};

export const OnboardingContainer = () => {
  useEffect(() => {
    Object.values(IMAGE_MAP_BY_STEP).map((image) => {
      const imageObject = new Image();
      imageObject.src = image;
    });
  }, []);

  const dispatch = useDispatch();
  const onboardingStep = useSelector(getOnboardingStep);

  const onSuccess = () => {
    dispatch(setShouldRefreshToken(true));
  };

  const { mutate: completeOnboarding, isLoading } = useCompleteOnboarding({ onSuccess });

  const handleNextClick = () => {
    if (onboardingStep === ONBOARDING_STEPS.GAMES) {
      dispatch(setOnboardingStep(ONBOARDING_STEPS.RESOURCES));

      return;
    }

    if (onboardingStep === ONBOARDING_STEPS.RESOURCES) {
      dispatch(setOnboardingStep(ONBOARDING_STEPS.REWARDS));

      return;
    }

    if (onboardingStep === ONBOARDING_STEPS.REWARDS) {
      completeOnboarding();

      return;
    }
  };

  const handleSkipClick = () => {
    completeOnboarding();
  };

  return (
    <TutorialScreen
      currentActiveStep={CURRENT_ACTIVE_STEP[onboardingStep]}
      activeSteps={Object.values(ONBOARDING_STEPS).length}
      buttonText={isLoading ? 'Finishing...' : 'Next'}
      image={IMAGE_MAP_BY_STEP[onboardingStep]}
      title={TITLE_MAP_BY_STEP[onboardingStep]}
      description={DESCRIPTION_MAP_BY_STEP[onboardingStep]}
      onNext={handleNextClick}
      onSkip={handleSkipClick}
      isLoading={isLoading}
    />
  );
};
