import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { BottomModal } from '@/components/BottomModal';

import { AssetsSwapForm } from './AssetsSwapForm';
import { isModalVisible } from '../selectors';
import { resetState, setModalVisible } from '../slice';

const StyledBottomModal = styled(BottomModal)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const AssetsSwap = () => {
  const dispatch = useDispatch();

  const isVisible = useSelector(isModalVisible);

  const handleClose = () => {
    dispatch(setModalVisible(false));
    dispatch(resetState());
  };

  return (
    <StyledBottomModal type="secondary" onClose={handleClose} isVisible={isVisible}>
      <AssetsSwapForm />
    </StyledBottomModal>
  );
};
