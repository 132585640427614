import styled from 'styled-components';

import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import stageQr from '@/assets/images/stage-qr.png';
import productionQr from '@/assets/images/production-qr.png';
import qrBackground from '@/assets/images/qr-background.png';
import hideable from '@/utils/hideable';

const Container = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.purple.primary['600']}`,
  padding: '32px 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 16,
  margin: '0 24px',
  backgroundImage: `url(${qrBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center 170px',
  backgroundSize: 'contain',
  boxShadow: theme.boxShadow.primary,
  backgroundColor: theme.colors.darkPurple,
}));

const DescriptionText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginTop: 8,
  marginBottom: 32,
}));

const CodeContainer = styled.div({
  background: 'linear-gradient(0deg, #3A1259, #3A1259), linear-gradient(180deg, #E78BF6 0%, #9867FF 100%)',
  border: '1px solid #E78BF6',
  borderRadius: 8,
  boxShadow: '0px 0px 20px 0px #DE00FF66',
  padding: 4,
});

const IconsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 16,
});

const StyledIcon = styled(Icon)({
  margin: '0 8px',
});

const QrCode = styled.img({
  width: 120,
});

export const WebQrCodeComponent = () => {
  const isProduction = window.location.hostname === 'blockgames.app';

  return (
    <Container>
      <Text type="h3" weight={600} align="center">
        Mobile-only gaming campaigns
      </Text>
      <DescriptionText align="center">
        Open Rewarded Play on your mobile browser using the QR code below or visit www.blockgames.app
      </DescriptionText>
      <CodeContainer>
        <QrCode src={isProduction ? productionQr : stageQr} />
      </CodeContainer>

      <IconsContainer>
        <StyledIcon name="googlePlaySmall" />
        <StyledIcon name="appStore" />
      </IconsContainer>
    </Container>
  );
};

export const WebQrCode = hideable(WebQrCodeComponent);
