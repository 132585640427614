import { useRef } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { useOutsideClick } from '@/utils/hooks/useOutsideClick';
import hideable from '@/utils/hideable';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-button-image.svg';

type TType = 'primary' | 'secondary';

interface IModal {
  onClose: () => void;
  children: React.ReactNode;
  type?: TType;
}

interface IStyleByType {
  type: TType;
  theme: DefaultTheme;
}

interface IModalContent {
  type: TType;
}

const getStyleByType = ({ type, theme }: IStyleByType) =>
  ({
    primary: {
      backgroundColor: theme.colors.purple.primary['600'],
    },
    secondary: {
      backgroundColor: theme.colors.background,
    },
  }[type]);

const Overlay = styled.div({
  position: 'absolute',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'rgba(16, 8, 21, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  backdropFilter: 'blur(2px)',
});

const ModalContent = styled.div<IModalContent>(({ theme, type }) => ({
  border: `1px solid ${theme.colors.purple.primary['400']}`,
  borderRadius: 10,
  position: 'relative',
  marginTop: 'auto',
  width: '100%',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  overflow: 'auto',
  ...getStyleByType({ type, theme }),
}));

const CloseButton = styled.button({
  position: 'absolute',
  top: 24,
  right: 24,
  outline: 'none',
  border: 'none',
  background: 'none',
});

const BottomModalComponent: React.FC<IModal> = ({ type = 'primary', children, onClose, ...rest }) => {
  const ref = useRef(null);

  useOutsideClick(ref, onClose);

  return (
    <Overlay>
      <ModalContent type={type} ref={ref} {...rest}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {children}
      </ModalContent>
    </Overlay>
  );
};

export const BottomModal = hideable(BottomModalComponent);
