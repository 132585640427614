import styled from 'styled-components';

import { CompleteProfileBoxContainer } from '@/features/CompleteProfile/components/CompleteProfileBoxContainer';
import hideable from '@/utils/hideable';
import tutorial from '@/assets/images/locked-contest.png';

const StyledCompleteProfileBox = styled(CompleteProfileBoxContainer)({
  margin: '8px 0 0 0',
});

const TutorialImage = styled.img({
  height: 'auto',
});

const LockedContestComponent = () => (
  <>
    <StyledCompleteProfileBox isVisible />
    <TutorialImage src={tutorial} alt="tutorial-image" />
  </>
);

export const LockedContest = hideable(LockedContestComponent);
