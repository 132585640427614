import { useMutation, useQueryClient } from 'react-query';

import { httpsFunction } from '@/utils/firebase';

interface IUseReferral {
  onSuccess: () => void;
}

interface IUseAddReferral {
  nickname: string;
}

export const useAddReferral = ({ onSuccess }: IUseReferral) => {
  const queryClient = useQueryClient();
  return useMutation<any, any, IUseAddReferral>(
    async ({ nickname }) => {
      const addReferral = httpsFunction('addReferral');

      await addReferral({ nickname });
    },
    {
      onSuccess: () => {
        onSuccess();

        queryClient.invalidateQueries(['useReferralSettingsData']);
      },
    }
  );
};

export const useSkipReferral = ({ onSuccess }: IUseReferral) => {
  return useMutation(
    async () => {
      const skipReferral = httpsFunction('skipReferral');

      await skipReferral();
    },
    {
      onSuccess,
    }
  );
};
