import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { firebaseApp, httpsFunction } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';
import { REWARD_NOTIFICATION_TYPE } from './constants';

interface IUseNotificationsData {
  uid: string;
}

interface IUseSetNotificationSeen {
  notificationUid: string;
}

export interface IReward {
  type: 'credits' | 'item';
  name?: string;
  creditsAmount?: number;
}

export const useSetNotificationSeen = () => {
  const queryClient = useQueryClient();

  return useMutation<any, any, IUseSetNotificationSeen>(
    async ({ notificationUid }) => {
      const setNotificationSeen = httpsFunction('setNotificationSeen');

      await setNotificationSeen({ notificationUid });
    },
    {
      onMutate: () => {
        queryClient.setQueryData('useNotificationsData', []);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['useAssetsData']);
        queryClient.invalidateQueries(['useNotificationsData']);
      },
    }
  );
};

export const useNotificationsData = ({ uid }: IUseNotificationsData) =>
  useQuery({
    queryKey: ['useNotificationsData'],
    enabled: !!uid,
    queryFn: async () => {
      const queryRef = query(
        collection(getFirestore(firebaseApp), `users/${uid}/notifications`),
        where('seen', '==', false)
      );

      const notificationsDocs = (await getDocs(queryRef)).docs;

      return notificationsDocs.map((notification) => ({
        uid: notification.id,
        creditsEarned: notification.data().creditsEarned as number | undefined,
        ticketsEarned: notification.data().ticketsEarned as number | undefined,
        type: notification.data().type as REWARD_NOTIFICATION_TYPE,
        penalty: notification.data().penalty as number | undefined,
        contestPlace: notification.data().contestPlace as 1 | 2 | 3 | undefined,
        rewards: notification.data().rewards as IReward[] | undefined,
        isWalletNeeded: notification.data().isWalletNeeded as boolean | undefined,
      }));
    },
  });

export const useNotifications = () => {
  const { uid } = useAuth();
  const { data: notifications = [] } = useNotificationsData({ uid: uid || '' });

  return {
    notifications,
  };
};
