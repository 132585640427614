import styled from 'styled-components';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import { Text } from '@/components/Text';
import { GameCard } from '@/components/GameCard';
import { Icon } from '@/components/Icon/Icon';
import hideable from '@/utils/hideable';
import { IGames } from '@/features/Games/types';

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 24,
  marginBottom: 8,
  marginTop: 16,
});

const StyledSwiperSlide = styled(SwiperSlide)({
  width: 124,
  marginLeft: 16,
  ':first-of-type': {
    marginLeft: 24,
  },
  ':last-of-type': {
    marginRight: 24,
  },
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
}));

type GamesCarouselRowProps = {
  data: IGames;
  title: string;
  dotStatus?: 'greenDot' | 'redDot';
};

export const GamesCarouselRowComponent = ({ data, title, dotStatus }: GamesCarouselRowProps) => (
  <>
    <TitleContainer>
      {dotStatus && <Icon name={dotStatus} className="mr-2" />}
      <Title weight={600} type="p_m">
        {title}
      </Title>
    </TitleContainer>
    <SwiperComponent slidesPerView="auto">
      {data.map((game) => (
        <StyledSwiperSlide key={game.slug}>
          <GameCard
            slug={game.slug}
            image={game.storageImages.backgroundImage}
            storeId={(game.androidStoreID || game.iosStoreID) as string}
            logo={game.storageImages.logo}
          />
        </StyledSwiperSlide>
      ))}
    </SwiperComponent>
  </>
);

export const GamesCarouselRow = hideable(GamesCarouselRowComponent);
