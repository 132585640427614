import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';
import { useQuery } from 'react-query';

import { firebaseApp } from '@/utils/firebase';
import { useAuth } from '@/features/Auth/hooks';
import { useNicknameData } from '../Nickname/queries';

interface IUseReferrals {
  uid?: string;
}

export const useReferralSettingsData = ({ uid }: IUseReferrals) =>
  useQuery({
    queryKey: ['useReferralSettingsData', uid],
    enabled: !!uid,
    queryFn: async () => {
      const referralSettingsDataRef = await getDoc(doc(getFirestore(firebaseApp), `users/${uid}/settings/referrals`));
      const availableReferralTime = referralSettingsDataRef.data()?.availableReferralTime as number | undefined;
      const referredByNickname = referralSettingsDataRef.data()?.referredByNickname as number | undefined;
      const currentTime = new Date().getTime();

      return {
        availableReferralTime,
        referredByNickname,
        isReferralCodeAvailable: !availableReferralTime || currentTime > availableReferralTime,
      };
    },
  });

export const useReferralsData = ({ uid }: IUseReferrals) =>
  useQuery({
    queryKey: ['useReferrals', uid],
    enabled: !!uid,
    queryFn: async () => {
      const referralDocs = (await getDocs(collection(getFirestore(firebaseApp), `users/${uid}/referrals`))).docs;

      return referralDocs.map((referral) => ({
        nickname: referral.data().nickname as string,
        activityCredits: referral.data().activityCredits as number,
        viralityCredits: referral.data().viralityCredits as number,
      }));
    },
  });

export const useReferrals = () => {
  const { uid } = useAuth();
  const { data: referrals = [] } = useReferralsData({ uid });
  const { data: referralSettings } = useReferralSettingsData({ uid: uid });
  const { data } = useNicknameData({ uid });

  const totalCreditsReceived = referrals.reduce(
    (accumulator, currentValue) => accumulator + currentValue.activityCredits + currentValue.viralityCredits,
    0
  );
  const referralsAmount = referrals.length;
  const friendsToInvite = referralsAmount < 3 ? 3 : 5;
  const friendsInvitedStageProgress = referralsAmount < 3 ? referralsAmount : (referralsAmount - 3) % 5;

  const referralCode = data?.nickname;
  const referralLink = referralCode
    ? `https://${window.location.hostname}?referral_code=${encodeURIComponent(referralCode)}`
    : '';

  return {
    referrals,
    referralsAmount,
    friendsToInvite,
    totalCreditsReceived,
    friendsInvitedStageProgress,
    referralLink,
    isReferralCodeAvailable: referralSettings?.isReferralCodeAvailable,
    availableReferralTime: referralSettings?.availableReferralTime,
    referredByNickname: referralSettings?.referredByNickname,
  };
};
