import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import styled from 'styled-components';

interface IMenuOption {
  title: string;
  iconType: 'internalLink' | 'externalLink';
  onClick: () => void;
}

const ICON_BY_TYPE = {
  internalLink: <Icon name="chevronRight" />,
  externalLink: <Icon name="externalLink" />,
};

const StyledMenuOption = styled.button(({ theme }) => ({
  border: 'none',
  outline: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px dashed ${theme.colors.purple.primary['700']}`,
  color: theme.colors.neutral.primary['0'],
  lineHeight: '20px',
  padding: '16px 0',
  ':last-of-type': {
    borderBottom: 'none',
  },
}));

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.colors.neutral.primary['0'],
}));

export const MenuOption = ({ title, iconType, onClick }: IMenuOption) => (
  <StyledMenuOption onClick={onClick}>
    <StyledText type="p_l" weight={500}>
      {title}
    </StyledText>
    {ICON_BY_TYPE[iconType]}
  </StyledMenuOption>
);
