import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { FormikHelpers } from 'formik';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '@/utils/firebase';
import { ResetPasswordForm, IPasswordResetFormValues } from './forms/ResetPasswordForm';
import { AuthErrorCodes } from 'firebase/auth';
import { Text } from '@/components/Text';
import styled from 'styled-components';
import { showToast } from '@/utils/toast';

const actionCodeSettings = {
  url: `${process.env.VITE_FIREBASE_EMAIL_CONTINUE_URL}login`,
};

const StyledText = styled(Text)({
  marginBottom: 32,
});

export const ResetEmailPassword = () => {
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(firebaseAuth);
  const [isSuccess, setSuccess] = useState(false);

  const handleFormSubmit = async (
    { email }: IPasswordResetFormValues,
    { setSubmitting }: FormikHelpers<IPasswordResetFormValues>
  ) => {
    const success = await sendPasswordResetEmail(email, actionCodeSettings);

    if (success) {
      setSubmitting(false);
      setSuccess(true);
      return;
    }

    Sentry.configureScope((scope) => scope.setLevel('error').setUser({ email }));
  };

  useEffect(() => {
    if (error && 'code' in error) {
      switch (error.code) {
        case AuthErrorCodes.INVALID_PASSWORD:
          showToast({ type: 'error', text: 'Wrong password' });
          return;
        case AuthErrorCodes.USER_DELETED:
          showToast({ type: 'error', text: 'We couldn`t find this user' });
          return;
        default:
          showToast({ type: 'error', text: 'Something has gone wrong. Please inform our support team via Discord' });
          Sentry.captureException(error);
      }
    }
  }, [error]);

  const buttonText = sending ? 'Sending...' : isSuccess ? 'New password sent' : 'Send new password';

  return (
    <>
      <StyledText type="h2" weight={600} align="center">
        Reset password
      </StyledText>
      <ResetPasswordForm isSuccess={isSuccess} onSubmit={handleFormSubmit} submitText={buttonText} />
    </>
  );
};
