import { useNavigate } from 'react-router-dom';

import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { LINKS } from '@/utils/constants/common';
import { useWalletsAddresses } from '@/features/Vault/queries';

import { SignUpRewardNotification } from './SignUpRewardNotification';
import { ContestCreditsRewardNotification } from './ContestCreditsRewardNotification';
import { ContestItemsRewardNotification } from './ContestItemsRewardNotification';
import { GetStartedReward } from './GetStartedReward';
import { ContestNftItemsRewardNotification } from './ContestNftItemsRewardNotification';
import { REWARD_NOTIFICATION_TYPE } from '../constants';
import { useNotifications, useSetNotificationSeen } from '../queries';

const UPP_DOMAIN = process.env.VITE_UPP_DOMAIN as string;

export const RewardNotifications = () => {
  const { notifications } = useNotifications();
  const navigate = useNavigate();
  const openLink = useOpenLink();

  const notification = notifications[0];
  const { mutate: setNotificationSeen } = useSetNotificationSeen();
  const { data: walletAddresses } = useWalletsAddresses();

  const handleClose = () => {
    setNotificationSeen({ notificationUid: notification.uid });
  };

  const handleClaim = () => {
    navigate('/games');
    handleClose();
  };

  const handleSupportClick = () => {
    openLink(LINKS.CHATBASE_BOT);
  };

  if (!notification) {
    return null;
  }

  const handleGoToUpp = () => {
    openLink(UPP_DOMAIN);
    handleClose();
  };

  if (notification.type === REWARD_NOTIFICATION_TYPE.SIGN_UP_REWARD) {
    return (
      <SignUpRewardNotification
        onClose={handleClose}
        onClaim={handleClaim}
        creditsEarned={notification.creditsEarned}
        ticketsEarned={notification.ticketsEarned}
      />
    );
  }

  if (
    notification.type === REWARD_NOTIFICATION_TYPE.CONTEST_CREDITS_REWARD &&
    notification.contestPlace &&
    notification.creditsEarned
  ) {
    return (
      <ContestCreditsRewardNotification
        onClose={handleClose}
        onClaim={handleClaim}
        place={notification.contestPlace}
        creditsEarned={notification.creditsEarned}
      />
    );
  }

  if (
    notification.type === REWARD_NOTIFICATION_TYPE.CONTEST_ITEMS_REWARD &&
    notification.contestPlace &&
    !notification.isWalletNeeded &&
    !!notification.rewards?.length
  ) {
    return (
      <ContestItemsRewardNotification
        onClose={handleClose}
        onReachSupportClick={handleSupportClick}
        place={notification.contestPlace}
        rewards={notification.rewards}
      />
    );
  }

  if (
    notification.type === REWARD_NOTIFICATION_TYPE.CONTEST_ITEMS_REWARD &&
    notification.contestPlace &&
    notification.isWalletNeeded &&
    !!notification.rewards?.length
  ) {
    return (
      <ContestNftItemsRewardNotification
        onClose={handleClose}
        onReachSupportClick={handleSupportClick}
        onGoToUppClick={handleGoToUpp}
        walletAddress={
          walletAddresses?.evmAddress
            ? `${walletAddresses.evmAddress.slice(0, 6)}...${walletAddresses.evmAddress.slice(-4)}`
            : ''
        }
        rewards={notification.rewards}
      />
    );
  }

  if (notification.type === REWARD_NOTIFICATION_TYPE.COMPLETE_PROFILE_REWARD && notification.creditsEarned) {
    return <GetStartedReward onClose={handleClose} onClaim={handleClaim} creditsEarned={notification.creditsEarned} />;
  }

  return null;
};
