import { ReactNode } from 'react';
import styled from 'styled-components';

export interface IPillToggle {
  selected: string;
  className?: string;
  onChange(val: string): void;
  options: Array<{ value: string; label: ReactNode; isSoon?: boolean }>;
}

export interface IOption {
  readonly value: string;
  readonly isActive: boolean;
  readonly isSoon: boolean;
  readonly label?: ReactNode;
  onChange: (value: string) => void;
}

const PillToggleWrapper = styled.div({
  display: 'inline-flex',
  width: '100%',
  borderRadius: 8,
});

const OptionWrapper = styled.div<Omit<IOption, 'value' | 'onChange'>>(({ theme, isActive }) => ({
  background: isActive ? 'linear-gradient(180deg, #E78BF6 0%, #9867FF 100%)' : theme.colors.darkPurple,
  color: isActive ? theme.colors.purple.primary?.['900'] : 'white',
  fontWeight: 600,
  cursor: 'pointer',
  fontSize: 14,
  borderRadius: 8,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '10px 0',
}));

const SoonBadge = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 34,
  height: 16,
  borderRadius: 4,
  background: 'linear-gradient(180deg, #E78BF6 0%, #9867FF 100%)',
  color: theme.colors.purple.primary['350'],
  fontSize: 10,
  lineHeight: '12px',
  marginLeft: 12,
}));

const Option = ({ value, isActive, onChange, label, isSoon, ...rest }: IOption) => {
  const handleClick = () => {
    if (isActive || isSoon) {
      return;
    }

    onChange(value);
  };

  return (
    <OptionWrapper isActive={isActive} onClick={handleClick} isSoon={isSoon} {...rest}>
      {label}
      {isSoon && <SoonBadge>soon</SoonBadge>}
    </OptionWrapper>
  );
};

export const PillToggle = ({ onChange, selected, options = [], ...rest }: IPillToggle) => {
  const handleChange: IPillToggle['onChange'] = (val) => {
    onChange(val);
  };

  return (
    <PillToggleWrapper {...rest}>
      {options.map(({ value, label, isSoon = false }) => (
        <Option
          isSoon={isSoon}
          value={value}
          key={value}
          label={label}
          isActive={selected === value}
          onChange={handleChange}
        />
      ))}
    </PillToggleWrapper>
  );
};
