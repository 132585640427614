import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Button } from '@/components/Button/Button';
import { BackButton } from './BackButton';
import { Icon } from './Icon/Icon';

interface IActivationTutorialScreen {
  title: string;
  activeSteps: number;
  currentActiveStep: number;
  image: React.ReactNode;
  points: IPoints[];
  isLoading: boolean;
  onNext: () => void;
  onBack: () => void;
}

interface IPoints {
  title: string;
  description?: string;
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 400,
  paddingTop: 26,
  height: '100%',
});

const StepNumber = styled(Text)(({ theme }) => ({
  color: theme.colors.yellow.primary['500'],
}));

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginLeft: 8,
}));

const PointsContainer = styled.div({
  marginTop: 12,
});

const PointContainer = styled.div({
  display: 'flex',
  marginBottom: 12,
});

const PointTextContainer = styled.div({
  marginLeft: 18,
});

const PointDescription = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  marginTop: 8,
}));

const StyledButton = styled(Button)({
  width: 56,
  height: 56,
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto',
  marginBottom: '20px',
  width: '100%',
});

const Star = styled(Icon)({
  minWidth: 12,
  minHeight: 12,
  marginTop: 10,
});

export const ActivationTutorialScreen = ({
  image,
  activeSteps,
  title,
  currentActiveStep,
  points,
  isLoading,
  onNext,
  onBack,
  ...rest
}: IActivationTutorialScreen) => (
  <Container {...rest}>
    <BackButton onBack={onBack} />
    {image}
    <PointsContainer>
      {points.map((point) => (
        <PointContainer key={point.title}>
          <Star name="yellowStar" />
          <PointTextContainer>
            <Text type="h3" weight={600}>
              {point.title}
            </Text>
            {point.description && <PointDescription type="h3">{point.description}</PointDescription>}
          </PointTextContainer>
        </PointContainer>
      ))}
    </PointsContainer>
    <ButtonContainer>
      <TitleContainer>
        <StepNumber weight={600}>
          {currentActiveStep}/{activeSteps}
        </StepNumber>
        <Title weight={600}>{title}</Title>
      </TitleContainer>
      <StyledButton onClick={onNext} disabled={isLoading}>
        <Icon name="chevronBlackRight" />
      </StyledButton>
    </ButtonContainer>
  </Container>
);
