import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NAME, ACTIVATION_STEPS } from './constants';
import { getPlatform } from '@/utils/getPlatform';

export interface IActivationSteps {
  activationStep: ACTIVATION_STEPS;
}

const firstStep = getPlatform() === 'ios' ? ACTIVATION_STEPS.HIT_ALLOW : ACTIVATION_STEPS.INSTALL;

const initialState: IActivationSteps = {
  activationStep: firstStep,
};

export const activationStepsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setActivationStep: (state, action: PayloadAction<ACTIVATION_STEPS>) => {
      state.activationStep = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setActivationStep, resetState } = activationStepsSlice.actions;
