import styled from 'styled-components';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { Button } from '@/components/Button/Button';
import { FormInput } from '@/components/Input/FormInput';
import { ErrorMessage as CustomErrorMessage } from '@/components/ErrorMessage/ErrorMessage';
import { Icon } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';

const NewPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
});

export interface IPasswordResetFormValues {
  password: string;
}

export interface IPasswordResetForm {
  onSubmit: (password: string) => void;
}

const Title = styled(Text)({
  marginBottom: 5,
});

const StyledButton = styled(Button)({
  marginTop: 32,
});

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const NewResetPasswordFormComponent = ({ onSubmit }: IPasswordResetForm) => (
  <>
    <Title type="h3" weight={600}>
      Create new password
    </Title>
    <Formik
      initialValues={{ password: '' }}
      validationSchema={NewPasswordFormValidationSchema}
      onSubmit={(values) => {
        onSubmit(values.password);
      }}
    >
      {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => (
        <StyledForm>
          <FormInput
            label="New password"
            name="password"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            suffix={dirty && (errors.password ? <Icon name="cross" /> : <Icon name="successTick" />)}
          />
          <ErrorMessage
            name="password"
            render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
          />
          <StyledButton type="submit" disabled={isSubmitting}>
            Change password
          </StyledButton>
        </StyledForm>
      )}
    </Formik>
  </>
);

export const NewResetPasswordForm = hideable(NewResetPasswordFormComponent);
