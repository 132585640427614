import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import ChallengeDropboxVisual from '@/assets/visuals/challenge_dropbox.svg';
import { Button } from '@/components/Button/Button';

import { ChallengeType, IChallenge, QuestType } from '../types';
import { useClaimCredits } from '../mutations';
import { BasicChallenge } from './challenges/BasicChallenge';
import { ProgressChallenge } from './challenges/ProgressChallenge';
import { TimeChallenge } from './challenges/TimeChallenge';

export const ChallengeContainer = styled.div<{ isNew?: boolean; disabled?: boolean }>(
  ({ theme, isNew, disabled = false }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.purple.primary['600'],
    boxShadow: theme.boxShadow.primary,
    borderRadius: 8,
    width: '100%',
    marginBottom: 12,
    padding: '23px 13px',
    position: 'relative',
    overflow: 'hidden',
    ...(disabled && { opacity: 0.5 }),
    ...(isNew && {
      border: `1px solid ${theme.colors.green.primary['500']}`,
      '::before': {
        content: '"New"',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.colors.green.primary['500'],
        fontSize: 12,
        fontWeight: 600,
        color: theme.colors.purple.primary['600'],
        padding: '3px 11px',
        borderBottomRightRadius: 8,
      },
    }),
  })
);

export const Visual = styled.div<{ url?: string }>(({ url }) => ({
  backgroundImage: `url(${url || ChallengeDropboxVisual})`,
  minHeight: 41,
  minWidth: 70,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
}));

export const ClaimBtn = styled(Button)({
  padding: '8px 12px',
  height: 40,
  width: 100,
  borderRadius: 5,
  fontSize: 12,
});

export const CreditContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const ChallengeEntry = ({ challenge, disabled }: { challenge: IChallenge; disabled?: boolean }) => {
  const { id: gameSlug } = useParams();
  const { mutate: claimCredits, isLoading: isClaimingCredits } = useClaimCredits();

  const handleClaim = async () => {
    if (!gameSlug) {
      console.error('Could not find game slug from params');
      return;
    }

    await claimCredits({
      questType: QuestType.CHALLENGE,
      baseChallengeID: challenge.baseChallengeID,
      userChallengeID: challenge.userChallengeID,
      gameSlug: gameSlug,
    });
  };

  switch (challenge.type) {
    case ChallengeType.BASIC:
      return (
        <BasicChallenge challenge={challenge} onClaim={handleClaim} disabled={disabled} isLoading={isClaimingCredits} />
      );
    case ChallengeType.PROGRESS:
      return (
        <ProgressChallenge
          challenge={challenge}
          onClaim={handleClaim}
          disabled={disabled}
          isLoading={isClaimingCredits}
        />
      );
    case ChallengeType.TIME_BASED:
      return (
        <TimeChallenge challenge={challenge} onClaim={handleClaim} disabled={disabled} isLoading={isClaimingCredits} />
      );
    default:
      return null;
  }
};
