import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { BackButton } from '@/components/BackButton';
import { Button } from '@/components/Button/Button';
import { PillToggle } from '@/components/PillToggle/PillToggle';
import { DailyPlay } from '@/features/DailyPlay/components/DailyPlay';
import { CampaignStatus, GameIntegrationType } from '@/features/Games/types';
import { Challenges } from '@/features/Challenges/components/Challenges';
import { useIsIosApp } from '@/features/App/hooks';
import { useChallenges } from '@/features/Challenges/queries';
import { getTimeLeftOnUserActivatedCampaign } from '@/features/Games/queries';

import { getGame } from '../queries';
import { Description } from './Description';
import { GameTitleHeader } from './GameTitleHeader';
import { GameActivationStatus } from './GameActivationStatus';
import { FaqSection } from './FaqSection';
import { CampaignDurationInfo } from './CampaignDurationInfo';
import { GameActivationFailed } from './GameActivationFailed';
import { GameCampaignEnded } from './GameCampaignEnded';
import { ActiveCampaignTimer, EndedCampaignTimer } from './CampaignTimer';

const InstallButton = styled(Button)({
  width: '100%',
  margin: '20px 0',
});

const StyledBackBtn = styled(BackButton)({
  width: 'auto',
  margin: 0,
});

const BackButtonContainer = styled.div({
  marginBottom: 22,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

enum AssetView {
  DAILY_PLAY = 'dailyPlay',
  CHALLENGES = 'challenges',
  DESCRIPTION = 'description',
}

const PILL_NAV_OPTIONS = [
  { label: 'Daily Play', value: 'dailyPlay' },
  { label: 'Challenges', value: 'challenges' },
  { label: 'Description', value: 'description' },
];

const PILL_NAV_OPTIONS_AFFILIATED = [
  { label: 'Challenges', value: 'challenges' },
  { label: 'Description', value: 'description' },
];

const resolveNavOptions = (integrationType: GameIntegrationType | undefined) => {
  switch (integrationType) {
    case GameIntegrationType.SDK:
    case GameIntegrationType.MMP:
      return PILL_NAV_OPTIONS;
    case GameIntegrationType.AFFILIATED:
      return PILL_NAV_OPTIONS_AFFILIATED;
    default:
      return PILL_NAV_OPTIONS;
  }
};

export const Game = () => {
  const isIOSapp = useIsIosApp();
  const navigate = useNavigate();
  const { id: gameSlug, campaignId } = useParams();

  const { game, campaign, campaignStatus, isActivationInProgress, isCampaignEnded, hasActiveCampaign } = getGame(
    gameSlug,
    campaignId
  );
  const { isAllChallengesClaimed, isAllAvailableChallengesClaimed } = useChallenges(gameSlug, campaignId);
  const timeLeftOnActiveCampaignString = getTimeLeftOnUserActivatedCampaign(gameSlug);

  const [assetView, setAssetView] = useState<AssetView>(
    game?.integrationType === GameIntegrationType.SDK ? AssetView.DAILY_PLAY : AssetView.CHALLENGES
  );

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleInstallClick = async () => {
    if (!hasActiveCampaign) {
      navigate(`/activation-tutorial`, { state: { slug: gameSlug, campaignId } });

      return;
    }

    if (game?.deepLink) {
      window.open(game.deepLink, '_blank');
    }
  };

  const buttonText = hasActiveCampaign ? 'Play Now' : 'Activate';

  if (isCampaignEnded && campaignStatus === CampaignStatus.ACTIVATED && !!game) {
    return (
      <>
        <BackButton onBack={handleBackClick} />
        <GameTitleHeader game={game} className="mb-5" />
        {!isAllAvailableChallengesClaimed && (
          <Challenges
            gameSlug={gameSlug}
            campaignId={campaignId}
            integrationType={game?.integrationType}
            title={game?.title || ''}
            showOnlyClaimable
          />
        )}
        <GameCampaignEnded
          className="!mt-0"
          isDisabled={!isAllAvailableChallengesClaimed}
          slug={gameSlug}
          campaignId={campaignId}
        />
      </>
    );
  }

  if (campaignStatus === CampaignStatus.FAILED && game) {
    return (
      <>
        <BackButton onBack={handleBackClick} />
        <GameTitleHeader game={game} />
        <GameActivationFailed slug={game.slug} campaignId={campaignId} />
      </>
    );
  }

  return (
    <>
      <BackButtonContainer>
        <StyledBackBtn onBack={handleBackClick} />
        <EndedCampaignTimer
          isVisible={!timeLeftOnActiveCampaignString && hasActiveCampaign && !isActivationInProgress}
        />
        <ActiveCampaignTimer isVisible={!!timeLeftOnActiveCampaignString}>
          {timeLeftOnActiveCampaignString}
        </ActiveCampaignTimer>
      </BackButtonContainer>
      {!game && <Skeleton height={102} className="rounded-lg" containerClassName="w-full" />}
      {!!game && (
        <>
          <GameTitleHeader game={game} />
          {!isIOSapp && !isAllChallengesClaimed && (
            <>
              <InstallButton disabled={isActivationInProgress} onClick={handleInstallClick}>
                {buttonText}
              </InstallButton>
              <CampaignDurationInfo isVisible={!hasActiveCampaign} campaign={campaign} />
            </>
          )}

          {hasActiveCampaign && !isAllChallengesClaimed && <GameActivationStatus campaignStatus={campaignStatus} />}
        </>
      )}
      {!game && <Skeleton height={102} className="rounded-lg" containerClassName="w-full" />}
      {!!game && (
        <PillToggle
          options={resolveNavOptions(game?.integrationType)}
          onChange={(view) => setAssetView(view as AssetView)}
          className="mb-6 mt-5"
          selected={assetView}
        />
      )}
      {assetView === AssetView.DAILY_PLAY && <DailyPlay />}
      {assetView === AssetView.DESCRIPTION && (
        <Description description={game?.description} descriptionImages={game?.storageImages?.descriptionImages} />
      )}
      {assetView === AssetView.CHALLENGES && (
        <Challenges
          gameSlug={gameSlug}
          campaignId={campaignId}
          integrationType={game?.integrationType}
          title={game?.title || ''}
        />
      )}
      <FaqSection />
    </>
  );
};
