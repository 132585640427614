import { AffiliateCampaignProvider } from '@/features/Challenges/types';
import { ICampaign } from '@/features/Games/types';

export const resolveCampaignDeepLink = (campaign: ICampaign | undefined, userID: string | undefined): string => {
  if (!campaign) {
    return '';
  }

  const { id, provider, trackingUrl } = campaign;

  if (!id || !userID) {
    return '';
  }

  if (provider.toUpperCase() === AffiliateCampaignProvider.AYET) {
    // campaign ID example - at-android-259598
    // Parsing the numbers at the end
    const offerID = id.split('-')[2];
    return `https://www.ayetstudios.com/s2s/pub/${offerID}/11734/12764/14552?external_identifier=${userID}`;
  }

  if (provider.toUpperCase() === AffiliateCampaignProvider.TYRADS) {
    if (!trackingUrl) {
      return '';
    }

    return `${trackingUrl}?sub1=${userID}&user_id=${userID}`;
  }

  return '';
};
