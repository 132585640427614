import styled from 'styled-components';
import { Icon } from '@/components/Icon/Icon';
import { getFormattedNumber } from '@/utils/helpers/getFormattedNumber';

interface IBlCreditValue {
  value: number;
  fontSize?: number;
  iconSize?: number;
  className?: string;
  iconClass?: string;
}

const Value = styled.span<{ fontSize: number }>((props) => ({
  fontSize: `${props.fontSize}px`,
  fontWeight: 700,
}));

const Container = styled.div({
  height: 22,
});

export const BLCreditValue = ({
  value,
  fontSize = 16,
  iconSize = 21,
  className = '',
  iconClass = '',
}: IBlCreditValue) => (
  <Container className={`flex items-center ${className}`}>
    <Icon name="blCredit" width={iconSize} height={iconSize} className={`mr-2 ${iconClass}`} />
    <Value fontSize={fontSize}>{getFormattedNumber(value)}</Value>
  </Container>
);
