export const timeLeftUntil = (endTime: number) => {
  const now = new Date();
  const timeLeft = endTime - now.getTime();
  const durationInSeconds = Math.floor(timeLeft / 1000);

  // Calculate the hours, minutes, and seconds
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  // Convert hours, minutes, and seconds to two-digit format if needed
  const formattedHours = ('0' + hours).slice(-2);
  const formattedMinutes = ('0' + minutes).slice(-2);
  const formattedSeconds = ('0' + seconds).slice(-2);

  // Create the formatted time string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
