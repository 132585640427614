import { Icon } from '@/components/Icon/Icon';
import { Link } from '@/components/Link/Link';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { TournamentBoard } from './TournamentBoard/TournamentBoard';

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background: linear-gradient(70.4deg, #ffffff -32.87%, #e6b5e8 8.78%, #e78bf6 61.53%, #9867ff 100.4%);
  border: 1px solid #000000;
  border-radius: 50%;
  margin-right: 9px;
`;

const Multiplier = styled.div<HTMLAttributes<HTMLDivElement> & { success?: boolean }>`
  width: 63px;
  height: 49px;
  background: #e6dbec;
  background: ${({ success }) => (success ? '#D4EDD2' : '#e6dbec')};
  border: 1px solid ${({ success }) => (success ? '#214F2E' : '#000')};
  box-shadow: 4px 4px 0px #000000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  margin-right: 20px;
  position: relative;

  .icon {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
`;

export const StandardTournaments = () => {
  return (
    <div className="w-full">
      <div className="flex justify-center mb-5">
        <Multiplier success>
          <Icon name="successRoundGreenTick" className="icon" />
          x1
        </Multiplier>
        <Multiplier>
          <Icon name="lock" className="icon" />
          x2
        </Multiplier>
        <Multiplier>
          <Icon name="lock" className="icon" />
          x3
        </Multiplier>
      </div>

      <div className="mb-5 text-center text-sm xs:text-base">
        <div className="inline-block">
          <div className="flex items-center">
            <Dot />
            <Link external to="google.com" className="mr-1">
              Standard Hero
            </Link>
            owners get x2 bonus prizes
          </div>
          <div className="flex items-center">
            <Dot />
            <Link external to="google.com" className="mr-1">
              Founder hero
            </Link>
            owners get x3 bonus prizes
          </div>
        </div>
      </div>

      <TournamentBoard title="Master of the 1vs1 arena" isJoined={true} endDate={'3d 14h 15min'} rewardType="USD" />
      <TournamentBoard
        title="Master of the 1vs1 arena"
        isJoined={false}
        joinFee={10}
        endDate={'3d 14h 15min'}
        rewardType="BL_CREDIT"
      />
    </div>
  );
};
