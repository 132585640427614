import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';
import { ReactComponent as Logo } from '@/assets/logos/white-logo.svg';
import { Text } from './Text';

interface IAuthBackButton {
  onBack?: () => void;
}

const Container = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  width: 320,
  marginBottom: 40,
});

const Button = styled.div({
  display: 'flex',
  cursor: 'pointer',
});

export const AuthBackButton = ({ onBack, ...rest }: IAuthBackButton) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (onBack) {
      onBack();

      return;
    }
    navigate(-1);
  };

  return (
    <Container {...rest}>
      <Button onClick={onClick}>
        <Icon name="chevronLeft" /> <Text type="p_m">Back</Text>
      </Button>
      <Logo />
    </Container>
  );
};
