import { useLocation, Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { RewardNotifications } from '@/features/RewardNotifications/components/RewardNotifications';
import { Navbar } from '@/components/Navbar/Navbar';
import { Header } from '@/features/Header/components/Header';
import { useAuth } from '@/features/Auth/hooks';
import { BREAKPOINTS } from '@/theme/constants';
import { routesWithoutHeaderAndFooter, routesWithoutFooter, heightSpecifiedPaths } from './constants';

interface IPageWrapperProps {
  isBigPadding: boolean;
}

interface ILayoutProps {
  isHeightSpecified: boolean;
}

const PageWrapper = styled.div<IPageWrapperProps & ILayoutProps>(({ isBigPadding, isHeightSpecified }) => ({
  padding: `${isBigPadding ? '50px' : 0} 0 ${isBigPadding ? '140px' : 0} 0`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minHeight: isHeightSpecified ? '100%' : 'unset',
  [BREAKPOINTS.SM_WIDTH]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const ContentWrapper = styled.div<ILayoutProps>(({ isHeightSpecified }) => ({
  width: '100%',
  minHeight: isHeightSpecified ? '100%' : 'unset',
  maxWidth: 600,
}));

export const ProtectedRoute = () => {
  const { isAuthenticated, isActivationStack, isOnboardingStack, isNicknameStack, isEmailVerificationStack } =
    useAuth();
  const location = useLocation();
  const isFooterVisible = !routesWithoutFooter.includes(location.pathname);
  const isHeaderAndFooterVisible = !routesWithoutHeaderAndFooter.includes(location.pathname);
  const isHeightSpecified = heightSpecifiedPaths.includes(location.pathname);

  if (!isAuthenticated) {
    return <Navigate to="/login/method" state={{ from: location }} replace />;
  }

  if (isEmailVerificationStack) {
    return <Navigate to="/email-verification" state={{ from: location }} replace />;
  }

  if (isActivationStack) {
    return <Navigate to="/sign-up-referral" state={{ from: location }} replace />;
  }

  if (isNicknameStack) {
    return <Navigate to="/nickname" state={{ from: location }} replace />;
  }

  if (isOnboardingStack) {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  return (
    <>
      <Header isVisible={isHeaderAndFooterVisible} />
      <PageWrapper isHeightSpecified={isHeightSpecified} isBigPadding={isHeaderAndFooterVisible}>
        <ContentWrapper isHeightSpecified={isHeightSpecified}>
          <Outlet />
        </ContentWrapper>
      </PageWrapper>
      <Navbar isVisible={isHeaderAndFooterVisible && isFooterVisible} />
      <RewardNotifications />
    </>
  );
};
