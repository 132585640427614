import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Timestamp, collection, getDocs, getFirestore, orderBy, query, where, limit } from 'firebase/firestore';
import { FunctionsError } from 'firebase/functions';

import { firebaseApp, httpsFunction } from '@/utils/firebase';

import { useAuth } from '../Auth/hooks';
import { TNotificationType } from './types';
import { showToast } from '@/utils/toast';

interface IUseNotificationsListData {
  uid?: string;
}

interface IUseManageNotificationsVisibilityParams {
  state: 'seen' | 'hidden';
}

export const useNotificationsListData = ({ uid }: IUseNotificationsListData) =>
  useQuery({
    queryKey: ['useNotificationsListData'],
    enabled: !!uid,
    queryFn: async () => {
      const queryRef = query(
        collection(getFirestore(firebaseApp), `users/${uid}/notifications-list`),
        orderBy('timestamp', 'desc'),
        where('isHidden', '==', false),
        limit(50)
      );
      const notificationsListDocs = (await getDocs(queryRef)).docs;

      return notificationsListDocs.map((notification) => ({
        description: notification.data().description as string | undefined,
        label: notification.data().label as string | undefined,
        title: notification.data().title as string | undefined,
        timestamp: notification.data().timestamp as Timestamp | undefined,
        isSeen: notification.data().isSeen as boolean | undefined,
        type: notification.data().type as TNotificationType | undefined,
        path: notification.data().path as string | undefined,
        uid: notification.id,
      }));
    },
  });

export const useNotificationsList = () => {
  const { uid } = useAuth();

  const { data, ...rest } = useNotificationsListData({ uid });
  const hasNewNotifications = data?.some((notification) => !notification.isSeen);

  return {
    data,
    hasNewNotifications,
    hasNotifications: !!data?.length,
    ...rest,
  };
};

export const useNotificationByUid = ({ uid }: IUseNotificationsListData) => {
  const { data } = useNotificationsList();

  return data?.find((notification) => notification.uid === uid);
};

export const useManageNotificationsVisibility = () => {
  const queryClient = useQueryClient();
  const { hasNewNotifications, hasNotifications } = useNotificationsList();

  return useMutation<any, FunctionsError, IUseManageNotificationsVisibilityParams>(
    async ({ state }) => {
      const setNotificationsVisibility = httpsFunction<IUseManageNotificationsVisibilityParams, string>(
        'setNotificationsVisibility'
      );

      if (state === 'seen' && !hasNewNotifications) {
        return;
      }

      if (state === 'hidden' && !hasNotifications) {
        return;
      }

      await setNotificationsVisibility({ state });
    },
    {
      onMutate: () => {
        queryClient.setQueryData(['useNotificationsListData'], []);
      },
      onError: () => {
        showToast({ type: 'error', text: 'Something went wrong. Please try again later.' });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['useNotificationsListData']);
      },
    }
  );
};
