import { useQuery } from 'react-query';
import { getDocs, collection, getFirestore, where, query, QueryDocumentSnapshot, doc } from 'firebase/firestore';
import { add, isAfter } from 'date-fns';

import { firebaseApp } from '@/utils/firebase';
import { getUserLocation, useAuth } from '@/features/Auth/hooks';

import {
  IBaseChallengeFirestoreResponse,
  SocMediaChallengeStatus,
  SocialType,
  IUserChallengeFirestoreResponse,
  IChallenge,
  ISocialMediaChallenge,
} from './types';
import { useGame } from '../Game/queries';

export const useChallengeListData = ({
  uid,
  gameSlug,
  campaignId,
}: {
  uid: string | undefined;
  gameSlug: string | undefined;
  campaignId?: string;
}) =>
  useQuery<IChallenge[]>({
    queryKey: ['useChallengeListData', uid, gameSlug],
    enabled: !!uid && !!gameSlug,
    queryFn: async () => {
      const db = getFirestore(firebaseApp);

      const campaignRef = doc(db, `campaigns/${campaignId}`);
      const baseChallengeDocs = (
        await getDocs(
          query(
            collection(db, `challenges/${gameSlug}/list`),
            where('isActive', '==', true),
            where('campaignRef', '==', campaignRef)
          )
        )
      ).docs as Array<QueryDocumentSnapshot<IBaseChallengeFirestoreResponse>>;

      const userChallengeDocs = (
        await getDocs(collection(getFirestore(firebaseApp), `users/${uid}/challenges/${gameSlug}/list`))
      ).docs as QueryDocumentSnapshot<IUserChallengeFirestoreResponse>[];

      const userChallengeData = userChallengeDocs.map((challenge) => {
        const data = challenge.data();
        return { ...data, id: challenge.id };
      });

      const challenges = baseChallengeDocs
        .map((challenge) => {
          const userData = userChallengeData?.find((userChallenge) => userChallenge.challengeID.id === challenge.id);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { challengeID, id, ...rest } = userData || {};

          const challengeData = challenge.data();
          const isDepChallengeClaimed = userChallengeData.find(
            (userChallenge) => userChallenge.challengeID.id === challengeData.showAfterCompleted?.id
          )?.isClaimed;

          if (challengeData.showAfterCompleted?.id && !isDepChallengeClaimed) {
            return undefined;
          }

          const omitNewStatus =
            typeof challengeData.shouldShowNewStatus === 'boolean' && challengeData.shouldShowNewStatus === false;

          return {
            baseChallengeID: challenge.id,
            userChallengeID: userData?.id,
            description: challengeData.description,
            endDate: challengeData.endDate,
            isActive: challengeData.isActive,
            title: challengeData.title,
            type: challengeData.type,
            credits: challengeData.credits,
            icon: challengeData.icon,
            isNew: omitNewStatus
              ? false
              : isAfter(add(userData?.seenAt?.toDate() || new Date(), { hours: 24 }), new Date()),
            ...rest,
          } as IChallenge;
        })
        .filter((challenge): challenge is IChallenge => !!challenge)
        ?.sort((a, b) => {
          if (a.isNew && !b.isNew) {
            return -1;
          }
          if (!a.isNew && b.isNew) {
            return 1;
          }
          return 0;
        })
        ?.sort((a, b) => a.credits - b.credits);

      return challenges;
    },
  });

export const useSocMediaChallengeData = ({ uid, gameName }: { uid: string; gameName: string }) =>
  useQuery({
    queryKey: ['useSocMediaChallengeData', uid],
    enabled: !!uid && !!gameName,
    queryFn: async () => {
      const socialMediaChallengeDocs = (
        await getDocs(
          query(
            collection(getFirestore(firebaseApp), `challenges/social-media-challenges/${gameName}`),
            where('isActive', '==', true)
          )
        )
      ).docs;

      const socialMediaDatas = (
        await getDocs(query(collection(getFirestore(firebaseApp), `users/${uid}/challenges/${gameName}/social`)))
      ).docs.map((data) => data.data())[0];

      return socialMediaChallengeDocs.map(
        (socialMediaChallenge) =>
          ({
            credits: socialMediaChallenge.data().credits,
            isActive: socialMediaChallenge.data().isActive,
            title: socialMediaChallenge.data().title,
            link: socialMediaChallenge.data().link,
            type: socialMediaChallenge.data().type as SocialType,
            status: socialMediaDatas?.[socialMediaChallenge.data().type] || SocMediaChallengeStatus.NOT_SHARED,
          } as ISocialMediaChallenge)
      );
    },
  });

export const useChallenges = (gameSlug?: string, campaignId?: string) => {
  const { uid } = useAuth();
  const { userCountry } = getUserLocation();

  const { data: game } = useGame(gameSlug, uid, userCountry);

  const challengeQuery = useChallengeListData({
    uid,
    gameSlug,
    campaignId,
  });

  const mediaChallenges = useSocMediaChallengeData({ uid: uid || '', gameName: game?.name || '' });
  const isAllChallengesClaimed = challengeQuery.data?.every(({ isClaimed }) => isClaimed);
  const isAllAvailableChallengesClaimed = !challengeQuery.data?.some(({ canClaim }) => canClaim);

  return {
    challengeQuery,
    mediaChallenges,
    isAllChallengesClaimed,
    isAllAvailableChallengesClaimed,
  };
};
