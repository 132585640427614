import { Icon } from '@/components/Icon/Icon';
import { Divider } from '@/components/Divider';
import { ListContainer } from '@/components/ListContainer';
import { TournamentBoard } from './TournamentBoard/TournamentBoard';

export const SponsoredTournaments = () => {
  return (
    <div className="w-full">
      <ListContainer className="w-full p-6 mb-5">
        <div className="flex mb-5">
          <Icon name="cashSack" width={20} height={20} className="mr-3" /> 1000 best winners each week
        </div>
        <Divider className="mb-5" />
        <div className="flex">
          <Icon name="lock" width={20} height={20} className="mr-3 shrink-0" /> Founder Hero collectible is required to
          participate
        </div>
      </ListContainer>

      <TournamentBoard title="Master of the 1vs1 arena" isJoined={true} endDate={'3d 14h 15min'} rewardType="USD" />
      <TournamentBoard title="Master of the 1vs1 arena" isJoined={false} endDate={'3d 14h 15min'} rewardType="USD" />
      <TournamentBoard title="Master of the 1vs1 arena" isJoined={true} endDate={'3d 14h 15min'} rewardType="USD" />
    </div>
  );
};
