import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface IGames {
  selectedGenre: string;
}

const initialState: IGames = {
  selectedGenre: 'All games',
};

export const gamesSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setSelectedGenre: (state, action: PayloadAction<string>) => {
      state.selectedGenre = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setSelectedGenre, resetState } = gamesSlice.actions;
