import { add, formatDistanceStrict } from 'date-fns';
import styled from 'styled-components';

import ArrowUp from '@/assets/icons/arrow-container-up.svg';
import { theme as baseTheme } from '@/theme/theme';
import { Icon } from '@/components/Icon/Icon';
import { ICampaign } from '@/features/Games/types';
import hideable from '@/utils/hideable';

const CampaignTimeContainer = styled.div(({ theme }) => ({
  position: 'relative',
  width: '100%',
  padding: '16px',
  background: theme.colors.background,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.colors.purple.primary['400']}`,
  borderRadius: 8,
  height: 52,
  zIndex: 1,
  '&:before': {
    content: `url(${ArrowUp})`,
    position: 'absolute',
    left: 0,
    right: 0,
    top: -16,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const StyledText = styled.div(({ theme }) => ({
  color: theme.colors.purple.primary[50],
  fontSize: 12,
  fontWeight: 600,
  marginLeft: 4,
}));

export const CampaignDurationInfoComponent = ({ campaign }: { campaign?: ICampaign }) => {
  if (!campaign) {
    return;
  }

  const days = formatDistanceStrict(new Date(), add(new Date(), { seconds: campaign.attributionWindow }), {
    unit: 'day',
  });
  return (
    <CampaignTimeContainer>
      <Icon fill={baseTheme.colors.yellow.primary[500]} name="clock"></Icon>
      <StyledText>{days} to complete all the challenges</StyledText>
    </CampaignTimeContainer>
  );
};

export const CampaignDurationInfo = hideable(CampaignDurationInfoComponent);
