import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import backgroundImg from '@/assets/images/profile-background.png';
import { ReactComponent as Avatar1 } from '@/assets/avatars/avatar1.svg';
import { ReactComponent as Avatar2 } from '@/assets/avatars/avatar2.svg';
import { ReactComponent as Avatar3 } from '@/assets/avatars/avatar3.svg';
import { ReactComponent as Avatar4 } from '@/assets/avatars/avatar4.svg';
import { ReactComponent as Avatar5 } from '@/assets/avatars/avatar5.svg';
import { PageHeader } from '@/components/PageHeader';
import { Text } from '@/components/Text';
import { useNickname } from '@/features/Nickname/queries';

import { useProfile } from '../queries';
import { useOpenLink } from '@/utils/hooks/useOpenLink';
import { LINKS } from '@/utils/constants/common';
import { Icon } from '@/components/Icon/Icon';
import { Button } from '@/components/Button/Button';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 24,
});

const ProfileHeader = styled.div({
  width: '100%',
  height: 289,
  backgroundImage: `url(${backgroundImg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const ProfileImg = styled.div({
  marginTop: 68,
  display: 'flex',
  justifyContent: 'center',
});

const Nickname = styled(Text)({
  textAlign: 'center',
  marginTop: -16,
  marginBottom: 8,
});

const StyledIcon = styled(Icon)({
  marginLeft: 4,
});

const CTAButton = styled(Button)({
  width: 190,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 'auto',
});

const userIconByNumber = {
  1: <Avatar1 />,
  2: <Avatar2 />,
  3: <Avatar3 />,
  4: <Avatar4 />,
  5: <Avatar5 />,
};

export const Header = () => {
  const navigate = useNavigate();
  const { nickname } = useNickname();
  const { iconNumber } = useProfile();
  const openLink = useOpenLink();

  const onBack = () => {
    navigate(-1);
  };

  const onSettingsClick = () => {
    navigate('/settings');
  };

  const onClick = () => {
    openLink(LINKS.UPP_LAUNCH_TASK);
  };

  return (
    <Container>
      <PageHeader
        title=""
        onBack={onBack}
        isSettingsVisible
        onSettingsClick={onSettingsClick}
        className="!w-11/12 z-10"
      />
      <ProfileHeader>
        <ProfileImg>{userIconByNumber[iconNumber] || <Avatar1 />}</ProfileImg>
        <Nickname type="h4" weight={600}>
          {nickname}
        </Nickname>
        <CTAButton buttonSize="medium" onClick={onClick}>
          <Text type="p_s" weight={500} className="!text-black">
            Go to Universal Profile
          </Text>
          <StyledIcon name="arrowUpRightBlack" />
        </CTAButton>
      </ProfileHeader>
    </Container>
  );
};
