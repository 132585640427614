import Skeleton from 'react-loading-skeleton';

import { GameIntegrationType } from '@/features/Games/types';

import { useChallenges } from '../queries';
import { FollowUs } from './FollowUs';
import { ChallengesCompleted } from './ChallengesCompleted';
import { ChallengeList } from './ChallengeList';

export const Challenges = ({
  title,
  integrationType,
  gameSlug,
  campaignId,
  showOnlyClaimable,
}: {
  title: string | null;
  integrationType: GameIntegrationType | undefined;
  gameSlug?: string;
  campaignId?: string;
  showOnlyClaimable?: boolean;
}) => {
  const { challengeQuery, isAllChallengesClaimed } = useChallenges(gameSlug, campaignId);

  const challengesResource = challengeQuery?.data;

  const challenges = challengesResource
    ?.filter(({ credits, title: challengeTitle }) => credits !== 0 && challengeTitle.toLowerCase() !== 'purchase')
    .filter(({ canClaim }) => (showOnlyClaimable ? canClaim : true));

  if (challengeQuery.isLoading || challengeQuery.isIdle) {
    return (
      <>
        <Skeleton height={90} className="rounded-lg mb-6" containerClassName="w-full mb-6" />
        <Skeleton count={3} height={80} className="rounded-lg" containerClassName="w-full" />
      </>
    );
  }

  if (isAllChallengesClaimed) {
    return <ChallengesCompleted />;
  }

  return (
    <div>
      {integrationType === GameIntegrationType.SDK && (
        <h3 className="font-semibold mb-2 text-sm text-[#BAA1D2]">Challenges</h3>
      )}
      <ChallengeList challenges={challenges || []} />
      <FollowUs title={title} />
    </div>
  );
};
