import { useLocation, Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '@/features/Auth/hooks';
import { ReactComponent as Logo } from '@/assets/logos/white-logo.svg';

import { rootAuthenticatedRoute } from './constants';

const StyledLogo = styled(Logo)({
  alignSelf: 'flex-end',
});

export const NicknameRoute = () => {
  const { isAuthenticated, isAuthenticatedStack, isActivationStack, isOnboardingStack, isEmailVerificationStack } =
    useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login/method" state={{ from: location }} replace />;
  }

  if (isActivationStack) {
    return <Navigate to="/sign-up-referral" state={{ from: location }} replace />;
  }

  if (isOnboardingStack) {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  if (isEmailVerificationStack) {
    return <Navigate to="/email-verification" state={{ from: location }} replace />;
  }

  if (isAuthenticatedStack) {
    return <Navigate to={rootAuthenticatedRoute} state={{ from: location }} replace />;
  }

  return (
    <>
      <StyledLogo />
      <Outlet />
    </>
  );
};
