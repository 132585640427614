import styled from 'styled-components';

import hideable from '@/utils/hideable';

import { Icon } from './Icon/Icon';

type TSignInButtonType = 'apple' | 'email' | 'google';

interface ISignInSmallButton {
  buttonType: TSignInButtonType;
  onClick: () => void;
}

const iconByType = {
  email: <Icon name="email" />,
  apple: <Icon name="apple" />,
  google: <Icon name="googleWhite" />,
};

const StyledButton = styled('button')(({ theme }) => ({
  border: `1px solid ${theme.colors.neutral.primary[1000]}`,
  outline: 'none',
  height: 56,
  width: 56,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.boxShadow.primary,
  backgroundColor: theme.colors.purple.primary['600'],
}));

const SignInSmallButtonComponent = ({ buttonType, onClick, ...rest }: ISignInSmallButton) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    onClick();
  };

  return (
    <StyledButton onClick={handleClick} {...rest}>
      {iconByType[buttonType]}
    </StyledButton>
  );
};

export const SignInSmallButton = hideable(SignInSmallButtonComponent);
