import { Icon } from '@/components/Icon/Icon';
import { useCountdown } from '@/features/Challenges/hooks/useCountdown';
import { IChallenge } from '@/features/Challenges/types';
import { resolveChallengeVisual } from '@/features/Challenges/utils/challengeVisuals';
import { ChallengeContainer, ClaimBtn, Visual } from '../ChallengeEntry';
import { abbreviateNumber } from '@/utils/helpers/abbreviateNumber';

export const TimeChallenge = ({
  challenge,
  onClaim,
  isLoading,
  disabled,
}: {
  challenge: IChallenge;
  onClaim(): void;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const countdown = useCountdown(challenge.endDate.toDate());
  return (
    <ChallengeContainer disabled={disabled} isNew={challenge.isNew}>
      <Visual url={resolveChallengeVisual(challenge.icon)} />
      <div className="flex-1 px-2">
        <div className="text-[#F1E7FA] font-semibold text-sm mb-1">{challenge.title}</div>

        <div className="flex items-center text-[#F1E7FA] text-xs font-semibold">
          <Icon name="clock" className="mr-2" /> {countdown}
        </div>
      </div>
      <div className="w-[75px] flex justify-center">
        {challenge.canClaim ? (
          <ClaimBtn disabled={isLoading} onClick={onClaim}>
            {isLoading ? (
              'Claiming...'
            ) : (
              <>
                Claim <Icon name="credit" height={24} className="ml-2 mr-1" /> {abbreviateNumber(challenge.credits)}
              </>
            )}
          </ClaimBtn>
        ) : (
          <div className="flex items-center justify-end text-[#F1E7FA] font-semibold">
            <Icon name="credit" height={24} className="ml-2 mr-1" /> {abbreviateNumber(challenge.credits)}
          </div>
        )}
      </div>
    </ChallengeContainer>
  );
};
