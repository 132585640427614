import styled from 'styled-components';

import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';
import { ReactComponent as LoveVisual } from '@/assets/visuals/love_visual.svg';

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: 'auto',
  marginTop: 140,
});

const Visual = styled(LoveVisual)({
  marginBottom: 10,
});

const Title = styled(Text)({
  marginBottom: 5,
});

const Paragraph = styled(Text)({
  width: 230,
  textAlign: 'center',
});

const LoadingEmailVerificationComponent = () => (
  <Container>
    <Visual />
    <Title type="h3" weight={600}>
      Loading...
    </Title>
    <Paragraph colorType="light">Hold tight!</Paragraph>
  </Container>
);

export const LoadingEmailVerification = hideable(LoadingEmailVerificationComponent);
