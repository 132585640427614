import styled from 'styled-components';

import { Button } from '@/components/Button/Button';
import redeemVisual from '@/assets/images/redeem_rewards_visual.png';
import { Icon } from '@/components/Icon/Icon';

const StyledImg = styled.img({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 350,
  width: '100%',
});

const ClaimBtn = styled(Button)({
  maxWidth: 250,
  width: '100%',
  margin: '0 auto 16px',
});

const PlatformButton = styled.button(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.colors.darkPurple,
  maxWidth: 140,
  padding: '11px 0',
  color: theme.colors.purple.primary['50'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 11,
  width: '100%',
  marginRight: 8,
  position: 'relative',
  '&:last-child': {
    marginRight: 0,
  },
  svg: {
    marginRight: 5,
  },
}));

const Tag = styled.div(({ theme }) => ({
  background: 'linear-gradient(180deg, #E78BF6 0%, #9867FF 100%)',
  fontSize: 10,
  color: theme.colors.purple.primary['700'],
  borderRadius: 3,
  width: 32,
  height: 14,
  position: 'absolute',
  top: -10,
  right: -10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const RedeemRewards = () => {
  return (
    <div>
      <div className="text-center">
        <div className="text-[#F1E7FA] text-xl mb-1">Redeem rewards</div>
        <div className="text-[#BAA1D2] text-sm">
          Download BlockGames application or visit the <br /> website to claim your earned rewards!
        </div>
      </div>
      <StyledImg src={redeemVisual} className="relative -top-2.5" />
      <ClaimBtn>Claim your rewards</ClaimBtn>
      <div className="text-[#9473B5] text-xs text-center mb-1">Available on:</div>
      <div className="flex justify-center">
        <PlatformButton onClick={() => window.open('https://google.com', '_blank')}>
          <Icon width={16} name="globe" />
          Website
        </PlatformButton>
        <PlatformButton onClick={() => window.open('https://google.com', '_blank')}>
          <Icon width={16} name="googlePlay" />
          Google Play
        </PlatformButton>
        <PlatformButton className="cursor-default">
          <Tag>soon</Tag>
          <Icon width={16} name="apple" />
          App Store
        </PlatformButton>
      </div>
    </div>
  );
};
