import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useRef } from 'react';
import { Swiper as SwiperComponent, SwiperRef, SwiperSlide } from 'swiper/react';

import { Text } from '@/components/Text';
import { GameCardBig } from '@/components/GameCardBig';
import { useGetUserActivatedCampaignByGameId } from '@/features/GamesIOS/queries';
import hideable from '@/utils/hideable';

import { getSelectedGenre } from '../selectors';
import { setSelectedGenre } from '../slice';
import { useGetCampaignByStoreId } from '../queries';
import { IGames } from '../types';

interface IGenreButton {
  isSelected: boolean;
}

interface IGamesByGenre {
  data: IGames;
  genres: string[];
}

const Container = styled.div({
  width: '100%',
  overflow: 'hidden',
});

const HorizontallyScrollableContainer = styled.div({
  display: 'flex',
  width: '100%',
  overflowY: 'scroll',
  boxSizing: 'content-box',
  margin: '8px 0 16px 0',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

const StyledSwiperSlide = styled(SwiperSlide)({
  width: 242,
  marginLeft: 16,
  ':first-of-type': {
    marginLeft: 24,
  },
  ':last-of-type': {
    marginRight: 24,
  },
});

const GenreButton = styled.div<IGenreButton>(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.colors.purple.primary['600'] : 'unset',
  borderRadius: 20,
  ':first-of-type': {
    marginLeft: 24,
  },
  ':last-of-type': {
    marginRight: 24,
  },
}));

const GenreText = styled(Text)<IGenreButton>(({ theme, isSelected }) => ({
  color: isSelected ? theme.colors.purple.primary['50'] : theme.colors.purple.primary['300'],
  padding: '8px 16px',
  whiteSpace: 'nowrap',
}));

const GamesByGenreComponent = ({ data, genres }: IGamesByGenre) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const swiperRef = useRef<SwiperRef | null>(null);
  const getCampaignByStoreId = useGetCampaignByStoreId();
  const getUserActivatedCampaignByGameId = useGetUserActivatedCampaignByGameId();

  const selectedGenre = useSelector(getSelectedGenre);

  const onGenreClick = (genre: string) => {
    dispatch(setSelectedGenre(genre));

    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  const onGameClick = (slug: string, storeId: string) => {
    const campaign = getCampaignByStoreId(storeId);
    navigate(`/games/${slug}/${campaign?.id}`);
  };

  return (
    <Container>
      <HorizontallyScrollableContainer>
        {genres.map((genre) => (
          <GenreButton key={genre} isSelected={selectedGenre === genre} onClick={() => onGenreClick(genre)}>
            <GenreText weight={600} isSelected={selectedGenre === genre}>
              {genre}
            </GenreText>
          </GenreButton>
        ))}
      </HorizontallyScrollableContainer>
      <SwiperComponent slidesPerView="auto" ref={swiperRef}>
        {data.map((game) => (
          <StyledSwiperSlide key={game.slug}>
            <GameCardBig
              key={game.slug}
              slug={game.slug}
              campaignStatus={getUserActivatedCampaignByGameId(game.slug)?.status}
              gameStoreId={game?.iosStoreID || game?.androidStoreID || ''}
              genres={game.genre}
              image={game.storageImages.backgroundImage}
              logo={game.storageImages.logo}
              onOpenClick={onGameClick}
              title={game.title}
            />
          </StyledSwiperSlide>
        ))}
      </SwiperComponent>
    </Container>
  );
};

export const GamesByGenre = hideable(GamesByGenreComponent);
