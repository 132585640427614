import { useState } from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ContestPlaceholder from '@/assets/visuals/contest_placeholder.png';
import { LoaderIcon } from '@/components/SVGs/LoaderIcon';

interface IGameThumbnail {
  image: string;
  logo: string;
}

const ImgContainer = styled(LazyLoadImage)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 9,
});

const GameIcon = styled(LazyLoadImage)({
  position: 'absolute',
  top: 10,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 124,
});

const StyledLoader = styled(LoaderIcon)({
  position: 'absolute',
  top: -20,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  margin: 'auto',
  opacity: 0.5,
});

export const GameThumbnail = ({ image, logo }: IGameThumbnail) => {
  const [isMainImageLoaded, setIsMainImageLoaded] = useState(false);

  return (
    <>
      <ImgContainer
        src={image}
        onLoad={() => {
          setIsMainImageLoaded(true);
        }}
      />
      {!!logo && <GameIcon src={logo} />}
      {!isMainImageLoaded && (
        <>
          <StyledLoader />
          <ImgContainer src={ContestPlaceholder} />
        </>
      )}
    </>
  );
};
