import styled from 'styled-components';

import { ReactComponent as Usdc } from '@/assets/images/usdc.svg';
import { ReactComponent as Paypal } from '@/assets/images/paypal-gift-card.svg';
import { ReactComponent as Amazon } from '@/assets/images/amazon-gift-card.svg';
import { ReactComponent as Playstation } from '@/assets/images/playstation-gift-card.svg';
import { ReactComponent as GooglePlay } from '@/assets/images/google-play-gift-card.svg';
import { ReactComponent as Apple } from '@/assets/images/apple-app-store-gift-card.svg';
import { TShopItemType } from '@/features/Shop/types';
import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import hideable from '@/utils/hideable';

interface IShopItemSummary {
  creditsValue: number;
  newBalance: number;
  balance: number;
  isLoading: boolean;
  type?: TShopItemType;
  descriptionText: JSX.Element;
  title: string;
  onConfirm: () => void;
}

const IconContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary[600],
  border: `1px solid ${theme.colors.purple.primary[500]}`,
  borderRadius: 3,
  width: 70,
  height: 70,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 16,
}));

const StyledUsdc = styled(Usdc)({
  maxWidth: 32,
  width: '100%',
});

const StyledPaypal = styled(Paypal)({
  maxWidth: 32,
  width: '100%',
});

const StyledAmazon = styled(Amazon)({
  maxWidth: 32,
  width: '100%',
});

const StyledPlaystation = styled(Playstation)({
  maxWidth: 32,
  width: '100%',
});

const StyledGooglePlay = styled(GooglePlay)({
  height: 32,
  width: '100%',
});

const StyledApple = styled(Apple)({
  height: 32,
  width: '100%',
});

const StyledCreditsIcon = styled(Icon)({
  marginRight: 4,
});

const RowFlexbox = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const SpaceBetweenContainer = styled(RowFlexbox)({
  justifyContent: 'space-between',
});

const MainInfoContainer = styled(SpaceBetweenContainer)({
  marginTop: 24,
});

const BalanceInfoContainer = styled(SpaceBetweenContainer)({
  marginBottom: 16,
});

const StyledButton = styled(Button)({
  width: '100%',
  marginTop: 8,
});

const Divider = styled.div({
  width: '100%',
  opacity: 1,
  borderTop: '1px solid #502572',
  margin: '24px 0',
});

const ICON_BY_TYPE = {
  amazon: <StyledAmazon />,
  paypal: <StyledPaypal />,
  playstation: <StyledPlaystation />,
  usdc: <StyledUsdc />,
  googleplay: <StyledGooglePlay />,
  apple: <StyledApple />,
};

const ShopItemSummaryComponent = ({
  creditsValue,
  newBalance,
  balance,
  isLoading,
  type = 'usdc',
  descriptionText,
  title,
  onConfirm,
}: IShopItemSummary) => (
  <>
    <Text type="p_l" weight={500}>
      Order summary
    </Text>
    <MainInfoContainer>
      <RowFlexbox>
        <IconContainer>{ICON_BY_TYPE[type]}</IconContainer>
        <Text weight={600}>{title}</Text>
      </RowFlexbox>
      <RowFlexbox>
        <StyledCreditsIcon name="credit" />
        <Text weight={700}>{creditsValue}</Text>
      </RowFlexbox>
    </MainInfoContainer>
    <Divider />
    <BalanceInfoContainer>
      <RowFlexbox>
        <StyledCreditsIcon name="credit" />
        <Text weight={700}>{balance}</Text>
      </RowFlexbox>
      <Text colorType="light">Current balance</Text>
    </BalanceInfoContainer>
    <BalanceInfoContainer>
      <RowFlexbox>
        <StyledCreditsIcon name="credit" />
        <Text weight={700}>{newBalance}</Text>
      </RowFlexbox>
      <Text colorType="light">New balance</Text>
    </BalanceInfoContainer>
    <StyledButton onClick={onConfirm} disabled={isLoading}>
      {isLoading ? 'Confirming...' : 'Confirm'}
    </StyledButton>
    <Divider />
    <Text colorType="extraLight">{descriptionText}</Text>
  </>
);

export const ShopItemSummary = hideable(ShopItemSummaryComponent);
