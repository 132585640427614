import { TRootState } from '@/store/store';

export const getPlatform = ({ app }: TRootState) => app.platform;

export const getAppsflyerAndroidId = ({ app }: TRootState) => app.appsflyerAndroidId;

export const getAppsflyerIosId = ({ app }: TRootState) => app.appsflyerIosId;

export const getAppsflyerWebId = ({ app }: TRootState) => app.appsflyerWebId;

export const getFirebaseAndroidId = ({ app }: TRootState) => app.firebaseAndroidId;

export const getFirebaseIosId = ({ app }: TRootState) => app.firebaseIosId;

export const getFirebaseWebId = ({ app }: TRootState) => app.firebaseWebId;
