import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Text } from '@/components/Text';
import hideable from '@/utils/hideable';

import { getActiveSubTab } from '../selectors';
import { SUB_TABS } from '../constants';

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 30,
  backgroundColor: theme.colors.purple.primary['600'],
  borderRadius: 8,
  boxShadow: '2px 2px 0px 0px #000000',
  margin: '8px 0',
}));

const TEXT_MAP_BY_SUB_TAB = {
  [SUB_TABS.ACTIVE]: 'No contests',
  [SUB_TABS.MY_ENTRIES]: 'No entries',
  [SUB_TABS.ENDED]: 'No ended contests',
};

const NoContestsMessageComponents = () => {
  const activeSubTab = useSelector(getActiveSubTab);

  return (
    <Container>
      <Text weight={600}>{TEXT_MAP_BY_SUB_TAB[activeSubTab]}</Text>
    </Container>
  );
};

export const NoContestsMessage = hideable(NoContestsMessageComponents);
