import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { FormInput } from '@/components/Input/FormInput';
import { Button } from '@/components/Button/Button';
import { ErrorMessage } from '@/components/ErrorMessage/ErrorMessage';
import { setShouldRefreshToken } from '@/features/Auth/slice';

import { getReferralCode } from '../selectors';
import { useAddReferral } from '../queries';
import { useReferrals } from '@/features/Referral/queries';
import { Text } from '@/components/Text';

interface IFormFields {
  nickname: string;
}

const ReferralFormFieldValidation = Yup.object().shape({
  nickname: Yup.string().required('Referral code is required'),
});

const Container = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  maxWidth: 320,
  width: '100%',
});

const StyledError = styled(ErrorMessage)({
  alignSelf: 'flex-start',
  marginLeft: 0,
});

const ReferralNickname = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  borderRadius: 8,
  padding: '18px 16px',
}));

export const ReferralForm = () => {
  const dispatch = useDispatch();

  const initialValue = useSelector(getReferralCode);

  const onSuccess = () => {
    dispatch(setShouldRefreshToken(true));
  };

  const { referredByNickname } = useReferrals();
  const { mutate: addReferral, isLoading, isError } = useAddReferral({ onSuccess });

  const handleSubmit = ({ nickname }: IFormFields, { setSubmitting }: FormikHelpers<IFormFields>) => {
    addReferral({ nickname });

    setSubmitting(false);
  };

  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
    setValueCallback: (field: string, value: any) => void
  ) => {
    try {
      const url = new URL(event.clipboardData.getData('Text'));
      const searchParams = new URLSearchParams(url.search);
      const nickname = searchParams.get('referral_code');

      if (!nickname) {
        return;
      }

      setValueCallback('nickname', nickname);
      event.preventDefault();
    } catch {
      return;
    }
  };

  return (
    <Formik
      validationSchema={ReferralFormFieldValidation}
      initialValues={{ nickname: initialValue }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ handleChange, handleBlur, values, setFieldValue }) => (
        <Container>
          {!referredByNickname && (
            <>
              <FormInput
                label="Referral code"
                name="nickname"
                type="nickname"
                value={values.nickname}
                onBlur={handleBlur}
                onChange={handleChange}
                onPaste={(event) => handlePaste(event, setFieldValue)}
              />
              {!!isError && <StyledError className="ml-5 mt-1">Invalid referral code</StyledError>}
              <Button className="mt-5" type="submit" disabled={isLoading || values.nickname.length === 0}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </>
          )}
          {referredByNickname && (
            <ReferralNickname>
              <Text type="p_l" weight={500} colorType="light">
                {referredByNickname}
              </Text>
            </ReferralNickname>
          )}
        </Container>
      )}
    </Formik>
  );
};
