import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { Button } from '@/components/Button/Button';
import { resolveCampaignIndicationIcon } from '@/features/Game/components/GameActivationStatus';
import { CampaignStatus } from '@/features/Games/types';

import { GameThumbnail } from './GameThumbnail';

interface IGameCardBig {
  title: string;
  logo: string;
  image: string;
  slug: string;
  gameStoreId: string;
  campaignStatus?: CampaignStatus;
  onOpenClick: (slug: string, gameStoreId: string) => void;
  genres: string[];
}

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  cursor: 'pointer',
  height: 180,
  width: 242,
  border: `1px solid ${theme.colors.purple.primary['500']}`,
  borderRadius: 9,
}));

const Footer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto',
  padding: '16px 10px',
  backgroundColor: 'rgba(52, 12, 83, 0.6)',
  overflow: 'hidden',
  zIndex: 1,
  border: `1px solid ${theme.colors.purple.primary['600']}`,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}));

const StyledButton = styled(Button)({
  height: 32,
  minWidth: 32,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
});

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const TextContainer = styled.div({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  marginRight: 8,
});

const Title = styled(Text)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const StyledIcon = styled(Icon)({
  minWidth: 16,
  marginRight: 4,
});

const GenreText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

export const GameCardBig = ({
  image,
  title,
  slug,
  gameStoreId,
  genres,
  logo,
  onOpenClick,
  campaignStatus,
  ...rest
}: IGameCardBig) => (
  <Container onClick={() => onOpenClick(slug, gameStoreId)} {...rest}>
    <GameThumbnail image={image} logo={logo} />
    <Footer>
      <TextContainer>
        <TitleContainer>
          {campaignStatus && <StyledIcon name={resolveCampaignIndicationIcon(campaignStatus)} />}
          <Title type="p_s" className="z-10" weight={600}>
            {title}
          </Title>
        </TitleContainer>
        <GenreText type="p_xs" weight={400}>
          {genres?.join(', ')}
        </GenreText>
      </TextContainer>
      <StyledButton>
        <Icon name="chevronBlackRight" />
      </StyledButton>
    </Footer>
  </Container>
);
