import { useQuery } from 'react-query';
import { getBoolean, fetchAndActivate } from 'firebase/remote-config';

import { remoteConfig } from '@/utils/firebase';

export const getRemoteConfigValues = async () => {
  await fetchAndActivate(remoteConfig);

  const isExtraPointsEnabled = getBoolean(remoteConfig, 'is_extra_points_enabled');
  const isGiftCardsEnabled = getBoolean(remoteConfig, 'is_gift_cards_enabled');
  const isSocialPointsEnabled = getBoolean(remoteConfig, 'is_social_points_enabled');

  return {
    isExtraPointsEnabled,
    isGiftCardsEnabled,
    isSocialPointsEnabled,
  };
};

export const useRemoteConfig = () =>
  useQuery({
    queryKey: ['useRemoteConfig'],
    queryFn: getRemoteConfigValues,
  });
