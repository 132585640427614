import { useState, useEffect } from 'react';
import { differenceInSeconds } from 'date-fns';

const DAY_IN_SECONDS = 86400;
const HOUR_IN_SECONDS = 3600;
const MINUTE_IN_SECONDS = 60;
const SECOND_IN_MILLISECONDS = 1000;

export const getUTCDate = () => {
  const date = new Date();
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

const formatTime = (secondsRemaining: number) => {
  if (secondsRemaining > DAY_IN_SECONDS) {
    return `${Math.floor(secondsRemaining / DAY_IN_SECONDS)}`.padStart(2, '0');
  }

  const hours = `${Math.max(0, Math.floor(secondsRemaining / HOUR_IN_SECONDS))}`.padStart(2, '0');
  const minutes = `${Math.max(0, Math.floor((secondsRemaining % HOUR_IN_SECONDS) / 60))}`.padStart(2, '0');
  const seconds = `${Math.max(0, secondsRemaining % MINUTE_IN_SECONDS)}`.padStart(2, '0');

  const formattedDuration = `${hours}:${minutes}:${seconds}`;

  return formattedDuration;
};

export const useCountdown = (startDate?: Date | number, endDate?: Date | number): [string, boolean, boolean] => {
  const start = startDate || new Date();
  const end = endDate || getUTCDate();
  const [timeLeft, setTimeLeft] = useState(differenceInSeconds(end, start));
  const [isMoreThanADayLeft, setIsMoreThanADayLeft] = useState(differenceInSeconds(end, start) > DAY_IN_SECONDS);
  const [isEndingSoon, setIsEndingSoon] = useState(differenceInSeconds(end, start) < HOUR_IN_SECONDS);

  useEffect(() => {
    const timer = setInterval(() => {
      const secondsLeft = differenceInSeconds(end, start);
      const isEndingInAnHour = secondsLeft < HOUR_IN_SECONDS;
      const moreThanADayLeft = secondsLeft > DAY_IN_SECONDS;

      setIsEndingSoon(isEndingInAnHour);
      setIsMoreThanADayLeft(moreThanADayLeft);
      setTimeLeft(secondsLeft);
    }, SECOND_IN_MILLISECONDS);

    return () => clearTimeout(timer);
  }, [timeLeft, isEndingSoon, start, endDate]);

  return [formatTime(timeLeft), isEndingSoon, isMoreThanADayLeft];
};
