import styled from 'styled-components';
import { Icon } from './Icon/Icon';

interface IToastCloseButton {
  closeToast: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const CloseButton = styled.button({
  background: 'none',
  border: 'none',
  outline: 'none',
  height: 14,
  padding: 8,
  margin: 0,
  cursor: 'pointer',
});

export const ToastCloseButton = ({ closeToast }: IToastCloseButton) => (
  <CloseButton onClick={closeToast}>
    <Icon name="close" />
  </CloseButton>
);
