import { useState } from 'react';
import styled, { css } from 'styled-components';

import { Text } from '@/components/Text';
import { ReactComponent as DoubleArrowDown } from '@/assets/icons/double-arrow-down.svg';
import { LoaderIcon } from '@/components/SVGs/LoaderIcon';

export interface IHeader {
  name: keyof IDataItem;
  component: string | JSX.Element;
}

export interface IDataItem {
  [key: string]: JSX.Element | string;
  key: string;
}

interface ITable {
  header: IHeader[];
  data: IDataItem[];
  footer?: JSX.Element;
  isLoading?: boolean;
  maxRows?: number;
  noDataText?: string;
}

interface IBottomStyleProps {
  isBottomRounded: boolean;
}

const StyledTable = styled.table(({ theme }) => ({
  width: '100%',
  borderCollapse: 'separate',
  border: `1px solid ${theme.colors.purple.primary['600']}`,
  borderRadius: 10,
}));

const HeaderRow = styled.tr({
  borderBottom: 'none',
  padding: '12px 16px',
});

const ComponentStyle = () => css`
  white-space: nowrap;
  text-align: left;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  padding-left: 16px;

  &:first-of-type {
    border-left: none;
  }

  &:last-child {
    padding-right: 16px;
  }
`;

const LoadMoreButton = styled.button({
  position: 'relative',
  width: 80,
  height: 20,
});

const ButtonText = styled.span(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  fontWeight: 500,
  lineHeight: '14px',
  fontSize: 12,
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
}));

const StyledLoadMoreIcon = styled(DoubleArrowDown)({
  marginLeft: 8,
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
});

const HeaderComponent = styled.th`
  color: ${({ theme }) => theme.colors.purple.primary['200']};
  padding: 12px 0;
  ${ComponentStyle}
`;

const RowComponent = styled.td<IBottomStyleProps>`
  color: ${({ theme }) => theme.colors.purple.primary['50']};
  border-top: 1px solid ${({ theme }) => theme.colors.background};
  padding: 16px 0;
  ${ComponentStyle}

  :last-child {
    border-bottom-right-radius: ${({ isBottomRounded }) => (isBottomRounded ? '8px' : 0)};
  }

  :first-child {
    border-bottom-left-radius: ${({ isBottomRounded }) => (isBottomRounded ? '8px' : 0)};
  }
`;

const ContentRow = styled.tr((props) => ({
  backgroundColor: props.theme.colors.purple.primary['600'],
  borderBottom: `1px solid ${props.theme.colors.background}`,
}));

const MessageContainer = styled.td<IBottomStyleProps>(({ theme, isBottomRounded }) => ({
  backgroundColor: theme.colors.purple.primary['600'],
  borderTop: `1px solid ${theme.colors.background}`,
  borderBottomLeftRadius: isBottomRounded ? 10 : 0,
  borderBottomRightRadius: isBottomRounded ? 10 : 0,
  textAlign: 'center',
  padding: '18px 0',
}));

const LoadMoreContainer = styled(MessageContainer)({
  padding: '12px 0 18px 0',
});

const FooterContainer = styled(MessageContainer)({
  backgroundColor: 'transparent',
});

MessageContainer.defaultProps = { colSpan: 3 };

export const Table = ({ header, data, isLoading, footer, maxRows = 5, noDataText = 'No Data', ...rest }: ITable) => {
  const [visibleItemsNumber, setVisibleItemsNumber] = useState(maxRows);

  const handleLoadMoreClick = () => {
    setVisibleItemsNumber((prev) => prev + 5);
  };

  return (
    <StyledTable cellSpacing={0} {...rest}>
      <thead>
        <HeaderRow>
          {header.map((col) => (
            <HeaderComponent key={col.name as string}>{col.component}</HeaderComponent>
          ))}
        </HeaderRow>
      </thead>
      <tbody>
        {isLoading && (
          <tr>
            <MessageContainer colSpan={header.length} isBottomRounded={!footer}>
              <LoaderIcon className="mx-auto" />
            </MessageContainer>
          </tr>
        )}
        {!isLoading &&
          data.slice(0, visibleItemsNumber).map((val, index) => (
            <ContentRow key={val.key}>
              {header.map((col) => (
                <RowComponent isBottomRounded={index === data.length - 1 && !footer} key={col.name as string}>
                  {val[col.name]}
                </RowComponent>
              ))}
            </ContentRow>
          ))}
        {!data.length && !isLoading && (
          <tr>
            <MessageContainer colSpan={header.length} isBottomRounded={!footer}>
              <Text type="p_xs" align="center" weight={400}>
                {noDataText}
              </Text>
            </MessageContainer>
          </tr>
        )}
        {data.length > visibleItemsNumber && (
          <tr>
            <LoadMoreContainer isBottomRounded={!footer} colSpan={header.length}>
              <LoadMoreButton onClick={handleLoadMoreClick}>
                <ButtonText>Load more</ButtonText> <StyledLoadMoreIcon />
              </LoadMoreButton>
            </LoadMoreContainer>
          </tr>
        )}
      </tbody>
      {!!footer && (
        <tfoot>
          <tr>
            <FooterContainer isBottomRounded colSpan={header.length}>
              {footer}
            </FooterContainer>
          </tr>
        </tfoot>
      )}
    </StyledTable>
  );
};
