import styled from 'styled-components';

import { Icon } from '@/components/Icon/Icon';
import hideable from '@/utils/hideable';

const ContestDisclaimerTag = styled.div(({ theme }) => ({
  color: theme.colors.state.info,
  border: `1px solid ${theme.colors.state.info}`,
  fontSize: 12,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  padding: '14px 12px',
  marginTop: 20,
  svg: {
    marginRight: 7,
  },
}));

const AppleDisclaimerTagComponent = () => {
  return (
    <ContestDisclaimerTag>
      <Icon name="toastInfo" height={17} width={17} />
      Apple is not involved in any way with this contest
    </ContestDisclaimerTag>
  );
};

export const AppleDisclaimerTag = hideable(AppleDisclaimerTagComponent);
