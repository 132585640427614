import styled from 'styled-components';
import { Icon } from '@/components/Icon/Icon';

const CompleteIndicator = styled.div<{ isCompleted: boolean }>(({ theme, isCompleted }) => ({
  position: 'absolute',
  width: 10,
  height: 10,
  margin: 'auto',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  borderRadius: '50%',
  backgroundColor: isCompleted ? theme.colors.yellow.primary['500'] : theme.colors.purple.primary['300'],
}));

const MilestoneContainer = styled.div<{ isCompleted: boolean; isClaimed: boolean }>(
  ({ theme, isCompleted, isClaimed }) => ({
    backgroundColor: theme.colors.darkPurple,
    position: 'relative',
    marginBottom: 12,
    padding: '17px 15px',
    borderRadius: 8,
    display: 'flex',
    color: isClaimed ? theme.colors.purple.primary['300'] : theme.colors.purple.primary['100'],
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: 152,
    zIndex: 1,
    svg: {
      opacity: isClaimed ? 0.6 : 1,
    },
    ['::after']: {
      content: '""',
      position: 'absolute',
      width: 3,
      backgroundColor: isCompleted ? theme.colors.yellow.primary['500'] : theme.colors.purple.primary['300'],
      height: 'calc(100% + 12px)',
      left: 0,
      right: 0,
      bottom: -12,
      margin: 'auto',
    },
    ':first-of-type': {
      marginTop: 40,
      ['::after']: {
        top: -50,
        background: `linear-gradient(#43164d 20% ,#FFD500 40%, ${
          isCompleted ? theme.colors.yellow.primary['500'] : theme.colors.purple.primary['300']
        } 40% 100%)`,
        height: 'calc(100% + 100px)',
      },
    },
    ':last-of-type': {
      border: `1px solid ${theme.colors.purple.primary['300']}`,
      svg: {
        width: 23,
      },
      ['::after']: {
        top: -30,
        bottom: 0,
        height: '50%',
      },
    },
  })
);

export const TimeMilestone = ({
  time,
  isCompleted,
  isClaimed,
  credits,
}: {
  time: number;
  isCompleted: boolean;
  isClaimed: boolean;
  credits: number;
}) => {
  return (
    <MilestoneContainer isCompleted={isCompleted} isClaimed={isClaimed}>
      <div className="text-xs">{time / 60} min</div>
      <div className="flex text-sm items-center">
        <Icon name="credit" width={20} className="mr-0.5" /> {credits}
      </div>
      <CompleteIndicator isCompleted={isCompleted} />
    </MilestoneContainer>
  );
};
