import styled from 'styled-components';

import { ReferralForm } from './ReferralForm';
import { PageHeader } from '@/components/PageHeader';

const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledPageHeader = styled(PageHeader)({
  marginBottom: 32,
});

export const SettingsReferralForm = () => (
  <Container>
    <StyledPageHeader title="Enter referral code" />
    <ReferralForm />
  </Container>
);
