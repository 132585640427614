import { XS_WIDTH } from '@/utils/constants/windowWidth';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const ListContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 5px 5px 0px #000000;
  border-radius: 10px;
  overflow: hidden;
`;

export const ListContainerHeader = styled.div`
  background: #e6dbec;
  border-bottom: 1px solid #000000;
  padding: 12px 25px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;

  @media (max-width: ${XS_WIDTH}px) {
    padding: 12px 15px;
  }
`;

export const ListContainerFooter = styled.div`
  background: #cfa7f4;
  border-top: 1px solid #000000;
  padding: 27px 24px;

  @media (max-width: ${XS_WIDTH}px) {
    padding: 27px 15px;
  }
`;

interface IListContainerEntry extends HTMLAttributes<HTMLDivElement> {
  height?: number;
  state?: 'success' | 'error';
}

const resolveState = (state: IListContainerEntry['state']) => {
  if (!state) {
    return undefined;
  }

  return {
    success: '#D6EED9',
    error: 'red',
  }[state];
};

export const ListContainerEntry = styled.div<IListContainerEntry>`
  border-bottom: 1px dashed #000000;
  background-color: ${({ state }) => resolveState(state) || 'white'};
  padding: 27px 24px;
  ${({ height }) => height && `height: ${height}px;`}

  &:nth-child(2n) {
    background-color: #f6f2f8;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: ${XS_WIDTH}px) {
    padding: 27px 15px;
  }
`;
