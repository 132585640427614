import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { BottomModal } from '@/components/BottomModal';
import { Button } from '@/components/Button/Button';
import { Icon } from '@/components/Icon/Icon';
import { LINKS } from '@/utils/constants/common';

import { isVersionModalOpen } from '../selectors';
import { setVersionModalVisibility } from '../slice';
import { useOpenLink } from '@/utils/hooks/useOpenLink';

const StyledBottomModal = styled(BottomModal)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '48px 32px',
});

const Heading = styled.h2(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  fontSize: 20,
  fontWeight: 600,
}));

const Paragraph = styled.div(({ theme }) => ({
  color: theme.colors.purple.primary['200'],
  fontSize: 14,
  marginBottom: 12,
  fontWeight: 400,
  ':last-of-type': {
    marginBottom: 16,
  },
}));

const StyledButton = styled(Button)({
  width: '100%',
  marginTop: 24,
});

const StyledIcon = styled(Icon)({
  marginLeft: 8,
});

export const AppVersionInfo = () => {
  const dispatch = useDispatch();
  const openLink = useOpenLink();

  const isVisible = useSelector(isVersionModalOpen);

  const handleClose = () => {
    dispatch(setVersionModalVisibility(false));
  };

  const onFeedbackClick = () => {
    openLink(LINKS.FEEDBACK);
  };

  const onBugReportClick = () => {
    window.open(LINKS.CHATBASE_BOT);
  };

  return (
    <StyledBottomModal type="secondary" onClose={handleClose} isVisible={isVisible}>
      <div>
        <div className="flex items-center ml-0 mr-auto mb-4">
          <Heading>Alpha version</Heading>
        </div>
        <Paragraph>
          This is an early version of an app, that may be not fully functional, may contain bugs and is still being
          actively developed!
        </Paragraph>
        <Paragraph>
          Your feedback is extremely valuable to us, as we’re building the future of gaming! Your contributions will be
          rewarded appropriately!
        </Paragraph>
        <StyledButton buttonType="secondary" onClick={onFeedbackClick}>
          Share feedback <StyledIcon name="externalLink" />
        </StyledButton>
        <StyledButton buttonType="secondary" onClick={onBugReportClick}>
          Report a bug <StyledIcon name="externalLink" />
        </StyledButton>
      </div>
    </StyledBottomModal>
  );
};
