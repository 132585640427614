import styled from 'styled-components';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  height: '100%',
});

const Logo = styled.img({
  width: '80%',
});

export const GameCardLogo = ({ logoUrl }: { logoUrl: string }) => (
  <Container>
    <Logo src={logoUrl} alt="Logo" />
  </Container>
);
