import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME, TABS } from './constants';

interface IContest {
  activeTab: string;
  ticketsAmount: number;
  isProvablyFairModalVisible: boolean;
}

const initialState: IContest = {
  activeTab: TABS.ENTRY,
  ticketsAmount: 0,
  isProvablyFairModalVisible: false,
};

export const contestSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    setTicketsAmount: (state, action: PayloadAction<number>) => {
      state.ticketsAmount = action.payload;
    },
    setProvablyFairModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isProvablyFairModalVisible = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setActiveTab, setTicketsAmount, setProvablyFairModalVisible, resetState } = contestSlice.actions;
