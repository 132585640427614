import styled from 'styled-components';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

import hideable from '@/utils/hideable';

import { ShopCard, IOnClickProps } from './ShopCard';
import { useGiftCardsByType } from '../queries';

const Container = hideable(
  styled.div({
    width: '100%',
    overflow: 'hidden',
    marginBottom: 16,
  })
);

const Title = styled.h3(({ theme }) => ({
  color: theme.colors.purple.primary[200],
  fontSize: 14,
  fontWeight: 600,
  marginLeft: 32,
  marginBottom: 8,
}));

const StyledSwiperSlide = styled(SwiperSlide)({
  width: 124,
  marginLeft: 16,
  ':first-of-type': {
    marginLeft: 24,
  },
  ':last-of-type': {
    marginRight: 24,
  },
});

export const GooglePlayCarousel = () => {
  const navigate = useNavigate();

  const { data } = useGiftCardsByType('googleplay');

  const onCardClick = ({ id, type }: IOnClickProps) => {
    navigate('/gift-card-shop-item', { state: { type, id } });
  };

  return (
    <Container isVisible={!!data?.length}>
      <Title>Google Play gift cards</Title>
      <SwiperComponent slidesPerView="auto">
        {data?.map((giftCard) => (
          <StyledSwiperSlide key={giftCard.id}>
            <ShopCard
              id={giftCard.id}
              type="googleplay"
              onClick={onCardClick}
              value={giftCard.payoutAmountLocalCurrency}
              price={giftCard.creditAmount}
              creditPriceLabel={giftCard.creditPriceLabel}
              currencyCode={giftCard.currencyCode}
            />
          </StyledSwiperSlide>
        ))}
      </SwiperComponent>
    </Container>
  );
};
