import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Text } from '@/components/Text';
import { Icon } from '@/components/Icon/Icon';
import { Button } from '@/components/Button/Button';
import { TimeProgressIndicator } from '@/components/TimeLeftIndicator';
import { TicketsProgressIndicator } from '@/components/TicketsProgressIndicator';
import { ProvablyFairBadge } from '@/components/ProvablyFairBadge';
import { EndedIndicator } from '@/components/EndedIndicator';
import { TABS } from '@/features/Contest/constants';
import { ReactComponent as ComingSoonImg } from '@/assets/images/coming_soon_green.svg';

import { IContest } from '../types';
import { ContestThumbnail } from './ContestThumbnail';

interface IStyledContestItemProps {
  isMarked: boolean;
}

const Container = styled.div<IStyledContestItemProps>(({ isMarked, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: 252,
  width: '100%',
  overflow: 'hidden',
  cursor: 'pointer',
  border: `1px solid ${isMarked ? theme.colors.yellow.primary['500'] : theme.colors.purple.primary['600']}`,
  borderRadius: 9,
  margin: '8px 0',
}));

const Footer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto',
  padding: '16px 10px',
  backgroundColor: 'rgba(52, 12, 83, 0.8)',
  overflow: 'hidden',
  zIndex: 1,
  borderTop: `1px solid ${theme.colors.purple.primary['600']}`,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}));

const StyledButton = styled(Button)({
  maxHeight: 32,
});

const ButtonText = styled(Text)(({ theme }) => ({
  color: theme.colors.neutral.primary['1000'],
  textWrap: 'nowrap',
  whiteSpace: 'nowrap',
}));

const InformationButtonText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
}));

const UserEntryContentContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const UserEntryInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const UserEntryButton = styled(Button)({
  width: 32,
  height: 32,
  marginLeft: 16,
  fontSize: 24,
  fontWeight: 300,
  borderRadius: 6,
});

const UserEntryText = styled(Text)(({ theme }) => ({
  color: theme.colors.purple.primary['50'],
  whiteSpace: 'nowrap',
}));

const Title = styled(Text)({
  marginRight: 8,
});

const StyledComingSoonImg = styled(ComingSoonImg)({
  margin: '12px 0',
});

export const ContestItem = ({
  image,
  title,
  type,
  isProvablyFair,
  isEnded,
  isSoon,
  endTime = 0,
  currentTicketsAmount = 0,
  maxTicketsAmount = 0,
  userEntry,
  uid,
}: IContest) => {
  const navigate = useNavigate();

  const handleOpenContest = () => {
    if (!isSoon) {
      navigate(`/contest/${uid}/${isEnded ? TABS.WINNERS : TABS.ENTRY}`);
    }
  };

  return (
    <Container isMarked={!!userEntry} onClick={handleOpenContest}>
      <ContestThumbnail image={image} />
      <TicketsProgressIndicator
        isVisible={type === 'tickets' && !isEnded && !isSoon}
        currentTicketsAmount={currentTicketsAmount}
        maxTicketsAmount={maxTicketsAmount}
      />
      <TimeProgressIndicator isVisible={type === 'time' && !isEnded && !isSoon} endTime={endTime} />
      <EndedIndicator isVisible={isEnded} />
      <ProvablyFairBadge isVisible={isProvablyFair && !isSoon} />
      <Footer>
        <Title type="p_s" className="z-10" weight={600}>
          {title}
        </Title>
        {!!userEntry && !isEnded && !isSoon && (
          <UserEntryContentContainer>
            <UserEntryInfoContainer>
              <UserEntryText type="p_xs" weight={400} align="right">
                Your entry
              </UserEntryText>
              <UserEntryContentContainer>
                <Icon name="ticketSmall" />
                <Text type="p_l" weight={600} align="right">
                  {userEntry}
                </Text>
              </UserEntryContentContainer>
            </UserEntryInfoContainer>
            <UserEntryButton>+</UserEntryButton>
          </UserEntryContentContainer>
        )}
        {!userEntry && !isEnded && !isSoon && (
          <StyledButton>
            <Icon name="ticketSmall" />
            <ButtonText type="p_s" weight={500}>
              Add tickets
            </ButtonText>
          </StyledButton>
        )}
        {isEnded && (
          <StyledButton buttonType="secondary">
            <InformationButtonText type="p_s" weight={500}>
              Information
            </InformationButtonText>
          </StyledButton>
        )}
        {isSoon && <StyledComingSoonImg />}
      </Footer>
    </Container>
  );
};
