import { useChallenges } from '../queries';
import Skeleton from 'react-loading-skeleton';
import { SocialMediaEntry } from './SocialMediaEntry';

export const FollowUs = ({ title }: { title: string | null }) => {
  const { mediaChallenges } = useChallenges();

  if (!mediaChallenges.isLoading && !mediaChallenges.data?.length) {
    return null;
  }

  return (
    <div className="mb-12 mt-6">
      {title ? (
        <h3 className="font-semibold mb-2 text-sm text-[#BAA1D2]">Follow {title}</h3>
      ) : (
        <h3 className="font-semibold mb-2 text-sm text-[#BAA1D2]">Follow us</h3>
      )}
      {mediaChallenges.isLoading && (
        <Skeleton
          count={3}
          height={130}
          className="rounded-lg w-1/3 mr-3.5 last-of-type:mr-0"
          containerClassName="w-full flex"
        />
      )}
      <div className="flex">
        {!!mediaChallenges?.data?.length &&
          mediaChallenges?.data?.map((challenge, index) => (
            <SocialMediaEntry key={index} socialMediaChallenge={challenge} />
          ))}
      </div>
    </div>
  );
};
