import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Icon, IconProps } from '@/components/Icon/Icon';
import { Text } from '@/components/Text';
import { BREAKPOINTS, DESKTOP_CARD_DIMENSIONS } from '@/theme/constants';
import { isApp } from '@/utils/isApp';
import hideable from '@/utils/hideable';

interface ILink {
  name: string;
  to: string;
  icon: IconProps['name'];
}

interface IStyleProps {
  isActive: boolean;
}

const NavbarWrapper = styled.div({
  backgroundColor: 'rgba(50, 5, 84, 0.95)',
  padding: isApp() ? `16px 20px 32px 14px` : `10px 20px 10px 14px`,
  height: isApp() ? 92 : 69,
  display: 'flex',
  justifyContent: 'space-around',
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  zIndex: 20,
  borderTop: '1px solid #521383',
  [BREAKPOINTS.SM_WIDTH]: {
    left: 'unset',
    bottom: 'unset',
    right: 'unset',
    width: DESKTOP_CARD_DIMENSIONS.WIDTH,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
});

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  color: theme.colors.purple.primary['300'],
  width: 85,
  height: 49,
}));

const Title = styled(Text)<IStyleProps>(({ theme, isActive }) => ({
  fontSize: 11,
  color: isActive ? theme.colors.neutral.primary['50'] : theme.colors.purple.primary['300'],
  marginTop: 5,
}));

const DesktopWrapper = styled.div({
  [BREAKPOINTS.SM_WIDTH]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: isApp() ? 92 : 69,
  },
});

const navLinks: Array<ILink> = [
  { name: 'Discovery', to: '/games', icon: 'discovery' },
  { name: 'Referrals', to: '/referrals', icon: 'referrals' },
  { name: 'Contests', to: '/contests', icon: 'trophy' },
  { name: 'Shop', to: '/shop', icon: 'goodies' },
];

export const NavbarComponent = () => (
  <DesktopWrapper>
    <NavbarWrapper>
      {navLinks.map(({ name, to, icon }) => (
        <StyledNavLink key={to} to={to}>
          {({ isActive }) => (
            <>
              <Icon name={icon} height={30} width={30} />
              <Title className="title" type="p_s" weight={600} isActive={isActive}>
                {name}
              </Title>
            </>
          )}
        </StyledNavLink>
      ))}
    </NavbarWrapper>
  </DesktopWrapper>
);

export const Navbar = hideable(NavbarComponent);
