import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

interface IAuth {
  shouldRefreshToken: boolean;
  isAuthLoading: boolean;
  isEmailVerified: boolean;
}

const initialState: IAuth = {
  shouldRefreshToken: false,
  isAuthLoading: false,
  isEmailVerified: false,
};

export const authSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setShouldRefreshToken: (state, action: PayloadAction<boolean>) => {
      state.shouldRefreshToken = action.payload;
    },
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isAuthLoading = action.payload;
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.isEmailVerified = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setShouldRefreshToken, setEmailVerified, setAuthLoading, resetState } = authSlice.actions;
