import { ActivationTutorial as ActivationTutorialComponent } from '@/features/ActivationTutorial/components/ActivationTutorial';
import styled from 'styled-components';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
});

export const ActivationTutorial = () => (
  <Container>
    <ActivationTutorialComponent />
  </Container>
);
