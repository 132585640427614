import { useSelector } from 'react-redux';
import { getFirestore, getDoc, doc, Timestamp } from 'firebase/firestore';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { firebaseApp, httpsFunction } from '@/utils/firebase';
import {
  getAppsflyerAndroidId as getAppsflyerAndroidIdSelector,
  getAppsflyerIosId as getAppsflyerIosIdSelector,
  getAppsflyerWebId as getAppsflyerWebIdSelector,
  getFirebaseAndroidId as getFirebaseAndroidIdSelector,
  getFirebaseIosId as getFirebaseIosIdSelector,
  getFirebaseWebId as getFirebaseWebIdSelector,
} from '@/features/App/selectors';

export const useUserLocation = (userId: string | undefined) =>
  useQuery({
    queryKey: ['useUserLocation'],
    enabled: !!userId,
    queryFn: async () => {
      const userData = (await getDoc(doc(getFirestore(firebaseApp), `users/${userId}`)))?.data();

      const userContinent: string = userData?.continentCode || '';
      const userCountry: string = userData?.countryCode || '';

      return { userContinent, userCountry };
    },
  });

export const useUserDailyActivity = (userId: string | undefined) =>
  useQuery({
    queryKey: ['useUserDailyActivity'],
    enabled: !!userId,
    queryFn: async () => {
      const userData = (await getDoc(doc(getFirestore(firebaseApp), `users/${userId}`)))?.data();

      const lastDailyActivityReward: Timestamp | null = userData?.lastDailyActivityReward || null;

      return lastDailyActivityReward;
    },
  });

export const useGrantDailyCreditReward = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const grantDailyActivityCreditReward = httpsFunction('grantDailyActivityCreditReward');

      await grantDailyActivityCreditReward();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['useUserDailyActivity'] });
      },
    }
  );
};

export const useHandleUserSignIn = () => {
  const appsflyerAndroidId = useSelector(getAppsflyerAndroidIdSelector);
  const appsflyerIosId = useSelector(getAppsflyerIosIdSelector);
  const appsflyerWebId = useSelector(getAppsflyerWebIdSelector);
  const firebaseAndroidId = useSelector(getFirebaseAndroidIdSelector);
  const firebaseIosId = useSelector(getFirebaseIosIdSelector);
  const firebaseWebId = useSelector(getFirebaseWebIdSelector);

  return useMutation(async () => {
    const handleAfterUserSignIn = httpsFunction('handleAfterUserSignIn');

    await handleAfterUserSignIn({
      appsflyerAndroidId,
      appsflyerIosId,
      appsflyerWebId,
      firebaseAndroidId,
      firebaseIosId,
      firebaseWebId,
    });
  });
};

export const useHandleAfterUserRegistration = () => {
  const appsflyerAndroidId = useSelector(getAppsflyerAndroidIdSelector);
  const appsflyerIosId = useSelector(getAppsflyerIosIdSelector);
  const appsflyerWebId = useSelector(getAppsflyerWebIdSelector);
  const firebaseAndroidId = useSelector(getFirebaseAndroidIdSelector);
  const firebaseIosId = useSelector(getFirebaseIosIdSelector);
  const firebaseWebId = useSelector(getFirebaseWebIdSelector);

  return useMutation(async () => {
    const handleAfterUserRegistration = httpsFunction('handleAfterUserRegistration');

    handleAfterUserRegistration({
      appsflyerAndroidId,
      appsflyerIosId,
      appsflyerWebId,
      firebaseAndroidId,
      firebaseIosId,
      firebaseWebId,
    });
  });
};
