import { applyActionCode, confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { useMutation } from 'react-query';
import * as mixpanel from 'mixpanel-browser';

import { firebaseAuth } from '@/utils/firebase';

export const useVerifyEmail = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation(
    async ({ actionCode }: { actionCode: string }) => {
      await applyActionCode(firebaseAuth, actionCode);
    },
    {
      onSuccess: () => {
        mixpanel.track('email-verified');
        onSuccess();
      },
    }
  );
};

interface IResetPasswordParams {
  password: string;
  actionCode: string;
}

export const useResetPassword = ({ onSuccess }: { onSuccess: () => void }) =>
  useMutation(
    async ({ password, actionCode }: IResetPasswordParams) => {
      await verifyPasswordResetCode(firebaseAuth, actionCode);
      await confirmPasswordReset(firebaseAuth, actionCode, password);
    },
    {
      onSuccess: () => {
        mixpanel.track('password-reset');
        onSuccess();
      },
    }
  );
