import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NAME, ONBOARDING_STEPS } from './constants';

export interface IOnboarding {
  onboardingStep: ONBOARDING_STEPS;
  isOnboardingCompleted: boolean;
}

const initialState: IOnboarding = {
  onboardingStep: ONBOARDING_STEPS.GAMES,
  isOnboardingCompleted: false,
};

export const onboardingSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setOnboardingStep: (state, action: PayloadAction<ONBOARDING_STEPS>) => {
      state.onboardingStep = action.payload;
    },
    setOnboardingCompleted: (state, action: PayloadAction<boolean>) => {
      state.isOnboardingCompleted = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setOnboardingStep, setOnboardingCompleted, resetState } = onboardingSlice.actions;
