import styled from 'styled-components';
import { BackButton } from './BackButton';
import { Text } from './Text';
import { Icon } from './Icon/Icon';

interface IPageHeader {
  title: string;
  onBack?: () => void;
  isSettingsVisible?: boolean;
  onSettingsClick?: () => void;
  className?: string;
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: 32,
  position: 'relative',
  width: '100%',
});

const StyledBackButton = styled(BackButton)({
  position: 'absolute',
  maxWidth: 60,
  top: 0,
  left: 0,
  bottom: 0,
  margin: 0,
});

const SettingsButton = styled.button({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  margin: 0,
  background: 'none',
  border: 'none',
  outline: 'none',
});

const Title = styled(Text)({
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const PageHeader = ({ title, onBack, isSettingsVisible, onSettingsClick, ...rest }: IPageHeader) => (
  <Container {...rest}>
    <StyledBackButton onBack={onBack} />
    <Title type="h3" weight={600}>
      {title}
    </Title>
    {isSettingsVisible && (
      <SettingsButton onClick={onSettingsClick}>
        <Icon name="settings" />
      </SettingsButton>
    )}
  </Container>
);
