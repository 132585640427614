import styled from 'styled-components';

import noNewNotifications from '@/assets/images/no-new-notifications.png';
import hideable from '@/utils/hideable';
import { Text } from '@/components/Text';

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '120px 52px 0 52px',
});

const Image = styled.img({
  maxWidth: 180,
});

const Title = styled(Text)({
  marginBottom: 4,
});

export const NoNotificationsComponent = () => (
  <Container>
    <Image src={noNewNotifications} />
    <Title type="h3" align="center" weight={600}>
      No new notifications
    </Title>
    <Text colorType="light" type="p_m" align="center">
      If something important happens, the bell will ring
    </Text>
  </Container>
);

export const NoNotifications = hideable(NoNotificationsComponent);
