import { useQuery } from 'react-query';
import { getDoc, doc, getFirestore } from 'firebase/firestore';

import { useAuth } from '@/features/Auth/hooks';
import { firebaseApp } from '@/utils/firebase';

export const useVpnStatus = (userId: string | undefined) =>
  useQuery({
    queryKey: ['useVpnStatus'],
    enabled: !!userId,
    queryFn: async () => {
      const userData = (await getDoc(doc(getFirestore(firebaseApp), `users/${userId}`)))?.data();

      const isUsingVpn = userData?.isUsingVpn || false;

      return isUsingVpn;
    },
  });

export const getVpnStatus = () => {
  const { uid } = useAuth();
  const { data, ...rest } = useVpnStatus(uid);

  return { isUsingVpn: data, ...rest };
};
