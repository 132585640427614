import styled from 'styled-components';

import ChestOpen from '@/assets/visuals/chest_open.svg';

const Visual = styled.div({
  backgroundImage: `url(${ChestOpen})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: 85,
  marginTop: 67,
  marginBottom: 15,
});

const StyledText = styled.div(({ theme }) => ({
  color: theme.colors.purple.primary['100'],
  fontSize: 16,
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: 57,
}));

export const DailyPlayCompleted = () => {
  return (
    <>
      <Visual />
      <StyledText>
        You’ve collected all available <br /> Daily Play rewards
      </StyledText>
    </>
  );
};
