import styled from 'styled-components';

interface ICard {
  children: React.ReactNode;
}

export const CardWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 430px;
  width: 100%;
`;

export const Card = ({ children, ...rest }: ICard) => <CardWrapper {...rest}>{children}</CardWrapper>;
