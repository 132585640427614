import styled from 'styled-components';
import SwordsVisual from '@/assets/visuals/swords_visual.png';
import { BLCreditValue } from '@/components/BLCreditValue/BLCreditValue';

const SwordsVisualWrapper = styled.div`
  height: 18px;
  width: 18px;
  background-image: url(${SwordsVisual});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
`;

export const TournamentBoardEntry = ({
  place,
  playerName,
  trophies,
  rewardType,
  rewardValue,
}: {
  place: string | number;
  playerName: string;
  trophies: string | number;
  rewardType: 'USD' | 'BL_CREDIT';
  rewardValue: number;
}) => {
  return (
    <div className="flex p-4 xxs:p-6 border-b border-gray border-dashed last:border-none">
      <div className="w-16 font-semibold">{place}.</div>
      <div className="w-2/4 flex items-center">{playerName}</div>
      <div className="w-1/4 flex font-semibold items-center justify-center">
        <SwordsVisualWrapper /> {trophies}
      </div>
      <div className="w-1/4 font-semibold flex flex-col items-end justify-center">
        <div>
          {rewardType === 'USD' ? (
            `${rewardValue}`
          ) : (
            <BLCreditValue iconSize={17} iconClass="mr-1" value={rewardValue} />
          )}
        </div>
      </div>
    </div>
  );
};
