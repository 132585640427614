import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    background: '#320554',
    gradient: 'linear-gradient(180deg, #E78BF6 0%, #9867FF 100%)',
    darkPurple: '#3A1259',
    red: '#E25252',
    discord: '#5865F2',
    purple: {
      primary: {
        '900': '#320554',
        '800': '#250F36',
        '700': '#320554',
        '600': '#521383',
        '500': '#7625B4',
        '400': '#8B39CF',
        '350': '#633C84',
        '300': '#9473B5',
        '200': '#BAA1D2',
        '100': '#DBC9E8',
        '50': '#F1E7FA',
      },
    },
    neutral: {
      primary: {
        '1000': '#000000',
        '900': '#181818',
        '800': '#2D2D2D',
        '700': '#434343',
        '600': '#5B5B5B',
        '500': '#757575',
        '400': '#8E8E8E',
        '300': '#AAAAAA',
        '200': '#C5C5C5',
        '100': '#E2E2E2',
        '0': '#FFFFFF',
      },
    },
    green: {
      primary: {
        '600': '#489B5F',
        '500': '#AAEE51',
        '50': '#F2FDE5',
      },
    },
    yellow: {
      primary: {
        '500': '#FFD500',
        '600': '#FFEC8C',
        '50': '#FFFBE5',
      },
    },
    blue: {
      primary: {
        '500': '#5ADEE7',
        '50': '#E9FBFC',
      },
    },
    pink: {
      primary: {
        '500': '#E65EFA',
        '400': '#E380F2',
        '50': '#FAE2FD',
      },
    },
    grey: {
      light: '#E7E7E7',
    },
    state: {
      error: '#E25252',
      success: '#7CB036',
      info: '#647DB9',
    },
  },
  boxShadow: {
    primary: '2px 2px 0px #000000',
    thin: '1px 1px 0px 0px #000000',
  },
};
