import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Button } from '@/components/Button/Button';
import { FormInput } from '@/components/Input/FormInput';
import { ErrorMessage as CustomErrorMessage } from '@/components/ErrorMessage/ErrorMessage';
import { Icon } from '@/components/Icon/Icon';

const RegisterFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    .required('You must repeat your password'),
});

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export interface IEmailRegisterFormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

export interface IEmailRegisterForm {
  onSubmit(values: IEmailRegisterFormValues, formikHelpers: FormikHelpers<IEmailRegisterFormValues>): void;
  submitText: string;
}

export const EmailRegisterForm = ({ onSubmit, submitText }: IEmailRegisterForm) => {
  return (
    <Formik
      initialValues={{ email: '', password: '', passwordConfirmation: '' }}
      validationSchema={RegisterFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => (
        <StyledForm>
          <FormInput
            label="Email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            suffix={dirty && (errors.email ? <Icon name="cross" /> : <Icon name="successTick" />)}
          />

          <ErrorMessage
            name="email"
            render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
          />
          <FormInput
            label="Password"
            name="password"
            type="password"
            className="mt-3"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />

          <ErrorMessage
            name="password"
            render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
          />

          <FormInput
            label="Confirm password"
            name="passwordConfirmation"
            type="password"
            className="mt-3"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirmation}
          />

          <ErrorMessage
            name="passwordConfirmation"
            render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
          />
          <Button className="mt-5" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
};
