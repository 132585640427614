import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { httpsFunction } from '@/utils/firebase';
import { logout } from '@/features/Auth/actions';

export const useDeleteAccount = () => {
  const dispatch = useDispatch();
  return useMutation(
    async () => {
      const deleteUser = httpsFunction('deleteUser');
      await deleteUser();
    },
    {
      onSuccess: () => {
        dispatch(logout());
      },
    }
  );
};
